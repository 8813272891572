<template>
  <div class="row m-0">
    <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
      <InputBox
          placeholder="Поиск монеты"
          class="mb-3"
          v-model="searchCurrency"
      />
    </div>
    <SimpleTable
        :data-length="Object.keys(balance).length"
        :columns="['Монета', 'Основной', 'Трейд', 'Стейкинг']"
        class="col-lg-12 mb-3"
    >
      <tr v-for="currency, index in filteredCoinList" :key="'currency' + index">
        <td>
          <div style="display: flex; align-items: center; width: 60px;">
            <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
              <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(currency)" alt="">
            </div>
            {{ $coinName(currency) }}
          </div>
        </td>
        <td>
          <div class="form-control mb-0">
            <input step="0.01" type="number" v-model="balance[currency].fundingWallet">
          </div>
        </td>
        <td>
          <div class="form-control mb-0">
            <input step="0.01" type="number" v-model="balance[currency].spotWallet">
          </div>
        </td>
        <td>
          <div class="form-control mb-0">
            <input step="0.01" type="number" v-model="balance[currency].stakingWallet">
          </div>
        </td>
      </tr>
    </SimpleTable>
    <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-4">
      <Button
          :is-async="true"
          :callback="userUpdateHandler"
          class="primary w-100"
      >
        Обновить
      </Button>
    </div>
  </div>
</template>

<script>
import SimpleTable from "@/components/backoffice/UI/SimpleTable";
import axios from "@/plugins/axios";
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";

export default {
  name: "UserBalance",
  props: {
    userId: String,
    balance: Object
  },
  components: {
    SimpleTable,
    Button,
    InputBox
  },
  data() {
    return {
      searchCurrency: ''
    }
  },
  methods: {
    async userUpdateHandler() {
      try {
        const fetchedData = await axios.post('/backoffice/user/user/updateBalance', {
          userId: this.userId,
          balance: this.balance
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    }
  },
  computed: {
    filteredCoinList() {
      return Object.keys(this.balance).filter(x => x.toLowerCase().includes(this.searchCurrency.toLowerCase()))
    }
  },
  mounted() {
    for (const coin of Object.values(this.balance)) {
      coin.fundingWallet = coin.fundingWallet === 0 ? 0 : coin.fundingWallet.toFixed(7);
      coin.spotWallet = coin.spotWallet === 0 ? 0 : coin.spotWallet.toFixed(7);
      coin.stakingWallet = coin.stakingWallet === 0 ? 0 : coin.stakingWallet.toFixed(7);
    }
  }
}
</script>

<style scoped>

</style>