<template>
  <Page
      :title="'Юзер ' + fetchedData.userInfo.email"
      parse-url="/backoffice/user/user/info"
      :post-data="{ userId: $route.params.userId }"
      @loaded-data="dataLoading($event)"
  >
    <div v-if="isLoaded">

      <div class="col-lg-4 col-md-12 col-xs-12">
        <div class="Card mb-5">
          <div class="Card-content">
            <div class="Card-header bordered">
              <h4>Информация юзера</h4>
            </div>
            <UserTotal
              :user-info="fetchedData.userInfo"
            />
          </div>
        </div>

        <div class="Card mb-5">
          <div class="Card-content">
            <div class="Card-header bordered">
              <h4>Логи</h4>
            </div>
            <UserLogs
                :user-id="$route.params.userId"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-md-12 col-xs-12">
        <div class="Card">
          <div class="Card-content">
            <div class="Card-header p-0 pl-4 pr-4 bordered">
              <div style="flex-wrap: wrap;" class="d-flex">
                <span @click="userMenu.currentTab = tab" class="tabs-link" :class="{ active: tab === userMenu.currentTab }" v-for="tab, index in userMenu.tabs" :key="'tab' + index">{{ tab }}</span>
              </div>
            </div>

            <div class="pt-4" v-show="userMenu.currentTab === 'Данные'">
              <UserInfo
                  :user-id="$route.params.userId"
                  :information="fetchedData.userInfo"
              />
            </div>

            <div class="pt-4" v-show="userMenu.currentTab === 'Баланс'">
              <UserBalance
                  :user-id="$route.params.userId"
                  :balance="fetchedData.userInfo.balance"
              />
            </div>

            <div class="pt-4" v-if="userMenu.currentTab === 'Адреса'">
              <UserAddresses
                  :user-id="$route.params.userId"
                  :wallet-info="fetchedData.userInfo.wallet"
              />
            </div>

            <div class="pt-4" v-if="userMenu.currentTab === 'Транзакции'">
              <UserTransactions
                  :user-id="$route.params.userId"
                  :coin-list="fetchedData.coinList"
              />
            </div>

            <div class="pt-4" v-if="userMenu.currentTab === 'Ордера'">
              <UserOrders
                  :user-id="$route.params.userId"
                  :coin-pair="fetchedData.coinPair"
                  :markets="fetchedData.markets"
              />
            </div>

            <div class="pt-4" v-if="userMenu.currentTab === 'Верификация'">
              <UserVerification
                  :user-id="$route.params.userId"
                  :verification-message="fetchedData.userInfo.errorMessage.verification"
              />
            </div>

            <div class="pt-4" v-if="userMenu.currentTab === 'Уведомления'">
              <UserNotification
                  :user-id="$route.params.userId"
              />
            </div>

            <div class="pt-4" v-if="userMenu.currentTab === 'Сессии'">
              <UserSessions
                  :user-id="$route.params.userId"
              />
            </div>

            <div class="pt-4" v-if="userMenu.currentTab === 'Смарт депозит'">
              <UserSmartDeposit
                  :user-id="$route.params.userId"
                  :information="fetchedData.userInfo"
              />
            </div>

          </div>
        </div>
      </div>

    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import UserLogs from "@/views/BackOffice/User/Users/User/UserLogs";
import UserInfo from "@/views/BackOffice/User/Users/User/UserInfo";
import UserTotal from "@/views/BackOffice/User/Users/User/UserTotal";
import UserBalance from "@/views/BackOffice/User/Users/User/UserBalance";
import UserAddresses from "@/views/BackOffice/User/Users/User/UserAddresses";
import UserTransactions from "@/views/BackOffice/User/Users/User/UserTransactions";
import UserOrders from "@/views/BackOffice/User/Users/User/UserOrders";
import UserVerification from "@/views/BackOffice/User/Users/User/UserVerification";
import UserNotification from "@/views/BackOffice/User/Users/User/UserNotification";
import UserSessions from "@/views/BackOffice/User/Users/User/UserSessions";
import UserSmartDeposit from "./SmartDeposit.vue";

export default {
  name: "User",
  components: {
    UserNotification,
    Page,
    UserTotal,
    UserLogs,
    UserInfo,
    UserBalance,
    UserAddresses,
    UserTransactions,
    UserOrders,
    UserVerification,
    UserSessions,
    UserSmartDeposit
  },
  data() {
    return {
      userMenu: {
        currentTab: 'Данные',
        tabs: [
          'Данные',
          'Баланс',
          'Адреса',
          'Транзакции',
          'Ордера',
          'Верификация',
          'Уведомления',
          'Сессии',
          'Смарт депозит'
        ]
      },
      fetchedData: {
        userInfo: {}
      },
      isLoaded: false
    }
  },
  methods: {
    dataLoading(data) {
      this.fetchedData = data;
      this.isLoaded = true;
    }
  },
}
</script>

<style lang="scss" scoped>

span.tabs-link {
  display: block;
  padding: 16px 16px;
  position: relative;
  color: var(--vz-link-color);
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;

  &.active {
    color: #405189;

    &::after {
      content: "";
      background: #405189;
      height: 1px;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      -webkit-transition: all 250ms ease 0s;
      transition: all 250ms ease 0s;
      -webkit-transform: scale(0);
      transform: scale(1);
    }
  }
}

</style>