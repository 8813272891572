<template>
  <Page
      title="Последние логи юзеров"
      parse-url="/backoffice/user/allLogs"
      @loaded-data="dataLoading($event)"
  >
    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Последние 100 логов</h4>
        </div>
        <SimpleTable
            :data-length="fetchedData.logs.length"
            :columns="['Юзер', 'Действие', 'Айпи', 'Время']"
        >
          <tr v-for="log, index in fetchedData.logs" :key="'logs' + index">
            <td>{{ log.username }}</td>
            <td>{{ log.action }}</td>
            <td>{{ log.ip }}</td>
            <td>{{ log.date | toDate('recent') }}</td>
          </tr>
        </SimpleTable>
      </div>
    </div>
  </Page>
</template>

<script>
import SimpleTable from "@/components/backoffice/UI/SimpleTable";
import Page from "@/components/backoffice/Page";

export default {
  name: "Main",
  components: {
    SimpleTable,
    Page
  },
  data() {
    return {
      fetchedData: {
        logs: []
      },
      logsTableData: [],
      logsTableColumns: [
        {
          name: 'username',
          title: 'Юзер',
          isSortable: true
        },
        {
          name: 'action',
          title: 'Действие',
          isSortable: false
        },
        {
          name: 'ip',
          title: 'Айпи',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
      ],
    }
  },
  methods: {
    dataLoading(data) {
      this.fetchedData = data;
    }
  },
}
</script>

<style scoped>
.hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
</style>