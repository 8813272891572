import Index from '@/views/Index/index';
import Auth from '@/views/Auth';
import Registration from '@/views/Registration';
import HelpCenter from '@/views/HelpCenter/index';
import Dashboard from '@/views/Dashboard/Main/index';
import DashboardLayout from '@/views/Dashboard/Main/template';
import WalletsLayout from '@/views/Dashboard/Wallets/template';
import Wallets from '@/views/Dashboard/Wallets/Main/index';
import Deposit from '@/views/Dashboard/Wallets/Deposit/index';
import Withdraw from '@/views/Dashboard/Wallets/Withdraw/index';
import Transfer from '@/views/Dashboard/Wallets/Transfer/index';
import History from '@/views/Dashboard/History';
import SettingsLayout from '@/views/Dashboard/Settings/template';
import Verification from '@/views/Dashboard/Settings/Verification/index';
import Security from '@/views/Dashboard/Settings/Security/index';
import SecurityPassword from '@/views/Dashboard/Settings/Security/password';
import SecurityEmail from '@/views/Dashboard/Settings/Security/email';
import SecurityLoginHistory from '@/views/Dashboard/Settings/Security/login-history';
import SecurityGoogleAuthentication from '@/views/Dashboard/Settings/Security/google-authentication';
import SecurityWalletConnect from '@/views/Dashboard/Settings/Security/wallet-connect';
import Referral from '@/views/Dashboard/Referral';
import Staking from '@/views/Dashboard/Staking';
import Orders from '@/views/Dashboard/Orders';
import UserTickets from '@/views/Dashboard/Tickets/Main';
import UserTicket from '@/views/Dashboard/Tickets/Ticket';
import Exchange from '@/views/Exchange/ExchangeIndex';
import Markets from '@/views/Markets';
import Fees from '@/views/Fees';
import Terms from '@/views/Terms';
import Privacy from '@/views/Privacy';
import AccountActivation from '@/views/AccountActivation';
import ForgotPassword from '@/views/ForgotPassword';
import ReferralLink from '@/views/ReferralLink';
import Faq from '@/views/Faq';
import Listing from '@/views/Listing';
import About from '@/views/About';
import BuyCrypto from '@/views/BuyCrypto';
import PageNotFound from '@/views/PageNotFound';
import store from '../store';
import i18n from "@/i18n";

const titleCreator = (pageName) => `${i18n.t(pageName)} | ${store.state.Index.websiteName.short}`;
export default [
  {
    path: '/activation/:verificationLink',
    name: 'AccountActivation',
    component: AccountActivation,
    meta: { title: 'Account activation' }
  },
  {
    path: '/invite/:referralLink',
    name: 'ReferralLink',
    component: ReferralLink,
    meta: { title: 'Referral link' }
  },
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:locale',
    component: {
      render: h => h('router-view')
    },
    children: [
      {
        path: '/',
        name: 'Index',
        component: Index,
        meta: { title: titleCreator('index') }
      },
      {
        path: 'auth',
        name: 'Auth',
        component: Auth,
        meta: { title: titleCreator('signIn') },
        beforeEnter: (to, from, next) => {
          if (store.state.Index.isAuthed) return next(`/${i18n.locale}`);
          next()
        }
      },
      {
        path: 'reset-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: { title: titleCreator('resetPassword') },
        beforeEnter: (to, from, next) => {
          if (store.state.Index.isAuthed) return next(`/${i18n.locale}`);
          next()
        }
      },
      {
        path: 'sign-up',
        name: 'Registration',
        component: Registration,
        meta: { title: titleCreator('signUp') },
        beforeEnter: (to, from, next) => {
          if (store.state.Index.isAuthed) return next(`/${i18n.locale}`);
          next()
        }
      },
      /*{
        path: 'help-center',
        name: 'HelpCenter',
        component: HelpCenter,
        meta: {
          title: titleCreator('helpCenter'),
          layout: 'helpCenter'
        }
      },*/
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: DashboardLayout,
        meta: {
          layout: 'dashboard',
          beforeEnter: (to, from, next) => {
            if (!store.state.Index.isAuthed) return next(`/${i18n.locale}`);
            next()
          }
        },
        children: [
          {
            path: '/',
            name: 'DashboardMain',
            component: Dashboard,
            meta: {
              title: titleCreator('dashboard'),
              layout: 'dashboard'
            }
          },
          {
            path: 'wallets',
            name: 'Wallets',
            component: WalletsLayout,
            meta: {
              layout: 'dashboardWallets',
            },
            children: [
              {
                path: '/',
                name: 'WalletsMain',
                component: Wallets,
                meta: {
                  title: titleCreator('wallets'),
                  layout: 'dashboardWallets'
                }
              },
              {
                path: 'deposit',
                name: 'Deposit',
                component: Deposit,
                meta: {
                  title: titleCreator('deposit'),
                  layout: 'dashboardWallets'
                }
              },
              {
                path: 'withdraw',
                name: 'Withdraw',
                component: Withdraw,
                meta: {
                  title: titleCreator('withdraw'),
                  layout: 'dashboardWallets'
                }
              },
              {
                path: 'transfer',
                name: 'Transfer',
                component: Transfer,
                meta: {
                  title: titleCreator('transfer'),
                  layout: 'dashboardWallets'
                }
              }
            ]
          },
          {
            path: 'settings',
            name: 'Settings',
            component: SettingsLayout,
            meta: {
              layout: 'dashboardSettings',
            },
            children: [
              {
                path: '/',
                name: 'SettingsMain',
                component: SettingsLayout,
                meta: {
                  title: titleCreator('settings'),
                  layout: 'dashboardSettings'
                },
                children: [
                  {
                    path: 'verification',
                    name: 'Verification',
                    component: Verification,
                    meta: {
                      title: titleCreator('verification'),
                      layout: 'dashboardSettings'
                    }
                  },
                  {
                    path: 'security',
                    name: 'Security',
                    component: Security,
                    meta: {
                      title: titleCreator('security'),
                      layout: 'dashboardSettings'
                    },
                    children: [
                      {
                        path: 'password',
                        name: 'SecurityPassword',
                        component: SecurityPassword,
                        meta: {
                          title: titleCreator('security'),
                          layout: 'dashboardSettings'
                        }
                      },
                      {
                        path: 'email',
                        name: 'SecurityEmail',
                        component: SecurityEmail,
                        meta: {
                          title: titleCreator('security'),
                          layout: 'dashboardSettings'
                        }
                      },
                      {
                        path: 'login-history',
                        name: 'SecurityLoginHistory',
                        component: SecurityLoginHistory,
                        meta: {
                          title: titleCreator('security'),
                          layout: 'dashboardSettings'
                        }
                      },
                      {
                        path: 'google-authentication',
                        name: 'SecurityGoogleAuthentication',
                        component: SecurityGoogleAuthentication,
                        meta: {
                          title: titleCreator('security'),
                          layout: 'dashboardSettings'
                        }
                      },
                      {
                        path: 'wallet-connect',
                        name: 'SecurityWalletConnect',
                        component: SecurityWalletConnect,
                        meta: {
                          title: titleCreator('security'),
                          layout: 'dashboardSettings'
                        }
                      }
                    ]
                  },
                ]
              }
            ]
          },
          {
            path: 'referral',
            name: 'Referral',
            component: Referral,
            meta: {
              title: titleCreator('referral'),
              layout: 'dashboard'
            }
          },
          {
            path: 'staking',
            name: 'Staking',
            component: Staking,
            meta: {
              title: titleCreator('staking'),
              layout: 'dashboard'
            }
          },
          {
            path: 'orders',
            name: 'Orders',
            component: Orders,
            meta: {
              title: titleCreator('orders'),
              layout: 'dashboard'
            }
          },
          {
            path: 'history',
            name: 'History',
            component: History,
            meta: {
              title: titleCreator('history'),
              layout: 'dashboard'
            }
          },
          {
            path: 'tickets',
            name: 'Tickets',
            component: UserTickets,
            meta: {
              title: titleCreator('tickets'),
              layout: 'dashboard'
            }
          },
          {
            path: 'tickets/:ticketId',
            name: 'Ticket',
            component: UserTicket,
            meta: {
              title: titleCreator('ticket'),
              layout: 'dashboard'
            }
          }
        ]
      },
      {
        path: 'exchange',
        name: 'Exchange',
        component: Exchange,
        meta: {layout: 'terminal' },
        children: [
          {
            path: ':pair',
            name: 'ExchangePair',
            component: Exchange,
            meta: {layout: 'terminal' },
          }
        ]
      },
      {
        path: 'markets',
        name: 'Markets',
        component: Markets,
        meta: {
          title: titleCreator('markets')
        }
      },
      {
        path: 'fees',
        name: 'Fees',
        component: Fees,
        meta: {
          title: titleCreator('fees')
        }
      },
      {
        path: 'terms',
        name: 'Terms',
        component: Terms,
        meta: {
          title: titleCreator('terms')
        }
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: Privacy,
        meta: {
          title: titleCreator('privacy')
        }
      },
      {
        path: 'faq',
        name: 'Faq',
        component: Faq,
        meta: {
          title: titleCreator('faq')
        }
      },
      {
        path: 'listing-with-us',
        name: 'Listing',
        component: Listing,
        meta: {
          title: titleCreator('listing')
        }
      },
      /*{
        path: 'about-us',
        name: 'About',
        component: About,
        meta: {
          title: titleCreator('about')
        }
      },*/
      {
        path: 'buy-crypto',
        name: 'BuyCrypto',
        component: BuyCrypto,
        meta: {
          title: titleCreator('buycrypto')
        }
      },
    ]
  },
  { path: "*", component: PageNotFound,
    meta: {
      title: titleCreator('notFound'),
      layout: 'default'
    }
  }
];