<i18n>
{
  "en": {
    "searchInput": "Search"
  },
  "zh": {
    "searchInput": "搜索"
  },
  "kr": {
    "searchInput": "검색"
  }
}
</i18n>

<template>
  <div>
    <section id="main-content" class="section hero">
      <div class="hero-inner">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" focusable="false" viewBox="0 0 12 12" class="search-icon" aria-hidden="true">
          <circle cx="4.5" cy="4.5" r="4" fill="none" stroke="currentColor"></circle>
          <path stroke="currentColor" stroke-linecap="round" d="M11 11L7.5 7.5"></path>
        </svg>
        <form role="search" class="search search-full" action="/help/search">
          <input type="search" name="query" id="query" :placeholder="$t('searchInput')" autocomplete="off" aria-label="Search">
        </form>
      </div>
    </section>
    <section class="container">
      <div
          class="block col-lg-4 col-md-6 col-xs-12"
          v-for="categorie, index in categories"
          :key="index"
      >
        <div class="blocks-item">
          <router-link
              class="blocks-item-link"
              to="kidato"
          >
            {{ categorie.title }}
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HelpCenter",
  data() {
    return{
      categories: [
        {
          title: 'Lacrimoza perdunosa'
        },
        {
          title: 'Lacrimoza perdunosa'
        },
        {
          title: 'Lacrimoza perdunosa'
        },
        {
          title: 'Lacrimoza perdunosa'
        },
        {
          title: 'Lacrimoza perdunosa'
        },
        {
          title: 'Lacrimoza perdunosa'
        },
        {
          title: 'Lacrimoza perdunosa'
        },
        {
          title: 'Lacrimoza perdunosa'
        },
        {
          title: 'Lacrimoza perdunosa'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.hero {
  background-image: url('/help-center-background.png');
  background-position: center;
  background-size: cover;
  height: 300px;
  padding: 0 20px;
  text-align: center;
  width: 100%;
}

.search-full input[type=search] {
  border: 1px solid #fff;
}

.search input[type=search] {
  border: 1px solid #ddd;
  border-radius: 2px;
  box-sizing: border-box;
  color: #666;
  height: 40px;
  padding-left: 40px;
  padding-right: 20px;
  -webkit-appearance: none;
  width: 100%;
}
input:not([type=checkbox]) {
  outline: none;
}

@media (min-width: 768px) {
  .section {
    margin-bottom: 60px;
  }
}

.section {
  margin-bottom: 40px;
}

.hero-inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 610px;
  margin: 0 auto;
}

.search-icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 15px;
  z-index: 1;
  width: 18px;
  height: 18px;
  color: #777;
}

.blocks-item {
  border: 1px solid #825DF4;
  border-radius: 2px;
  box-sizing: border-box;
  color: #825DF4;
  display: flex;
  flex: 1 0 340px;
  margin: 0 15px 30px;
  max-width: 100%;
  text-align: center;
  transition: .2s;
}

.blocks-item-link {
  color: #825DF4;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  border-radius: inherit;
}

.blocks-item:hover, .blocks-item:focus, .blocks-item:active {
  background-color: #825DF4;
}

.blocks-item:hover * {
  color: white !important;
}
</style>