<template>
  <Page
      :title="'Домен ' + domainInfo.domain"
      parse-url="/backoffice/admin/domainInfo"
      :post-data="{ domainId: $route.params.domainId }"
      @loaded-data="dataLoading($event)"
  >
    <div v-if="isLoaded">

      <div class="container">
        <div class="Card">
          <div class="Card-content">
            <div class="Card-header p-0 pl-4 pr-4 mb-4">
              <div style="flex-wrap: wrap;" class="d-flex">
                <span @click="domainMenu.currentTab = tab" class="tabs-link" :class="{ active: tab === domainMenu.currentTab }" v-for="tab, index in domainMenu.tabs" :key="'tab' + index">{{ tab }}</span>
              </div>
            </div>

            <DomainGeneral
                v-show="domainMenu.currentTab === 'Общие'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
                :coin-list="coinList"
            />

            <DomainPages
                v-show="domainMenu.currentTab === 'Страницы'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
            />

            <DomainDNS
                v-if="domainMenu.currentTab === 'DNS и почтовые данные'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
            />

            <DomainStaking
                v-if="domainMenu.currentTab === 'Стейкинг'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
                :coin-list="coinList"
            />

            <DomainTrading
                v-if="domainMenu.currentTab === 'Трейдинг'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
                :coin-list="coinList"
            />

            <DomainCustomPages
                v-if="domainMenu.currentTab === 'Кастомные страницы'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
                :editable-pages="editablePages"
                :coin-list="coinList"
            />

            <DomainPromocodes
                v-if="domainMenu.currentTab === 'Промокоды'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
            />

          </div>
        </div>
      </div>

    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import DomainGeneral from "@/views/BackOffice/Admin/Settings/Domain/DomainGeneral";
import DomainPages from "@/views/BackOffice/Admin/Settings/Domain/DomainPages";
import DomainCustomPages from "@/views/BackOffice/Admin/Settings/Domain/DomainCustomPages";
import DomainDNS from "@/views/BackOffice/Admin/Settings/Domain/DomainDNS";
import DomainStaking from "@/views/BackOffice/Admin/Settings/Domain/DomainStaking";
import DomainTrading from "@/views/BackOffice/Admin/Settings/Domain/DomainTrading";
import DomainPromocodes from "@/views/BackOffice/Admin/Settings/Domain/DomainPromocodes.vue";

export default {
  name: "Domain",
  components: {
    Page,
    DomainGeneral,
    DomainPages,
    DomainCustomPages,
    DomainDNS,
    DomainStaking,
    DomainTrading,
    DomainPromocodes
  },
  data() {
    return {
      domainMenu: {
        currentTab: 'Общие',
        tabs: [
          'Общие',
          'Страницы',
          'DNS и почтовые данные',
          'Стейкинг',
          'Трейдинг',
          'Кастомные страницы',
          'Промокоды',
        ]
      },
      domainInfo: {},
      coinList: [],
      isLoaded: false,
      editablePages: {}
    }
  },
  methods: {
    dataLoading(data) {
      this.domainInfo = data.domainInfo;
      this.coinList = data.coinList;
      this.editablePages = data.editablePages;
      this.isLoaded = true;
    }
  },
}
</script>

<style lang="scss" scoped>

span.tabs-link {
  display: block;
  padding: 16px 16px;
  position: relative;
  color: var(--vz-link-color);
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;

  &.active {
    color: #405189;

    &::after {
      content: "";
      background: #405189;
      height: 1px;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      -webkit-transition: all 250ms ease 0s;
      transition: all 250ms ease 0s;
      -webkit-transform: scale(0);
      transform: scale(1);
    }
  }
}

@media (min-width: 992px) {
  .container {
    width: calc(970px - 250px);
  }
}

@media (min-width: 768px){
  .container {
    width: 750px;
  }
}
@media (min-width: 1200px){
  .container {
    width: calc(1170px - 250px);
  }
}

</style>