<i18n>
{
  "en": {
    "title": "Deposit",
    "createDeposit": "Create Deposit",
    "depositHistory": "Deposit History",
    "tablePlaceholder": "Search by coins, address, MEMO...",
    "tableColumns": {
      "coin": "Coin",
      "amountUsd": "Amount in USD",
      "amount": "Amount",
      "status": "Status",
      "date": "Date",
      "hash": "Hash"
    },
    "status": {
      "completed": "Completed",
      "pending": "Pending",
      "canceled": "Canceled"
    },
    "copied": "Copied",
    "create": "Create",
    "expired": "Expired",
    "waiting": "Waiting",
    "pending": "Pending",
    "completed": "Completed",
    "expirein": "Expires in",
    "details": "Details",
    "buy": "Buy {0}",
    "aboutDeposit": {
      "title": "Send only {0} in {1} network to this deposit address.",
      "minimal": "Minimal deposit amount:",
      "description": "Sending coin or token other than {0} in {1} network to this address may result in the loss of your deposit.",
      "description3": "Do not send amounts less than the minimum deposit amount, otherwise your transaction will not be found and you will lose funds. Consider the transaction fee.",
      "description4": "Send the transaction on time. If you sent a transaction after the timer expired, please contact tickets.",
      "memo": "For this coin, you must fill in the MEMO/Destination Tag field when sending a transaction. Otherwise, you will lose funds.",
      "network": "Network"
    }
  },
  "zh": {
    "title": "定金",
    "createDeposit": "創建存款",
    "depositHistory": "存款历史",
    "tablePlaceholder": "按硬幣、地址、備忘錄搜索...",
    "tableColumns": {
      "coin": "硬币",
      "amountUsd": "美元金額",
      "amount": "数量",
      "status": "地位",
      "date": "日期",
      "hash": "哈希"
    },
    "status": {
      "completed": "完毕",
      "pending": "待定状态",
      "canceled": "取消"
    },
    "copied": "已复制",
    "create": "創建",
    "expired": "過期",
    "waiting": "等待",
    "pending": "待定",
    "completed": "完成",
    "expirein": "過期時間",
    "details": "細節",
    "buy": "购买 {0}",
    "aboutDeposit": {
      "title": "仅将 {1} 网络中的 {0} 发送到此存款地址。",
      "minimal": "最低存款金额：",
      "description": "将 {1} 网络中的 {0} 以外的硬币或代币发送到此地址可能会导致您的存款丢失。",
      "description3": "不要发送低于最低存款金额的金额，否则您的交易将找不到，您将损失资金。请考虑交易费用。",
      "description4": "按时发送交易。如果您在定时器到期后发送交易，请联系支持。",
      "memo": "对于这枚硬币，您必须在发送交易时填写 MEMO/Destination Tag 字段，否则您将损失资金。",
      "network": "网络"
    }
  },
  "kr": {
    "title": "보증금",
    "createDeposit": "예금 생성",
    "depositHistory": "입금 내역",
    "tablePlaceholder": "코인, 주소, 메모로 검색...",
    "tableColumns": {
      "coin": "동전",
      "amountUsd": "USD 금액",
      "amount": "금액이",
      "status": "상태",
      "date": "날짜",
      "hash": "해시"
    },
    "status": {
      "completed": "완전한",
      "pending": "보류 중",
      "canceled": "취소 된"
    },
    "copied": "복사됨",
    "create": "만들다",
    "expired": "만료",
    "waiting": "기다립니다",
    "pending": "보류 중",
    "completed": "완료",
    "expirein": "에 만료",
    "details": "세부 사항",
    "buy": "{0} 구매",
    "aboutDeposit": {
      "title": "{1} 네트워크의 {0}만 이 입금 주소로 보내십시오.",
      "minimal": "최소 입금액:",
      "description": "{1} 네트워크의 {0} 이외의 코인이나 토큰을 이 주소로 보내면 보증금이 손실될 수 있습니다.",
      "description3": "최소 입금액보다 적은 금액을 보내지 마십시오. 그렇지 않으면 거래를 찾을 수 없으며 자금을 잃게 됩니다. 거래 수수료를 고려하십시오.",
      "description4": "제시간에 트랜잭션을 보내십시오. 타이머가 만료된 후 트랜잭션을 보낸 경우 지원에 문의하십시오.",
      "memo": "이 코인의 경우 거래를 보낼 때 MEMO/목적지 태그 필드를 작성해야 합니다. 그렇지 않으면 자금을 잃게 됩니다.",
      "network": "회로망"
    }
  }
}
</i18n>

<template>
  <Page :is-page-loaded="dataLoadingStatus" :title="$t('title')">
    <PageContainer :no-line="true" v-if="dataLoadingStatus.isDone">
      <div class="container" style="margin-bottom: 40px;">
        <div class="col-lg-12 col-md-12 col-xs-12" v-show="$store.state.Index.exchangeInfo.promotion && !$store.state.Index.userInfo.settings.isWelcomeBonusUsed">
          <div class="alert alert-primary">
            {{ $store.state.Index.exchangeInfo.promotion }}
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12" v-show="addressInfo.memo">
          <div class="alert alert-danger">
            {{ $t('aboutDeposit.memo') }}
          </div>
        </div>
        <div style="margin-bottom: 15px;" class="col-lg-6 col-md-12 col-xs-12">
          <CoinsDropInput
              :on-item-change="changeCurrentCoin"
              :coins-object="coinList"
              :balance="balance"
              style="margin-bottom: 20px;"
          />

          <div class="address-change-zone">
            <div class="network">
              <label>{{ $t('aboutDeposit.network') }}</label>
              <div class="networks-row">
                <span @click="changeNetwork(network)" :class="{ current: currentNetwork === network }" v-for="network, index in coinList.find(x => x.code === currentCoin).networks.deposit" :key="index">{{ network }}</span>
              </div>
            </div>

            <div class="address-info">
              <DataLoading style="height: 334px;" v-if="isAddressLoading" />
              <div v-else>
                <div class="qr-code">
                  <img :src="qrcode" alt="">
                </div>
                <div class="address">
                  <span class="value">{{ addressInfo.address }}</span>
                  <span @click="copyText(addressInfo.address)" class="copy">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.998 10C20.986 7.825 20.89 6.647 20.121 5.879C19.243 5 17.828 5 15 5H12C9.172 5 7.757 5 6.879 5.879C6 6.757 6 8.172 6 11V16C6 18.828 6 20.243 6.879 21.121C7.757 22 9.172 22 12 22H15C17.828 22 19.243 22 20.121 21.121C21 20.243 21 18.828 21 16V15" stroke="#808191" stroke-width="1.5" stroke-linecap="round"/>
                      <path d="M3 10V16C3 16.7956 3.31607 17.5587 3.87868 18.1213C4.44129 18.6839 5.20435 19 6 19M18 5C18 4.20435 17.6839 3.44129 17.1213 2.87868C16.5587 2.31607 15.7956 2 15 2H11C7.229 2 5.343 2 4.172 3.172C3.518 3.825 3.229 4.7 3.102 6" stroke="#808191" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                  </span>
                </div>

                <div class="address" v-if="addressInfo.memo">
                  <span class="value">
                    <div style="display: flex; align-items: center;">
                      <svg style="margin-right: 5px;" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7491 14.1857C19.7661 14.2147 19.7801 14.2457 19.7931 14.2767C19.9121 14.5697 19.9801 14.8787 19.9971 15.1947C20.0361 15.9497 19.7791 16.6737 19.2721 17.2357C18.7661 17.7977 18.0711 18.1287 17.3161 18.1667L6.55906 18.1677C6.14506 18.1677 5.80906 17.8317 5.80906 17.4177C5.80906 17.0037 6.14506 16.6677 6.55906 16.6677H17.2781C17.5941 16.6497 17.9201 16.4957 18.1591 16.2307C18.3971 15.9667 18.5171 15.6277 18.4991 15.2727C18.4921 15.1387 18.4651 15.0067 18.4181 14.8807L11.1691 2.19469C10.8101 1.54569 9.99906 1.31169 9.35706 1.66669C9.13806 1.78769 8.95906 1.96669 8.83806 2.18569L1.58306 14.8817C1.46606 15.1997 1.47406 15.5447 1.60606 15.8577C1.74406 16.1847 2.00106 16.4387 2.33006 16.5717C2.46906 16.6287 2.61306 16.6607 2.76206 16.6687C3.17506 16.6887 3.49406 17.0417 3.47306 17.4557C3.45306 17.8557 3.12106 18.1677 2.72406 18.1677C2.71106 18.1677 2.69906 18.1677 2.68606 18.1667C2.36806 18.1507 2.05806 18.0817 1.76406 17.9617C1.06606 17.6777 0.519065 17.1377 0.225065 16.4417C-0.0699353 15.7457 -0.0749353 14.9757 0.210065 14.2757C0.222065 14.2447 0.236065 14.2147 0.253065 14.1857L7.53006 1.45069C7.78206 0.994688 8.16506 0.612688 8.63006 0.353688C9.99706 -0.402312 11.7221 0.0936881 12.4771 1.45969L19.7491 14.1857ZM10.0011 10.598C10.4151 10.598 10.7511 10.262 10.7511 9.84799V7.02099C10.7511 6.60699 10.4151 6.27099 10.0011 6.27099C9.58706 6.27099 9.25106 6.60699 9.25106 7.02099V9.84799C9.25106 10.262 9.58706 10.598 10.0011 10.598ZM10.0011 14.0189C10.4151 14.0189 10.7511 13.6829 10.7511 13.2689V13.2589C10.7511 12.8449 10.4151 12.5139 10.0011 12.5139C9.58706 12.5139 9.25106 12.8549 9.25106 13.2689C9.25106 13.6829 9.58706 14.0189 10.0011 14.0189Z" fill="#ff3b4d"/>
                      </svg>
                      MEMO/Destination Tag: {{ addressInfo.memo }}
                    </div>
                  </span>
                  <span @click="copyText(addressInfo.memo)" class="copy">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.998 10C20.986 7.825 20.89 6.647 20.121 5.879C19.243 5 17.828 5 15 5H12C9.172 5 7.757 5 6.879 5.879C6 6.757 6 8.172 6 11V16C6 18.828 6 20.243 6.879 21.121C7.757 22 9.172 22 12 22H15C17.828 22 19.243 22 20.121 21.121C21 20.243 21 18.828 21 16V15" stroke="#808191" stroke-width="1.5" stroke-linecap="round"/>
                      <path d="M3 10V16C3 16.7956 3.31607 17.5587 3.87868 18.1213C4.44129 18.6839 5.20435 19 6 19M18 5C18 4.20435 17.6839 3.44129 17.1213 2.87868C16.5587 2.31607 15.7956 2 15 2H11C7.229 2 5.343 2 4.172 3.172C3.518 3.825 3.229 4.7 3.102 6" stroke="#808191" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                  </span>
                </div>
                <div class="address">
                  <div style="display: flex; align-items: center;">
                    <svg style="margin-right: 5px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 11.9998C2 6.48479 6.486 1.99979 12 1.99979C17.514 1.99979 22 6.48479 22 11.9998C22 17.5138 17.514 21.9998 12 21.9998C8.873 21.9998 5.983 20.5768 4.073 18.0978C3.828 17.7788 3.887 17.3218 4.206 17.0778C4.523 16.8318 4.979 16.8928 5.225 17.2098C6.858 19.3298 9.327 20.5458 12 20.5458C16.712 20.5458 20.546 16.7128 20.546 11.9998C20.546 7.28779 16.712 3.45379 12 3.45379C7.288 3.45379 3.454 7.28779 3.454 11.9998C3.454 12.4378 3.487 12.8728 3.552 13.2988C3.612 13.6948 3.34 14.0658 2.943 14.1268C2.544 14.1928 2.175 13.9158 2.115 13.5188C2.039 13.0208 2 12.5098 2 11.9998ZM12 7.45389C11.586 7.45389 11.25 7.78989 11.25 8.20389V12.6239C11.25 13.0379 11.586 13.3739 12 13.3739C12.414 13.3739 12.75 13.0379 12.75 12.6239V8.20389C12.75 7.78989 12.414 7.45389 12 7.45389ZM12.0102 15.0457H12.0002C11.5862 15.0457 11.2552 15.3817 11.2552 15.7957C11.2552 16.2097 11.5962 16.5457 12.0102 16.5457C12.4242 16.5457 12.7602 16.2097 12.7602 15.7957C12.7602 15.3817 12.4242 15.0457 12.0102 15.0457Z" fill="#ffbd00"/>
                    </svg>
                    {{ $t('aboutDeposit.minimal') }} {{ minimalDepositAmount }} {{ currentCoin }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-xs-12">
          <div class="wrapper" style="display: block; padding: 15px;">
            <div style=" padding-top: 20px;text-align: center;margin: 5px 0 10px 0;">
              <img width="64" :src="`/currencies/` + currentCoin + `.png`" alt="">
            </div>
            <div style="font-weight: 500; border-bottom: 1px solid #e0e1e9; padding-bottom: 20px;text-align: center;">
              <h3>
                {{ $t('aboutDeposit.title', [currentCoin, currentNetwork]) }}
              </h3>
              <span style="color: rgba(10,15,56,.7); font-size: 14px;">{{ $t('aboutDeposit.description', [currentCoin, currentNetwork]) }}</span>
            </div>
            <div style="color: rgba(10,15,56,.7); font-weight: 500;font-size: 14px; padding: 20px 0;">
              <ul style="padding-left: 20px; margin: 12px 0;">
                <li>{{ $t('aboutDeposit.description3') }}</li>
              </ul>
            </div>

            <Button :callback="() => openBuyCrypto()" style="width: 100%; margin-bottom: 20px;">{{ $t('buy', [currentCoin]) }}</Button>
          </div>
        </div>
      </div>

      <div class="container">
        <h2 style="font-size: 18px;">{{ $t('depositHistory') }}</h2>
        <OnlineTable
            parse-url="/main/user/deposits"
            :column-list="tableColumns"
            default-sort="date"
            :search-placeholder-text="$t('tablePlaceholder')"
            @handle-data="tableData = $event"
        >
          <tr v-for="data, index in tableData" :key="index">
            <td>
              <div style="display: flex; align-items: center; width: 60px;">
                <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                  <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(data.currency)" alt="">
                </div>
                {{ data.currency }}
              </div>
            </td>
            <td>{{ data.amountCurrency.toFixed(6) }}</td>
            <td :class="data.isPending ? 'pending' : 'completed'" style="min-width: 150px;"><span>{{ data.isPending ? 'Pending' : 'Completed' }}</span></td>
            <td style="min-width: 200px;">{{ data.date | toDate }}</td>
            <td class="td-hash" @click="copyText(data.hash)">{{ data.hash }}</td>
          </tr>
        </OnlineTable>
      </div>
    </PageContainer>
  </Page>
</template>

<script>
import PageContainer from "@/components/main/UI/PageContainer";
import Page from "@/components/main/UI/Page";
import CoinsDropInput from "@/components/main/UI/CoinsDropInput";
import axios from "@/plugins/axios";
import OnlineTable from "@/components/main/UI/OnlineTable";
import DataLoading from "@/components/main/DataLoading";
import Button from "@/components/main/UI/Button";

export default {
  name: "Deposit",
  components: {
    PageContainer,
    Page,
    OnlineTable,
    CoinsDropInput,
    DataLoading,
    Button
  },
  data() {
    return {
      isAddressLoading: false,
      tableData: [],
      tableColumns: [
        {
          name: 'currency',
          title: this.$t('tableColumns.coin'),
          isSortable: true
        },
        {
          name: 'amountCurrency',
          title: this.$t('tableColumns.amount'),
          isSortable: true
        },
        {
          name: 'status',
          title: this.$t('tableColumns.status'),
          isSortable: true
        },
        {
          name: 'date',
          title: this.$t('tableColumns.date'),
          isSortable: true
        },
        {
          name: 'hash',
          title: this.$t('tableColumns.hash'),
          isSortable: true
        }
      ],
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      currentCoin: this.$route.params.coin || 'BTC',
      currentNetwork: '',
      currentAddress: 'None',
      coinList: [],
      addressInfo: {},
      balance: {},
      qrcode: null,
      minimalDepositAmount: 0
    }
  },
  methods: {
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.$notify({
        type: 'info',
        text: this.$t('copied')
      });
    },
    async changeNetwork(network) {
      if (this.currentNetwork === network) return;
      this.currentNetwork = network;
      await this.getDepositInfo(network);
    },
    async openBuyCrypto() {
      this.$router.push({ name: 'BuyCrypto' });
    },
    async getDepositInfo(network = null) {
      try {
        if (this.isAddressLoading) return;
        this.isAddressLoading = true;
        let fetchedData = await axios.post('main/user/deposit', {
          coin: this.currentCoin,
          network: this.currentNetwork
        });
        if (fetchedData.data.error) {
          this.dataLoadingStatus.isDone = true;
          this.dataLoadingStatus.isError = true;
          return;
        }
        this.coinList = fetchedData.data.coinList;
        this.balance = fetchedData.data.balance;
        this.addressInfo = fetchedData.data.addressInfo;
        this.minimalDepositAmount = fetchedData.data.minimalDepositAmount;
        this.qrcode = fetchedData.data.qrcode;
        if (network === null) this.currentNetwork = this.coinList.find(x => x.code === this.currentCoin).networks.deposit[0];
        this.dataLoadingStatus.isDone = true;
        this.isAddressLoading = false;
      } catch (e) {
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
      }
    },
    async changeCurrentCoin(coin) {
      this.currentCoin = coin;
      this.currentNetwork = coin;
      await this.getDepositInfo();
    }
  },
  async mounted() {
    try {
      await this.getDepositInfo();
    } catch (e) {
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>

.address-change-zone {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 10px;
  background: var(--card, #F6F9FD);
}

.dark {
  h3 {
    color: #d8d6d3;
  }
  .network label {
    color: rgb(171, 165, 156);
  }
  .network span {
    border-color: rgb(66, 71, 74);
  }
  div { border-bottom-color: #383d3e !important;  }
  .wrapper span { color: rgba(197, 193, 189, 0.7) !important; }
  div ul li { color: rgba(197, 193, 189, 0.7) !important; }
  .address-info {
    border-color: #383d3e !important;

    .address {
      background-color: transparent;
      color: rgb(197, 193, 189);
      border-color: #383d3e;

      .copy {
        border-color: initial;
        background-image: initial;
        background-color: rgb(28, 30, 31);
      }
    }
  }
  .network .networks-row {
    background: rgb(33, 34, 35) !important;
    border-color: #363b3d !important;
  }
  .network span {
    background: transparent;
  }
  .network span.current {
    background-image: initial;
    color: rgb(216, 214, 211);
  }
  .address-change-zone {
    background: rgb(33, 34, 35) !important;
  }

  img {
    border-color: #363b3d !important;
  }
}

.completed {
  color: #00871e !important;
}

.waiting {
  color: #216fc3!important;
}

.expired {
  color: #d90518!important;
}

.pending {
  color: #e9b10f!important;
}

@media (max-width: 385px) {
  .address {
    text-align: left !important;
  }
}


@media (max-width: 385px) {
  .address {
    text-align: left !important;
  }
}

.address-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 8px;
  width: 100%;

  .qr-code {
    text-align: center;
    width: 100%;
    margin-bottom: 32px;

    img {
      padding: 10px;
      border-radius: 10px;
      border: 1px solid var(--Stoke, #F0F0F0);
      width: 100%;
      height: 100%;
      max-width: 165px;
    }
  }

  .address {
    display: flex;
    padding: 10px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Stoke, #F0F0F0);
    margin-bottom: 10px;
    font-size: 12px;

    span.value {
      display: block;
      text-overflow: ellipsis;
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
    }

    .copy {
      height: 24px;
      cursor: pointer;
    }
  }
}


.alert {
  padding: 16px 24px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;

  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
  }

  &.alert-primary {
    color: #004085;
    background-color: #cce5ff;

  }

  &.alert-success {
    color: #155724;
    background-color: #d4edda;
  }
}


.ellipsis {
  opacity: 1;
  animation: fade 2s linear infinite;
}

.redo {
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

td.td-hash {
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  cursor: pointer;
}

.network {

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    margin-bottom: 10px;
    display: block;
  }

  .networks-row {
    display: flex;
    padding: 4px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Stoke, #F0F0F0);
    background: var(--white, #FFF);
    font-size: 14px;
  }

  span {
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    cursor: pointer;
    height: 34px;

    &:first-child {
      margin-left: 0 !important;
    }
    &.current {
      background: var(--Main-color, #2A85FF);
      color: white;
    }
  }
}
</style>