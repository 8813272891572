<i18n>
{
  "en": {
    "title": "Wallets",
    "fundingWallet": "Funding Wallet",
    "spotWallet": "Spot Wallet",
    "searchPlaceholder": "Search asset..",
    "deposit": "Deposit",
    "withdraw": "Withdraw",
    "transfer": "Transfer",
    "buy": "Buy",
    "totalBalance": "TOTAL BALANCE",
    "tableColumns": {
      "asset": "Asset",
      "available": "Available",
      "locked": "Locked",
      "total": "Total",
      "usdValue": "USD Value"
    }
  },
  "zh": {
    "title": "我的钱包",
    "fundingWallet": "资金钱包",
    "spotWallet": "现货钱包",
    "searchPlaceholder": "搜索资产..",
    "deposit": "订金",
    "withdraw": "提款",
    "transfer": "转移",
    "buy": "买",
    "totalBalance": "总余额",
    "tableColumns": {
      "asset": "资产",
      "available": "可得到",
      "locked": "锁定",
      "total": "全部的",
      "usdValue": "美元价值"
    }
  },
  "kr": {
    "title": "내 지갑",
    "fundingWallet": "펀딩 지갑",
    "spotWallet": "스팟 지갑",
    "searchPlaceholder": "자산 검색..",
    "deposit": "보증금",
    "withdraw": "철회하다",
    "transfer": "이체",
    "buy": "구입하다",
    "totalBalance": "전체 균형",
    "tableColumns": {
      "asset": "동전",
      "available": "사용 가능",
      "locked": "잠금",
      "total": "합계",
      "usdValue": "달러 가치"
    }
  }
}
</i18n>

<template>
  <Page :is-page-loaded="dataLoadingStatus" :title="$t('title')">
    <PageContainer :no-line="true">
      <div style="margin-bottom: 20px;" class="section">
        <div class="dashboard">
          <span style="font-size: 12px; color: #808191; font-weight: 400;" class="title">{{ $t('totalBalance') }}</span>
          <span style=" font-size: 18px;" class="description">{{ $store.state.Index.userInfo.totalBalance.btc.toFixed(8) }} <span class="currency">BTC</span></span>
        </div>
      </div>
      <div class="section type-change" style="width: 100%; display: flex; margin-bottom: 15px;">
        <div @click="typeBalance = 'funding'" :class="{ active: typeBalance === 'funding' }" style="display: block; margin-right: 20px; width: 300px; cursor: pointer" class="wrapper choose-type type-change">

          <div style="display: flex; align-items: center; margin-bottom: 5px;">
            <span style="margin-bottom: 0; margin-right: 10px; font-size: 12px;" class="title">{{ $t('fundingWallet') }}</span>
            <svg style="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78489 0.5H14.2142C17.4044 0.5 20 3.15478 20 6.41891V13.5811C20 16.8452 17.4044 19.5 14.2142 19.5C13.8462 19.5 13.5476 19.1944 13.5476 18.8179C13.5476 18.4414 13.8462 18.1358 14.2142 18.1358C16.6693 18.1358 18.6667 16.0931 18.6667 13.5811V7.86499H15.3831C14.3049 7.8659 13.4258 8.76447 13.4249 9.86858C13.4258 10.9727 14.3049 11.8713 15.3831 11.8722H16.7476C17.1156 11.8722 17.4142 12.1778 17.4142 12.5543C17.4142 12.9308 17.1156 13.2364 16.7476 13.2364H15.3831C13.5689 13.2355 12.0924 11.7248 12.0916 9.86858C12.0924 8.01233 13.5689 6.50168 15.3831 6.50077H18.6667V6.41891C18.6667 3.90692 16.6693 1.86422 14.2142 1.86422H5.78489C3.80622 1.86422 2.14578 3.19934 1.56711 5.02831H10.3547C10.7227 5.02831 11.0213 5.3339 11.0213 5.71043C11.0213 6.08786 10.7227 6.39254 10.3547 6.39254H1.336C1.336 6.39709 1.33533 6.40141 1.33467 6.40573C1.334 6.41005 1.33333 6.41437 1.33333 6.41891V13.5811C1.33333 16.0931 3.32978 18.1358 5.78489 18.1358H10.0258C10.3938 18.1358 10.6924 18.4414 10.6924 18.8179C10.6924 19.1944 10.3938 19.5 10.0258 19.5H5.78489C2.59467 19.5 0 16.8452 0 13.5811V6.41891C0 3.15478 2.59467 0.5 5.78489 0.5ZM14.861 9.80711C14.861 9.43058 15.1596 9.125 15.5276 9.125H15.8308C16.1988 9.125 16.4974 9.43058 16.4974 9.80711C16.4974 10.1836 16.1988 10.4892 15.8308 10.4892H15.5276C15.1596 10.4892 14.861 10.1836 14.861 9.80711Z" fill="#FFB700"/>
            </svg>
          </div>
          <span style="font-size: 18px;" class="description">{{ wallet.funding.toFixed(8) }} <span class="currency">BTC</span></span>
          <span style="color: grey; font-size: 12px;">
            ~ {{ new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              currencyDisplay: 'symbol'
            }).format(wallet.funding * prices.find(x => x.code === 'BTC').usdPrice) }}
          </span>
        </div>
        <div @click="typeBalance = 'spot'" :class="{ active: typeBalance === 'spot' }" style="display: block; width: 300px; cursor: pointer" class="wrapper  choose-type type-change">
          <div style="display: flex; align-items: center; margin-bottom: 5px;">
            <span style="margin-bottom: 0; margin-right: 10px; font-size: 12px;" class="title">{{ $t('spotWallet') }}</span>
            <svg style="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78489 0.5H14.2142C17.4044 0.5 20 3.15478 20 6.41891V13.5811C20 16.8452 17.4044 19.5 14.2142 19.5C13.8462 19.5 13.5476 19.1944 13.5476 18.8179C13.5476 18.4414 13.8462 18.1358 14.2142 18.1358C16.6693 18.1358 18.6667 16.0931 18.6667 13.5811V7.86499H15.3831C14.3049 7.8659 13.4258 8.76447 13.4249 9.86858C13.4258 10.9727 14.3049 11.8713 15.3831 11.8722H16.7476C17.1156 11.8722 17.4142 12.1778 17.4142 12.5543C17.4142 12.9308 17.1156 13.2364 16.7476 13.2364H15.3831C13.5689 13.2355 12.0924 11.7248 12.0916 9.86858C12.0924 8.01233 13.5689 6.50168 15.3831 6.50077H18.6667V6.41891C18.6667 3.90692 16.6693 1.86422 14.2142 1.86422H5.78489C3.80622 1.86422 2.14578 3.19934 1.56711 5.02831H10.3547C10.7227 5.02831 11.0213 5.3339 11.0213 5.71043C11.0213 6.08786 10.7227 6.39254 10.3547 6.39254H1.336C1.336 6.39709 1.33533 6.40141 1.33467 6.40573C1.334 6.41005 1.33333 6.41437 1.33333 6.41891V13.5811C1.33333 16.0931 3.32978 18.1358 5.78489 18.1358H10.0258C10.3938 18.1358 10.6924 18.4414 10.6924 18.8179C10.6924 19.1944 10.3938 19.5 10.0258 19.5H5.78489C2.59467 19.5 0 16.8452 0 13.5811V6.41891C0 3.15478 2.59467 0.5 5.78489 0.5ZM14.861 9.80711C14.861 9.43058 15.1596 9.125 15.5276 9.125H15.8308C16.1988 9.125 16.4974 9.43058 16.4974 9.80711C16.4974 10.1836 16.1988 10.4892 15.8308 10.4892H15.5276C15.1596 10.4892 14.861 10.1836 14.861 9.80711Z" fill="#FFB700"/>
            </svg>
          </div>
          <span style="font-size: 18px;" class="description">{{ wallet.spot.toFixed(8) }} <span class="currency">BTC</span></span>
          <span style="color: grey; font-size: 12px;">
            ~ {{ new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'symbol'
          }).format(wallet.spot * prices.find(x => x.code === 'BTC').usdPrice) }}
          </span>
        </div>
      </div>
      <div class="section">

        <div style="position:relative; overflow-x: auto;" class="">
          <div class="search-input" :class="{ dark: $store.state.Index.isDarkMode }">
            <input v-model="searchQuery" type="text" :placeholder="$t('searchPlaceholder')">
            <div class="placeholder">
              <font-awesome-icon
                  :icon="['fal', 'search']"
              />
            </div>
          </div>
        </div>

        <Table
            :data-loaded="Object.keys(searchForQuery)"
            :column-list="tableColumns"
            v-if="typeBalance === 'funding'"
            style="overflow-x: auto; position: relative;"
        >
          <tr v-for="coin, index in Object.keys(searchForQuery)" :key="index">
            <td>
              <div style="display: flex; align-items: center;">
                <div style="width: 16px;height: 16px;margin-right: 10px;">
                  <img style="width: 100%;height: 100%;" :src="$coinImage(coin)" alt="">
                </div>
                <span>{{ coin }}</span>
              </div>
            </td>
            <td>{{ tableBalanceData[coin].fundingWallet.toFixed(6) }}</td>
            <td>{{ tableBalanceData[coin].stakingWallet.toFixed(6) }}</td>
            <td>{{ (tableBalanceData[coin].fundingWallet + tableBalanceData[coin].stakingWallet).toFixed(6) }}</td>
            <td>{{ new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              currencyDisplay: 'symbol'
            }).format((tableBalanceData[coin].fundingWallet + tableBalanceData[coin].stakingWallet) * prices.find(x => x.code === coin).usdPrice) }}</td>
            <td style="text-align: left; display: flex; justify-content: space-between;">
              <router-link
                  class="route-link"
                  :to="{ name: 'Deposit', params: { coin } }"
                  :class="{ disabled: !prices.find(x => x.code === coin).isDepositable }"
                  :disabled="!prices.find(x => x.code === coin).isDepositable"
              >
                {{ $t('deposit') }}
              </router-link>
              <router-link
                  class="route-link"
                  :to="{ name: 'Withdraw', params: { coin } }"
              >
                {{ $t('withdraw') }}
              </router-link>
              <router-link
                  class="route-link"
                  :to="{ name: 'Transfer', params: { coin } }"
              >
                {{ $t('transfer') }}
              </router-link>
              <router-link
                  class="route-link"
                  :class="{ disabled: !prices.find(x => x.code === coin).isDepositable }"
                  :disabled="!prices.find(x => x.code === coin).isDepositable"
                  :to="{ name: 'BuyCrypto', params: { coin } }"
              >
                {{ $t('buy') }}
              </router-link>
            </td>
          </tr>
        </Table>

        <Table
            :data-loaded="Object.keys(searchForQuery)"
            :column-list="tableColumns"
            style="overflow-x: auto; position: relative;"
            v-else
        >
          <tr v-for="coin, index in Object.keys(searchForQuery)" :key="index">
            <td>
              <div style="display: flex; align-items: center;">
                <div style="width: 16px;height: 16px;margin-right: 10px;">
                  <img style="width: 100%;height: 100%;" :src="$coinImage(coin)" alt="">
                </div>
                <span>{{ coin }}</span>
              </div>
            </td>
            <td>{{ tableBalanceData[coin].spotWallet.toFixed(6) }}</td>
            <td>{{ new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              currencyDisplay: 'symbol'
            }).format(tableBalanceData[coin].spotWallet * prices.find(x => x.code === coin).usdPrice) }}</td>
            <td style="text-align: left">
              <router-link
                  class="route-link"
                  :to="{ name: 'Transfer', params: { coin } }"
              >
                {{ $t('transfer') }}
              </router-link>
            </td>
          </tr>
        </Table>
      </div>
    </PageContainer>
  </Page>
</template>

<script>
import PageContainer from "@/components/main/UI/PageContainer";
import Table from "@/components/main/UI/Table";
import Page from "@/components/main/UI/Page";
import axios from "@/plugins/axios";

export default {
  name: "Wallets",
  components: {
    PageContainer,
    Page,
    Table
  },
  data() {
    return {
      typeBalance: 'funding',
      fundingTableBalanceColumns: [
        {
          name: 'asset',
          title: this.$t('tableColumns.asset'),
          isSortable: true,
          style: 'width: 10%;'
        },
        {
          name: 'available',
          title: this.$t('tableColumns.available'),
          isSortable: true,
          style: 'width: 10%;'
        },
        {
          name: 'locked',
          title: this.$t('tableColumns.locked'),
          isSortable: true,
          style: 'width: 10%;'
        },
        {
          name: 'total',
          title: this.$t('tableColumns.total'),
          isSortable: true,
          style: 'width: 10%;'
        },
        {
          name: 'usdValue',
          title: this.$t('tableColumns.usdValue'),
          isSortable: true,
          style: 'width: 10%;'
        },
        {
          name: 'actions',
          title: '',
          isSortable: false,
          style: 'width: 50%;'
        },
      ],
      spotTableBalanceColumns: [
        {
          name: 'asset',
          title: this.$t('tableColumns.asset'),
          isSortable: true,
          style: 'width: 10%;'
        },
        {
          name: 'available',
          title: this.$t('tableColumns.available'),
          isSortable: true,
          style: 'width: 10%;'
        },
        {
          name: 'usdValue',
          title: this.$t('tableColumns.usdValue'),
          isSortable: true,
          style: 'width: 10%;'
        },
        {
          name: 'actions',
          title: '',
          isSortable: true,
          style: 'width: 10%;'
        }
      ],
      tableBalanceData: [],
      prices: [
        {
          code: 'BTC',
          usdPrice: 0
        }
      ],
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      searchQuery: '',
      wallet: { funding: 0, spot: 0 }
    }
  },
  computed: {
    sortBalance() {
      const totalBalance = sum => this.typeBalance === 'funding' ? sum.fundingWallet + sum.stakingWallet : sum.spotWallet;
      return Object.fromEntries(
          Object.entries(this.tableBalanceData)
              .sort(([a,a1],[b,b1]) => (totalBalance(b1)*this.prices.find(x => x.code === b).usdPrice) - (totalBalance(a1)*this.prices.find(x => x.code === a).usdPrice))
      )
    },
    searchForQuery() {
      return Object.fromEntries(
          Object.entries(this.sortBalance).filter((key, val) =>
            key[0].toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            this.prices.find(x => x.code === key[0]).name.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
        )
    },
    tableColumns() {
      return this[this.typeBalance+'TableBalanceColumns']
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('Index/fetchUserInfo');
      let fetchedData = await axios.get('main/user/balance');
      if (fetchedData.data.error) {
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
        return;
      }
      this.tableBalanceData = fetchedData.data.balance;
      this.prices = fetchedData.data.price;
      this.wallet = fetchedData.data.wallet;
      this.dataLoadingStatus.isDone = true;
    } catch (e) {
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>

.dark {
  .choose-type {
    &.active { background: #3b3d3f  !important;  }
  }
}

.choose-type {
  padding: 16px 24px !important;

  background: rgba(42, 133, 255, 0.10) !important;

  svg {
    path {
      fill: #2A85FF;
    }
  }
  &.active {
    background: #FFF4D8 !important;

    svg {
      path {
        fill: #FFB700;
      }
    }
  }
}

.route-link {
  padding: 8px 12px;
  color: #2e4ce5;
  font-weight: 500;
  text-decoration: none;

  &.disabled {
    color: grey;
    pointer-events: none;
  }
}
.search-input {
  float: right;
  position: relative;
  display: inline-block;
  max-width: 280px;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid var(--Stoke, #F0F0F0);

  &.dark {

    border-color: #363b3d;

    input {
      color: rgb(197, 193, 189);
      border-color: #363b3d;
      outline-color: initial;
    }
  }
}

.search-input .placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}

.search-input input {
  height: 100%;
  width: 100%;
  padding: 14px 16px 14px calc(14px + 20px + 10px);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
  border: none;
  background: transparent;
  border-radius: 2px;
  outline: 0;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out;
  transition: .25s ease-out;
  font-size: 12px;
}

@media (max-width: 490px) {
  .type-change {
    display: block !important;

    div {
      margin: 10px 0;
      width: 100% !important;
    }
  }
}

img {
  vertical-align: inherit !important;
}
</style>