<template>
  <button
      class="btn"
      :class="{[customType]: customType, default: !customType, loading: isLoading}"
      :disabled="isDisabled || isLoading"
      :style="{ width: customWidth || 'auto' }"
      @click="processClick"
  >
    <slot></slot>
      <svg class="spinner" viewBox="0 0 50 50" v-show="isLoading">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    isDisabled: Boolean,
    isAsync: {
      type: Boolean,
      default: true
    },
    customWidth: String,
    customType: String,
    callback: Function,
    answerDelay: Number
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    async processClick() {
      try {
        if (!this.isAsync) return this.callback();
        this.isLoading = true;
        await new Promise(resolve => setTimeout(resolve, this.answerDelay));
        await this.callback();
        this.isLoading = false;
      } catch (e) {
        console.log(e)
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.loading {
  color: transparent !important;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  left: 47.5%;
  height: 15px;
}

.spinner .path {
  stroke: #fff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>