<i18n>
{
  "en": {
    "title": "Google Authentication",
    "firststep": "Download Google Authenticator APP",
    "secondstep": "Scan QR code or enter secret key to link your Google Authenticator to {0} account.",
    "thirdstep": "Enter the 6-digit verification code on your Google Authenticator",
    "googlecode": "Google Code",
    "disablegoogle": "Disable Google Authentication",
    "disable": "Disable"
  },
  "zh": {
    "title": "谷歌認證",
    "firststep": "下載谷歌驗證器APP",
    "secondstep": "掃描二維碼或輸入密鑰將您的 Google Authenticator 鏈接到 {0} 帳戶。",
    "thirdstep": "在您的 Google Authenticator 上輸入 6 位驗證碼",
    "googlecode": "谷歌代碼",
    "disablegoogle": "禁用谷歌身份驗證",
    "disable": "禁用"
  },
  "kr": {
    "title": "구글 인증",
    "firststep": "Google OTP 앱 다운로드",
    "secondstep": "QR 코드를 스캔하거나 비밀 키를 입력하여 Google OTP를 {0} 계정에 연결하십시오.",
    "thirdstep": "Google OTP에 6자리 인증 코드를 입력하세요.",
    "googlecode": "구글 코드",
    "disablegoogle": "Google 인증 비활성화",
    "disable": "비활성화"
  }
}
</i18n>
<template>
  <div>
    <h3>{{ $t('title') }}</h3>
    <div v-if="$store.state.Index.userInfo.settings.twoFactorAuthorization.isActive">
      <h4>{{ $t('disablegoogle') }}</h4>
      <Button
          custom-width="100%"
          :is-async="true"
          :callback="disableTwoFactor"
          style="max-width: 450px;"
      >
        {{ $t('disable') }}
      </Button>
    </div>
    <div v-else>
      <div>
        <h4>1. {{ $t('firststep') }}</h4>
        <div style="padding-left: 15px;">
          <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
            <img style="cursor: pointer;" width="160" src="/app-store.svg" alt="">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" target="_blank">
            <img style="cursor: pointer;" width="200" src="/google-play.png" alt="">
          </a>
        </div>
      </div>
      <div>
        <h4>2. {{ $t('secondstep', [$store.state.Index.websiteName.short]) }}</h4>
        <div style="padding-left: 15px;">
          <img width="150" :src="'https://api.qrserver.com/v1/create-qr-code/?data=' + $store.state.Index.userInfo.settings.twoFactorAuthorization.qrcode"><br><br>
          <span>{{ $store.state.Index.userInfo.settings.twoFactorAuthorization.secretKey }}</span>
        </div>
      </div>
      <div>
        <h4>3. {{ $t('thirdstep') }}</h4>
        <div style="padding-left: 15px;">
          <InputBox
              v-for="control, index in formControls"
              :key="index"
              :type="control.type"
              :label="$t(control.label)"
              :validation="control.validation"
              v-model="control.value"
              @check-valid="control.isValid = $event"
              :error-message="$t(control.errorMessage)"
              style="max-width: 450px;"
          />
          <Button
              custom-width="100%"
              :is-async="true"
              :callback="enableTwoFactor"
              :is-disabled="!$isFormValid(formControls)"
              style="max-width: 450px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwoFactorModal from "@/mixins/two-factor-modal";
import InputBox from "@/components/main/UI/InputBox";
import Button from "@/components/main/UI/Button";
import axios from "@/plugins/axios";

export default {
  name: "google-authentication",
  components: {
    InputBox,
    Button
  },
  mixins: [TwoFactorModal],
  data() {
    return {
      formControls: {
        codeInput: {
          value: '',
          type: 'number',
          label: 'googlecode',
          isValid: false,
          validation: {
            required: true,
            maxLength: 6,
            minLength: 6
          }
        }
      },
    }
  },
  methods: {
    async enableTwoFactor() {
      try {
        const fetchedData = await axios.post('/main/user/twofactorenable', {
          code: this.formControls.codeInput.value
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (!fetchedData.data.error) return location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async disableTwoFactor(twoFactorCode) {
      try {
        const fetchedData = await axios.post('/main/user/twofactordisable', {
          twoFactorCode
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (fetchedData.status === 206) return this.openTwoFactorConfirmation(this.disableTwoFactor);
        if (!fetchedData.data.error) return location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style scoped>

</style>