<i18n>
{
  "en": {
    "searchPlaceholder": "Search currency name",
    "markets": "Markets",
    "table": {
      "pair": "Pair",
      "lastPrice": "Last Price",
      "change24h": "Change 24H",
      "low24h": "Low 24H",
      "high24h": "High 24H",
      "volume24h": "Volume 24H"
    },
    "highlight": "Highlight Coin",
    "gainers": "Top Gainer Coin",
    "losers": "Top Loser Coin",
    "volume": "Top Volume Coin"
  },
  "zh": {
    "searchPlaceholder": "搜索币种名称",
    "markets": "市场",
    "table": {
      "pair": "对",
      "lastPrice": "最后价格",
      "change24h": "24小时变化",
      "low24h": "低24小时",
      "high24h": "高24小时",
      "volume24h": "音量 24 小时"
    },
    "highlight": "高亮硬币",
    "gainers": "最大赢家硬币",
    "losers": "最大输家硬币",
    "volume": "最高音量硬币"
  },
  "kr": {
    "searchPlaceholder": "통화 이름 검색",
    "markets": "시장",
    "table": {
      "pair": "쌍",
      "lastPrice": "마지막 가격",
      "change24h": "24시간 변경",
      "low24h": "낮은 24시간",
      "high24h": "높은 24시간",
      "volume24h": "볼륨 24시간"
    },
    "highlight": "하이라이트 코인",
    "gainers": "탑 게이너 코인",
    "losers": "탑 패자 코인",
    "volume": "최고 볼륨 코인"
  }
}
</i18n>
<template>
  <Page style="padding: 0;" :is-page-loaded="dataLoadingStatus">

    <div class="top row">
      <div class="container">
        <h1 style="margin-bottom: 60px;">{{ $t('markets') }}</h1>
        <div class="losers">
          <div class="col-lg-3 col-md-4 col-xs-12" v-for="category, index in coinsTop" :key="index">
            <div class="category">{{ category.title }}</div>
            <router-link tag="div" :to="$linkCreator($i18n.locale, 'exchange/' + coin.coin + '_' + coin.market)" class="coin" v-for="coin, index in category.data" :key="index + Math.random()">
              <div class="data">
                <div class="title">
                  <div style="border-radius: 100%; width: 24px; height: 24px; margin-right: 8px;"><img style="width: 100%;" :src="$coinImage(coin.coin)" alt=""></div>
                  {{ coin.coin }}
                </div>
                <div class="value"> {{ coin.price }}</div>
                <div class="percent" :class="coin.priceChange.percent > 0 ? 'plus' : 'minus'">{{ coin.priceChange.percent > 0 ? '+'+coin.priceChange.percent : coin.priceChange.percent }}%</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="markets">
        <div class="header-view">
          <div class="coins">
            <span @click="currentMarket = market" :class="{ current: currentMarket === market }" v-for="market, index in markets" :key="index">{{ market }}</span>
          </div>
          <div class="search">
            <i class="fas fa-search"></i>
            <input v-model="searchString" :placeholder="$t('searchPlaceholder')" type="text">
          </div>
        </div>

        <div style="overflow-x: auto;">
          <div class="table">
            <div class="head">
              <div class="td">{{ $t('table.pair') }}</div>
              <div class="td">{{ $t('table.lastPrice') }}</div>
              <div class="td">{{ $t('table.change24h') }}</div>
              <div class="td">{{ $t('table.low24h') }}</div>
              <div class="td">{{ $t('table.high24h') }}</div>
              <div class="td">{{ $t('table.volume24h') }}</div>
            </div>
            <router-link tag="div" :to="$linkCreator($i18n.locale, 'exchange/' + pair.coin + '_' + pair.market)" class="tr" v-for="pair, index in filteredPairList" :key="index">
              <div class="td">
                <div style="width: 32px; height: 32px; margin-right: 5px;border-radius: 100%;">
                  <img style="border-radius: 100%;width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(pair.coin)" alt="">
                </div>
                <div style="margin-right: 3px;">{{ pair.coin }}</div> <span class="market">/ {{ pair.market }}</span>
              </div>
              <div class="td">{{ pair.price }}</div>
              <div class="td" :class="pair.priceChange.percent > 0 ? 'plus' : 'minus'" >{{ pair.priceChange.percent > 0 ? '+' + pair.priceChange.percent.toFixed(2) : pair.priceChange.percent.toFixed(2) }}%</div>
              <div class="td">{{ pair.priceLow24h }}</div>
              <div class="td">{{ pair.priceHigh24h }}</div>
              <div class="td">{{ pair.volume.toFixed(6) }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import axios from "@/plugins/axios";
import Page from "@/components/main/UI/Page";

export default {
  name: "markets",
  components: {
    Page
  },
  data() {
    return {
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      markets: [],
      pairs: [],
      coinList: [],
      currentMarket: '',
      searchString: ''
    }
  },
  computed: {
    filteredPairList() {
      return this.pairs
          .filter(x =>
              x.market === this.currentMarket && x.coin.toLowerCase().includes(this.searchString.toLowerCase()) ||
              x.market === this.currentMarket && x.market.toLowerCase().includes(this.searchString.toLowerCase())
          )
          .sort((a, b) => (b.volume * this.coinList.find(x => x.code === b.coin).usdPrice) - (a.volume * this.coinList.find(x => x.code === a.coin).usdPrice))
    },
    coinsTop() {
      const pairsArray = [].concat(this.pairs);
      return [
        {
          title: this.$t('highlight'),
          data: pairsArray.filter(x => x.market === 'USDT').sort((a, b) => (b.volume * this.coinList.find(x => x.code === b.coin).usdPrice) - (a.volume * this.coinList.find(x => x.code === a.coin).usdPrice)).slice(3, 6)
        },
        {
          title: this.$t('gainers'),
          data: pairsArray.filter(x => x.market === 'USDT').sort((a, b) => b.priceChange.percent - a.priceChange.percent).slice(0, 3)
        },
        {
          title: this.$t('losers'),
          data: pairsArray.filter(x => x.market === 'USDT').sort((a, b) => a.priceChange.percent - b.priceChange.percent).slice(0, 3)
        },
        {
          title: this.$t('volume'),
          data: pairsArray.filter(x => x.market === 'USDT').sort((a, b) => (b.volume * this.coinList.find(x => x.code === b.coin).usdPrice) - (a.volume * this.coinList.find(x => x.code === a.coin).usdPrice)).slice(0, 3)
        }
      ]
    }
  },
  async mounted() {
    try {
      let fetchedData = await axios.get('main/exchange/markets');
      if (fetchedData.data.error) {
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
        return;
      }
      this.markets = fetchedData.data.markets;
      this.pairs = fetchedData.data.pairs;
      this.coinList = fetchedData.data.coinList;
      this.currentMarket = this.markets[0];
      this.dataLoadingStatus.isDone = true;
    } catch (e) {
      console.log(e)
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>

.dark {
  .top {
    background-image: linear-gradient(0deg, rgb(33, 35, 37) 0px, rgb(33, 35, 36) 6.67%, rgb(33, 35, 36) 13.33%, rgb(33, 35, 36) 20%, rgb(33, 35, 36) 26.67%, rgb(33, 34, 35) 33.33%, rgb(32, 33, 34) 40%, rgb(31, 33, 33) 46.67%, rgb(31, 33, 33) 53.33%, rgb(30, 32, 33) 60%, rgb(29, 32, 33) 66.67%, rgb(29, 31, 32) 73.33%, rgb(29, 31, 32) 80%, rgb(28, 30, 31) 86.67%, rgb(28, 30, 31) 93.33%, rgb(28, 30, 31) 100%);
    background-color: initial;
  }
  .markets .header-view .coins span.current {
    background-color: rgb(35, 38, 39);
  }
  .markets .header-view .coins span {
    border-color: initial;
    background-color: transparent;
    outline-color: initial;
    color: rgba(197, 193, 189, 0.7) !important;
  }
  .markets .header-view .search input {
    color: rgb(197, 193, 189);
    border-color: initial;
    background-color: rgb(35, 38, 39);
    outline-color: initial;
  }
  .markets .table .tr:hover {
    background-image: initial;
    background-color: rgb(35, 38, 39);
  }
  .markets .table .head div.td {
    color: rgba(197, 193, 189, 0.6);
  }
  .markets .table .tr div.td {
    border-bottom-color: rgb(54, 59, 61);
  }
  .dark .markets .table .head {
    border-bottom-color: rgb(54, 59, 61);
  }
  .top .coin .data div.title {
    color: rgb(197, 193, 189);
  }
  .top .coin:hover {
    background-image: initial;
    background-color: rgb(40, 42, 43);
  }
  .markets .header-view .coins span:hover {
    color: rgb(197, 193, 189) !important;
  }
}

.markets {
  margin-bottom: 40px;

  .header-view {
    width: 100%;
    display: inline-block;
    margin-top: 10px;

    .coins {
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 20px;

      span {
        padding: 12px 16px;
        color: rgba(10,15,56,.7) !important;
        font-size: 14px;
        font-weight: 500;
        border: none;
        background-color: transparent;
        border-radius: 8px;
        outline: 0;
        cursor: pointer;
        -webkit-transition: .25s ease-out;
        -o-transition: .25s ease-out;
        transition: .25s ease-out;color: #0a0f38;

        &.current {
          background-color: #edf0f5;
        }

        &:hover {
          color: #0a0f38 !important;
        }
      }
    }

    .search {
      position: relative;
      display: inline-block;
      float: right;
      max-width: 380px;
      width: 100%;
      height: 48px;

      .fa-search {
        position: absolute;
        top: 35%;
        left: 18px;
      }

      input {
        width: 100%;
        height: 100%;
        padding: 14px 16px 14px calc(14px + 20px + 10px);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #0a0f38;
        font-size: 12px;
        font-weight: 500;
        border: none;
        background-color: #edf0f5;
        border-radius: 6px;
        outline: 0;
        -webkit-transition: .25s ease-out;
        -o-transition: .25s ease-out;
        transition: .25s ease-out;
      }
    }
  }

  .table {
    min-width: 900px;

    .head {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #e8eaf0;

      div.td {
        width: 100%;
        padding: 16px 20px;
        color: rgba(10,15,56,.6);
        font-size: 12px;
        font-weight: 600;
        vertical-align: middle;
      }

    }

    .tr {
      display: flex;
      cursor: pointer;
      transition: .3s;

      &:hover {
        background: #f1f1f1;
      }

      div.td {
        width: 100%;
        padding: 16px 20px;
        font-size: 14.5px;
        font-weight: 600;
        vertical-align: middle;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e8eaf0;

        &.minus { color: #e25050; }
        &.plus { color: #01aa78; }

        span.market {
          font-size: 12px;
        }
      }
    }

  }
}

.top {
  display: block;
  position: relative;
  background: linear-gradient(0deg,#f2f3f7 0,#f3f3f7 6.67%,#f3f4f8 13.33%,#f3f4f8 20%,#f4f5f8 26.67%,#f5f6f9 33.33%,#f7f7fa 40%,#f8f9fb 46.67%,#f9fafc 53.33%,#fbfbfc 60%,#fcfcfd 66.67%,#fdfdfe 73.33%,#fefefe 80%,#fff 86.67%,#fff 93.33%,#fff 100%);
  padding-bottom: 40px;
  margin-bottom: 10px;

  div.category {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: calc(100% + 16px);
    height: 32px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #848E9C;
  }

  .coin {
    cursor: pointer;
    transition: .3s;

    &:hover {
      background:  #E7E8EA;
    }

    .data {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 8px 8px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-weight: 700;
      display: flex;
      align-items: center;
      font-size: 13px;

      &.gainer { background: #eff9f5; }
      &.loser { background: #fdf5f5; }

      div.title {
        width: 120px;
        color: #0a0f38;
        display: flex;
        align-items: center;
      }

      div.value {
        width: 95px;
      }

      div.percent {
        width: 95px;
        margin-left: 4px;
        text-align: right;

        &.plus { color: #01aa78; }
        &.minus { color: #e25050; }
      }
    }

  }
}

</style>