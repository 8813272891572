<template>
  <Page
      title="Арбитраж"
      parse-url="/backoffice/user/arbitrage"
      @loaded-data="dataLoading($event)"
  >
    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header">
          <h4>Настройки арбитража</h4>
        </div>
        <div style="overflow-y: auto;" class="chart p-4">
          <div class="form-control col-lg-12 d-flex align-items-center mb-4">
            <label class="m-0 mr-3">Авто-арбитраж активен</label>
            <SwitchButton
                :is-checked="fetchedData.arbitrage.isActive"
                @switch-change="fetchedData.arbitrage.isActive = $event"
            />
          </div>

          <div class="row m-0">
            <div class="mb-3">Кошельки, на которые уходят возвратные депозиты, так же их можно пополнять для выплаты выводов.</div>
            <div class="d-flex align-items-center mb-3" v-for="address, index in fetchedData.arbitrage.wallet" :key="'address' + index">
              <div class="mr-2" style="display: flex; align-items: center; width: 100px;">
                <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                  <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(index)" alt="">
                </div>
                {{ index }}
              </div>
              <InputBox
                  :label-none="true"
                  type="text"
                  class="mb-0 w-100"
                  :value="address"
                  :disabled="true"
              />
            </div>
          </div>

          <InputBox
              label="Порог в USD, при котором если сумма депозита менее чем сумма ниже, будут автоматически перенаправляться на кошельки сгенерированные выше для возможности выплаты юзеру"
              type="number"
              class="mb-0 w-100"
              :value="fetchedData.arbitrage.maxDepositToBack"
              :disabled="true"
          />


          <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-2 mt-4">
            <Button
                class="w-100 primary"
                :callback="updateArbitrage"
            >
              Обновить
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Выводы ожидающие оплаты</h4>
        </div>
        <Table
            ref="arbitrageTableComponent"
            parse-url="/backoffice/user/arbitrage/withdraws"
            :columnList="arbitrageTableColumns"
            default-sort="date"
            searchPlaceholderText="Введите почту, хеш или монету"
            @handle-data="arbitrageTableData = $event"
        >
          <tr v-for="withdraw, index in arbitrageTableData" :key="'withdraw' + index">
            <td>
              <router-link :to="'/back-office/users/' + withdraw.userId">
                {{ withdraw.username }}
              </router-link>
            </td>
            <td>{{ withdraw.address }}</td>
            <td>
              <div style="display: flex; align-items: center; width: 120px;">
                <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                  <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="'/currencies/'+withdraw.currency+'.png'" alt="">
                </div>
                {{ withdraw.network }}
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center justify-content-space-between">
                {{ withdraw.amount }}<span class="text-muted" style="font-size: 12px; float: right;">{{withdraw.currency}}</span>
              </div>
            </td>
            <td @click="updateTransactionField(withdraw.userId, withdraw._id, 'withdraws', 'hash', withdraw.hash)" class="hash">{{ withdraw.hash }}</td>
            <td>{{ withdraw.date | toDate }}</td>
            <td class="no-animate">
              <div style="font-size: 12px;" class="d-flex justify-content-space-between align-items-center">
                Ожидает
                <SwitchButton
                    @switch-change="updateTransactionField(withdraw.userId, withdraw._id, 'withdraws', 'isPending', $event)"
                    :is-checked="withdraw.isPending"
                />
              </div>

              <div style="font-size: 12px;" class="d-flex justify-content-space-between align-items-center">
                Ошибка
                <SwitchButton
                    @switch-change="updateTransactionField(withdraw.userId, withdraw._id, 'withdraws', 'isError', $event)"
                    :is-checked="withdraw.isError"
                />
              </div>
            </td>
            <td class="no-animate" style="text-align: center;">
              <div class="d-flex align-items-center justify-content-space-between">
                <div @click="payTransaction(withdraw._id, withdraw.amount, withdraw.currency, withdraw.isPending, withdraw.isError)" class="pay-btn">
                  <font-awesome-icon class="icon" :icon="['fas', 'money-check-alt']" />
                </div>
                <div @click="deleteTransaction(withdraw.userId, withdraw._id, 'withdraws')" class="delete-btn">
                  <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
                </div>
              </div>
            </td>
          </tr>
        </Table>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import NotificationCard from "@/components/backoffice/UI/NotificationCard";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton.vue";

export default {
  name: "Deposits",
  components: {
    SwitchButton,
    Page,
    Table,
    InputBox,
    Button
  },
  data() {
    return {
      isStatisticsLoaded: false,
      isStatisticsLoading: false,
      statisticText: 'Нажмите, чтобы загрузить статистику',
      arbitrageTableData: [],
      arbitrageTableColumns: [
        {
          name: 'email',
          title: 'Почта',
          isSortable: true
        },
        {
          name: 'address',
          title: 'Адрес',
          isSortable: true
        },
        {
          name: 'currency',
          title: 'Сеть',
          isSortable: true
        },
        {
          name: 'amount',
          title: 'Сумма',
          isSortable: true
        },
        {
          name: 'hash',
          title: 'Хеш',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
        {
          name: 'isPending',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        }
      ],
      fetchedData: {
        arbitrage: {
          wallet: {
            BTC: null,
            'ERC-20': null,
            'BEP-20': null,
            'TRC-20': null,
            XRP: null,
          },
          maxDepositToBack: 200,
          isActive: false
        }
      },
    }
  },
  methods: {
    dataLoading(data) {
      this.fetchedData = data.spammerInfo;
    },
    async updateTransactionField(userId, transactionId, type, field, current) {
      try {
        let value;
        if (field !== 'isPending' && field !== 'isError') {
          value = prompt(
              field === 'date'
                  ? `Введите новую дату. Используйте полные названия месяцев на английском.`
                  : `Введите новое значение.`,
              field === 'date'
                  ? this.formatDate(current )
                  : current
          );
        } else {
          value = current;
        }
        if (field !== 'isPending' && field !== 'isError' && !value) return;
        if(field === 'date' && isNaN(new Date(value).getTime())) return this.$callNotification(this.$i18n.locale, { error: true, message: 'Введена неверная дата.' });

        const fetchedData = await axios.post('/backoffice/user/user/updateTransaction', {
          userId,
          transactionId,
          type,
          field,
          value
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.arbitrageTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async updateArbitrage() {
      try {
        const fetchedData = await axios.post('backoffice/user/arbitrage/update', {
          status: this.fetchedData.arbitrage.isActive
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async deleteTransaction(userId, transactionId, type) {
      try {
        const confirmation = confirm('Вы действительно хотите удалить эту транзакцию?');
        if (!confirmation) return;

        const fetchedData = await axios.post('/backoffice/user/user/deleteTransaction', {
          userId,
          transactionId,
          type
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.arbitrageTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async payTransaction(withdrawId, amount, coin, isPending, isError) {
      try {
        const confirmation = confirm(`Вы действительно хотите оплатить этот вывод? Сумма на выплату ${amount} ${coin}.`);
        if (!confirmation) return;

        if (!isPending && !isError) {
          const confirmation = confirm(`Этот вывод уже имеет статус выполнен. Вы действительно УВЕРЕНЫ что хотите оплатить этот вывод?`);
          if (!confirmation) return;
        }

        this.$callNotification(this.$i18n.locale, {
          type: 'info',
          message: 'Выплата производится, пожалуйста подождите (НЕ ОБНОВЛЯЙТЕ СТРАНИЦУ!)'
        });
        const fetchedData = await axios.post('/backoffice/user/arbitrage/payout', {
          withdrawId
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.arbitrageTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
  }
}
</script>

<style scoped>
.hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

div.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}

div.pay-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #6cf048;
    }
  }
}
</style>