<i18n>
{
  "en": {
    "title": "Sorry! We encountered an unexpected error.",
    "description": "Please try back again shortly.",
    "buttonText": "Back to home"
  },
  "zh": {
    "title": "我们遇到了意外错误。",
    "description": "请稍后再试。",
    "buttonText": "返回主页"
  },
  "kr": {
    "title": "죄송합니다! 예기치 않은 오류가 발생했습니다.",
    "description": "잠시 후 다시 시도하십시오.",
    "buttonText": "홈으로"
  }
}
</i18n>

<template>
  <main>
    <div>
      <h1>{{ $t('title') }}</h1>
      <span>{{ $t('description') }}</span>
      <router-link tag="button" to="/">{{ $t('buttonText') }}</router-link>
    </div>
  </main>
</template>

<script>
export default {
name: "PageNotFound"
}
</script>

<style scoped>
main {
  background-image: url('/error-404.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 38%;
  display: flex;
  height: calc(100vh - 58px);
  align-items: center;
}

div {
  margin-left: 10%;
}

button {
  color: white;
  padding: 10px;
  border-radius: 5px;
  background: #2A85FF;
  display: block;
  margin-top: 15px;
  cursor: pointer;
  text-decoration: none;
  width: auto;
  transition: .3s;
  border: none;
}

a:hover {
  opacity: .8;
}
</style>