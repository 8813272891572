<template>
  <div class="Page loading" :class="{ sm: $store.state.Index.isMobile, 'small-menu': $store.state.Backoffice.isMenuSmall }" v-if="!loadingStatus.isDone || loadingStatus.isError">
    <span v-show="!loadingStatus.isDone && !loadingStatus.isError">Загрузка данных...</span>
    <span v-show="loadingStatus.isError">Ошибка при загрузке данных<div class="text-muted mt-2">{{ customError }}</div></span>
  </div>
  <div class="Page" :class="{ sm: $store.state.Index.isMobile, 'small-menu': $store.state.Backoffice.isMenuSmall }" v-else>
    <div class="title" v-if="!noHeader">{{ title }}</div>
    <div :style="containerStyle" style="padding: 20px 12px 60px 12px;" class="container-fluid">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "Page",
  props: {
    title: String,
    noHeader: Boolean,
    containerStyle: Object,
    parseUrl: String,
    postData: Object,
  },
  data() {
    return {
      loadingStatus: { isDone: false, isError: false },
      customError: ''
    }
  },
  async mounted() {
    try {
      if (!this.parseUrl) return this.loadingStatus.isDone = true;
      const fetchedData = await axios[this.postData ? 'post' : 'get'](this.parseUrl, this.postData);
      if (fetchedData.data.error) {
        this.customError = fetchedData.data.description;
        return this.loadingStatus.isError = true;
      }
      this.$emit('loaded-data', fetchedData.data);
      this.loadingStatus.isDone = true;
    } catch (e) {
      console.log(e)
      this.loadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>

@media (min-width: 768px) {
  .Page {
    margin-left: 250px;
  }
  .Page.small-menu {
    margin-left: 70px;
  }
  .Page.loading {
    margin-left: 125px;
  }
  .Page.loading.small-menu {
    margin-left: 35px;
  }
}

.Page {
  padding-top: 70px;
  transition: margin .1s;
  &.sm { margin-left: 0 !important; }

  div.title {
    padding: 10px 24px;
    background-color: #212529;
    -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    border-bottom: none;
    border-top: none;
    font-weight: 700;
    font-size: 15px!important;
    text-transform: uppercase;
    border-top: 1px solid #1A1E22;
  }
}

div.loading {
  padding-top: 35px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

</style>