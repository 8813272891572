<i18n>
{
  "en": {
    "title": "Funds History",
    "deposit": "Deposits",
    "withdraw": "Withdrawals",
    "transfer": "Transfers",
    "tablePlaceholder": "Search by coins, address, MEMO...",
    "tableWithdrawPlaceholder": "Search by coin, hash, comment...",
    "tableColumns": {
      "coin": "Coin",
      "amountUsd": "Amount in USD",
      "amount": "Amount",
      "status": "Status",
      "date": "Date",
      "hash": "Hash",
      "comment": "Comment"
    },
    "status": {
      "completed": "Completed",
      "pending": "Pending",
      "error": "Error",
      "canceled": "Canceled",
      "awaiting": "Awaiting"
    },
    "userTransfer": {
      "userTransfer": "Transfer To User",
      "inputs": {
        "recipient": "Recipient",
        "amount": "Amount"
      },
      "userTransferHistory": "User Transfer History",
      "tableColumns": {
        "sender": "Sender",
        "recipient": "Recipient",
        "amount": "Amount",
        "date": "Date"
      },
      "tablePlaceholder": "Search by sender, recipient, currency...",
      "me": "Me"
    }
  },
  "zh": {
    "title": "资金历史",
    "deposit": "订金",
    "withdraw": "提现",
    "transfer": "转移",
    "tablePlaceholder": "按硬幣、地址、備忘錄搜索...",
    "tableWithdrawPlaceholder": "按硬币、哈希、评论搜索...",
    "tableColumns": {
      "coin": "硬币",
      "amountUsd": "美元金額",
      "amount": "数量",
      "status": "地位",
      "date": "日期",
      "hash": "哈希",
      "comment": "评论"
    },
    "status": {
      "completed": "完毕",
      "pending": "待定状态",
      "error": "错误",
      "awaiting": "等待",
      "canceled": "取消"
    },
    "userTransfer": {
      "userTransfer": "转移给用户",
      "inputs": {
        "recipient": "接受者",
        "amount": "数量"
      },
      "userTransferHistory": "用户转移历史",
      "tableColumns": {
        "sender": "发件人",
        "recipient": "接受者",
        "amount": "数量",
        "date": "日期"
      },
      "tablePlaceholder": "按发件人、收件人、货币搜索...",
      "me": "我"
    }
  },
  "kr": {
    "title": "자금 내역",
    "deposit": "보증금",
    "withdraw": "철회하다",
    "transfer": "전송을",
    "tablePlaceholder": "코인, 주소, 메모로 검색...",
    "tableWithdrawPlaceholder": "코인, 해시, 댓글로 검색...",
    "tableColumns": {
      "coin": "동전",
      "amountUsd": "USD 금액",
      "amount": "금액이",
      "status": "상태",
      "date": "날짜",
      "hash": "해시",
      "comment": "논평"
    },
    "status": {
      "completed": "완전한",
      "pending": "보류 중",
      "error": "오류",
      "awaiting": "대기 중",
      "canceled": "취소 된"
    },
    "userTransfer": {
      "userTransfer": "사용자에게 전송",
      "inputs": {
        "recipient": "받는 사람",
        "amount": "금액"
      },
      "userTransferHistory": "사용자 전송 기록",
      "tableColumns": {
        "sender": "발신자",
        "recipient": "받는 사람",
        "amount": "금액",
        "date": "날짜"
      },
      "tablePlaceholder": "보낸 사람, 받는 사람, 통화로 검색...",
      "me": "나"
    }
  }
}
</i18n>
<template>
  <Page :title="$t('title')" class="dashboard">
    <PageContainer :no-line="true">
      <div class="container-fluid" style="margin-bottom: 40px;">

        <div style="padding-bottom: 9px; margin: 20px 0;">
          <span class="tabs" @click="historyType = type" :class="{ active: historyType === type }" v-for="type, index in types" :key="'type ' + index">
            {{ $t(type) }}
          </span>
        </div>

        <div class="container-fluid" v-show="historyType === 'deposit'">
          <OnlineTable
              parse-url="/main/user/deposits"
              :column-list="tableDepositColumns"
              default-sort="date"
              :search-placeholder-text="$t('tablePlaceholder')"
              @handle-data="tableDepositData = $event"
          >
            <tr v-for="data, index in tableDepositData" :key="index">
              <td>
                <div style="display: flex; align-items: center; width: 60px;">
                  <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                    <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(data.currency)" alt="">
                  </div>
                  {{ data.currency }}
                </div>
              </td>
              <td>{{ data.amountCurrency.toFixed(6) }}</td>
              <td :class="data.isPending ? 'pending' : 'completed'" style="min-width: 150px;"><span>{{ data.isPending ? $t('status.pending') : $t('status.completed') }}</span></td>
              <td style="min-width: 200px;">{{ data.date | toDate }}</td>
              <td class="td-hash" @click="copyText(data.hash)">{{ data.hash }}</td>
            </tr>
          </OnlineTable>
        </div>

        <div class="container-fluid" v-show="historyType === 'withdraw'">
          <OnlineTable
              parse-url="/main/user/withdraws"
              :column-list="tableWithdrawColumns"
              default-sort="date"
              :search-placeholder-text="$t('tableWithdrawPlaceholder')"
              @handle-data="tableWithdrawData = $event"
          >
            <tr v-for="data, index in tableWithdrawData" :key="index">
              <td class="hash" @click="!data.isPending && !data.isError ? copyAddress(data.hash) : null">
                {{ data.isPending && !data.isError ? $t('status.awaiting') : !data.isPending && !data.isError ? data.hash : $t('status.canceled') }}
              </td>
              <td>
                <div style="display: flex; align-items: center; width: 100px;">
                  <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                    <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(data.currency)" alt="">
                  </div>
                  {{ data.amount.toFixed(8) }}
                </div>
              </td>
              <td :class="data.isPending && !data.isError ? 'pending': !data.isPending && !data.isError ? 'completed' : 'error'">{{ data.isPending && !data.isError ? $t('status.pending') : !data.isPending && !data.isError ? $t('status.completed') : $t('status.error') }}</td>
              <td  style="min-width: 200px;">{{ data.date | toDate }}</td>
              <td  style="min-width: 150px;">{{ data.comment }}</td>
            </tr>
          </OnlineTable>
        </div>

        <div class="container-fluid" v-show="historyType === 'transfer'">
          <OnlineTable
              parse-url="/main/user/userTransfer"
              :column-list="tableTransferColumns"
              default-sort="date"
              :search-placeholder-text="$t('userTransfer.tablePlaceholder')"
              @handle-data="tableTransferData = $event"
          >
            <tr v-for="data, index in tableTransferData" :key="index">
              <td>{{ data.userInfo.email === $store.state.Index.userInfo.email ? $t('userTransfer.me') : hideUserEmail(data.userInfo.email) }}</td>
              <td>{{ data.recipientInfo.email === $store.state.Index.userInfo.email ? $t('userTransfer.me') : hideUserEmail(data.recipientInfo.email) }}</td>
              <td>
                <div style="display: flex; align-items: center; width: 100px;">
                  <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                    <img style="height: 100%; vertical-align: unset;" :src="$coinImage(data.currency)" alt="">
                  </div>
                  {{ data.amount.toFixed(8) }}
                </div>
              </td>
              <td style="min-width: 200px;">{{ data.date | toDate }}</td>
            </tr>
          </OnlineTable>
        </div>

      </div>
    </PageContainer>
  </Page>
</template>

<script>
import PageContainer from "@/components/main/UI/PageContainer";
import Page from "@/components/main/UI/Page";
import axios from "@/plugins/axios";
import OnlineTable from "@/components/main/UI/OnlineTable";
import CoinsDropInput from "@/components/main/UI/CoinsDropInput";
import InputBox from "@/components/main/UI/InputBox";
import Button from "@/components/main/UI/Button";

export default {
  name: "Orders",
  components: {
    PageContainer,
    Page,
    OnlineTable
  },
  data() {
    return {
      types: ['deposit', 'withdraw', 'transfer'],
      historyType: 'deposit',
      tableDepositData: [],
      tableDepositColumns: [
        {
          name: 'currency',
          title: this.$t('tableColumns.coin'),
          isSortable: true
        },
        {
          name: 'amountCurrency',
          title: this.$t('tableColumns.amount'),
          isSortable: true
        },
        {
          name: 'status',
          title: this.$t('tableColumns.status'),
          isSortable: true
        },
        {
          name: 'date',
          title: this.$t('tableColumns.date'),
          isSortable: true
        },
        {
          name: 'hash',
          title: this.$t('tableColumns.hash'),
          isSortable: true
        }
      ],

      tableWithdrawData: [],
      tableWithdrawColumns: [
        {
          name: 'hash',
          title: this.$t('tableColumns.hash'),
          isSortable: true
        },
        {
          name: 'amountCurrency',
          title: this.$t('tableColumns.amount'),
          isSortable: true
        },
        {
          name: 'status',
          title: this.$t('tableColumns.status'),
          isSortable: true
        },
        {
          name: 'date',
          title: this.$t('tableColumns.date'),
          isSortable: true
        },
        {
          name: 'comment',
          title: this.$t('tableColumns.comment'),
          isSortable: true
        }
      ],
      tableTransferData: [],
      tableTransferColumns: [
        {
          name: 'userInfo.email',
          title: this.$t('userTransfer.tableColumns.sender'),
          isSortable: true
        },
        {
          name: 'recipientInfo.email',
          title: this.$t('userTransfer.tableColumns.recipient'),
          isSortable: true
        },
        {
          name: 'amount',
          title: this.$t('userTransfer.tableColumns.amount'),
          isSortable: true
        },
        {
          name: 'date',
          title: this.$t('userTransfer.tableColumns.date'),
          isSortable: true
        }
      ]
    }
  },
  methods: {
    hideUserEmail(email) {
      return email.slice(0, 3) + "***" + email.slice(email.indexOf("@"), email.length);
    }
  }
}
</script>

<style lang="scss" scoped>

.dark {
  .tabs.active {
    background-color: rgb(35, 38, 39);
  }
  .tabs:hover {
    background-color: rgb(35, 38, 39);
  }
}

.tabs {
  padding: 10px 10px;
  margin: 0 10px;
  transition: .3s;
  border-radius: 2px;
  cursor: pointer;
}

.tabs:first-child {
  margin-left: 0;
}

.tabs:hover {
  background-color: #edf0f5;
}

.tabs.active {
  background-color: #edf0f5;
}


.completed {
  color: #00871e !important;
}

.waiting {
  color: #216fc3!important;
}

.expired {
  color: #d90518!important;
}

.pending {
  color: #e9b10f!important;
}
td.td-hash {
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  cursor: pointer;
}

</style>