import axios from "../../plugins/axios";

const state = {
  axios
}

const getters = {

}

const mutations = {

}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}