<template>
  <div class="Notification" v-if="userInfo.notifications && userInfo.notifications.length !== 0" :class="{ dark: $store.state.Index.isDarkMode }">
    <div class="window col-lg-4 col-md-6 col-xs-12">
      <img class="notification-background" src="/notification_new.jpg" alt="">
      <div class="header">
        <h3>{{ showingNotification.title }} </h3>
      </div>
      <div class="body">{{ showingNotification.text }}</div>
      <div v-if="userInfo.notifications.length > 1">
        <div style="text-align: center; margin-top: 10px; color: white;">{{ currentIndex+1 }} of {{ userInfo.notifications.length }} notifications</div>
        <div class="buttons">
          <button @click="changeNotification(currentIndex + 1)" style="float: right;" v-show="currentIndex < userInfo.notifications.length - 1">Next</button>
        </div>
      </div>
      <div class="close">
        <span @click="$store.dispatch('Index/setNotificationWindowStatus')">Close</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import {mapState} from "vuex";

export default {
  name: "NotificationWindow",
  data() {
    return {
      currentIndex: 0
    }
  },
  methods: {
    changeNotification(index) {
      this.currentIndex = index;
      this.setNotificationAsRead();
    },
    async setNotificationAsRead(){
      try {
        await axios.post('/main/user/readNotification', { notificationId: this.userInfo.notifications[this.currentIndex]._id });
      } catch (e) {
        //e
      }
    }
  },
  computed: {
    ...mapState('Index', [
      'userInfo'
    ]),
    showingNotification() {
      return this.userInfo.notifications[this.currentIndex]
    }
  },
  mounted() {
    this.setNotificationAsRead();
  }
}
</script>

<style lang="scss" scoped>

@media (max-width: 650px) {
  .Notification div.window {
    width: 100% !important;
  }
}

div.Notification.dark {
  background-image: initial;
  background-color: rgba(6, 6, 6, 0.7);

  div.window {
  }
  div.window div.body {
    color: #000000;
  }
  div.window div.header h3 {
    color: #000000;
  }
  div.window div.buttons button {
    outline-color: initial;
    background-image: initial;
    background-color: rgb(41, 43, 44);
    border-color: rgb(56, 60, 62);
  }
}

  div.Notification {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999999;
    border-radius: 5px;
    position: fixed;

    div.window {
      background: white;
      position: relative;
      width: 650px;
      left:50%;
      top:50%;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      height: 300px;
      padding: 0 !important;

      .notification-background {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        z-index: 100;
      }

      div.header {

        z-index: 101;
        position: relative;
        padding: 10px 15px;
        h3 {
          max-width: 59%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 0;
          margin-bottom: 0;
          font-size: 28px;
          font-weight: 800;
          line-height: 36px;
          color: #000000;
        }
      }

      div.body {
        height: 235px;
        padding: 0 15px;
        width: 59%;
        position: relative;
        z-index: 101;
        max-height: 500px;
        overflow-y: auto;
        line-height: 28px;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        word-break: break-word;
      }

      div.buttons {
        margin-top: 15px;
        text-align: center;

        button {
          outline: none;
          background: #e7e7e7;
          border: 1px solid #e7e7e7;
          border-radius: 5px;
          padding: 5px 12px;
        }
      }

      div.close {
        color: white !important;
        z-index: 101;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        cursor: pointer;
      }
    }
  }
</style>