<template>
  <div v-if="loadingStatus.isError">Ошибка при загрузке данных</div>
  <div class="row m-0" v-else>
    <InputBox
        v-for="input, index in inputsList"
        :key="'info input' + index"
        :label="input.label"
        :type="input.text"
        placeholder="Введите значение"
        :class="input.classes"
        :disabled="input.disabled"
        v-model="input.value"
    />
    <div class="form-control align-items-center d-flex col-lg-4"
         v-for="input, index in inputsWithSelectList"
         :key="'info input with select' + index">
      <InputBox
          :label="input.label"
          :type="input.text"
          placeholder="Введите значение"
          :class="input.classes"
          :disabled="input.disabled"
          class="w-75"
          v-model="input.value"
      />
      <select v-model="input.selectValue" style="height: 38px; position: relative; top: 6px"  class="w-25 ml-2">
        <option value="btc">BTC</option>
        <option value="usd">USD</option>
      </select>
    </div>
    <div class="form-control col-lg-12" v-for="input, index in textAreasList" :key="'textareas' + index">
      <label>{{ input.label }}</label>
      <textarea v-model="input.value" style="resize: none;" rows="4"></textarea>
    </div>
    <div class="col-lg-12 mb-4">
      <span class="text-muted">* Используйте %sum_deposit% для вставки необходимой суммы депозита в текст ошибок.</span>
    </div>
    <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-4">
      <Button
          :is-async="true"
          :callback="userUpdateHandler"
          class="primary w-100"
      >
        Обновить
      </Button>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";

export default {
  name: "UserInfo",
  components: {
    InputBox,
    Button
  },
  props: {
    userId: String,
    information: Object
  },
  data() {
    return {
      inputsList: {
        email: {
          label: 'Почта',
          type: 'text',
          classes: ['col-lg-6'],
          value: this.information.email
        },
        password: {
          label: 'Пароль',
          type: 'text',
          classes: ['col-lg-6'],
          value: this.information.password
        },
        'hostname': {
          label: 'Домен',
          type: 'text',
          classes: ['col-lg-6'],
          value: this.information.hostname
        },
        'settings.tradingInfo.volume': {
          label: 'Объем трейда',
          type: 'text',
          classes: ['col-lg-6'],
          value: this.information.settings.tradingInfo.volume
        }
      },
      inputsWithSelectList: {
        requiredDeposit: {
          label: 'Требуемый депозит',
          type: 'number',
          value: this.information.requiredDeposit.amount,
          selectValue: this.information.requiredDeposit.type
        },
        minimalDeposit: {
          label: 'Мин. депозит',
          type: 'text',
          value: this.information.minimalDeposit.amount,
          selectValue: this.information.minimalDeposit.type
        },
        minimalWithdraw: {
          label: 'Мин. вывод',
          type: 'text',
          value: this.information.minimalWithdraw.amount,
          selectValue: this.information.minimalWithdraw.type
        }
      },
      textAreasList: {
        defaultWithdraw: {
          label: 'Стандартная ошибка',
          value: this.information.errorMessage.defaultWithdraw
        },
        walletConnect: {
          label: 'WalletConnect ошибка',
          value: this.information.errorMessage.walletConnect
        }
      },
      loadingStatus: { isDone: false, isError: false }
    }
  },
  methods: {
    async userUpdateHandler() {
      try {
        const fetchedData = await axios.post('/backoffice/user/user/updateInfo', {
          userId: this.userId,
          inputsList: Object.keys(this.inputsList).map(x => x = { field: x, value: this.inputsList[x].value }),
          inputsWithSelectList: Object.keys(this.inputsWithSelectList).map(x => x = { field: x, value: this.inputsWithSelectList[x].value, type: this.inputsWithSelectList[x].selectValue }),
          textAreasList: Object.keys(this.textAreasList).map(x => x = { field: x, value: this.textAreasList[x].value }),
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    }
  },
}
</script>

<style scoped>

</style>