<template>
  <Page
      title="Юзеры"
      parse-url="/backoffice/user/users"
      @loaded-data="dataLoading($event)"
  >
    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header">
          <h4>Статистика месяца</h4>
        </div>
        <div style="text-align: center;" class="row m-0">
          <div class="border dashed start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.totalUsers }}</p>
            <p class="text-muted m-0">Всего</p>
          </div>
          <div class="border dashed end start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.currentMonth }}</p>
            <p class="text-muted m-0">Текущий месяц</p>
          </div>
        </div>
        <div style="overflow-y: auto;" class="chart p-4">
          <Bar
              :chart-options="chartOptions"
              :chart-data="chartData"
              :styles="{ 'min-width': '800px' }"
              :height="200"
              v-if="isStatisticsLoaded"
          />
          <div @click="loadStatistics" class="d-flex align-items-center justify-content-center text-muted" style="cursor: pointer; font-size: 12px;" v-else>
            {{ statisticText }}
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="Card col-lg-6 col-md-6 col-xs-12 mb-5">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Привязка юзера</h4>
          </div>
          <div class="p-4">
            <InputBox
                label="Айди, почта или адрес депозита"
                type="text"
                placeholder="Введите айди, адрес, почту или кошелек"
                @input="attachUserInput = $event"
            />
            <Button
                :is-async="true"
                :callback="attachUserHandler"
                class="primary w-100"
                :is-disabled="attachUserInput.trim() === ''"
            >
              Привязать
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-12 mb-4">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Привязанные юзеры</h4>
        </div>
        <Table
            ref="usersTableComponent"
            parse-url="/backoffice/user/spammerUsers"
            :columnList="usersTableColumns"
            default-sort="attachedDate"
            searchPlaceholderText="Введите айди, почту, адрес, страну, промокод или домен"
            @handle-data="usersTableData = $event"
        >
          <tr v-for="user, index in usersTableData" :key="'user' + index">
            <td>
              <router-link :to="'/back-office/users/' + user._id">
                {{ user.email }}
              </router-link>
            </td>
            <td>{{ user.attachedDate | toDate }}</td>
            <td>{{ user.settings.lastActiveDate | toDate('recent') }}</td>
            <td>
              <span style="font-size: 13px;" class="d-flex align-items-center">
                <img class="mr-2" width="25px" height="15px" :src="'/country/' + user.country.toLowerCase() + '.svg'" />{{ user.country }}
              </span>
            </td>
            <td>{{ user.hostname }}</td>
            <td class="no-animate" style="text-align: center;">
            <span @click="unlinkUser(user._id)" class="delete-btn">
              <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
            </span>
            </td>
          </tr>
        </Table>
      </div>
    </div>

    <div class="Card col-lg-12 mb-5" v-if="!$store.state.Backoffice.spammerInfo.supportMember.isActive">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Экспорт юзеров</h4>
        </div>
        <div class="p-4 row m-0">

          <p>Данные экспортируются в формате "почта:страна:домен:имя:дата_регистрации:сумма_депозита"</p>

          <div class="mb-5">
            <p>Статус экспорта: <span :class="$store.state.Backoffice.spammerInfo.settings.usersExport.isActive ? 'color-success' : 'color-danger'">{{ $store.state.Backoffice.spammerInfo.settings.usersExport.isActive ? "В работе" : "Не в работе" }}</span></p>
            <p>Последняя дата экспорта: <span :class="$store.state.Backoffice.spammerInfo.settings.usersExport.isActive ? 'color-warning' : $store.state.Backoffice.spammerInfo.settings.usersExport.lastDate ? 'color-success' : 'color-danger'">{{ $store.state.Backoffice.spammerInfo.settings.usersExport.lastDate ? $options.filters.toDate($store.state.Backoffice.spammerInfo.settings.usersExport.lastDate) : "Никогда" }}</span></p>
            <div v-show="$store.state.Backoffice.spammerInfo.settings.usersExport.fileName">
              <a :href="`/files/office/${$store.state.Backoffice.spammerInfo.settings.usersExport.fileName}`">Ссылка на файл</a>
              <p>Количество юзеров: <span :class="$store.state.Backoffice.spammerInfo.settings.usersExport.isActive ? 'color-warning' : 'color-success'">{{ $store.state.Backoffice.spammerInfo.settings.usersExport.count }}</span></p>
            </div>
          </div>

          <div class="form-controls mb-3">
            <div class="col-lg-12 mb-3 d-flex align-items-center">
              <label class="mr-3">Дата регистрации</label>
              <SwitchButton
                  :is-checked="userRegisterDate.isActive"
                  @switch-change="userRegisterDate.isActive = !userRegisterDate.isActive"
              />
            </div>
            <InputBox
                label="От даты регистрации"
                type="date"
                class="col-lg-6"
                v-model="userRegisterDate.minValue"
            />
            <InputBox
                label="До даты регистрации"
                type="date"
                class="col-lg-6"
                v-model="userRegisterDate.maxValue"
            />
          </div>

          <div class="form-controls mb-3">
            <div class="col-lg-12 mb-3 d-flex align-items-center">
              <label class="mr-3">Последнее время активности</label>
              <SwitchButton
                  :is-checked="userLastActiveDate.isActive"
                  @switch-change="userLastActiveDate.isActive = !userLastActiveDate.isActive"
              />
            </div>
            <InputBox
                label="От минут назад"
                type="number"
                class="col-lg-6"
                placeholder="Введите минимальное значение в минутах"
                v-model="userLastActiveDate.minValue"
            />
            <InputBox
                label="До минут назад"
                type="number"
                class="col-lg-6"
                placeholder="Введите максимальное значение в минутах"
                v-model="userLastActiveDate.maxValue"
            />
          </div>

          <div class="form-controls mb-3">
            <div class="col-lg-12 mb-3 d-flex align-items-center">
              <label class="mr-3">Внесено долларов</label>
              <SwitchButton
                  :is-checked="userDeposited.isActive"
                  @switch-change="userDeposited.isActive = !userDeposited.isActive"
              />
            </div>
            <InputBox
                label="От $"
                type="number"
                class="col-lg-6"
                placeholder="Введите минимальное значение в долларах"
                v-model="userDeposited.minValue"
            />
            <InputBox
                label="До $"
                type="number"
                placeholder="Введите максимальное значение в долларах"
                class="col-lg-6"
                v-model="userDeposited.maxValue"
            />
          </div>

          <div class="form-controls col-lg-6 mb-3">
            <div class="mb-3 d-flex align-items-center">
              <label class="mr-3">Использовал определенный промокод</label>
              <SwitchButton
                  :is-checked="promocode.isActive"
                  @switch-change="promocode.isActive = !promocode.isActive"
              />
            </div>
            <InputBox
                label="Промокод"
                type="text"
                placeholder="Введите промокод"
                v-model="promocode.text"
            />
          </div>

          <div class="form-controls col-lg-6 mb-3">
            <div class="mb-3 d-flex align-items-center">
              <label class="mr-3">Только на определенном домене</label>
              <SwitchButton
                  :is-checked="domain.isActive"
                  @switch-change="domain.isActive = !domain.isActive"
              />
            </div>
            <InputBox
                label="Домен"
                type="text"
                placeholder="Введите домен по шаблону domain.com"
                v-model="domain.text"
            />
          </div>

          <div class="form-controls col-lg-12 mb-3">
            <div class="mb-3 d-flex align-items-center">
              <label class="mr-3">Определенные страны</label>
              <SwitchButton
                  :is-checked="country.isActive"
                  @switch-change="country.isActive = !country.isActive"
              />
            </div>
            <InputBox
                label="Список стран"
                type="text"
                placeholder="Введите страны по шаблону US,GB,TR"
                class="col-lg-6 p-0 pr-4"
                v-model="country.text"
            />
          </div>

          <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mt-4 mb-4">
            <Button
                :is-async="true"
                :callback="searchStatistic"
                class="primary w-100"
            >
              Экспортировать
            </Button>
          </div>

        </div>
      </div>
    </div>

  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Filler, Tooltip, Legend, BarElement, LineElement, LineController, PointElement, CategoryScale, LinearScale, LogarithmicScale, LogarithmicScaleOptions } from 'chart.js'
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton.vue";

ChartJS.register(Title, Tooltip, Legend, Filler, BarElement, LineElement, CategoryScale, LineController, PointElement, LinearScale, LogarithmicScale)
export default {
  name: "Users",
  components: {
    SwitchButton,
    Page,
    Bar,
    Button,
    InputBox,
    Table
  },
  data() {
    return {
      statisticData: {
        findedUsers: [],
        countryData: {},
        total: { n: 0, d: 0 }
      },
      afkTimesList: [
        {
          title: 'Час',
          minValue: 60,
          maxValue: 70
        },
        {
          title: '12 часов',
          minValue: 60 * 12,
          maxValue: 60 * 13
        },
        {
          title: 'День',
          minValue: 60 * 24,
          maxValue: 60 * 25
        },
        {
          title: '2 дня',
          minValue: 60 * 24 * 2,
          maxValue: 60 * 25 * 2
        },
        {
          title: 'Неделя',
          minValue: 60 * 24 * 7,
          maxValue: 60 * 24 * 8
        },
        {
          title: 'Месяц',
          minValue: 60 * 24 * 30,
          maxValue: 60 * 24 * 31
        }
      ],
      userRegisterDate: {
        isActive: false,
        minValue: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7).toISOString().substring(0,10),
        maxValue: new Date().toISOString().substring(0,10),
      },
      userLastActiveDate: {
        isActive: false,
        minValue: 10,
        maxValue: 120,
      },
      userDeposited: {
        isActive: false,
        minValue: 0,
        maxValue: 100,
      },
      age: {
        isActive: false,
        minValue: 18,
        maxValue: 40,
      },
      promocode: {
        isActive: false,
        text: null
      },
      domain: {
        isActive: false,
        text: null
      },
      country: {
        isActive: false,
        text: null
      },
      isStatisticsLoaded: false,
      isStatisticsLoading: false,
      statisticText: 'Нажмите, чтобы загрузить статистику',
      attachUserInput: '',
      findUserInput: '',
      findUserResult: null,
      usersTableData: [],
      usersTableColumns: [
        {
          name: 'email',
          title: 'Почта',
          isSortable: true
        },
        {
          name: 'attachedDate',
          title: 'Дата привязки',
          isSortable: true
        },
        {
          name: 'settings.lastActiveDate',
          title: 'Посл. активность',
          isSortable: true
        },
        {
          name: 'country',
          title: 'Страна',
          isSortable: true
        },
        {
          name: 'hostname',
          title: 'Домен',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        },
      ],
      fetchedData: {
        users: {
          total: 0,
          data: [],
          percentDifference: 0
        }
      },
      chartData: {
        labels: Array.from({ length: new Date(new Date().getFullYear(), 1, 0).getDate() }, (_, i) => i + 1),
        datasets: [
          {
            type: 'bar',
            label: 'Юзеры',
            data: [],
            backgroundColor: '#0DA592',
            barThickness: 10,
            order: 2
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },
        scales: {
          yAxes: {
            grace: '10%',
            ticks: {
              precision: 0, // точность округления значений по оси y
              maxTicksLimit: 6, // максимальное количество значений (и рёбер сетки) по оси y
            }
          }
        }
      }
    }
  },
  methods: {
    async searchStatistic() {
      try {
        const fetchedData = await axios.post('backoffice/user/user/exportUsers', {
          statisticInfo: {
            userRegisterDate: this.userRegisterDate,
            userLastActiveDate: this.userLastActiveDate,
            newNotificationInfo: this.newNotificationInfo,
            userDeposited: this.userDeposited,
            promocode: this.promocode,
            domain: this.domain,
            country: this.country,
            //age: this.age
          }
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
   async unlinkUser(userId) {
     const confirmDelete = confirm('Вы уверены, что хотите отвязать этого юзера?');
     if (!confirmDelete) return;

     try {
       const fetchedData = await axios.post('backoffice/user/user/unlink', {
         userId
       });
       this.$callNotification(
           this.$i18n.locale,
           fetchedData
       );
       if (!fetchedData.data.error) this.$refs.usersTableComponent.fetchTableData();
     } catch (error) {
       console.log(error)
       this.$callNotification(this.$i18n.locale, error);
     }
   },
   async attachUserHandler() {
      try {
        const fetchedData = await axios.post('backoffice/user/user/attach', {
          userInfo: this.attachUserInput
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (!fetchedData.data.error) this.$refs.usersTableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async findUserHandler() {
      try {
        const fetchedData = await axios.post('backoffice/user/user/find', {
          userInfo: this.findUserInput
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );

        if (!fetchedData.data.error) this.findUserResult = fetchedData.data.userInfo;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    dataLoading(data) {
      this.fetchedData = data;
    },
    async loadStatistics() {
      try {
        if (this.isStatisticsLoading) return;
        this.statisticText = "Загрузка данных..."
        this.isStatisticsLoading = true;
        const fetchedData = await axios.get('backoffice/user/users/statistics');

        if (fetchedData.data.error) return this.statisticText = "Ошибка при загрузке статистики";
        this.chartData.datasets[0].data = fetchedData.data.users.data;
        this.isStatisticsLoaded = true;
        this.isStatisticsLoading = false;
      } catch (error) {
        console.log(error)
        this.isStatisticsLoading = false;
        this.statisticText = "Ошибка при загрузке статистики"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
span.delete-btn {
  text-align: center;
  cursor: pointer;

.icon {
  transition: .1s;
&:hover {
   color: #f06548;
 }
}
}
</style>
