<template>
  <div class="row m-0">
    <div class="no-chat" v-if="!ticketId">
      Выберите тикет, который хотели бы открыть
    </div>
    <div class="loading" v-else-if="!loadingStatus.isDone || loadingStatus.isError">
      <span v-show="!loadingStatus.isDone && !loadingStatus.isError">Загрузка чата...</span>
      <span v-show="loadingStatus.isError">Ошибка при загрузке чата<div class="text-muted mt-2">{{ customError }}</div></span>
    </div>
    <div :class="{ 'p-0': $store.state.Index.isMobile }" class="Chat" v-else>
      <div class="Card mb-2">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Информация</h4>
          </div>
          <div style="font-size: 12px;" class="d-flex justify-content-space-between m-0 p-4">
            <div>
              <div>Айди: {{ ticketInfo.id }}</div>
              <div>Категория: {{ ticketInfo.category }}</div>
              <div>Заголовок: {{ ticketInfo.subject }}</div>
              <div>Дата создания: {{ ticketInfo.date | toDate }}</div>
              <div>Статус:
                <span :class="{ 'color-danger': ticketInfo.isClosed, 'color-warning': !ticketInfo.isAnswered && !ticketInfo.isClosed, 'color-success': ticketInfo.isAnswered && !ticketInfo.isClosed }">
                  {{ ticketInfo.isClosed ? 'Закрыт' : ticketInfo.isAnswered ? 'Отвечен' : 'Ожидает ответа' }}
                </span>
              </div>
            </div>
            <div class="d-flex" style="align-items: end;">
              <Button
                  :class="ticketInfo.isClosed ? 'success' : 'danger'"
                  :callback="() => updateStatus(!ticketInfo.isClosed)"
              >
                {{ ticketInfo.isClosed ? 'Открыть' : 'Закрыть' }} тикет
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div :class="{ mobile: $store.state.Index.isMobile }" class="Chat-header p-3">
        <div style="position: relative;" class="d-flex align-items-center">
          <span style="position: absolute; right: 0; font-size: 11px;">{{ userInfo.hostname }}</span>
          <h4 style="font-weight: 500;" class="mt-0 mb-1"><router-link style="text-overflow: ellipsis; display: block; max-width: 170px; overflow: hidden; white-space: nowrap;" :to="`/back-office/users/${userInfo._id}`">{{ userInfo.email }}</router-link></h4>
          <img class="ml-3 mr-1" width="25px" height="15px" :src="'/country/' + userInfo.country.toLowerCase() + '.svg'" /> <span style="font-size: 12px;">{{ userInfo.country }}</span>
          <span class="text-muted ml-3" style="font-size: 11px;">~{{ userInfo.deposited | usdFormat }}</span>
        </div>
        <div style="font-size: 12px;" class="d-flex justify-content-space-between align-items-center">
          <span class="d-flex align-items-center text-muted">
            <span
                style="width: 10px; height: 10px; border-radius: 50%; display: inline-block;"
                :class="{ 'bg-success': lastUserOnline <= 1000 * 60 * 1 * 10, 'bg-warning': lastUserOnline >= 1000 * 60 * 1 * 10 && lastUserOnline <= 1000 * 60 * 1 * 60, 'bg-danger': lastUserOnline > 1000 * 60 * 60,}"
                class="mr-2"
            >
            </span>
            Last online {{ userInfo.settings.lastActiveDate | toDate('recent') }}
          </span>

          <div title="Заметка" @click="changeUserDescription()" style="cursor: pointer;" class="text-muted d-flex align-items-center justify-content-center">
            {{ userInfo.liveSupportDescription || 'Изменить заметку' }}
          </div>
        </div>
      </div>
      <div class="Chat-body" ref="chatBox">
        <div class="messages">
          <div class="wrapper" :class="{ editing: currentEditMessage === message._id }" v-for="message, index in ticketInfo.messages" :key="'ticket messages' + index">
            <div :class="{ 'mr-auto': message.isSupport, 'ml-auto': !message.isSupport }" class="message">
              <div style="position: relative;">
                <div class="more-btn" @click="openDropdownMenu('message_' + index)" v-if="message.isSupport">
                  <font-awesome-icon
                      class="icon"
                      :icon="['fal', 'ellipsis-v']"
                  />

                  <div class="dropdown-menu" :id="'message_' + index">
                    <span class="dropdown-item" @click="enableMessageEditMode(message._id, message.text, message.supportMember)">Изменить</span>
                    <span class="dropdown-item" @click="deleteMessage(message._id)">Удалить</span>
                  </div>
                </div>
                <span class="text">{{ message.text }}</span>
                <span class="date mt-2">
                <span class="is-read" v-show="message.isSupport && message.isRead">
                  <font-awesome-icon
                      class="color-success"
                      :icon="['fal', 'check-double']"
                  />
                </span>
                {{ message.date | toDate }}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Chat-typeArea p-4 row m-0">
        <div @click="disableMessageEditMode" class="edit-mode color-danger" v-show="isMessageEditorModeEnabled">
          Режим редактирования <font-awesome-icon :icon="['fal', 'times']" />
        </div>
        <div class="col-lg-12 d-flex p-0 mt-5">
          <div class="form-control mb-0 mr-2 w-100">
            <textarea
                maxlength="1500"
                v-model.trim="messageInput"
                placeholder="Наберите ваше сообщение..."
                style="resize: vertical;" rows="4"
            />
          </div>
          <Button
              class="success"
              :callback="isMessageEditorModeEnabled ? editMessage : sendMessage"
          >
            <font-awesome-icon
                :icon="isMessageEditorModeEnabled ? ['fas', 'check'] : ['fas', 'paper-plane']"
            />
          </Button>
        </div>

        <div class="col-lg-12 d-flex p-0 pb-2 form-control mb-0">
          <select style="font-size: 12px;" class="mt-2" v-model="selectedPreset" @change="selectOnChange($event)">
            <option value="choosePreset" disabled selected>Выберите пресет</option>
            <option v-for="preset, index in $store.state.Backoffice.spammerInfo.settings.presets" :value="preset.message" :key="' presets ' + index">{{ preset.title }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import Button from "@/components/backoffice/UI/Button";
import * as workerTimers from 'worker-timers';

export default {
  name: "Chat",
  props: {
    ticketId: String
  },
  components: {
    Button
  },
  data() {
    return {
      selectedPreset: 'choosePreset',
      isChatLoading: false,
      loadingStatus: { isError: false, isDone: false },
      ticketInfo: [],
      userInfo: {},
      currentTicketId: null,
      sendButtonDisabled: false,
      customError: '',
      messageInput: '',
      currentEditMessage: '',
      isMessageEditorModeEnabled: false,
      autoUpdate: true,
    }
  },
  methods: {
    selectOnChange() {
      this.messageInput = this.selectedPreset;
    },
    async changeUserDescription() {
      try {
        const userDescription = prompt("Введите заметку для юзера (максимум 30 символов):", this.userInfo.liveSupportDescription);
        if (userDescription === null || userDescription.length <= 0 || userDescription.length > 30) return;

        const fetchedData = await axios.post("/backoffice/user/chat/newDescription", { userId: this.userInfo._id, description: userDescription });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$set(this.userInfo, 'liveSupportDescription', userDescription);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    onChatBoxScroll(el) {
      console.log(el.srcElement.scrollTop, el.srcElement.scrollHeight)
    },
    openDropdownMenu(id) {
      const otherMenus = document.getElementsByClassName('show-menu');
      for (const el of otherMenus) {
        if (el.id !== id) el.classList.remove('show-menu');
      }
      document.getElementById(id).classList.toggle('show-menu');
    },
    enableMessageEditMode(id, message) {
      this.isMessageEditorModeEnabled = true;
      this.messageInput = message;
      this.currentEditMessage = id;
    },
    disableMessageEditMode() {
      this.isMessageEditorModeEnabled = false;
      this.messageInput = '';
      this.currentEditMessage = '';
    },
    async deleteMessage(messageId) {
      try {
        this.disableMessageEditMode();
        const fetchedData = await axios.post("/backoffice/user/ticket/deleteMessage", { ticketId: this.ticketId, messageId });
        if (fetchedData.data.error) return this.$callNotification(this.$i18n.locale, fetchedData);
        const messageIndex = this.ticketInfo.messages.findIndex(x => x._id === messageId);
        if (messageIndex !== -1) this.ticketInfo.messages.splice(messageIndex, 1);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateStatus(status) {
      try {
        this.disableMessageEditMode();
        const fetchedData = await axios.post("/backoffice/user/ticket/updateStatus", { ticketId: this.ticketId, status });
        if (fetchedData.data.error) return this.$callNotification(this.$i18n.locale, fetchedData);
        this.ticketInfo.isClosed = status;
        this.$emit('update-chats');
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async sendMessage() {
      try {
        this.messageInput = this.messageInput.trim();
        if (this.messageInput === '') return;
        this.sendButtonDisabled = true;
        const fetchedData = await axios.post("/backoffice/user/ticket/newMessage", { ticketId: this.ticketId, text: this.messageInput });
        if (fetchedData.data.error) return this.$callNotification(this.$i18n.locale, fetchedData);
        this.messageInput = '';
        this.sendButtonDisabled = false;
        this.ticketInfo.messages.push(fetchedData.data.message);
        this.$nextTick(() => this.scrollToChatBottom());
        this.$emit('update-chats');
      } catch (error) {
        console.log(error)
        this.sendButtonDisabled = false;
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async editMessage() {
      try {
        this.messageInput = this.messageInput.trim();
        if (this.messageInput === '') return;
        this.sendButtonDisabled = true;
        const fetchedData = await axios.post("/backoffice/user/ticket/editMessage", { ticketId: this.ticketId, messageId: this.currentEditMessage, text: this.messageInput });
        if (fetchedData.data.error) return this.$callNotification(this.$i18n.locale, fetchedData);
        this.sendButtonDisabled = false;
        const messageIndex = this.ticketInfo.messages.findIndex(x => x._id === this.currentEditMessage);
        if (messageIndex !== -1) this.ticketInfo.messages[messageIndex] = fetchedData.data.message;
        this.disableMessageEditMode();
      } catch (error) {
        console.log(error)
        this.sendButtonDisabled = false;
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateChat() {
      try {
        if (this.isChatLoading) return;
        this.isChatLoading = true;
        const fetchedData = await axios.post("/backoffice/user/ticket", { ticketId: this.ticketId });
        if (fetchedData.data.error) {
          this.customError = fetchedData.data.description;
          this.clearCurrentInterval();
          return this.loadingStatus.isError = true;
        }
        if (this.ticketInfo.messages.length < fetchedData.data.ticketInfo.messages.length && fetchedData.data.ticketInfo.messages.length && !fetchedData.data.ticketInfo.messages[fetchedData.data.ticketInfo.messages.length-1].isSupport){
          this.ticketInfo = fetchedData.data.ticketInfo;
          this.userInfo = fetchedData.data.userInfo;
          this.$nextTick(() => this.scrollToChatBottom());
        } else {
          this.ticketInfo = fetchedData.data.ticketInfo;
          this.userInfo = fetchedData.data.userInfo;
        }
        this.isChatLoading = false;
        this.$emit('update-chats');
      } catch (e) {
        console.log(e)
        this.clearCurrentInterval();
        this.loadingStatus.isError = true;
      }
    },
    async loadingChat() {
      try {
        this.currentTicketId = this.ticketId;
        this.loadingStatus.isDone = false;
        this.loadingStatus.isError = false;
        const fetchedData = await axios.post("/backoffice/user/ticket", { ticketId: this.ticketId });
        if (fetchedData.data.error) {
          this.customError = fetchedData.data.description;
          return this.loadingStatus.isError = true;
        }
        this.ticketInfo = fetchedData.data.ticketInfo;
        this.userInfo = fetchedData.data.userInfo;
        this.loadingStatus.isDone = true;
        this.selectedPreset = 'choosePreset';
        this.messageInput = '';
        this.$nextTick(() => this.scrollToChatBottom());
      } catch (e) {
        console.log(e)
        this.loadingStatus.isError = true;
      }
    },
    scrollToChatBottom() {
      const el = this.$refs.chatBox;
      if (el) el.scrollTop = el.scrollHeight;
    },
    clearCurrentInterval() {
      if (this.updateTimer) workerTimers.clearInterval(this.updateTimer);
    }
  },
  computed: {
    lastUserOnline() {
      return Date.now() - this.userInfo.settings.lastActiveDate
    }
  },
  async beforeUpdate() {
    if (this.currentTicketId !== this.ticketId) {
      this.clearCurrentInterval();
      await this.loadingChat()
      this.updateTimer = workerTimers.setInterval(this.updateChat, 10000);
    }
  },
  async mounted() {
    if (this.ticketId) {
      await this.loadingChat();
      document.title = 'Ticket #' + this.ticketInfo.id + ' | Back Office'
      this.updateTimer = workerTimers.setInterval(this.updateChat, 10000);
    }
  },
  beforeDestroy() {
    this.clearCurrentInterval();
  }
}
</script>

<style lang="scss" scoped>

.Chat {
  position: relative;
  padding: 0 4px;
  height: calc(100vh - 78px);
  background: url("/chat-bg-pattern.png");
  background-color: #1A1D21;

  .Chat-header {
    border-bottom: 1px solid transparent;
    background-color: #212529;

    &.mobile {
      padding-left: 30px !important;
    }
  }

  .Chat-body {
    height: calc(100% - 425px);
    box-sizing: border-box;
    display: flex;
    overflow-y: auto;

    div.messages {
      margin-top: auto;
      padding: 24px;
      width: 100%;

      div.wrapper {
        border-radius: 5px;
        padding: 5px 0;
        margin-bottom: 24px;

        &.editing {
          background: #48c7860d;
        }
        &:last-child { margin-bottom: 0; }

        div.message {
          position: relative;
          display: flex;
          max-width: 80%;

          &.mr-auto {
            text-align: right;

            span.text {
              background-color: rgba(10,179,156, .15);
              color: #0ab39c;
            }
          }
          &.ml-auto {
            text-align: left;

            span.text {
              background-color: #2a2f34;
              color: #CED4DA;
            }
          }

          .more-btn {
            position: absolute;
            left: -20px;
            top: 0;
            cursor: pointer;
            width: 20px;
            text-align: center;

            .icon {
              font-size: 26px;
              color: grey;
            }

            .dropdown-menu {
              display: none;
              position: absolute;
              inset: 0px auto auto 0px;
              margin: 0px;
              transform: translate(0px, 34px);
              z-index: 1000;
              font-size: 12px;
              color: #ced4da;
              text-align: left;
              list-style: none;
              background-color: #292e33;
              background-clip: padding-box;
              border: 1px solid #32383e;
              border-radius: 0.3rem;

              &.show-menu { display: block; }

              .dropdown-item {
                display: block;
                width: 100%;
                padding: 0.35rem 1.2rem;
                clear: both;
                font-weight: 400;
                color: #adb5bd;
                text-align: inherit;
                text-decoration: none;
                white-space: nowrap;
                background-color: transparent;
                border: 0;
                transition: .1s;

                &:hover {
                  color: #b9bfc4;
                  background-color: #2f343a;
                }
              }
            }
          }

          span.text {
            display: block;
            padding: 12px 20px;
            position: relative;
            border-radius: 3px;
            order: 2;
            -webkit-box-shadow: none;
            box-shadow: none;
            word-wrap: break-word;
            word-break: break-word;
          }
          span.date {
            display: block;
            color: #878a99 !important;
            font-size: 11px;
          }
        }
      }
    }
  }

  .Chat-typeArea {
    border-top: 1px solid transparent;
    background-color: #212529;
    position: relative;

    .edit-mode {
      position: absolute;
      right: 10px;
      font-size: 12px;
      cursor: pointer;
      z-index: 99;
    }
  }
}

div.loading {
  padding-top: 35px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}


.mr-auto { margin-left: auto !important; justify-content: end; }

.update-icon {
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

div.no-chat {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 24px;
  background: #262A2F;
  border-radius: 15px;
}

</style>