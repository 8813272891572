<template>
  <div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Кастомные страницы</h4>
          </div>
          <div class="p-4 row m-0">

            <div class="form-control align-items-center col-lg-12 p-0">
              <label>Выберите страницу для редактирования</label>
              <select v-model="currentEditPage" style="height: 38px; position: relative; top: 6px"  class="w-100">
                <option :value="domain" v-for="domain, index in Object.keys(editablePages)" :key="'page ' + index">
                  {{ editablePages[domain] }}
                </option>
              </select>
            </div>

            <div class="form-control col-lg-12 d-flex align-items-center p-0">
              <label class="m-0 mr-3">Кастомная страница активна</label>
              <SwitchButton
                  :is-checked="domainInfo.customPages[currentEditPage].isActive"
                  @switch-change="domainInfo.customPages[currentEditPage].isActive = !domainInfo.customPages[currentEditPage].isActive"
              />
            </div>
            <div class="form-control col-lg-12 d-flex align-items-center p-0">
              <label class="m-0 mr-3">Использовать стандартный Navbar</label>
              <SwitchButton
                  :is-checked="domainInfo.customPages[currentEditPage].defaultNavbar"
                  @switch-change="domainInfo.customPages[currentEditPage].defaultNavbar = !domainInfo.customPages[currentEditPage].defaultNavbar"
              />
            </div>
            <div class="form-control col-lg-12 d-flex align-items-center p-0">
              <label class="m-0 mr-3">Использовать стандартный Footer</label>
              <SwitchButton
                  :is-checked="domainInfo.customPages[currentEditPage].defaultFooter"
                  @switch-change="domainInfo.customPages[currentEditPage].defaultFooter = !domainInfo.customPages[currentEditPage].defaultFooter"
              />
            </div>

            <div>
              <p :class="domainInfo.customPages[currentEditPage].isPageLoaded ? 'color-success' : 'color-danger'">HTML файл {{ domainInfo.customPages[currentEditPage].isPageLoaded ? "загружен" : "не загружен" }}</p>
              <br>

              <p class="text-muted m-0 mt-4">Если хотите использовать ссылки с нашего сайта для перехода, используйте ссылку с использованием переменной языка, пример - /en/fees</p>
              <p class="text-muted m-0 mt-4">
                Вы можете вставить в свою страницу некоторые переменные (если цены монет изменены на домене, они будут отображаться как вы указали!):<br>
                %price_COIN% - вставит цену указанной монеты COIN пример - %price_BTC% = 28540.02;<br>
                %price_change_COIN% - вставит изменение цены в процентах за сутки указанной монеты COIN пример - %price_change_BTC% = 1.03;<br>
                %domain_name% - вставит название домена с большой буквы пример - ваш домен bitexample.com -  %domain_name% = Bitexample;<br>
              </p>
              <p>Загрузить файл страницы (.html):</p>
              <div class="d-flex align-items-center">
                <input type="file" accept=".html" @change="uploadFile" :disabled="isFileLoading" />
                <font-awesome-icon class="load-spinner" :icon="['fas', 'spinner']" v-show="isFileLoading"></font-awesome-icon>
              </div>

              <p class="text-muted m-0 mt-4">Для использования загруженных файлов в своем .html используйте путь /files (пример - /files/logo.png)</p>
              <p>Загрузить дополнительные файлы (css, png, jpeg, jpg, svg, js):</p>
              <div class="d-flex align-items-center">
                <input type="file" accept=".css, .png, .jpeg, .jpg, .svg, .js" @change="uploadAdditionalFile" :disabled="isFileLoading" multiple />
                <font-awesome-icon class="load-spinner" :icon="['fas', 'spinner']" v-show="isFileLoading"></font-awesome-icon>
              </div>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mt-5">
              <Button
                  class="w-100 primary"
                  :callback="updateCustomPages"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import axios from "@/plugins/axios";


export default {
  name: "DomainPages",
  props: {
    domainInfo: Object,
    editablePages: Object
  },
  components: {
    Button,
    SwitchButton
  },
  data() {
    return {
      currentEditPage: "index",
      isFileLoading: false
    }
  },
  methods: {
    async uploadFile(e) {
      try {
        if (this.isFileLoading) return;

        this.isFileLoading = true;
        const formData = new FormData();
        formData.append('domainId', this.$route.params.domainId);
        formData.append('page', this.currentEditPage);
        formData.append('file', e.target.files[0]);
        const fetchedData = await axios.post('backoffice/user/domain/loadCustomPage', formData);
        this.$callNotification(this.$i18n.locale, fetchedData);
        this.isFileLoading = false;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
        this.isFileLoading = false;
      }
    },
    async uploadAdditionalFile(e) {
      try {
        if (this.isFileLoading) return;

        this.isFileLoading = true;
        const formData = new FormData();
        formData.append('domainId', this.$route.params.domainId);
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append('file' + i, e.target.files[i]);
        }
        const fetchedData = await axios.post('backoffice/user/domain/loadFilesCustomPage', formData);
        this.$callNotification(this.$i18n.locale, fetchedData);
        this.isFileLoading = false;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
        this.isFileLoading = false;
      }
    },
    async updateCustomPages() {
      try {
        const fetchedData = await axios.post('backoffice/user/domain/customPages', {
          domainId: this.$route.params.domainId,
          pages: this.domainInfo.customPages
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  }
}
</script>

<style scoped>
.terms .section {
  padding: 5px 25px;
  border-bottom: 1px solid #32383E;
  cursor: pointer;
  transition: .3s;
  font-size: 13px;
  border-radius: 5px;
}


.terms .section:hover {
  background: #343B41;
}

.terms .content {
  display: none;
}

.terms.open .content {
  display: block;
  transition: .3s visibility;
}

.terms .content textarea {
  resize: none;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.load-spinner {
  animation: rotating 2s linear infinite;
  margin: 5px 0;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>