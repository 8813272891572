import Vue from 'vue'
import App from './App.vue'
import VueRouter from './router'
import VueStore from './store'
import VueI18n from 'vue-i18n'
import i18n from './i18n'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import HelpfulFunctions from "./plugins/helpful-functions";
import VueSocketIO from "./plugins/vue-socket-io";
import VueExtendLayouts from 'vue-extend-layout';
import VModal from 'vue-js-modal';
import './plugins/filters';

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueI18n);
Vue.use(VModal);
Vue.use(VueExtendLayouts);
Vue.use(VueSocketIO);
Vue.use(HelpfulFunctions, VueStore);
Vue.config.productionTip = false

new Vue({
    i18n,
    router: VueRouter,
    store: VueStore,
    render: h => h(App)
}).$mount('#app')
