<template>
  <div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Список платежных провайдеров на странице Buy crypto</h4>
          </div>
          <div class="p-4 row m-0">

            <div class="alert alert-danger" v-show="!isProvidersSync">
              Ваш список платежных систем отличается от главного списка на сервере! Нажмите "Синхронизировать" чтобы получить актуальный список платежных систем.
            </div>

            <label class="text-muted mb-4" style="display: block; font-size: 12px;">Чтобы поменять платежки местами зажмите платежку и перенесите на нужное место.</label>
            <draggable v-model="domainInfo.buyCryptoProviders" class="mb-4">
              <transition-group>
                <div class="terms" v-for="provider, index in domainInfo.buyCryptoProviders" :key="' crypto buy providers ' + index">

                  <div class="d-flex align-items-center mb-2">
                    <div style="justify-content: space-between;" class="section d-flex w-100">
                      <div>{{ provider.title }}</div>
                      <div class="d-flex align-items-center mb-2">
                        <SwitchButton
                            :is-checked="provider.isActive"
                            @switch-change="provider.isActive = $event"
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </transition-group>
            </draggable>


            <div class="d-flex">
              <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
                <Button
                    class="w-100 primary"
                    :callback="() => syncProviders(true)"
                    v-show="!isProvidersSync"
                >
                  Синхронизировать
                </Button>
              </div>

              <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
                <Button
                    class="w-100 primary"
                    :callback="updateCryptoProviders"
                >
                  Обновить
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import draggable from 'vuedraggable'

export default {
  name: "DomainCryptoProviders",
  props: {
    domainInfo: Object
  },
  components: {
    SwitchButton,
    Button,
    draggable
  },
  data() {
    return {
      isProvidersSync: true
    }
  },
  methods: {
    async updateCryptoProviders() {
      try {
        const fetchedData = await axios.post('backoffice/user/domain/updateCryptoProviders', {
          domainId: this.$route.params.domainId,
          providers: this.domainInfo.buyCryptoProviders
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async syncProviders(syncProviders = false) {
      try {
        let fetchedData = await axios.post('/backoffice/user/domain/syncCryptoProviders', {
          domainId: this.$route.params.domainId,
          sync: syncProviders
        });

        this.isProvidersSync = fetchedData.data.syncStatus;
        if (syncProviders) {
          this.$callNotification(this.$i18n.locale, fetchedData);
          this.domainInfo.buyCryptoProviders = fetchedData.data.providers;
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  },
  async mounted() {
    await this.syncProviders();
  }
}
</script>

<style lang="scss" scoped>
.terms .section {
  padding: 5px 25px;
  border-bottom: 1px solid #32383E;
  cursor: pointer;
  transition: .3s;
  font-size: 13px;
  border-radius: 5px;
}


.terms .section:hover {
  background: #343B41;
}

.terms .content {
  display: none;
}

.terms.open .content {
  display: block;
  transition: .3s visibility;
}

.terms .content textarea {
  resize: none;
}

.alert {
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 10px;

  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;

  }

  &.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}
</style>