<i18n>
{
  "en": {
    "title": "Orders",
    "openOrdersTable": {
      "title": "Open Orders",
      "placeholder": "Search by coin, market...",
      "columns": {
        "side": "Side",
        "amount": "Amount",
        "price": "Price",
        "pair": "Pair",
        "date": "Date"
      }
    },
    "historyOrdersTable": {
      "title": "History Orders",
      "placeholder": "Search by coin, market...",
      "columns": {
        "side": "Side",
        "amount": "Amount",
        "price": "Price",
        "pair": "Pair",
        "avg": "AVG",
        "status": "Status",
        "date": "Inactive Date"
      }
    }
  },
  "zh": {
    "title": "订单",
    "openOrdersTable": {
      "title": "未结订单",
      "placeholder": "按硬币搜索，市场...",
      "columns": {
        "side": "边",
        "amount": "金额",
        "price": "价格",
        "pair": "对",
        "date": "日期"
      }
    },
    "historyOrdersTable": {
      "title": "历史订单",
      "placeholder": "按硬币搜索，市场...",
      "columns": {
        "side": "边",
        "amount": "金额",
        "price": "价格",
        "pair": "对",
        "avg": "平均",
        "status": "状态",
        "date": "非活动日期"
      }
    }
  },
  "kr": {
    "title": "주문",
    "openOrdersTable": {
      "title": "주문 열기",
      "placeholder": "코인, 마켓으로 검색...",
      "columns": {
        "side": "측면",
        "amount": "금액",
        "price": "가격",
        "pair": "쌍",
        "date": "날짜"
      }
    },
    "historyOrdersTable": {
      "title": "주문 내역",
      "placeholder": "코인, 마켓으로 검색...",
      "columns": {
        "side": "측면",
        "amount": "금액",
        "price": "가격",
        "pair": "쌍",
        "avg": "평균",
        "status": "상태",
        "date": "비활성 날짜"
      }
    }
  }
}
</i18n>
<template>
  <Page :title="$t('title')" class="dashboard">
    <PageContainer :no-line="true">
      <div class="container-fluid" style="margin-bottom: 40px;">

        <div style="padding-bottom: 9px; margin: 20px 0;">
          <span class="tabs" @click="ordersType = 'open'" :class="{ active: ordersType === 'open' }">{{ $t('openOrdersTable.title') }}</span>
          <span class="tabs" @click="ordersType = 'history'" :class="{ active: ordersType === 'history' }">{{ $t('historyOrdersTable.title') }}</span>
        </div>

        <div class="container-fluid" v-show="ordersType === 'open'">
          <OnlineTable
              parse-url="/main/user/open-orders"
              :column-list="openOrdersTableColumns"
              default-sort="openTime"
              :search-placeholder-text="$t('openOrdersTable.placeholder')"
              @handle-data="openOrdersTableData = $event"
          >
            <tr v-for="data, index in openOrdersTableData" :key="index">
              <td :class="data.isBuyerMaker ? 'plus' : 'minus'">{{ data.isBuyerMaker ? 'Buy' : 'Sell' }}</td>
              <td>{{ data.coin }}/{{ data.market }}</td>
              <td>{{ data.price }}</td>
              <td>{{ data.amount.toFixed(6) }}</td>
              <td>{{ data.openTime | toDate }}</td>
            </tr>
          </OnlineTable>
        </div>

        <div class="container-fluid" v-show="ordersType === 'history'">
          <OnlineTable
              parse-url="/main/user/history-orders"
              :column-list="historyOrdersTableColumns"
              default-sort="closeTime"
              :search-placeholder-text="$t('historyOrdersTable.placeholder')"
              @handle-data="historyOrdersTableData = $event"
          >
            <tr v-for="data, index in historyOrdersTableData" :key="index">
              <td :class="data.isBuyerMaker ? 'plus' : 'minus'">{{ data.isBuyerMaker ? 'Buy' : 'Sell' }}</td>
              <td>{{ data.coin }}/{{ data.market }}</td>
              <td>{{ data.middleClosePrice }}</td>
              <td>{{ data.price }}</td>
              <td>{{ data.amount.toFixed(6) }}</td>
              <td :class="data.isExecuted ? 'info' : 'minus'">{{ data.isExecuted ? 'Executed' : 'Canceled' }}</td>
              <td>{{ data.closeTime | toDate }}</td>
            </tr>
          </OnlineTable>
        </div>

      </div>
    </PageContainer>
  </Page>
</template>

<script>
import PageContainer from "@/components/main/UI/PageContainer";
import Page from "@/components/main/UI/Page";
import axios from "@/plugins/axios";
import OnlineTable from "@/components/main/UI/OnlineTable";
import CoinsDropInput from "@/components/main/UI/CoinsDropInput";
import InputBox from "@/components/main/UI/InputBox";
import Button from "@/components/main/UI/Button";

export default {
  name: "Orders",
  components: {
    PageContainer,
    Page,
    OnlineTable
  },
  data() {
    return {
      ordersType: 'open',
      openOrdersTableData: [],
      openOrdersTableColumns: [
        {
          name: 'isBuyerMaker',
          title: this.$t('openOrdersTable.columns.side'),
          isSortable: true
        },
        {
          name: 'pair',
          title: this.$t('openOrdersTable.columns.pair')
        },
        {
          name: 'price',
          title: this.$t('openOrdersTable.columns.price'),
          isSortable: true
        },
        {
          name: 'amount',
          title: this.$t('openOrdersTable.columns.amount'),
          isSortable: true
        },
        {
          name: 'date',
          title: this.$t('openOrdersTable.columns.date'),
          isSortable: true
        }
      ],
      historyOrdersTableData: [],
      historyOrdersTableColumns: [
        {
          name: 'isBuyerMaker',
          title: this.$t('historyOrdersTable.columns.side'),
          isSortable: true
        },
        {
          name: 'pair',
          title: this.$t('historyOrdersTable.columns.pair')
        },
        {
          name: 'middleClosePrice',
          title: this.$t('historyOrdersTable.columns.avg'),
          isSortable: true
        },
        {
          name: 'price',
          title: this.$t('historyOrdersTable.columns.price'),
          isSortable: true
        },
        {
          name: 'amount',
          title: this.$t('historyOrdersTable.columns.amount'),
          isSortable: true
        },
        {
          name: 'isExecuted',
          title: this.$t('historyOrdersTable.columns.status'),
          isSortable: true
        },
        {
          name: 'closeTime',
          title: this.$t('historyOrdersTable.columns.date'),
          isSortable: true
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.dark {
  .tabs.active {
    background-color: rgb(35, 38, 39);
  }
  .tabs:hover {
    background-color: rgb(35, 38, 39);
  }
}

.tabs {
  padding: 10px 10px;
  margin: 0 10px;
  transition: .3s;
  border-radius: 2px;
  cursor: pointer;
}

.tabs:first-child {
  margin-left: 0;
}

.tabs:hover {
  background-color: #edf0f5;
}

.tabs.active {
  background-color: #edf0f5;
}

.plus { color: #01aa78 !important; }
.minus { color: #e25050!important; }
.info { color: #74a1d1 !important; }

</style>