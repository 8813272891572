<i18n>
{
  "en": {
    "loadError": "Data loading error. Reload the page or try again later."
  },
  "zh": {
    "loadError": "데이터 로드 오류입니다. 페이지를 새로고침하거나 나중에 다시 시도하세요."
  },
  "kr": {
    "loadError": "数据加载错误。 重新加载页面或稍后再试。"
  }
}
</i18n>

<template>
  <div class="page" :class="{ 'no-line': noLine, dark: $store.state.Index.isDarkMode }">
    <DataLoading v-show="!isPageLoaded.isDone || isPageLoaded.isError">
      <span v-show="isPageLoaded.isError">{{ $t('loadError') }}</span>
    </DataLoading>
    <div :class="{ 'no-line': noLine, dark: $store.state.Index.isDarkMode }" v-show="isPageLoaded.isDone && !isPageLoaded.isError">
      <div class="page-header" v-show="title">
        <h1>{{ title }}</h1>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import DataLoading from "@/components/main/DataLoading";

export default {
  name: "Page",
  props: {
    title: String,
    noLine: Boolean,
    isPageLoaded: {
      type: Object,
      default() {
        return {
          isDone: true,
          isError: false
        }
      }
    }
  },
  components: {
    DataLoading
  }
}
</script>

<style lang="scss" scoped>

@media (max-width: 992px) {
  .page {
    padding: 10px !important;
  }
}

.page {
  padding: 30px;

  &.dashboard {
    min-height: calc(100vh - 60px);
  }
  &.dark {
    h1 {
      color: rgb(216, 214, 211) !important;
    }
    .page-header {
       border-bottom-color: #363b3d !important;
    }
  }
  &.no-line {

    .page-header {
      border: none !important;
    }
  }

  .page-header {

    padding-bottom: 8px;
    margin-bottom: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #e8eaf0;


    h1 {
      font-weight: 700;
      line-height: 2.35294rem;
    }
  }
}

</style>