<i18n>
{
  "en": {
    "title": "About us"
  },
  "zh": {
    "title": "About us"
  },
  "kr": {
    "title": "About us"
  }
}
</i18n>
<template>
  <Page style="padding: 0;" :is-page-loaded="dataLoadingStatus">

    <div class="top row">
      <div class="container">
        <h1 style="margin: 45px 0;">{{ $t('title') }}</h1>
      </div>
    </div>

    <div class="container-fluid" style="margin-bottom: 40px;padding: 0;">

      <div class="aboutPage">
        <div class="container">
          <div class="col-lg-7 col-md-7">
            <h2>In Brief</h2>
            <p>{{ about.description }}</p>
          </div>
          <div class="col-lg-4 col-md-4" :style="{'text-align': $store.state.Index.isMobile ? 'left' : 'right'}">
            <h2>Stats</h2>
            <div class="item">
              <p class="value">{{ about.stats.users }}</p>
              <p class="label">USER</p>
            </div>
            <div class="item">
              <p class="value">{{ about.stats.traded }}</p>
              <p class="label">TRADED SINCE 2017</p>
            </div>
          </div>
        </div>

        <div class="roadmap-div">
          <div class="container">
            <h2>History</h2>
            <div style="display: flex; justify-content: center; margin-bottom: 30px;">
              <ul class="timeline">
                <li v-for="year, index in about.roadmap" :key="'map key' + index"><span></span>
                  <div>
                    <div class="title">{{ year.title }}</div>
                    <div class="info">{{ year.text }}</div>
                  </div> <span class="number"><span>{{ year.date }}</span> <span></span></span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="container">
          <h2>{{ about.located.title }}</h2><br>
          {{ about.located.text }}<br><br>
          <div class="row">
            <div class="col-lg-6" style="text-align: center; margin-bottom: 15px;">
              <img :src="about.located.image_link === 'default' ? '/building.jpg' : about.located.image_link" style="max-width: 100%;max-height: 440px;">
            </div>
            <div class="col-lg-6">
              <div style="overflow:hidden;width: 100%;position: relative;">
                <iframe width="100%" height="440" :src="about.located.google_maps_link" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
              </div>
              <br>
            </div>

          </div>
        </div>
      </div>


    </div>
  </Page>
</template>

<script>
import axios from "@/plugins/axios";
import Page from "@/components/main/UI/Page";

export default {
  name: "Privacy",
  components: {
    Page
  },
  data() {
    return {
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      about: {},
    }
  },
  async mounted() {
    try {
      let fetchedData = await axios.get('main/exchange/about');
      if (fetchedData.data.error) {
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
        return;
      }
      this.about = fetchedData.data.about;
      this.dataLoadingStatus.isDone = true;
    } catch (e) {
      console.log(e)
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>
#gmap_canvas img{max-width:none!important;background:none!important}

.roadmap-div {
  margin-top: 30px; background: #F9FAFC;
}

.dark {
  .top {
    background-image: linear-gradient(0deg, #212325 0px, #212324 6.67%, #212324 13.33%, #212324 20%, #212324 26.67%, #212223 33.33%, #202122 40%, #1f2121 46.67%, #1f2121 53.33%, #1e2021 60%, #1d2021 66.67%, #1d1f20 73.33%, #1d1f20 80%, #1c1e1f 86.67%, #1c1e1f 93.33%, #1c1e1f 100%);
    background-color: initial;
  }
  .aboutPage h2 {
    color: rgb(187, 182, 176);
    border-bottom-color: rgb(55, 60, 61);
  }
  .aboutPage .item p.label {
    color: rgb(158, 151, 141);
  }
  .roadmap-div {
    background-image: initial;
    background-color: rgb(31, 33, 33);
  }
  .aboutPage .timeline li {
    background-color: rgba(28, 30, 31, 0.2);
    box-shadow: rgba(6, 6, 6, 0.12) 0px 0px 4px, rgba(6, 6, 6, 0.08) 0px 2px 2px;
  }
  .aboutPage .timeline li > span {
    background-image: initial;
    background-color: rgb(86, 92, 96);
  }
  .aboutPage .timeline {
    list-style-image: initial;
    color: rgb(216, 214, 211);
  }
}

.staking-table {

  margin-bottom: 40px;

  .header {
    display: flex;
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    padding: 5px 16px;

    div.td {
      color: rgb(112, 122, 138);
      font-size: 14px;
      width: 33%;
    }
  }

  .body {
    border-left: 1px solid #F5F5F5;
    border-right: 1px solid #F5F5F5;

    div.tr {
      border-bottom: 1px solid rgb(245, 245, 245);

      display: flex;
      align-items: center;
      padding: 16px 16px;

      div.td {
        width: 33%;
        color: rgb(112, 122, 138);
        font-size: 14px;
      }
    }
  }

}

.top {
  display: block;
  position: relative;
  background: linear-gradient(0deg,#f2f3f7 0,#f3f3f7 6.67%,#f3f4f8 13.33%,#f3f4f8 20%,#f4f5f8 26.67%,#f5f6f9 33.33%,#f7f7fa 40%,#f8f9fb 46.67%,#f9fafc 53.33%,#fbfbfc 60%,#fcfcfd 66.67%,#fdfdfe 73.33%,#fefefe 80%,#fff 86.67%,#fff 93.33%,#fff 100%);
  margin-bottom: 10px;

  div.category {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: calc(100% + 16px);
    height: 32px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #848E9C;
  }

  .coin {
    cursor: pointer;
    transition: .3s;

    &:hover {
      background:  #E7E8EA;
    }

    .data {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 8px 8px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-weight: 700;
      display: flex;
      align-items: center;
      font-size: 13px;

      &.gainer { background: #eff9f5; }
      &.loser { background: #fdf5f5; }

      div.title {
        width: 120px;
        color: #0a0f38;
        display: flex;
        align-items: center;
      }

      div.value {
        width: 95px;
      }

      div.percent {
        width: 95px;
        margin-left: 4px;
        text-align: right;

        &.plus { color: #01aa78; }
        &.minus { color: #e25050; }
      }
    }

  }
}

.aboutPage {

  h2 {
    display: inline-block;
    font-size: 38px;
    font-weight: 500;
    color: #333;
    border-bottom: 2px solid #e9eaea;
    padding-bottom: 8px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 30px;
      height: 4px;
      background-color: #815BF4;
    }
  }

  .item {
    margin-top: 40px;
    p {
      margin: 0;
    }
    p.value {
      font-size: 32px;
      color: #815BF4;
    }
    p.label {
      font-size: 16px;
      color: #666;
      margin-top: 10px;
    }
  }

  .timeline {
    margin: 0;
    margin-top: 100px;
    list-style: none;
    position: relative;
    color: #000000;
    font-size: 13px;

    &:before {
      content: "";
      width: 1px;
      height: 100%;
      position: absolute;
      border-left: 2px dashed #815BF4;
    }

    li {
      position: relative;
      margin-left: 30px;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 14px;
      border-radius: 6px;
      width: 250px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.08);

      &:not(:first-child) {
        margin-top: 60px;
      }

      span.number {
        height: 100%;

        span {
          position: absolute;
          font-size: 10px;
          left: -50px;
          width: 40px;
          font-weight: bold;

          &:first-child {
            top: 0;
          }
          &:last-child {
            top: 100%;
          }
        }
      }

      div {
        margin-left: 10px;

        .title, .type {
          font-weight: 600;
          font-size: 12px;
        }

        .info {
          font-weight: 300;
        }

        div {
          margin-top: 5px;
        }
      }

    }

    li > span {
      width: 2px;
      height: 100%;
      background: #747376;
      left: -30px;
      top: 0;
      position: absolute;
    }

    li span:after {
      top: 100% !important;
    }

    li > span:after,  li > span:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 2px solid #9141d7;
      position: absolute;
      background: #d286e7;
      left: -3px;
      top: 0;
    }

  }
}



</style>