<template>
  <div class="p-4">
    <div style="text-align: center" class="p-4" @click="loadUserLogs" v-if="loadingStatus.isDone && loadingStatus.isError">Ошибка при загрузке данных, нажмите чтобы загрузить заного</div>
    <div style="text-align: center; cursor: pointer;" @click="loadUserLogs" class="p-4" v-else-if="!loadingStatus.isDone && !loadingStatus.isError && !isLogsAreLoading">Нажмите чтобы загрузить последние логи</div>
    <div style="text-align: center" class="p-4" v-else-if="isLogsAreLoading">Загрузка данных...</div>
    <div style="height: 300px; overflow-y: auto;" v-else>
      <div class="mb-4" v-for="log, index in logs" :key="'log' + index">
        <p style="font-size: 12px;" class="text-muted m-0">{{ log.date | toDate }}</p>
        <p style="word-break: break-word;" class="m-0">{{ log.action }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import * as workerTimers from 'worker-timers';

export default {
  name: "UserLogs",
  props: {
    userId: String
  },
  data() {
    return {
      logs: [],
      isLogsAreLoading: false,
      currentLogsCount: 0,
      isLogsEnded: false,
      loadingStatus: { isDone: false, isError: false },
      updateTimer: null,
      autoUpdate: true,
    }
  },
  methods: {
    async loadUserLogs() {
      try {
        if (this.isLogsAreLoading) return;

        this.loadingStatus.isDone = false;
        this.isLogsAreLoading = true;
        const fetchedData = await axios.post('backoffice/admin/user/logs', { userId: this.userId });

        if (fetchedData.data.error) {
          this.loadingStatus.isDone = false;
          this.isLogsAreLoading = false;
          this.loadingStatus.isError = true;
        }
        this.logs = fetchedData.data.userLogs;
        this.loadingStatus.isDone = true;
        this.loadingStatus.isError = false;
        this.isLogsAreLoading = false;
      } catch (e) {
        console.log(e)
        this.isLogsAreLoading = false;
        this.loadingStatus.isDone = false;
        this.loadingStatus.isError = true;
      }
    }
  }
}
</script>

<style scoped>

.update-icon {
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>