<template>
  <div style="position: relative;">
    <iframe class="loading"  :class="{ mobile: $store.state.Index.isMobile }" :src="link"></iframe>
    <div class="preloader" :class="{ dark: $store.state.Index.isDarkMode }" v-show="!isLoaded">
      <img :src="$store.state.Index.logo" alt="">
    </div>
    <div style="text-align: center; margin-top: 15px;">Provided by our partner <a style="color: #80ACE9" href="https://www.trustpilot.com/review/transak.com?stars=5&verified=true" target="_blank">Transak</a> (c)</div>
  </div>
</template>

<script>
export default {
  name: "BuyCrypto",
  props: {
    currentCoin: String
  },
  data() {
    return {
      isLoaded: false,
      //link: 'https://buy.ramp.network/?variant=embedded-mobile&hostAppName=Ramp&hostLogoUrl=https%3A%2F%2Fbuy.ramp.network%2Fassets%2F46129537.svg&hostApiKey=wn25y7nx6cyb4oqutc5obnnywwwt4gz36yw5fypw&enabledFlows=ONRAMP%2COFFRAMP&defaultAsset=BTC&widgetInstanceId=2032637417&hostUrl=https%3A%2F%2Framp.network'
      link: `https://global.transak.com/?themeColor=7D00FF&defaultCryptoCurrency=${this.currentCoin || 'BTC'}&defaultPaymentMethod=credit_debit_card&hideMenu=true&productsAvailed=BUY`//`https://buy.moonpay.com/?apiKey=pk_live_R5Lf25uBfNZyKwccAZpzcxuL3ZdJ3Hc&theme=${this.$store.state.Index.isDarkMode ? 'dark' : 'light'}&colorCode=%237d00ff&defaultCurrencyCode=${this.currentCoin || 'BTC'}&currencyCode=${this.currentCoin || 'BTC'}`
    }
  },
  methods: {
    iframeLoaded() {
      this.isLoaded = true;
    }
  },
  mounted() {
    document.querySelector("iframe").addEventListener('load', this.iframeLoaded);
  }
}
</script>

<style lang="scss" scoped>


.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 640px;
  text-align: center;
  font-size: 45px;
  display: flex;
  background: #FFFFFF;
  align-items: center;
  justify-content: center;
  border: 5px solid #7D00FF;
  border-radius: 5px;

  &.dark {
    background: #1C1C1E;
  }
}

.loading {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 5px;
  border: 5px solid #7D00FF;

  &.mobile {
    height: calc(100vh - 163px);
  }
}


img {
  animation: spin 2s linear infinite !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>