<template>
  <ExchangeAdvanced />
<!--  <ExchangeSimple v-else />-->
</template>

<script>
import ExchangeAdvanced from "@/views/Exchange/ExchangeAdvanced";
import ExchangeSimple from "@/views/Exchange/ExchangeSimple";

export default {
  name: "ExchangeIndex",
  components: {
    ExchangeAdvanced,
    //ExchangeSimple
  }
}
</script>

<style scoped>

</style>