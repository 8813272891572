<template>

  <div :class="{ dark: $store.state.Index.isDarkMode }" style="background: transparent;">
    <h1>{{ title }}</h1>
    <router-link
        :to="link.disabled ? '' : $linkCreator($i18n.locale, link.link)"
        exact
        active-class="current"
        :class="{ 'mobile': isMobile }"
        v-for="link, index in links"
        :key="index"
    >
      <font-awesome-icon
          :icon="link.icon"
          style="margin-right: 20px; font-size: 28px; width: 28px;"
      />
      <div style="line-height: 20px;">
        <span class="name">{{ link.name }}</span>
        <span :style="link.style" class="info">{{ link.info }}</span>
      </div>
    </router-link>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DashboardMenuLink.vue",
  props: {
    title: String,
    links: Array
  },
  computed: {
    ...mapState('Index', [
      'isMobile'
    ])
  }
}
</script>

<style lang="scss" scoped>

.dark {
  a span.name {
    color: rgb(216, 214, 211) !important;
  }
  a span.info {
    color: rgba(197, 193, 189, 0.7);
  }
  a:hover {
    background-color: rgb(42, 46, 47);
    color: rgb(216, 214, 211) !important;
  }
  a svg path { fill: rgba(197, 193, 189, 0.7); }
}

a {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(10,15,56,.6);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.41176rem;
  letter-spacing: .01em;
  border-radius: 2px;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out;
  transition: .25s ease-out;
  text-decoration: none;
  outline: 0;
  margin: 4px 0px;

  &:hover {
    background-color: #dddee7;
    color: black !important;
  }

  &.current {
    color: #0a0f38;
  }

  &.mobile {
    margin: 4px 12px;
    padding: 5px 10px;
  }

  span.name {
    font-size: 14px; color: black !important;
  }
  span.info {
    margin-top: 2px;
    color: rgba(10,15,56,.7);
    font-size: 12.2px;
    display: block;
  }

}

</style>