<template>
  <div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Добавить новый аккаунт</h4>
          </div>
          <div class="p-4 row m-0">
            <p class="m-0 mb-4 text-muted">Чтобы найти ваш Account ID, нужно войти в аккаунт Cloudflare, и перейти на страницу dash.cloudflare.com - URL страницы обновиться и вы увидите ваш токен после слеша - dash.cloudflare.com/<b>ВАШ-ACCOUNT-ID</b> </p>
            <p class="m-0 mb-4 text-muted">Global API key можно найти на странице апи токенов в настройках аккаунта.</p>
            <InputBox
                :label="input.label"
                :placeholder="input.placeholder"
                class="col-lg-6 col-md-6"
                v-model="input.value"
                v-for="input, index in inputData"
                :key="'input account' + index"
            />
            <div class="p-4">
              <Button
                  class="w-100 primary"
                  :callback="createAccount"
                  :is-disabled="this.inputData.cloudflareEmail.value === '' || this.inputData.cloudflareKey.value === '' || this.inputData.cloudflareAccountId.value === ''"
              >
                Добавить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Аккаунты</h4>
          </div>
          <Table
              ref="cloudflareTableComponent"
              parse-url="/backoffice/admin/spammerCloudflareAccounts"
              :columnList="cloudflareTableColumns"
              default-sort="_id"
              searchPlaceholderText="Введите владельца или почту"
              @handle-data="cloudflareTableData = $event"
          >
            <tr v-for="account, index in cloudflareTableData" :key="'cloudflare account' + index">
              <td>{{ account.ownerName }}</td>
              <td>{{ account.email }}</td>
              <td>
                <div class="form-control m-0 p-0 col-lg-12 d-flex align-items-center">
                  <label class="m-0 mr-3">{{ account.isDefault ? "Включен" : "Выключен"}}</label>
                  <SwitchButton
                      :is-checked="account.isDefault"
                      @switch-change="updateAccountStatus(account._id, $event)"
                  />
                </div>
              </td>
              <td class="no-animate" style="text-align: center;">
                <span @click="deleteAccount(account._id)" class="delete-btn">
                  <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
                </span>
              </td>
            </tr>
          </Table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";

export default {
  name: "Domains",
  props: {
    information: Object
  },
  components: {
    InputBox,
    Button,
    Table,
    SwitchButton
  },
  data() {
    return {
      inputData: {
        login: {
          label: "Логин спаммера (оставить пустым если аккаунт для себя)",
          value: "",
          placeholder: "Spammer login"
        },
        cloudflareEmail: {
          label: "Cloudflare email",
          value: "",
          placeholder: "example@mail.com"
        },
        cloudflareKey: {
          label: "Cloudflare API key",
          value: "",
          placeholder: "Cloudflare Global API key"
        },
        cloudflareAccountId: {
          label: "Cloudflare account ID",
          value: "",
          placeholder: "Cloudflare account ID"
        },
      },
      isDomainDeleting: false,
      isDomainRepairing: false,
      newAccountSpammer: '',
      cloudflareTableData: [],
      cloudflareTableColumns: [
        {
          name: 'ownerName',
          title: 'Владелец',
          isSortable: true
        },
        {
          name: 'email',
          title: 'Аккаунт',
          isSortable: true
        },
        {
          name: 'isDefault',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        },
      ],
    }
  },
  methods: {
    // ТАБЛИЦА
    // ДОБАВЛЕНИЕ ДИЗАЙНА
    async createAccount() {
      try {
        const fetchedData = await axios.post('backoffice/admin/spammer/createCloudflareAccount', {
          login: this.inputData.login.value,
          email: this.inputData.cloudflareEmail.value,
          key: this.inputData.cloudflareKey.value,
          accountId: this.inputData.cloudflareAccountId.value,
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.newDomainInput = '';
          this.$refs.cloudflareTableComponent.fetchTableData();
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async deleteAccount(accountId) {
      try {
        const isConfirm = confirm('Вы уверены что удалить этот аккаунт Cloudflare? Вы больше не сможете управлять доменами которые используют данный аккаунт!');
        if (!isConfirm) return;
        const fetchedData = await axios.post('backoffice/admin/spammer/deleteCloudflareAccount', {
          accountId
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.cloudflareTableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateAccountStatus(accountId, value) {
      try {
        const fetchedData = await axios.post('backoffice/admin/spammer/updateCloudflareAccount', { accountId, value });
        if (fetchedData.data.error) {
          return this.$callNotification(this.$i18n.locale, fetchedData);
        }
        if (!fetchedData.data.error) this.$refs.cloudflareTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, {
          error: true,
          message: 'Ошибка при изменении прав юзера'
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>

span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}

span.repair-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f0a448;
    }
  }
}

</style>