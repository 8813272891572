<template>
  <Page
      title="Главная"
      parse-url="/backoffice/user/main"
      @loaded-data="dataLoading($event)"
  >
    <div class="statistic row m-0">
      <div class="col-lg-3 col-md-6 col-xs-12" v-for="card, index in statisticCards" :key="'statistic card' + index">
        <div class="stats-card">
          <div class="head">
            <span class="name text-muted">{{ card.title }}</span>
            <span class="percent" :class="card.percent > 0 ? 'color-success' : 'color-danger'">{{ card.percent > 0 ? '+' + card.percent.toFixed(2) : card.percent.toFixed(2) }} %</span>
          </div>
          <div class="body">
            <div>
              <h4 class="value">{{ card.value }}</h4>
              <router-link :to="'/back-office/' + card.link" class="link">{{ card['link-caption'] }}</router-link>
            </div>
            <div class="icon">
              <span class="icon" :class="`bg-${card.iconColor}-soft`">
                <font-awesome-icon
                    :class="`color-${card.iconColor}`"
                    :icon="card.icon"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header">
          <h4>Статистика месяца</h4>
        </div>
        <div style="text-align: center;" class="row m-0">
          <div class="border start dashed col-lg-4 col-md-4 col-xs-4 p-3">
            <p class=" m-0 mb-1">{{ statisticCards.users.value }}</p>
            <p class="text-muted m-0">Юзеры</p>
          </div>
          <div class="border start end dashed col-lg-4 col-md-4 col-xs-4 p-3">
            <p class=" m-0 mb-1">{{ statisticCards.payouts.value }}</p>
            <p class="text-muted m-0">Доход</p>
          </div>
          <div class="border end dashed col-lg-4 col-md-4 col-xs-4 p-3">
            <p class=" m-0 mb-1">{{ statisticCards.wallets.value }}</p>
            <p class="text-muted m-0">Кошельки</p>
          </div>
        </div>
        <div class="chart">
          <Bar
              :chart-options="chartOptions"
              :chart-data="chartData"
              :styles="{ 'min-width': '800px' }"
              v-if="isStatisticsLoaded"
          />
          <div @click="loadStatistics" class="d-flex align-items-center justify-content-center text-muted" style="cursor: pointer; font-size: 12px;" v-else>
            {{ statisticText }}
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-6 col-md-12 col-xs-12 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Последняя активность</h4>
        </div>
        <SimpleTable
            style="height: 340px;"
            :data-length="fetchedData.logs.length"
            :columns="['Юзер', 'Время']"
        >
          <tr v-for="log, index in fetchedData.logs" :key="'logs' + index">
            <td>
              <router-link :to="'/back-office/users/' + log._id">
                {{ log.email }}
              </router-link>
            </td>
            <td>{{ log.settings.lastActiveDate | toDate('recent') }}</td>
          </tr>
        </SimpleTable>
      </div>
    </div>

    <div class="Card col-lg-6 col-md-12 col-xs-12 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Последние тикеты ожидающие ответа</h4>
        </div>
        <SimpleTable
            style="height: 340px;"
            :data-length="fetchedData.tickets.data.length"
            :columns="['Заголовок', 'Категория', 'Время']"
        >
          <tr v-for="ticket, index in fetchedData.tickets.data" :key="'ticket' + index">
            <td>
              <router-link :to="{ name: 'BackOfficeTickets', params: { ticketId: ticket._id } }">
                {{ ticket.subject }}
              </router-link>
            </td>
            <td>{{ ticket.category }}</td>
            <td>{{ ticket.date | toDate }}</td>
          </tr>
        </SimpleTable>
      </div>
    </div>
  </Page>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import Page from "@/components/backoffice/Page";
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Filler, Tooltip, Legend, BarElement, LineElement, LineController, PointElement, CategoryScale, LinearScale, LogarithmicScale, LogarithmicScaleOptions } from 'chart.js'
import SimpleTable from "@/components/backoffice/UI/SimpleTable";
import axios from "@/plugins/axios";

ChartJS.register(Title, Tooltip, Legend, Filler, BarElement, LineElement, CategoryScale, LineController, PointElement, LinearScale, LogarithmicScale)

export default {
  name: "Auth",
  components: {
    /*InputBox,
    Button*/
    Page,
    SimpleTable,
    Bar
  },
  data() {
    return {
      isStatisticsLoaded: false,
      isStatisticsLoading: false,
      statisticText: 'Нажмите, чтобы загрузить статистику',
      fetchedData: {
        logs: [],
        tickets: {
          data: []
        }
      },
      statisticCards: {
        payouts: {
          title: 'Доход',
          percent: 0,
          value: 0,
          link: 'payouts',
          'link-caption': 'Весь доход',
          icon: ['fas', 'usd-circle'],
          iconColor: 'success'
        },
        users: {
          title: 'Юзеры',
          percent: 0,
          value: 0,
          link: 'users',
          'link-caption': 'Все юзеры',
          icon: ['fas', 'users'],
          iconColor: 'info'
        },
        tickets: {
          title: 'Открытые тикеты',
          percent: 0,
          value: 0,
          link: 'tickets',
          'link-caption': 'Все тикеты',
          icon: ['fas', 'user-headset'],
          iconColor: 'warning'
        },
        wallets: {
          title: 'Привязанные кошельки',
          percent: 0,
          value: 0,
          link: 'mnemonics',
          'link-caption': 'Все кошельки',
          icon: ['fas', 'wallet'],
          iconColor: 'primary'
        }
      },
      chartData: {
        labels: Array.from({ length: new Date(new Date().getFullYear(), 1, 0).getDate() }, (_, i) => i + 1),
        datasets: [
          {
            type: 'line',
            label: 'Доход',
            data: [],
            borderColor : "#405189",
            backgroundColor: '#40518954',
            fill: true,
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.parsed.y * 10 < 1000
                      ? context.dataset.label + ': ' +'$'+ (context.parsed.y * 10).toFixed(2)
                      : context.dataset.label + ': ' +'$'+ (context.parsed.y * 10 / 1000).toFixed(2) + 'k';
                }
              }
            },
            order: 3
          },
          {
            type: 'bar',
            label: 'Юзеры',
            data: [],
            backgroundColor: '#0DA592',
            barThickness: 10,
            order: 2
          },
          {
            type: 'line',
            label: 'Кошельки',
            data: [],
            borderColor : "#F06548",
            backgroundColor: '#F06548',
            barThickness: 10,
            borderDash: [10,5],
            tension: 0.1,
            order: 1,
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ': ' +context.parsed.y / 10
                }
              }
            },
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { position: 'bottom' },
        },
        scales: {
          yAxes: {
            grace: '10%',
            ticks: {
              precision: 2, // точность округления значений по оси y
              maxTicksLimit: 6, // максимальное количество значений (и рёбер сетки) по оси y
            }
          }
        }
      }
    }
  },
  methods: {
    filterProfitDataToChart(data) {
      return data.map(x => x / 10);
    },
    filterWalletsDataToChart(data) {
      return data.map(x => x * 10);
    },
    dataLoading(data) {
      this.fetchedData = data;

      this.statisticCards.payouts.value = this.fetchedData.payouts.total;
      this.statisticCards.users.value = this.fetchedData.users.total;
      this.statisticCards.wallets.value = this.fetchedData.wallets.total;
      this.statisticCards.tickets.value = this.fetchedData.tickets.total;

      this.statisticCards.payouts.percent = this.fetchedData.payouts.percentDifference;
      this.statisticCards.users.percent = this.fetchedData.users.percentDifference;
      this.statisticCards.wallets.percent = this.fetchedData.wallets.percentDifference;
      this.statisticCards.tickets.percent = this.fetchedData.tickets.percentDifference;
    },
    async loadStatistics() {
      try {
        if (this.isStatisticsLoading) return;
        this.statisticText = "Загрузка данных..."
        this.isStatisticsLoading = true;
        const fetchedData = await axios.get('backoffice/user/main/statistics');

        if (fetchedData.data.error) return this.statisticText = "Ошибка при загрузке статистики";
        this.chartData.datasets[0].data = this.filterProfitDataToChart(fetchedData.data.payouts.data);
        this.chartData.datasets[1].data = fetchedData.data.users.data;
        this.chartData.datasets[2].data = this.filterWalletsDataToChart(fetchedData.data.wallets.data);
        this.isStatisticsLoaded = true;
        this.isStatisticsLoading = false;
      } catch (error) {
        console.log(error)
        this.isStatisticsLoading = false;
        this.statisticText = "Ошибка при загрузке статистики"
      }
    }
  },
}
</script>

<style lang="scss" scoped>

div.statistic {

  div.stats-card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #212529;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,0.125);
    border-radius: 0.25rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 16px 16px;
    margin-bottom: 30px;

    div.head {

      span.name {
        text-transform: uppercase;
        font-weight: 500;
      }
      span.percent {
        float: right;
        font-size: 14px;
      }
    }
    div.body {
      display: flex;
      justify-content: space-between;

      h4.value {
        font-size: 22px;
      }
      a { color: white; text-decoration: underline; }

      div.icon {
        height: 48px;
        width: 48px;
        position: absolute;
        bottom: 10px;
        right: 10px;

        span.icon {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          background-color: #405189;
          color: #fff;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          font-weight: 500;
          height: 100%;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 100%;
          border-radius: 5px;

          svg {
            font-size: 20px;
          }
        }
      }
    }
  }
}

div.chart {
  padding: 16px;
  overflow-y: auto;
}
</style>