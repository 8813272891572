<template>
  <div>

    <div class="col-lg-6 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Двухфакторная аутентификация <span class="status" :class="information.settings.twoFactorAuthorization.isActive ? 'bg-success' : 'bg-danger'"></span></h4>
          </div>
          <div class="p-4 row m-0">
            <p class="text-muted m-0 mb-4">Используйте Google Authenticator или любое другое TOTP приложение.
              <br>Не бойтесь использовать двухфакторную аутентификацию, те данные что вы вводите для получения кодов никак не связаны с нашим сайтов.
              <br>Если боитесь какого-либо отслеживания со стороны разработчиков приложения, то используйте open-source проекты, например такие как "authelia".
              <br>Используя двухфакторную аутентификацию, вы защищаете себя и свой аккаунт от входа сторонних лиц, были случаи когда телеграм аккаунты спаммеров взламывались и заходили в спаммерку.
              <br>Так же при включенной двухфакторке, вы свободно можете менять пароль от аккаунта, перевязывать телеграм аккаунт у бота, менять кошельки.
            </p>
            <div v-if="information.settings.twoFactorAuthorization.isActive">
              <InputBox
                  label="Введите код из приложения для отключения двухфакторной защиты"
                  v-model="changeTwoFactorCodeInput"
                  type="number"
                  class="w-100"
                  placeholder="Код из приложения"
              />
            </div>
            <div style="font-size: 13px;" v-else>
              <div class="mb-3">
                <p class="m-0 mb-2">Отсканируйте QR код:</p>
                <qrcode-vue
                    :value="information.settings.twoFactorAuthorization.qrcode"
                    :size="200"
                />
              </div>
              <div class="mb-3">
                <p class="m-0 mb-1">или введите секретный ключ вручную:</p>
                <p class="m-0"><b>{{ information.settings.twoFactorAuthorization.secretKey }}</b></p>
              </div>
              <div>
                <InputBox
                    label="Введите код из приложения"
                    v-model="changeTwoFactorCodeInput"
                    type="number"
                    class="w-100"
                    placeholder="Код из приложения"
                />
              </div>
            </div>
            <Button
                :callback="changeTwoFactorStatus"
                class="w-100"
                :class="{ 'danger': information.settings.twoFactorAuthorization.isActive, 'success': !information.settings.twoFactorAuthorization.isActive }"
                :is-disabled="changeTwoFactorCodeInput === ''"
            >
              {{ information.settings.twoFactorAuthorization.isActive ? 'Выключить' : 'Включить' }}
            </Button>
            <p class="text-muted mb-0">После каждого отключения двухфакторки создается новый секретный ключ, поэтому старые коды работать не будут.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Телеграм <span class="status" :class="information.settings.telegram.isAuthed ? 'bg-success' : 'bg-danger'"></span></h4>
          </div>
          <div class="p-4">
            <div class="form-controls">
              <label v-if="!information.settings.telegram.isAuthed">Используйте команду <br><b>/auth {{ information.settings.telegram.activationCode }}</b><br> у <a :href="'https://t.me/' + information.settings.telegramBot.link" target="_blank">нашего бота</a> чтобы начать его использовать.</label>
              <label v-else>Привязанный аккаунт: {{ information.settings.telegram.name }}</label>
              <div v-if="information.settings.twoFactorAuthorization.isActive && information.settings.telegram.isAuthed">
                <InputBox
                    label="Отвязать текущий телеграм аккаунт от бота:"
                    v-model="telegramClearTwoFactorCodeInput"
                    type="number"
                    class="w-100 mt-3"
                    placeholder="Код из приложения"
                />
                <Button
                    :callback="clearTelegramBot"
                    class="danger w-100"
                    :is-disabled="telegramClearTwoFactorCodeInput === ''"
                >
                  Отвязать
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12 col-xs-12 mb-4" v-if="information.settings.twoFactorAuthorization.isActive">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Смена пароля</h4>
          </div>
          <div class="p-4">
            <InputBox
                label="Текущий пароль"
                type="password"
                v-model="passwordChange.current"
                class="w-100"
                placeholder="Введите текущий пароль"
            />
            <InputBox
                label="Новый пароль"
                type="password"
                v-model="passwordChange.new"
                class="w-100"
                placeholder="Введите новый пароль"
            />
            <Button
                class="w-100 primary mt-3"
                :callback="updatePassword"
                :is-disabled="passwordChange.new === '' || passwordChange.current === ''"
            >
              Обновить
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Кошельки для выплаты</h4>
          </div>
          <div class="p-4 row m-0">
            <p class="text-muted m-0 mb-3">Вы можете сохранить кошельки только один раз. После чего это будет возможно только с включенной двухфакторной защитой.</p>
            <InputBox
                v-for="wallet, index in information.addresses"
                :key="'spammer wallet' + index"
                :label="index"
                v-model="information.addresses[index]"
                :placeholder="`Введите ${index} кошелек`"
                :disabled="information.settings.isWalletsSavedOnce && !information.settings.twoFactorAuthorization.isActive"
                class="col-lg-6"
            />
            <Button
                class="primary w-100 mt-3"
                :callback="updateWallets"
                v-if="!information.settings.isWalletsSavedOnce || information.settings.twoFactorAuthorization.isActive"
            >
              Обновить
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import QrcodeVue from 'qrcode.vue';
import axios from "@/plugins/axios";
import TwoFactorModal from "@/mixins/two-factor-modal";

export default {
  name: "Security",
  props: {
    information: Object
  },
  components: {
    InputBox,
    Button,
    QrcodeVue,
  },
  mixins: [TwoFactorModal],
  data() {
    return {
      telegramClearTwoFactorCodeInput: '',
      changeTwoFactorCodeInput: '',
      passwordChange: {
        current: '',
        new: ''
      }
    }
  },
  methods: {
    async updatePassword(twoFactorCode) {
      try {
        const fetchedData = await axios.post('backoffice/admin/spammer/updatePassword', {
          passwordChange: this.passwordChange,
          twoFactorCode,
        });
        fetchedData.data.status = fetchedData.status;
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (fetchedData.status === 206) return this.openTwoFactorConfirmation(this.updatePassword);
        if (!fetchedData.data.error) {
          this.hideTwoFactorConfirmation();
          this.passwordChange = { current: '', new: '' };
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateWallets(twoFactorCode) {
      try {
        const fetchedData = await axios.post('backoffice/admin/spammer/updateWallets', {
          wallets: this.information.addresses,
          twoFactorCode,
        });
        fetchedData.data.status = fetchedData.status;
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (fetchedData.status === 206) return this.openTwoFactorConfirmation(this.updateWallets);
        if (!fetchedData.data.error) this.hideTwoFactorConfirmation();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async clearTelegramBot() {
      try {
        const fetchedData = await axios.post('backoffice/admin/spammer/clearTelegram', {
          twoFactorCode: this.telegramClearTwoFactorCodeInput
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.information.settings.telegram = fetchedData.data.telegram;
          this.telegramClearTwoFactorCodeInput = '';
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async changeTwoFactorStatus() {
      try {
        const fetchedData = await axios.post('backoffice/admin/spammer/twoFactorStatus', {
          twoFactorCode: this.changeTwoFactorCodeInput
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.information.settings.twoFactorAuthorization = fetchedData.data.twoFactor;
          this.changeTwoFactorCodeInput = '';
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

span.status {
  border-radius: 100%;
  display: inline-block;
  padding: 5px;
  height: 2px;
  margin-left: 5px;
}


</style>