<i18n>
{
  "en": {
    "liveSupport": "Live Support",
    "ticket": "Customer Support",
    "liveChat": "{0} Live Chat",
    "customer": "Customer support",
    "writeMessage": "Write your message",
    "me": "Me",
    "busy": "We're sorry, but at the moment all operators are busy. You can leave a ticket in dashboard area, describing your problem, and we will help you as soon as possible.",
    "error": "An error occurred while getting the chat status. Refresh the page to work correctly."
  },
  "zh": {
    "liveSupport": "實時支持",
    "ticket": "客户支持",
    "liveChat": "{0} 實時聊天",
    "customer": "客戶支持",
    "writeMessage": "寫下你的信息",
    "me": "我",
    "busy": "很抱歉，目前所有接線員都很忙。您可以在儀表板區域留下工單，描述您的問題，我們會盡快為您提供幫助。",
    "error": "獲取聊天狀態時出錯。 刷新頁面。"
  },
  "kr": {
    "liveSupport": "라이브 지원",
    "ticket": "고객 지원",
    "liveChat": "{0} 라이브 채팅",
    "customer": "고객 지원",
    "writeMessage": "메시지 작성",
    "me": "나",
    "busy": "죄송합니다. 현재 모든 교환원들이 바쁩니다. 대시보드 영역에 문제를 설명하는 티켓을 남겨주시면 최대한 빨리 도와드리겠습니다.",
    "error": "채팅 상태를 가져오는 동안 오류가 발생했습니다. 페이지를 새로고침하세요."
  }
}
</i18n>
<template lang="html">
  <section :class="{ dark: $store.state.Index.isDarkMode, mobile: $store.state.Index.isMobile }">
    <div @click="toggleChat" id="supportButton" v-if="!$store.state.Index.isLiveSupportOpened">
      <div class="notification" v-show="messages.filter(x => x.isSupport && !x.isRead).length && isActive">
        {{ messages.filter(x => x.isSupport && !x.isRead).length }}
      </div>
      {{ isActive ? $t('liveSupport') : $t('ticket') }}
    </div>
    <div id="supportWindow" v-else>
      <div class="header">
        <div style="display: flex; align-items: center;">
          <span>{{ $t('liveChat', [$options.filters.capitalize($store.state.Index.websiteName.short)]) }}</span>
        </div>
        <div @click="toggleChat" class="ml-auto">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFFFFF" class="css-1tvkylx" style="cursor: pointer;">
            <path d="M12.24 14.65L8 10.41 9.42 9l2.82 2.82L15.06 9l1.42 1.41-4.24 4.24z" fill="#FFFFFF"></path>
          </svg>
        </div>
      </div>
      <div class="busy" v-if="isAfk || isError">
        <div v-if="isAfk">
          <img class="mb-4" width=150 src="/busy.png" alt=""><br>
          {{ $t('busy') }}
        </div>
        <div v-else>
          <img class="mb-4" width=150 src="/busy.png" alt=""><br>
          {{ $t('error') }}
        </div>
      </div>
      <div class="body" v-else>
        <div class="supportBadge">
          <div style="display: flex; align-items: center;">
            <img width="25" :src="$store.state.Index.logo" alt="" style="margin-right: 5px;" data-v-cfc91daa="">
            <div>
              <b>{{ $store.state.Index.websiteName.short | capitalize }}</b><br>
              <span>{{ $t('customer') }}</span>
            </div>
          </div>
        </div>
        <div class="messageArea" id="messagesBox" ref="messagesBox">
          <div style="width: 100%; text-align: center;" v-show="isChatLoading">
            <font-awesome-icon
                :icon="['fas', 'spinner']"
                id="load-spinner"
            >

            </font-awesome-icon>
          </div>
          <div class="message" v-for="message, index in messages" :key="'support message' + index">
            <div class="date" v-if="isSameDate(messages[index - 1], message)">
              {{ new Date(message.date).getDate() }} {{ months[new Date(message.date).getMonth()] }}, {{ new Date(message.date).getFullYear() }}
            </div>
            <div style="display: flex;">
              <div style="margin: 0 10px;" :class="{ 'mr-auto': message.isSupport, 'ml-auto': !message.isSupport }">
                <div style="display: flex; align-items: center;">
                  <span :class="{ 'ml-auto': !message.isSupport }" class="messageAuthor">{{ message.isSupport ? message.isBot ? $options.filters.capitalize($store.state.domain) + ' Bot' : $options.filters.capitalize($store.state.domain) + ' ' + message.supportMember : $t('me') }}</span>
                </div>
                <div class="messageText">
                  <p>{{ message.text }}</p>
                </div>
                <span class="messageDate" :style="{ float: !message.isSupport ? 'right' : 'left' }">{{ message.date | toDate('noDate') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="typeArea">
          <div style="width: 80%;">
            <input v-model="message" type="text" @keyup.enter.exact="sendMessage" placeholder="Ask me something...">
          </div>
          <div @click="sendMessage">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 12L3 4L6 12L3 20L21 12Z" fill="#CCCCCC"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "@/plugins/axios";
import * as workerTimers from 'worker-timers';

export default {
  data() {
    return {
      isChatLoading: false,
      isOpened: false,
      isLoading: true,
      isError: false,
      isAfk: false,
      isActive: null,
      message: '',
      messages: [],
      offset: 0,
      totalDocuments: 0,
      sendDisabled: false,
      messagesCount: 0,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  methods: {
    isSameDate(date1, date2) {
      return date1 === undefined || new Date(date1.date).getDate() !== new Date(date2.date).getDate()
    },
    async updateChatStatus() {
      try {
        if (this.isChatLoading) return;
        this.isChatLoading = true;
        const fetchedData = await axios.post('/main/user/liveSupportStatus', {
          isOpened: this.$store.state.Index.isLiveSupportOpened,
          offset: this.offset
        });
        this.messages = fetchedData.data.messages;
        if (this.isActive !== fetchedData.data.isActive) {
          if (!fetchedData.data.isActive) await this.$store.dispatch('Index/openLiveSupport', fetchedData.data.isActive);
          await this.$store.dispatch('Index/statusLiveSupport', fetchedData.data.isActive);
          if (this.intervalFunction !== null) workerTimers.clearInterval(this.intervalFunction);
          this.intervalFunction = workerTimers.setInterval(this.updateChatStatus, fetchedData.data.isActive ? 10000 : 30000);
        }
        if (fetchedData.data.isAfk !== this.isAfk) {
          if (this.intervalFunction !== null) workerTimers.clearInterval(this.intervalFunction);
          this.intervalFunction = workerTimers.setInterval(this.updateChatStatus, fetchedData.data.isAfk ? 30000 : 10000);
        }
        this.isAfk = fetchedData.data.isAfk;
        this.isActive = fetchedData.data.isActive;
        if (this.messagesCount < fetchedData.data.messages.length && fetchedData.data.messages.length && fetchedData.data.messages[fetchedData.data.messages.length - 1].isSupport) {
          this.$nextTick(() => this.scrollToChatBottom());
        }
        this.messagesCount = fetchedData.data.messages.length;
        //this.isChatLoading = false;
      } catch (e) {
        this.isError = true;
        workerTimers.clearInterval(this.intervalFunction);
      }
    },
    async getPreviousMessages() {
      try {
        if (this.isChatLoading) return;
        if (this.totalDocuments > 0 && this.messagesCount === this.totalDocuments) return;

        this.isChatLoading = true;
        const prevHeight = this.$refs.messagesBox ? this.$refs.messagesBox.scrollHeight : 0;
        const fetchedData = await axios.post('/main/user/liveSupportStatus', {
          isOpened: this.$store.state.Index.isLiveSupportOpened,
          offset: this.offset
        });
        this.messages = fetchedData.data.messages.concat(this.messages);
        if (this.isActive !== fetchedData.data.isActive) {
          if (!fetchedData.data.isActive) await this.$store.dispatch('Index/openLiveSupport', fetchedData.data.isActive);
          await this.$store.dispatch('Index/statusLiveSupport', fetchedData.data.isActive);
        }
        this.isAfk = fetchedData.data.isAfk;
        this.isActive = fetchedData.data.isActive;

        this.offset += 10;
        this.totalDocuments = fetchedData.data.totalDocuments;
        this.messagesCount = this.messages.length;
        if (this.$refs.messagesBox) {
          this.$nextTick(() => {
            this.$refs.messagesBox.scrollTop = this.$refs.messagesBox.scrollHeight - prevHeight;
          });
        }
        this.isChatLoading = false;
      } catch (e) {
        console.log(e)
        this.isError = true;
        this.isChatLoading = false;
      }
    },
    async toggleChat() {
      if (!this.$store.state.Index.isLiveSupportEnabled) {
        return this.$router.push(this.$linkCreator(this.$i18n.locale, 'dashboard/tickets')).catch(e => null);
      }

      await this.$store.dispatch('Index/openLiveSupport', !this.$store.state.Index.isLiveSupportOpened);
      if (this.$store.state.Index.isLiveSupportOpened) {
        //this.updateChatStatus();
        await this.getPreviousMessages();

        if (this.$refs.messagesBox) {
          this.$refs.messagesBox.addEventListener('scroll', e => {
            if (this.$refs.messagesBox.scrollTop === 0) this.getPreviousMessages();
          })
        }
        this.$nextTick(() => this.scrollToChatBottom());
      }
    },
    async sendMessage() {
      try {
        if (this.sendDisabled) return
        this.message = this.message.trim();
        if (this.message === '') return;

        this.sendDisabled = true
        const fetchedData = await axios.post('/main/user/liveSupport', {message: this.message});
        setTimeout(() => this.sendDisabled = false, 5000);
        if (fetchedData.data.error) return this.$callNotification(this.$i18n.locale, fetchedData);

        this.message = ''
        this.isAfk = fetchedData.data.isAfk;
        this.messages.push(fetchedData.data.message);
        this.$nextTick(() => this.scrollToChatBottom());
      } catch (e) {
        setTimeout(() => this.sendDisabled = false, 5000);
        this.message = '';
        this.$callNotification(this.$i18n.locale, e)
      }
    },
    scrollToChatBottom() {
      const el = this.$refs.messagesBox;
      if (el) el.scrollTop = el.scrollHeight;
    },
  },
  sockets: {
    editLiveSupportMessage: function (data) {
      const index = this.messages.findIndex(x => x._id === data._id);
      if (index > -1) this.$set(this.messages, index, data);
    },
    deleteLiveSupportMessage: function (data) {
      const index = this.messages.findIndex(x => x._id === data._id);
      if (index > -1) this.messages.splice(index, 1);
    },
    newLiveSupportMessage: function (data) {
      this.messages.push(data);
      this.$nextTick(() => this.scrollToChatBottom());
    }
  },
  async mounted() {
    //await this.updateChatStatus();
    await this.getPreviousMessages();
  },
  destroyed() {
    if (this.$refs.messagesBox) {
      this.$refs.messagesBox.removeEventListener('scroll', e => {
        if (this.$refs.messagesBox.scrollTop === 0) this.getPreviousMessages();
      });
    }
  }
}
</script>

<style lang="scss" scoped>

.dark {
  #supportWindow .header[data-v-56aa275d] {
    background-image: initial;
    background-color: rgb(19, 23, 26);
    color: rgb(216, 214, 211);
  }
  #supportWindow .body div.supportBadge {
    box-shadow: rgba(6, 6, 6, 0.1) 0px 0.28571rem 0.57143rem 0px;
  }
  #supportWindow .body .messageArea .message .date {
    color: rgba(215, 213, 209, 0.65);
  }
  #supportWindow .body .messageArea .message .ml-auto .messageText {
    background-image: initial;
    background-color: #1d5eb5;
    color: rgb(216, 214, 211);
  }
  #supportWindow .body .messageArea .message .messageDate {
    color: rgba(144, 135, 123, 0.65);
  }
  #supportWindow .body .messageArea .message .messageText {
    background-image: initial;
    background-color: rgb(33, 35, 36);
    color: rgba(216, 214, 211, 0.65);
  }
  #supportWindow .body .typeArea {
    border-top-color: rgb(54, 59, 61) !important;
  }
  #supportWindow .body .typeArea input {
    border-color: #3B3B3B !important;
    outline-color: #3B3B3B !important;
    background: rgb(59, 59, 59) !important;
  }
  svg path {
    fill: #bbb6b0;
  }
  #supportWindow .body {
    background-image: initial;
    background-color: rgb(28, 30, 31);
  }
  #supportWindow .body .messageArea {
    border-top-color: rgb(54, 59, 61);
    color: rgb(216, 214, 211);
  }
  #supportButton {
    background-color: #2A85FF !important;
    color: rgb(216, 214, 211) !important;
    fill: rgb(216, 214, 211) !important;
  }
}

.mobile {
  #supportButton { bottom: 82px; }
}

#supportButton {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 0.92857rem 1.57143rem;
  border-radius: 999rem;
  font-size: 1.07143rem;
  background-color: #2A85FF !important;
  color: #ffffff !important;
  fill: #ffffff !important;
  z-index: 99999999999;
  cursor: pointer;
  transition: .1s;

  &:hover {
     background-color: #4695ff !important;
  }
}

#supportButton svg path {
  fill: #FFAA29;
}

#supportButton svg {
  width: 100%;
}

#supportButton span {
  display: block;
  opacity: 0;
  font-size: 14px;
  color: #FFAA29;
  width: 0;
}

#supportButton:hover span {
  width: 100%;
  margin-left: 5px;
  opacity: 1;
}

#supportButton .notification {
  position: absolute;
  font-size: 12px;
  background: #fc303e;
  border-radius: 100%;
  padding: 0px 5px;
  right: 0;
  top: -5px;
}

#supportWindow {
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 10px 40px;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  animation: 0.5s ease 0s 1 normal forwards running widget-show;
  display: block;
  bottom: 0;
  right: 0;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 375px;
}

#supportWindow .header {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  background: #12161C;
  color: white;
  width: 100%;
  padding: 15px;
  justify-content: space-between;
}

#supportWindow .header svg {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: white;
  font-size: 24px;
  fill: white;
  width: 1em;
  height: 1em;
}

#supportWindow .body {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  background: #FFFFFF;
  width: 100%;
  height: 500px;

  div.supportBadge {
    font-size: 13px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.285714rem 0.571429rem 0px;
    padding: 0.857143rem 1.14286rem;
    z-index: 3;
    flex-shrink: 0;
  }
}

#supportWindow .body .typeArea {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  border-top: 1px solid rgb(234, 236, 239);
  height: 72px;
  -webkit-box-align: center;
  align-items: center;
}

#supportWindow .body .typeArea input {
  background: inherit;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  margin-left: 15px;
  padding: 0 15px;
  border-radius: 5px;
}

#supportWindow .body .typeArea div {
  box-sizing: border-box;
  margin: auto;
  min-width: 0px;
  display: flex;
  border-radius: 100%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

#supportWindow .body .messageArea {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-top: 1px solid rgb(234, 236, 239);
  height: calc(100% - 57px - 72px);
  color: black;
  overflow: auto;
}

#supportWindow .body .messageArea .message {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
}

#supportWindow .body .messageArea .message .date {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: rgba(2, 2, 2, 0.65);
}

#supportWindow .body .messageArea .message .messageImage {
  background: #ffffff;
  border-radius: 100%;
  padding: 5px;
  display: flex;
  width: 40px;
  height: 40px;
}

#supportWindow .body .messageArea .message .messageImage img {
  width: 30px;
  height: 30px;
}

#supportWindow .body .messageArea .message .messageText {
  background: rgb(245, 245, 245);
  border-radius: 4px;
  padding: 10px 12px;
  white-space: pre-wrap;
  overflow: auto;
  color: rgba(0, 0, 0, 0.65);
  word-break: break-word;
  min-width: 160px;
}

#supportWindow .body .messageArea .message .ml-auto .messageText {
  background: #2A85FF;
  color: white;
 }

#supportWindow .body .messageArea .message .messageText p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
}

#supportWindow .body .messageArea .message .messageAuthor {
  font-size: 14px;
  margin-bottom: 7px;
}

#supportWindow .body .messageArea .message .messageDate {
  margin-top: 5px;
  color: rgba(129, 127, 127, 0.65);
  font-size: 12px;
}

@keyframes widget-show {
  0% {
    opacity: 0.5;
    transform: translate3d(0px, 50%, 0px);
  }

  100% {
    opacity: 1;
    transform: translate3d(0px, 0%, 0px);
  }
}

#supportWindow .busy {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  background: #FFFFFF;
  width: 100%;
  height: 450px;
  display: flex;
  padding: 25px;
  justify-content: center;
  align-items: center;
  color: #626262;
  text-align: center;
}

.mb-4 {
  margin-bottom: 15px;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

#load-spinner {
  animation: spin 2s linear infinite;
  margin: 5px 0;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
