<template>

  <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
    <div class="Card">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Настройки трейдинга</h4>
        </div>
        <div class="p-4 row m-0">

          <div class="form-control text-muted col-lg-12 mb-1">
            <label>* Если вы используете упрощенный вариант трейдинга, вам будет доступна манипуляция курсом валют на сайте.</label>
          </div>
          <div class="form-control col-lg-12 d-flex align-items-center">
            <label class="m-0 mr-3">Использовать упрощенный вариант трейдинга</label>
            <SwitchButton
                :is-checked="domainInfo.tradeSettings.isAdvancedExchange === false"
                @switch-change="domainInfo.tradeSettings.isAdvancedExchange = !domainInfo.tradeSettings.isAdvancedExchange"
            />
          </div>

          <div style=" padding-top: 20px;" class="mb-2 row m-0" v-show="!domainInfo.tradeSettings.isAdvancedExchange">
            <div class="form-control col-lg-12 d-flex align-items-center mb-3">
              <label class="m-0 mr-3">Включить изменение курса монет</label>
              <SwitchButton
                  :is-checked="domainInfo.tradeSettings.coinsPriceChange.isActive"
                  @switch-change="domainInfo.tradeSettings.coinsPriceChange.isActive = !domainInfo.tradeSettings.coinsPriceChange.isActive"
              />
            </div>


            <div class="form-control text-muted col-lg-12 mb-1 mt-5">
              <label>* Если включено изменения курса для всех монет, то оно будет игнорировать курс для отдельно выставленных монет.</label>
            </div>
            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Включить изменение курса ДЛЯ ВСЕХ монет</label>
              <SwitchButton
                  :is-checked="domainInfo.tradeSettings.coinsPriceChange.isChangeAllCoins.isActive"
                  @switch-change="domainInfo.tradeSettings.coinsPriceChange.isChangeAllCoins.isActive = !domainInfo.tradeSettings.coinsPriceChange.isChangeAllCoins.isActive"
              />
            </div>



            <InputBox
                label="Процент изменения курса"
                type="number"
                placeholder="Введите значение в %"
                class="col-lg-6 mb-5"
                v-model="domainInfo.tradeSettings.coinsPriceChange.isChangeAllCoins.percent"
                v-show="domainInfo.tradeSettings.coinsPriceChange.isChangeAllCoins.isActive"
            />

            <div class="form-control col-lg-12 mt-4">
              <label>Изменить курс конкретной монеты</label>

              <div class="p-0 row m-0">
                <SimpleTable
                    :data-length="domainInfo.tradeSettings.coinsPriceChange.changeCoins.length"
                    :columns="['Монета', 'Процент изменения курса', '']"
                    class="p-0 mb-3"
                >
                  <tr v-for="currency, index in domainInfo.tradeSettings.coinsPriceChange.changeCoins" :key="'currency' + index">
                    <td>
                      <div class="form-control mb-0">
                        <select v-model="domainInfo.tradeSettings.coinsPriceChange.changeCoins[index].currency">
                          <option :value="coin.code" v-for="coin, coinIndex in coinList" :key="'coin for price change' + index + coinIndex">{{ coin.code }}</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-control mb-0">
                        <input type="number" v-model="domainInfo.tradeSettings.coinsPriceChange.changeCoins[index].percent">
                      </div>
                    </td>
                    <td>
                      <div @click="domainInfo.tradeSettings.coinsPriceChange.changeCoins.splice(index, 1)" style="z-index: 99;">
                        <i class="fal fa-trash-alt"></i>
                      </div>
                    </td>
                  </tr>
                </SimpleTable>
              </div>

              <div class="col-lg-12 mt-3 mb-2 d-flex">
                <label @click="domainInfo.tradeSettings.coinsPriceChange.changeCoins.push({ currency: 'BTC', percent: 10 })" class="m-auto">+ добавить монету</label>
              </div>
            </div>
          </div>

          <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-4">
            <Button
                class="w-100 primary"
                :callback="updateTradeSettings"
            >
              Обновить
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import axios from "@/plugins/axios";
import SimpleTable from "@/components/backoffice/UI/SimpleTable";

export default {
  name: "DomainGeneral",
  props: {
    domainInfo: Object,
    coinList: Array
  },
  components: {
    Button,
    InputBox,
    SwitchButton,
    SimpleTable
  },
  methods: {
    async updateTradeSettings() {
      try {
        const fetchedData = await axios.post('backoffice/admin/domain/tradeSettings', {
          domainId: this.$route.params.domainId,
          tradeSettings: this.domainInfo.tradeSettings
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  }
}
</script>

<style scoped>

</style>