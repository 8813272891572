<template>
  <Page
      title="Мнемоник фразы"
  >

    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Мнемоники юзеров</h4>
        </div>
        <Table
            ref="mnemonicTable"
            parse-url="/backoffice/admin/mnemonics"
            :columnList="mnemonicsTableColumns"
            default-sort="date"
            searchPlaceholderText="Введите почту или название..."
            @handle-data="mnemonicsTableData = $event"
        >
          <tr v-for="mnemonic, index in mnemonicsTableData" :key="'user' + index">
            <td class="hash">
              <router-link :to="'/back-office/admin/mnemonics/' + mnemonic._id">
                {{ mnemonic.name }}
              </router-link>
            </td>
            <td>
              <router-link :to="'/back-office/admin/users/' + mnemonic.userId">
                {{ mnemonic.username }}
              </router-link>
            </td>
            <td>
              <div style="display: flex; align-items: center; justify-content: space-between;">
                <span>{{ mnemonic.isVerified ? 'Верифицирован' : 'Не верифицирован' }}</span>
                <SwitchButton
                    @switch-change="updateMnemonicStatus(mnemonic._id, $event)"
                    :is-checked="mnemonic.isVerified"
                />
              </div>
            </td>
            <td>{{ mnemonic.firstDate | toDate }}</td>
          </tr>
        </Table>
      </div>
    </div>
  </Page>
</template>

<script>
import Table from "@/components/backoffice/UI/Table";
import Page from "@/components/backoffice/Page";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";

export default {
  name: "Main",
  components: {
    Table,
    Page,
    SwitchButton
  },
  data() {
    return {
      isSomethingSending: false,
      mnemonicsTableData: [],
      mnemonicsTableColumns: [
        {
          name: 'name',
          title: 'Название',
          isSortable: true
        },
        {
          name: 'username',
          title: 'Юзер',
          isSortable: true
        },
        {
          name: 'isVerified',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'firstDate',
          title: 'Дата',
          isSortable: true
        },
      ],
    }
  },
  methods: {
    async updateMnemonicStatus(id, status) {
      try {
        const fetchedData = await axios.post('backoffice/admin/mnemonic/status', {
          mnemonicId: id,
          status
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.mnemonicTable.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style scoped>
.hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
</style>