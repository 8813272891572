<i18n>
{
  "en": {
    "incorrectValue": "Incorrect value"
  },
  "zh": {
    "incorrectValue": "值不正确"
  },
  "kr": {
    "incorrectValue": "잘못된 값"
  }
}
</i18n>

<template>
  <div class="Input" :class="{ invalid: !isValid && isTouched, dark: $store.state.Index.isDarkMode }">
    <label :for="htmlFor">{{ label }}</label>
    <input
        :type="type"
        :id="htmlFor"
        @input="onChangeHandler"
        :disabled="disabled"
        :value="value"
    />

    <span v-show="!isValid && isTouched">{{ errorMessage || $t('incorrectValue') }}</span>
  </div>
</template>

<script>
export default {
  name: "InputBox",
  props: {
    label: String,
    type: String,
    errorMessage: String,
    validation: Object,
    value: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      htmlFor: Math.random().toString(36).substring(2, 8),
      isValid: false,
      isTouched: false
    }
  },
  methods: {
    validateEmail(email) {
      const re = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
      return re.test(String(email).toLowerCase());
    },
    validateText(text) {
      const pattern = /^[a-z]+$/i;
      return pattern.test(String(text).toLowerCase());
    },
    validateControl (value, validation) {
      if (!validation) return true;
      let isValid = true;

      if (validation.required) {
        isValid = value.trim() !== '' && isValid;
      }
      if (validation.email) {
        isValid = this.validateEmail(value) && isValid;
      }
      if (validation.minLength) {
        isValid = value.length >= validation.minLength && isValid;
      }
      if (validation.maxLength) {
        isValid = value.length <= validation.maxLength && isValid;
      }
      if (validation.onlyText) {
        isValid = this.validateText(value) && isValid;
      }

      return isValid;
    },
    onChangeHandler (event) {
      this.isTouched = true;
      if (event.target.value.length >= this.validation.maxLength) event.target.value = event.target.value.slice(0, this.validation.maxLength);
      this.isValid = this.validateControl(event.target.value, this.validation);
      this.$emit('input', event.target.value);
      this.$emit('check-valid', this.isValid);
    }
  }
}
</script>

<style lang="scss" scoped>

.Input.dark {
  input {
    color: rgb(216, 214, 211);
    background-image: initial !important;
    background-color: #1d1f20 !important;
    border-color: rgb(66, 71, 74);
    outline-color: initial;
  }
  label {
    color: white;
    background: transparent;
  }
}

.Input {
  margin-bottom: 24px;
  background: transparent;
}

.Input label {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-size: 12px;
  line-height: 16px;
  color: #474D57;
}

.Input input {
  display: block;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--Stoke, #F0F0F0);
  padding: 7px;
  margin: 5px 0;
  width: 100%;
  outline: none;
  transition: all .2s ease;
  font-size: 12px;
  color: #808191;
}

.Input input:focus {
  border-color: #2A85FF;
}

.Input span {
  color: rgb(217, 48, 78);
  font-size: 12px;
}

.Input.invalid label {
  color: rgb(217, 48, 78);
}

.Input.invalid input {
  border-color: rgb(217, 48, 78);
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>