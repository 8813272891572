<template>
  <div class="page-container" :style="{ 'border-bottom': noLine ? 'none' : '1px solid #e8eaf0' }" :class="{ dark: $store.state.Index.isDarkMode }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PageContainer",
  props: {
    noLine: Boolean
  }
}
</script>

<style lang="scss" scoped>
.page-container::v-deep {



  @media (min-width: 992px) {
    .container {
      width: calc(970px - 360px) !important;
    }
  }

  @media (min-width: 768px) {
    .container {
      width: calc(970px - 360px) !important;
    }
  }

  @media (min-width: 1200px) {
    .container {
      width: calc(1170px - 360px) !important;
    }
  }

  &.dark {
    .content { background: transparent !important; }
    .section {
      background: transparent !important;

      .new-btn-empty a { color: white !important; }
    }
    .wrapped-content { background: rgb(33, 34, 35) !important }
    span.title { color: rgb(197, 193, 189) !important; }
    span.description { color: rgba(197, 193, 189, 0.7) !important; }
    .wrapper {
      background-color: rgb(33, 34, 35) !important;
      span.value {
        color: rgb(197, 193, 189)!important;

        b { color: rgba(197, 193, 189, 0.7) !important; }
      }
      span.title {
        color: rgb(197, 193, 189)!important;
      }
    }
    .section .head h3.title {
      color: rgb(216, 214, 211)!important;
    }
    .section .head div.route a {
      color: rgba(197, 193, 189, 0.7) !important;
      text-decoration-color: initial !important;
      outline-color: initial !important;
      background-color: rgb(33, 34, 35) !important;
    }

    .stats .items span {
      color: rgba(197, 193, 189, 0.6);
    }
    .stats .items h4 {
       color: rgb(197, 193, 189);
     }
    .stats .items {
      border-right-color: rgb(54, 59, 61);
    }
    .current-btc { b { color: rgba(197, 193, 189, 0.7) !important; } }
    .goal-btc { b { color: rgba(197, 193, 189, 0.7) !important; } }
    .progress {
      background-color: rgb(38, 41, 42);
    }
    .section .item .content {
      background-color: rgb(33, 34, 35) !important;
      text-decoration-color: initial;
      outline-color: initial;

      span.title {
        color: rgb(197, 193, 189);
      }
      span.description {
        color: rgba(197, 193, 189, 0.7);
      }
      .icon {
        color: rgb(166, 160, 151);
      }

    }
    .section .dashboard span.title {
      color: rgba(197, 193, 189, 0.7);
    }
    .section .dashboard span.description {
      color: rgb(197, 193, 189);
    }
    .section .dashboard span.currency {
      color: rgba(197, 193, 189, 0.7) !important;
    }
    .section span.currency {
      color: rgba(197, 193, 189, 0.7) !important;
    }
  }

  .section {

    span.title {
      display: block;
      color: rgba(10,15,56,.7);
      font-size: 12px;
      font-weight: 700;
    }

    span.description {
      display: block;
      color: #0a0f38;
      font-size: 22px;
      font-weight: 700;
    }

    span.currency {
      color: rgba(10,15,56,.7);
    }

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3.title {
        color: #000;
        font-size: 18px;
        font-weight: 700;
      }

      div.route {

        a {
          padding: 8px 16px;
          color: rgba(10,15,56,.7);
          font-size: 14px;
          font-weight: 500;
          line-height: 1.41176rem;
          -webkit-transition: .25s ease-out;
          -o-transition: .25s ease-out;
          transition: .25s ease-out;
          text-decoration: none;
          outline: 0;
          border-radius: 2px;
          background-color: #f4f5f9;
        }
      }
    }

    .item {
      padding: 10px;

      .content {
        border-radius: 10px;
        background: var(--card, #F6F9FD);
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 18px;

        .icon {
          margin-right: 12px;
          width: 42px;
          height: 42px;
          flex-shrink: 0;
          color: #55565a;
        }

        span.title {
          padding-top: 2px;
          color: #0a0f38;
          font-size: 14px;
          font-weight: 800;
          line-height: 24px;
          letter-spacing: .01em;
          display: block;
        }

        span.description {
          margin-top: 2px;
          color: rgba(10,15,56,.7);
          font-size: 12px;
          font-weight: 800;
          letter-spacing: .01em;
          display: block;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 10px;
    background: var(--card, #F6F9FD);

    span.title {
      margin-bottom: 4px;
      color: #0a0f38;
      font-size: 14px;
      font-weight: 600;
    }
    span.value {
      color: #0a0f38;
      font-size: 22px;
      font-weight: 700;
    }
  }

  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: rgb(233, 236, 239);
    border-radius: 0.25rem;

    .progress-bar {
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      color: rgb(255, 255, 255);
      text-align: center;
      background-color: rgb(0, 123, 255);
      transition: width 0.6s ease 0s;
    }
  }

  .red {
    color: #e25050 !important;
  }

  .green {
    color: #01aa78 !important;
  }

  .orange {
    color: #f7931a;
  }
}
</style>