<i18n>
{
  "en": {
    "title": "Change password",
    "passwordCurrent": "Old password",
    "passwordNew": "New password",
    "passwordConfirmation": "Repeat password",
    "passwordInputError": "The password should be 8 to 32 characters long"
  },
  "zh": {
    "title": "更改密碼",
    "passwordCurrent": "舊密碼",
    "passwordNew": "新密碼",
    "passwordConfirmation": "重複輸入密碼",
    "passwordInputError": "密码长度为8到32个字符"
  },
  "kr": {
    "title": "비밀번호 변경",
    "passwordCurrent": "기존 비밀번호",
    "passwordNew": "새 비밀번호",
    "passwordConfirmation": "비밀번호 반복",
    "passwordInputError": "비밀번호는 8 ~ 32 자 여야합니다."
  }
}
</i18n>
<template>

  <div>
    <h3 class="title">{{ $t('title') }}</h3>
    <InputBox
        v-for="control, index in formControls"
        :key="index"
        :type="control.type"
        :label="$t(control.label)"
        :validation="control.validation"
        v-model="control.value"
        @check-valid="control.isValid = $event"
        :error-message="$t(control.errorMessage)"
        style="max-width: 450px;"
    />
    <Button
        custom-width="100%"
        :is-async="true"
        :callback="passwordChangeHandler"
        :is-disabled="!$isFormValid(formControls)"
        style="max-width: 450px;"
    >
    </Button>
  </div>

</template>

<script>
import InputBox from "@/components/main/UI/InputBox";
import Button from "@/components/main/UI/Button";
import axios from "@/plugins/axios";
import TwoFactorModal from "@/mixins/two-factor-modal";

export default {
  name: "password",
  components: {
    InputBox,
    Button
  },
  mixins: [TwoFactorModal],
  data() {
    return {
      formControls: {
        passwordOldInput: {
          value: '',
          type: 'password',
          label: 'passwordCurrent',
          errorMessage: 'passwordInputError',
          isValid: false,
          validation: {
            required: true,
            minLength: 8,
            maxLength: 32
          }
        },
        passwordNewInput: {
          value: '',
          type: 'password',
          label: 'passwordNew',
          errorMessage: 'passwordInputError',
          isValid: false,
          validation: {
            required: true,
            minLength: 8,
            maxLength: 32
          }
        },
        passwordRepeatInput: {
          value: '',
          type: 'password',
          label: 'passwordConfirmation',
          errorMessage: 'passwordInputError',
          isValid: false,
          validation: {
            required: true,
            minLength: 8,
            maxLength: 32
          }
        }
      },
    }
  },
  methods: {
    async passwordChangeHandler(twoFactorCode) {
      try {
        const fetchedData = await axios.post('/main/user/passwordchange', {
          passwordOld: this.formControls.passwordOldInput.value,
          passwordNew: this.formControls.passwordNewInput.value,
          passwordRepeat: this.formControls.passwordRepeatInput.value,
          twoFactorCode
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (fetchedData.status === 206) return this.openTwoFactorConfirmation(this.passwordChangeHandler);
        if (!fetchedData.data.error) return location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  }
}
</script>

<style scoped>

</style>