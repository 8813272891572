<i18n>
{
  "en": {
    "title": "Wallet Connect",
    "explain": "Link wallets that will be known by the exchange as trusted, and make withdrawals to them.",
    "name": "Wallet name",
    "date": "Created date",
    "addresses": "Addresses",
    "action": "Action",
    "delete": "Delete",
    "searchPlaceholder": "Search by name or address...",
    "phrase": "Mnemonic phrase (12 or 24 word phrase)",
    "connectedwallets": "Connected wallets",
    "connectwallet": "Connect wallet",
    "verified": "Verified",
    "unverified": "Unverified",
    "status": "Status"
  },
  "zh": {
    "title": "錢包連接",
    "explain": "鏈接您的錢包，以便交易所將其標記為受信任，您可以稍後提取資金。",
    "name": "錢包名稱",
    "date": "創建日期",
    "addresses": "地址",
    "action": "行動",
    "delete": "刪除",
    "searchPlaceholder": "按姓名或地址搜索...",
    "phrase": "助記詞（12 或 24 字詞組）",
    "connectedwallets": "連接錢包",
    "connectwallet": "連接錢包",
    "verified": "已验证",
    "unverified": "未经验证",
    "status": "地位"
  },
  "kr": {
    "title": "지갑 연결",
    "explain": "지갑을 연결하면 해당 거래소에서 신뢰할 수 있는 것으로 표시하여 나중에 자금을 인출할 수 있습니다.",
    "name": "지갑 이름",
    "date": "만든 날짜",
    "addresses": "주소",
    "action": "액션",
    "delete": "삭제",
    "searchPlaceholder": "이름 또는 주소로 검색...",
    "phrase": "니모닉 구문(12 또는 24단어 구문)",
    "connectedwallets": "연결된 지갑",
    "connectwallet": "지갑 연결",
    "verified": "확인됨",
    "unverified": "미확인",
    "status": "상태"
  }
}
</i18n>
<template>
  <div>
    <div style="padding-bottom: 15px;">
      <h3>{{ $t('title') }}</h3>
      <h4>{{$t('explain')}}</h4>
    </div>
    <div style="padding-bottom: 30px;">
      <h3>{{ $t('connectwallet') }}</h3>
      <InputBox
          v-for="control, index in formControls"
          :key="index"
          :type="control.type"
          :label="$t(control.label)"
          :validation="control.validation"
          v-model="control.value"
          @check-valid="control.isValid = $event"
          :error-message="$t(control.errorMessage)"
          style="max-width: 450px;"
      />
      <Button
          custom-width="100%"
          :is-async="true"
          :callback="connectWallet"
          :is-disabled="!$isFormValid(formControls)"
          style="max-width: 450px;"
      >
      </Button>
    </div>
    <div>
      <h3>{{ $t('connectedwallets') }}</h3>
      <OnlineTable
          parse-url="/main/user/getconnectedwallets"
          :column-list="tableConnectedWallets.columns"
          default-sort="date"
          :search-placeholder-text="$t('searchPlaceholder')"
          @handle-data="tableConnectedWallets.data = $event"
      >
        <tr v-for="data, index in tableConnectedWallets.data" :key="index">
          <td>{{ data.name }}</td>
          <td v-html="userWallets(data.wallets)"></td>
          <td style="min-width: 200px;">{{ data.date | toDate }}</td>
          <td :class="data.isVerified ? 'completed' : 'expired'">{{ data.isVerified ? $t('verified') : $t('unverified') }}</td>
          <td><a @click="deleteWallet(data._id)" href="#">{{ $t('delete') }}</a></td>
        </tr>
      </OnlineTable>
    </div>
  </div>
</template>

<script>
import OnlineTable from "@/components/main/UI/OnlineTable";
import axios from "@/plugins/axios";
import InputBox from "@/components/main/UI/InputBox";
import Button from "@/components/main/UI/Button";

export default {
  name: "login-history",
  components: {
    OnlineTable,
    InputBox,
    Button
  },
  data() {
    return {
      formControls: {
        nameInput: {
          value: '',
          type: 'text',
          label: 'name',
          isValid: false,
          validation: {
            required: true,
            maxLength: 12,
            minLength: 4
          }
        },
        phraseInput: {
          value: '',
          type: 'password',
          label: 'phrase',
          isValid: false,
          validation: {
            required: true
          }
        },
      },
      tableConnectedWallets: {
        data: [],
        columns: [
          {
            name: 'name',
            title: this.$t('name'),
            isSortable: false
          },
          {
            name: 'wallets',
            title: this.$t('addresses'),
            isSortable: false
          },
          {
            name: 'date',
            title: this.$t('date'),
            isSortable: false
          },
          {
            name: 'isVerified',
            title: this.$t('status'),
            isSortable: false
          },
          {
            name: 'action',
            title: this.$t('action'),
            isSortable: false
          }
        ]
      },
    }
  },
  methods: {
    userWallets(wallets) {
      let uwallets = '';
      wallets.map(x => uwallets += `${x}<br>`)
      return uwallets;
    },
    async connectWallet() {
      try {
        const fetchedData = await axios.post('/main/user/connectwallet', {
          phrase: this.formControls.phraseInput.value,
          name: this.formControls.nameInput.value
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (!fetchedData.data.error) return location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async deleteWallet(id) {
      try {
        const fetchedData = await axios.post('/main/user/deletewallet', {
          id
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (!fetchedData.data.error) return location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style scoped>
.completed {
  color: #00871e;
}

.waiting {
  color: #216fc3;
}

.expired {
  color: #d90518;
}

.pending {
  color: #e9b10f;
}
</style>