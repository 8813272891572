import VueSocketIO from 'vue-socket.io';
import store from "../store";

export default new VueSocketIO({
  debug: false,
  connection: `https://${document.domain}`,
  vuex: {
    store,
    actionPrefix: 'SOCKET_ACTION_',
    mutationPrefix: 'SOCKET_MUTATION_'
  },
  options: { transports: ['websocket'], path: "/ws/" }
})
