<template>
  <div style="position: relative;" class="mt-3 border pl-3 pr-3 pt-2 pb-2">
    <font-awesome-icon
        class="icon"
        :icon="['fas', 'times']"
        @click="actionOnClose"
    />
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "NotificationCard",
  props: {
    showCondition: Boolean,
    actionOnClose: Function
  }
}
</script>

<style lang="scss" scoped>

.icon {
  position: absolute;
  right: 10px;
  top: 10px;
  transition: .1s;
  cursor: pointer;

  &:hover {
    color: #F06548;
  }
}

</style>