<template>
  <div class="Maintenance">
    <article>
      <span>{{ accessDenied.title || 'Error' }}</span>
      <div>
        <p>{{ accessDenied.text || accessDenied.description || 'Error while loading. Reload page is required.'}}</p>
        &mdash; The <b class="team">{{ websiteName.short }}</b> Team
      </div>
    </article>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AccessDenied",
  computed: {
    ...mapState('Index', [
        'accessDenied',
        'websiteName'
    ])
  }
}
</script>

<style scoped>
.Maintenance {
  display: block;
  width: 100%;
  height: 100vh;
  font-size: 20px;
  color: white;
  background: #12161C;
}
.Maintenance article {
  text-align: center;
  margin: 0 auto;
  padding-top: 150px;
}
.Maintenance span { font-size: 50px; }
.Maintenance a { color: #dc8100; text-decoration: none; }
.Maintenance a:hover { color: #333; text-decoration: none; }
.Maintenance .team { color: #716fb2; }
</style>