<template>
  <div class="row m-0">
    <div class="row m-0">
      <h3 class="mt-0">Перенаправление юзера</h3>
      <div class="text-muted col-lg-12 mb-3">
        Перенаправление возможно только в границах нашего сайта.<br>
        Пример перенаправления - "/dashboard" перенаправит все открытые страницы юзера на вкладку "Dashboard".<br>
        В ссылке не должно быть переменных языка, то есть /en, пишите сразу /нужная_ссылка.
      </div>
      <InputBox
        label="Страница перенаправления"
        placeholder="/dashboard"
        class="col-lg-12"
        @input="userLocation = $event"
      />
      <div style="float: left;" class="col-lg-4 col-md-12 col-xs-12 mb-2">
      <Button
          :is-async="true"
          :callback="changeUserLocation"
          class="primary w-100"
          :is-disabled="userLocation.trim() === ''"
      >
        Перенаправить
      </Button>
    </div>
      <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-5">
        <Button
            :is-async="true"
            :callback="() => changeUserLocation(true)"
            class="primary w-100"
        >
          Перезагрузить страницу юзеру
        </Button>
      </div>
    </div>
    <div class="col-lg-12 p-0">
      <h3>Сессии</h3>
      <Table
          ref="sessionsTableComponent"
          parse-url="/backoffice/user/user/sessions"
          :columnList="sessionsTableColumns"
          default-sort="authDate"
          :post-data="{ userId }"
          searchPlaceholderText="Введите айпи или страну"
          @handle-data="sessionsTableData = $event"
      >
        <tr v-for="session, index in sessionsTableData" :key="'address' + index">
          <td>{{ session.IP || '-' }}</td>
          <td>{{ session.country || '-' }}</td>
          <td>{{ session.userAgent || '-' }}</td>
          <td>{{ session.authDate | toDate }}</td>
          <td style="text-align: center;">
        <span @click="deleteSession(session._id)" class="delete-btn">
          <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
        </span>
          </td>
        </tr>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from "@/components/backoffice/UI/Table";
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import axios from "@/plugins/axios";

export default {
  name: "UserSessions",
  props: {
    userId: String
  },
  components: {
    Table,
    Button,
    InputBox
  },
  data() {
    return {
      userLocation: '',
      sessionsTableData: [],
      sessionsTableColumns: [
        {
          name: 'ip',
          title: 'Айпи',
          isSortable: true
        },
        {
          name: 'country',
          title: 'Страна',
          isSortable: true
        },
        {
          name: 'userAgent',
          title: 'Устройство',
          isSortable: true
        },
        {
          name: 'authDate',
          title: 'Дата входа',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        },
      ],
    }
  },
  methods: {
    async deleteSession(sessionId) {
      try {
        const fetchedData = await axios.post('/backoffice/user/user/deleteSession', {
          userId: this.userId,
          sessionId
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.sessionsTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async changeUserLocation(isReload) {
      try {
        const fetchedData = await axios.post('/backoffice/user/user/changeLocation', {
          userId: this.userId,
          page: this.userLocation,
          isReload
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 500;
  padding-left: 15px;
}

span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}
</style>