<template>
  <Page
      title="Статистика"
  >

    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Критерии поиска</h4>
        </div>
        <div class="p-4 row m-0">

          <div class="form-controls mb-3">
            <div class="col-lg-12 mb-3 d-flex align-items-center">
              <label class="mr-3">Дата регистрации</label>
              <SwitchButton
                  :is-checked="userRegisterDate.isActive"
                  @switch-change="userRegisterDate.isActive = !userRegisterDate.isActive"
              />
            </div>
            <div class="pr-4 pl-4 mb-3">
                <span @click="changeRegisterForms(afk)" class="color-warning mr-3" style="cursor: pointer;" v-for="afk, index in afkTimesList.slice(2)" :key="'afk' + index">
                  {{ afk.title }}
                </span>
            </div>
            <InputBox
                label="От даты регистрации"
                type="date"
                class="col-lg-6"
                v-model="userRegisterDate.minValue"
            />
            <InputBox
                label="До даты регистрации"
                type="date"
                class="col-lg-6"
                v-model="userRegisterDate.maxValue"
            />
          </div>

          <div class="form-controls mb-3">
            <div class="col-lg-12 mb-3 d-flex align-items-center">
              <label class="mr-3">Последнее время активности</label>
              <SwitchButton
                  :is-checked="userLastActiveDate.isActive"
                  @switch-change="userLastActiveDate.isActive = !userLastActiveDate.isActive"
              />
            </div>
            <div class="pr-4 pl-4 mb-3">
                <span @click="changeLastActiveForms(afk)" class="color-warning mr-3" style="cursor: pointer;" v-for="afk, index in afkTimesList" :key="'afk' + index">
                  {{ afk.title }}
                </span>
            </div>
            <InputBox
                label="От минут назад"
                type="number"
                class="col-lg-6"
                placeholder="Введите минимальное значение в минутах"
                v-model="userLastActiveDate.minValue"
            />
            <InputBox
                label="До минут назад"
                type="number"
                class="col-lg-6"
                placeholder="Введите максимальное значение в минутах"
                v-model="userLastActiveDate.maxValue"
            />
          </div>

          <div class="form-controls mb-3">
            <div class="col-lg-12 mb-3 d-flex align-items-center">
              <label class="mr-3">Внесено долларов</label>
              <SwitchButton
                  :is-checked="userDeposited.isActive"
                  @switch-change="userDeposited.isActive = !userDeposited.isActive"
              />
            </div>
            <InputBox
                label="От $"
                type="number"
                class="col-lg-6"
                placeholder="Введите минимальное значение в долларах"
                v-model="userDeposited.minValue"
            />
            <InputBox
                label="До $"
                type="number"
                placeholder="Введите максимальное значение в долларах"
                class="col-lg-6"
                v-model="userDeposited.maxValue"
            />
          </div>

          <div class="form-controls col-lg-6 mb-3">
            <div class="mb-3 d-flex align-items-center">
              <label class="mr-3">Использовал определенный промокод</label>
              <SwitchButton
                  :is-checked="promocode.isActive"
                  @switch-change="promocode.isActive = !promocode.isActive"
              />
            </div>
            <InputBox
                label="Промокод"
                type="text"
                placeholder="Введите промокод"
                v-model="promocode.text"
            />
          </div>

          <div class="form-controls col-lg-6 mb-3">
            <div class="mb-3 d-flex align-items-center">
              <label class="mr-3">Только на определенном домене</label>
              <SwitchButton
                  :is-checked="domain.isActive"
                  @switch-change="domain.isActive = !domain.isActive"
              />
            </div>
            <InputBox
                label="Домен"
                type="text"
                placeholder="Введите домен по шаблону domain.com"
                v-model="domain.text"
            />
          </div>

          <div class="form-controls col-lg-12 mb-3">
            <div class="mb-3 d-flex align-items-center">
              <label class="mr-3">Определенные страны</label>
              <SwitchButton
                  :is-checked="country.isActive"
                  @switch-change="country.isActive = !country.isActive"
              />
            </div>
            <InputBox
                label="Список стран"
                type="text"
                placeholder="Введите страны по шаблону US,GB,TR"
                class="col-lg-6 p-0 pr-4"
                v-model="country.text"
            />
          </div>

          <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mt-4 mb-4">
            <Button
                :is-async="true"
                :callback="searchStatistic"
                class="primary w-100"
                :answer-delay="3000"
            >
              Искать
            </Button>
          </div>

        </div>
      </div>
    </div>

    <div class="Card col-lg-12 mb-5" v-if="isLoadingDone">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Результаты поиска</h4>
        </div>
        <div class="p-4 row m-0">
          <div style="overflow-y: auto;" class="chart">

            <h3 class="mt-0">Users</h3>
            <Bar
                :chart-options="chartOptions"
                :chart-data="chartData"
                :styles="{ 'min-width': '800px' }"
                :height="200"
                v-if="isLoadingDone"
            />
            <h3>Deposited</h3>
            <Bar
                :chart-options="chartOptions2"
                :chart-data="chartData2"
                :styles="{ 'min-width': '800px' }"
                :height="200"
                v-if="isLoadingDone"
            />
            <SimpleTable
                :data-length="Object.keys(statisticData.countryData).length"
                :columns="['Country', 'Users', 'Deposited']"
                class="p-0 mb-3 mt-4 bordered "
            >
              <tr v-for="ct, index in Object.keys(statisticData.countryData)" :key="'cts index ' + index">
                <td>
                  <span style="font-size: 13px;" class="d-flex align-items-center">
                    <img class="mr-2" width="25px" height="15px" :src="'/country/' + ct.toLowerCase() + '.svg'" />{{ ct }}
                  </span>
                </td>
                <td>{{ statisticData.countryData[ct].n }}</td>
                <td>{{ statisticData.countryData[ct].d | usdFormat }}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{{ statisticData.total.n }}</td>
                <td>{{ statisticData.total.d | usdFormat }}</td>
              </tr>
            </SimpleTable>
          </div>
        </div>
      </div>
    </div>

  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, ArcElement, Filler, Tooltip, Legend, BarElement, LineElement, LineController, PointElement, CategoryScale, LinearScale, LogarithmicScale, LogarithmicScaleOptions } from 'chart.js'
import SimpleTable from "@/components/backoffice/UI/SimpleTable";

ChartJS.register(Title, Tooltip, Legend, Filler, BarElement, LineElement, CategoryScale, LineController, PointElement, LinearScale, LogarithmicScale, ArcElement)
export default {
  name: "Statistic",
  components: {
    Page,
    Bar,
    InputBox,
    Button,
    SwitchButton,
    SimpleTable
  },
  data() {
    return {
      chartData2: {
        labels: [],
        datasets: [
          {
            type: 'bar',
            label: 'Deposited',
            data: [],
            backgroundColor: '#F06548',
            barThickness: 10,
            order: 2,
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ': ' + '$'+ (context.parsed.y).toFixed(2)
                }
              }
            },
          }
        ]
      },
      chartOptions2: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      },
      chartData: {
        labels: [],
        datasets: [
          {
            type: 'bar',
            label: 'Users',
            data: [],
            backgroundColor: '#0DA592',
            barThickness: 10,
            order: 2
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      },
      isLoadingDone: false,
      statisticData: {
        findedUsers: [],
        countryData: {},
        total: { n: 0, d: 0 }
      },
      afkTimesList: [
        {
          title: 'Час',
          minValue: 60,
          maxValue: 70
        },
        {
          title: '12 часов',
          minValue: 60 * 12,
          maxValue: 60 * 13
        },
        {
          title: 'День',
          minValue: 60 * 24,
          maxValue: 60 * 25
        },
        {
          title: '2 дня',
          minValue: 60 * 24 * 2,
          maxValue: 60 * 25 * 2
        },
        {
          title: 'Неделя',
          minValue: 60 * 24 * 7,
          maxValue: 60 * 24 * 8
        },
        {
          title: 'Месяц',
          minValue: 60 * 24 * 30,
          maxValue: 60 * 24 * 31
        }
      ],
      userRegisterDate: {
        isActive: false,
        minValue: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7).toISOString().substring(0,10),
        maxValue: new Date().toISOString().substring(0,10),
      },
      userLastActiveDate: {
        isActive: false,
        minValue: 10,
        maxValue: 120,
      },
      userDeposited: {
        isActive: false,
        minValue: 0,
        maxValue: 100,
      },
      age: {
        isActive: false,
        minValue: 18,
        maxValue: 40,
      },
      promocode: {
        isActive: false,
        text: null
      },
      domain: {
        isActive: false,
        text: null
      },
      country: {
        isActive: false,
        text: null
      }
    }
  },
  methods: {
    changeRegisterForms(afk) {
      this.userRegisterDate.minValue = new Date(Date.now() - 1000 * 60 * afk.maxValue).toISOString().substring(0,10);
      this.userRegisterDate.maxValue = new Date(Date.now() - 1000 * 60 * afk.minValue).toISOString().substring(0,10);
    },
    changeLastActiveForms(afk) {
      this.userLastActiveDate.minValue = afk.minValue;
      this.userLastActiveDate.maxValue = afk.maxValue;
    },
    async searchStatistic() {
      try {
        this.isLoadingDone = false;
        const fetchedData = await axios.post('backoffice/user/statistic', {
          statisticInfo: {
            userRegisterDate: this.userRegisterDate,
            userLastActiveDate: this.userLastActiveDate,
            newNotificationInfo: this.newNotificationInfo,
            userDeposited: this.userDeposited,
            promocode: this.promocode,
            domain: this.domain,
            country: this.country,
            //age: this.age
          }
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.statisticData.findedUsers = fetchedData.data.findedUsers;
          this.statisticData.countryData = fetchedData.data.countryData;
          this.chartData.labels = [];
          this.chartData.datasets[0].data = [];
          this.chartData2.labels = [];
          this.chartData2.datasets[0].data = [];
          this.statisticData.total = fetchedData.data.total;
          for (const ct of Object.keys(this.statisticData.countryData)) {
            this.chartData.labels.push(ct);
            this.chartData.datasets[0].data.push(this.statisticData.countryData[ct].n);
            this.chartData2.labels.push(ct);
            this.chartData2.datasets[0].data.push(this.statisticData.countryData[ct].d);
          }
          this.isLoadingDone = true;
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style scoped>
.hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
</style>