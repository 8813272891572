<i18n>
{
  "en": {
    "titleBlock": {
      "title": "Be calm about your crypto",
      "description": "The best way to receive, send and trade cryptocurrency",
      "signIn": "Sign In",
      "getStarted": "Get started",
      "exchangeBtn": "Exchange",
      "dashboardBtn": "Dashboard",
      "titleStaking": "Staking Service",
      "descriptionStaking": "Your trusted partner for staking in blockchain networks",
      "stakingBtn": "Staking",
      "titleReferral": "Referral System",
      "descriptionReferral": "Invite friends and earn together",
      "referralBtn": "Referral page",
      "titleFees": "Extra-low fees",
      "descriptionFees": "Extra-low fees for trading, take all your profit",
      "feesBtn": "Markets"
    },
    "marketsBlock": {
      "nameColumn": "Name",
      "priceColumn": "Last Price",
      "changeColumn": "24h Change",
      "tradeColumn": "Trade",
      "seeMore": "See more"
    },
    "tradeBlock": {
      "title": "Spot and Leverage Market",
      "description": "{websiteName} is the cryptocurrency exchange platform that makes digital currency operations easy and affordable for everyone",
      "featuresList": [
        {
          "title": "Easy to use",
          "description": "Access the cryptocurrency market in a simple and reliable way."
        },
        {
          "title": "Choice of Digital assets",
          "description": "Trade the biggest and latest digital assets."
        },
        {
          "title": "Low fees",
          "description": "Trade at some of the lowest fees available in crypto."
        }
      ]
    },
    "choiceBlock": {
      "title": "Why choose {websiteName} ?",
      "description": "We have served one million users and provider fast, secure, and professional crypto trading services",
      "choiceList": [
        {
          "image": "/support.svg",
          "title": "24/7 Support",
          "text": "Our tickets team is available 24/7. We are receptive to your needs and are always available to help you out."
        },
        {
          "image": "/secure.svg",
          "title": "Secure",
          "text": "Advanced risk control system in the market. Hybrid hot/cold wallet systems and multi-signature technologies. 100% secure for trading and digital asset management"
        },
        {
          "image": "/use.svg",
          "title": "Easy to use",
          "text": "An intuitive and user-friendly trading interface will help make your pastime on our platform as comfortable and easy as possible."
        },
        {
          "image": "/fees.svg",
          "title": "Extra Low Trading Fees",
          "text": "Exchange with extra low fees, keep all your profit"
        },
        {
          "image": "/trusted.svg",
          "title": "Trusted by Industry Experts",
          "text": "State of the art infrastructure with SHA-384 layer encryption and multi server cross-referencing"
        },
        {
          "image": "/privacy.svg",
          "title": "Privacy Info Standards",
          "text": "Your information is secured with AUSTRAC inline with AML policy"
        }
      ]
    },
    "startBlock": {
      "title": "Start trading now",
      "already": "Already have an account?"
    }
  },
  "zh": {
    "titleBlock": {
      "title": "对您的加密货币保持冷静",
      "description": "接收，发送和交易加密货币的最佳方法。",
      "signIn": "登入",
      "getStarted": "开始吧",
      "exchangeBtn": "交換",
      "dashboardBtn": "儀錶盤",
      "titleStaking": "质押服务",
      "descriptionStaking": "您值得信赖的区块链网络质押合作伙伴。",
      "stakingBtn": "质押",
      "titleReferral": "推荐系统",
      "descriptionReferral": "邀请好友一起赚钱！",
      "referralBtn": "转介系统",
      "titleFees": "超低费用",
      "descriptionFees": "超低交易费用，拿走所有利润！",
      "feesBtn": "市场"
    },
    "marketsBlock": {
      "nameColumn": "名称",
      "priceColumn": "最新价",
      "changeColumn": "24h涨跌",
      "tradeColumn": "交易",
      "seeMore": "查看更多"
    },
    "tradeBlock": {
      "title": "現貨和槓桿市場",
      "description": "{websiteName}是一種加密貨幣交換平台，使每個人都能輕鬆進行數字貨幣操作並負擔得起",
      "featuresList": [
        {
          "title": "易於使用",
          "description": "以簡單可靠的方式進入加密貨幣市場。"
        },
        {
          "title": "數字資產的選擇",
          "description": "交易最大和最新的數字資產。"
        },
        {
          "title": "收費低",
          "description": "以最低的加密貨幣費用獲利。"
        }
      ]
    },
    "choiceBlock": {
      "title": "為什麼選擇{websiteName}？",
      "description": "我們已經為100萬用戶提供服務，並提供了快速，安全和專業的交易服務",
      "choiceList": [
        {
          "image": "/support.svg",
          "title": "24/7支持",
          "text": "我們的支持團隊全天候24/7。 我們接受您的需求，並隨時為您提供幫助。"
        },
        {
          "image": "/secure.svg",
          "title": "安全的",
          "text": "市場上先進的風險控制系統。 混合熱/冷錢包系統和多重簽名技術。 100％安全的交易和數字資產管理"
        },
        {
          "image": "/use.svg",
          "title": "易於使用",
          "text": "直觀，用戶友好的交易界面將幫助您盡可能輕鬆舒適地在我們的平台上工作。"
        },
        {
          "image": "/fees.svg",
          "title": "超低交易費",
          "text": "以超低的費用進行兌換，保持您的全部利潤"
        },
        {
          "image": "/trusted.svg",
          "title": "受到行業專家的信賴",
          "text": "具有SHA-384層加密和多服務器交叉引用的最先進基礎架構"
        },
        {
          "image": "/privacy.svg",
          "title": "隱私信息標準",
          "text": "您的信息通過符合反洗錢政策的AUSTRAC進行保護"
        }
      ]
    },
    "startBlock": {
      "title": "立即開始交易",
      "already": "已經有帳號了？"
    }
  },
  "kr": {
    "titleBlock": {
      "title": "암호 화폐에 대해 침착하십시오",
      "description": "암호 화폐를 받고, 보내고, 거래하는 가장 좋은 방법입니다.",
      "signIn": "로그인",
      "getStarted": "시작하다",
      "exchangeBtn": "교환",
      "dashboardBtn": "계기반",
      "titleStaking": "스테이킹 서비스",
      "descriptionStaking": "블록체인 네트워크에서 스테이킹을 위한 신뢰할 수 있는 파트너입니다.",
      "stakingBtn": "스테이킹",
      "titleReferral": "추천 시스템",
      "descriptionReferral": "친구를 초대하고 함께 적립하세요!",
      "referralBtn": "추천 페이지",
      "titleFees": "매우 낮은 수수료",
      "descriptionFees": "매우 낮은 거래 수수료, 모든 이익을 취하십시오!",
      "feesBtn": "시장"
    },
    "marketsBlock": {
      "nameColumn": "이름",
      "priceColumn": "마지막 가격",
      "changeColumn": "24 시간 변경",
      "tradeColumn": "거래하기",
      "seeMore": "더보기"
    },
    "tradeBlock": {
      "title": "스팟 및 레버리지 시장",
      "description": "{websiteName}은 누구나 쉽고 저렴하게 디지털 화폐를 운영 할 수있는 암호 화폐 교환 플랫폼입니다.",
      "featuresList": [
        {
          "title": "사용하기 쉬운",
          "description": "간단하고 신뢰할 수있는 방법으로 암호 화폐 시장에 접근하십시오."
        },
        {
          "title": "디지털 자산 선택",
          "description": "가장 크고 최신 디지털 자산을 거래하십시오."
        },
        {
          "title": "낮은 수수료",
          "description": "암호화폐 에서 사용 가능한 가장 낮은 수수료로 거래하십시오."
        }
      ]
    },
    "choiceBlock": {
      "title": "{websiteName}을 선택하는 이유는 무엇입니까?",
      "description": "백만 명의 사용자에게 서비스를 제공하고 빠르고 안전하며 전문적인 암호화 거래 서비스를 제공했습니다.",
      "choiceList": [
        {
          "image": "/support.svg",
          "title": "연중 무휴 지원",
          "text": "지원팀은 연중 무휴 24 시간 이용 가능합니다. 우리는 귀하의 요구를 수용하고 항상 귀하를 도울 수 있습니다."
        },
        {
          "image": "/secure.svg",
          "title": "안전한",
          "text": "시장의 고급 위험 관리 시스템. 하이브리드 핫 / 콜드 지갑 시스템 및 다중 서명 기술. 거래 및 디지털 자산 관리를위한 100 % 보안"
        },
        {
          "image": "/use.svg",
          "title": "사용하기 쉬운",
          "text": "직관적이고 사용자 친화적 인 거래 인터페이스는 플랫폼에서 가능한 한 편안하고 쉽게 작업 할 수 있도록 도와줍니다."
        },
        {
          "image": "/fees.svg",
          "title": "매우 낮은 거래 수수료",
          "text": "매우 낮은 수수료로 거래하고 모든 수익을 유지하세요"
        },
        {
          "image": "/trusted.svg",
          "title": "업계 전문가의 신뢰",
          "text": "SHA-384 계층 암호화 및 다중 서버 상호 참조를 사용하는 최첨단 인프라"
        },
        {
          "image": "/privacy.svg",
          "title": "개인 정보 보호 표준",
          "text": "귀하의 정보는 AML 정책에 따라 AUSTRAC 인라인으로 보호됩니다."
        }
      ]
    },
    "startBlock": {
      "title": "지금 거래 시작",
      "already": "이미 계정이 있습니까?"
    }
  }
}
</i18n>

<template>
  <Page style="padding: 0 !important;" :is-page-loaded="dataLoadingStatus">

    <html ref="htmlBlock"></html>
    <div v-if="customHtml === false">

      <div class="title-block" style="display: flex; align-items: center; justify-content: center;">

        <div style="background: transparent;" class="col-lg-9 col-md-12 col-xs-12">
          <h1>Crypto starts
            with {{ $store.state.Index.websiteName.short }}</h1>
          <h2 style="font-weight: 400; color: white;">Buy, sell and store over 200 digital assets at one of Europe’s leading exchanges. </h2>

          <div style="margin-top: 50px; display: flex; background: transparent;">
            <div style="margin-right: 50px;background: transparent;">
              <h2 style="color: #4A4ED5; margin-bottom: 5px;">{{ coinList.map(x => x.volume).reduce((cur, next) => cur + next || 0, 0) / 10 | usdFormat }}</h2>
              <span style="font-size: 16px; color: white;">24-hr Network Volume</span>
            </div>
            <div style="margin-right: 50px;background: transparent;">
              <h2 style="color: #4A4ED5; margin-bottom: 5px;">119</h2>
              <span style="font-size: 16px; color: white;">Spot Markets</span>
            </div>
            <div style="background: transparent;">
              <h2 style="color: #4A4ED5; margin-bottom: 5px;">0.0%</h2>
              <span style="font-size: 16px; color: white;">Fee for first trades</span>
            </div>
          </div>

          <div style="background: transparent;">
            <router-link style=" background: #4A4ED5; text-align: center;" :to="$linkCreator($i18n.locale, 'exchange')" class="auth-btn">{{ $t('titleBlock.exchangeBtn') }}</router-link>
            <router-link style="background: #4A4ED5; text-align: center;" :to="$linkCreator($i18n.locale, 'dashboard')" class="auth-btn">{{ $t('titleBlock.dashboardBtn') }}</router-link>
          </div>
        </div>
        <!--<swiper class="swiper" :options="swiperOption">
          <swiper-slide style="padding-bottom: 40px;">
            <div class="swiperBlock container">
              <div class="first-d col-lg-7 col-md-6 col-xs-12">
                <h1 :style="{ 'font-size': isMobile ? '32px' : '56px' }">{{ $t('titleBlock.title') }}</h1>
                <span>{{ $t('titleBlock.description') }}</span>
                <div>
                  <router-link :to="$linkCreator($i18n.locale, 'exchange')" class="auth-btn">{{ $t('titleBlock.exchangeBtn') }}</router-link>
                  <router-link :to="$linkCreator($i18n.locale, 'dashboard')" class="auth-btn">{{ $t('titleBlock.dashboardBtn') }}</router-link>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-xs-12">
                <img style="max-width: 400px;" width="100%" src="/welcome.svg" alt="welcome">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiperBlock container">
              <div class="first-d col-lg-7 col-md-6 col-xs-12">
                <h1 :style="{ 'font-size': isMobile ? '32px' : '56px' }">{{ $t('titleBlock.titleStaking') }}</h1>
                <span>{{ $t('titleBlock.descriptionStaking') }}</span>
                <div>
                  <router-link :to="$linkCreator($i18n.locale, 'dashboard/staking')" class="auth-btn">{{ $t('titleBlock.stakingBtn') }}</router-link>
                  <router-link :to="$linkCreator($i18n.locale, 'dashboard')" class="auth-btn">{{ $t('titleBlock.dashboardBtn') }}</router-link>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-xs-12">
                <img style="max-width: 400px;" width="100%" src="/staking.svg" alt="staking">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiperBlock container">
              <div class="first-d col-lg-7 col-md-6 col-xs-12">
                <h1 :style="{ 'font-size': isMobile ? '32px' : '56px' }">{{ $t('titleBlock.titleReferral') }}</h1>
                <span>{{ $t('titleBlock.descriptionReferral') }}</span>
                <div>
                  <router-link :to="$linkCreator($i18n.locale, 'dashboard/referral')" class="auth-btn">{{ $t('titleBlock.referralBtn') }}</router-link>
                  <router-link :to="$linkCreator($i18n.locale, 'dashboard')" class="auth-btn">{{ $t('titleBlock.dashboardBtn') }}</router-link>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-xs-12">
                <img style="max-width: 400px;" width="100%" src="/referral.svg" alt="referral">
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiperBlock container">
              <div class="first-d col-lg-7 col-md-6 col-xs-12">
                <h1 :style="{ 'font-size': isMobile ? '32px' : '56px' }">{{ $t('titleBlock.titleFees') }}</h1>
                <span>{{ $t('titleBlock.descriptionFees') }}</span>
                <div>
                  <router-link :to="$linkCreator($i18n.locale, 'markets')" class="auth-btn">{{ $t('titleBlock.feesBtn') }}</router-link>
                  <router-link :to="$linkCreator($i18n.locale, 'dashboard')" class="auth-btn">{{ $t('titleBlock.dashboardBtn') }}</router-link>
                </div>
              </div>
              <div class="col-lg-5 col-md-6 col-xs-12">
                <img style="max-width: 400px;" width="100%" src="/earn.svg" alt="earn">
              </div>
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>-->

      </div>

      <div class="markets-block container">

        <table class="table" :class="{ mobile: isMobile }">

          <thead v-show="!isMobile">
          <th>{{ $t('marketsBlock.nameColumn') }}</th>
          <th>{{ $t('marketsBlock.priceColumn') }}</th>
          <th>{{ $t('marketsBlock.changeColumn') }}</th>
          <th>{{ $t('marketsBlock.tradeColumn') }}</th>
          </thead>

          <tbody>
          <router-link tag="tr" :to="$linkCreator($i18n.locale, 'exchange/' + coin.code + '_USDT')" v-for="coin, index in filteredCoinList" :key="index">
            <td>
              <div class="coinName">
                <img width=32 :src="`/currencies/${coin.code}.png`" alt="">
                <div class="captions">
                  <span class="caption">{{ coin.code }}/USDT</span><br>
                  <span class="name">{{ coin.name }}</span>
                </div>
              </div>
            </td>
            <td style="font-size: 16px;" v-show="!isMobile"><p>{{ (coin.usdPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</p></td>
            <td
                :class="{ plus: coin.priceChange24h > 0, minus: coin.priceChange24h <= 0 }"
                class="change"
                v-show="!isMobile"
            >
              {{ coin.priceChange24h > 0 ? '+' + coin.priceChange24h.toFixed(2) + '%' : coin.priceChange24h.toFixed(2)+ '%' }}
            </td>
            <td v-show="!isMobile">
              <router-link :to="$linkCreator($i18n.locale, 'exchange/' + coin.code + '_USDT')" style="background: #4A4ED5; color: white;" class="trade-btn">
                {{ $t('marketsBlock.tradeColumn') }}
              </router-link>
            </td>

            <td class="rates" v-show="isMobile">
              <p>{{ coin.usdPrice | usdFormat }}</p>
              <p
                  :class="{ plus: coin.priceChange24h > 0, minus: coin.priceChange24h <= 0 }"
                  class="change"
              >
                {{ coin.priceChange24h > 0 ? '+' + coin.priceChange24h.toFixed(2) + '%' : coin.priceChange24h.toFixed(2)+ '%' }}
              </p>
            </td>
          </router-link>
          </tbody>

        </table>

        <div style="text-align: center; margin-top: 20px; font-size: 16px;">
          <router-link style="color: #4A4ED5;" :to="$linkCreator($i18n.locale, 'markets')">
            {{  $t('marketsBlock.seeMore')  }}
          </router-link>
        </div>

      </div>

      <div class="trade-block container-fluid">
        <div class="container">
          <div class="information col-lg-6 col-md-6">
            <p class="title">{{ $t('tradeBlock.title') }}</p>
            <p class="description">{{ $t('tradeBlock.description', { websiteName: websiteName.short }) }}</p>

            <div class="features" v-for="feature, index in $t('tradeBlock.featuresList')" :key="index">
              <p class="name">{{ feature.title }}</p>
              <p class="text">{{ feature.description }}</p>
            </div>
          </div>
          <div style="" class="col-lg-6 col-md-6">
            <img width="100%" src="/trading.png" alt="">
          </div>
        </div>
      </div>

      <div class="choice-block container">
        <p :style="{ 'font-size': isMobile ? '32px' : '48px' }" class="title">{{ $t('choiceBlock.title', { websiteName: websiteName.short }) }}</p>
        <p class="description">{{ $t('choiceBlock.description') }}</p>

        <div class="reasons">
          <div class="reason col-lg-4 col-md-6 col-12" v-for="choice, index in $t('choiceBlock.choiceList')" :key="index">
            <img :src="choice.image" alt="">
            <p class="name">{{ choice.title }}</p>
            <p class="text">{{ choice.text }}</p>
          </div>
        </div>
      </div>

      <div class="start-block container-fluid">
        <p :style="{ 'font-size': isMobile ? '32px' : '48px' }" class="title">{{ $t('startBlock.title') }}</p>
        <router-link
            :to="$linkCreator($i18n.locale, 'exchange')" class="auth-btn"
            style="background: #4A4ED5;"
            v-if="isAuthed"
        >
          {{ $t('titleBlock.exchangeBtn') }}
        </router-link>
        <router-link
            :to="$linkCreator($i18n.locale, 'sign-up')" class="auth-btn"
            v-else
        >
          {{ $t('titleBlock.getStarted') }}
        </router-link>
        <p
            v-if="!isAuthed"
            class="description"
        >
          {{ $t('startBlock.already') }} <router-link :to="$linkCreator($i18n.locale, 'auth')">{{ $t('titleBlock.signIn') }}</router-link>
        </p>
      </div>

    </div>
  </Page>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import {mapState} from "vuex";
import 'swiper/css/swiper.css'
import axios from "@/plugins/axios";
import Page from "@/components/main/UI/Page";

export default {
  name: 'Index',
  components: {
    Page
    /*Swiper,
    SwiperSlide*/
  },
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      customHtml: null
    }
  },
  methods: {
    setInnerHTML(elm, html) {
      elm.innerHTML = html;

      Array.from(elm.querySelectorAll("script"))
        .forEach( oldScriptEl => {
          const newScriptEl = document.createElement("script");

          Array.from(oldScriptEl.attributes).forEach( attr => {
            newScriptEl.setAttribute(attr.name, attr.value)
          });

          const scriptText = document.createTextNode(oldScriptEl.innerHTML);
          newScriptEl.appendChild(scriptText);

          oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
        });
}
  },
  computed: {
    ...mapState('Index', [
        'isAuthed',
        'coinList',
        'websiteName',
        'isMobile'
    ]),
    filteredCoinList() {
      return this.coinList.filter(coin => coin.code !== 'USDT' & coin.code !== 'WBTC').slice(0, 9);
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('Index/customPage', { isActive: false, defaultNavbar: false, defaultFooter: false });

      let fetchedData = await axios.get('main/exchange/index');
      if (fetchedData.data.error) {
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
        return;
      }
      if (fetchedData.data.page.settings.isActive) {
        this.customHtml = true;
        const htmlBlock = this.$refs.htmlBlock;
        this.setInnerHTML(htmlBlock, fetchedData.data.page.page);
        //this.customHtml = fetchedData.data.page.page;
        await this.$store.dispatch('Index/customPage', fetchedData.data.page.settings);
      } else {
        await this.$store.dispatch('Index/customPage', { isActive: false, defaultNavbar: true, defaultFooter: true });
        this.customHtml = false;
      }
      this.dataLoadingStatus.isDone = true;

    } catch (e) {
      console.log(e)
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  },
  destroyed() {
    this.$store.dispatch('Index/customPage', { isActive: false, defaultNavbar: true, defaultFooter: true });
  }

}
</script>

<style lang="scss" scoped>

.dark {

  a .auth-btn {
    background-color : #4A4ED5;
    color : #ffff;
  }

  div {
    background-color : #060d24;
  }

  a .trade-btn, a .auth-btn {
    background-color : #4A4ED5;
    color : #ffff;
  }

  div .trade-block.container-fluid {
    background-image : linear-gradient(to bottom, #04091c, #050a1e, #050b20, #060c22, #060d24);
  }

  div .start-block.container-fluid {
    background-image : linear-gradient(to bottom, #060d24, #060c22, #050b20, #050a1e, #04091c);
  }

  .choice-block .description {
    color: rgb(158, 151, 141);
  }
  .choice-block .reasons .reason .text {
    color: rgb(158, 151, 141);
  }
  .choice-block .reasons .reason .name {
    color: rgb(206, 203, 200);
  }
  .choice-block .reasons .reason img {
    filter: invert(80%) sepia(5%) saturate(149%) hue-rotate(349deg) brightness(107%) contrast(83%);
  }
  .markets-block table .coinName .captions .name {
    color: rgb(151, 143, 132);
  }
  .markets-block table thead {
    border-bottom-color: rgb(53, 57, 59);
  }
  .markets-block table td {
    border-bottom-color: rgb(56, 61, 62);
  }
  .markets-block table .coinName .captions .caption {
    color: rgb(197, 193, 188);
  }
  .markets-block table td .trade-btn[data-v-c05bd2ea] {
    color: #cb96fa;
    background: rgba(199, 146, 236, 0.3);
  }

  h2 {
    color : #4A4ED5;
  }
}

.swiper-pagination::v-deep .swiper-pagination-bullet {
  background: white !important;
  border-radius: 0;
  width: 24px;
  height: 4px;
}

.title-block, .features-block, .markets-block, .trade-block, .choice-block {
  box-sizing: border-box;
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .swiperBlock { display: block !important; }
}

.title-block {
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, #002329 50%, rgba(13,21,29,1) 100%);
  background-image: url("/new-bg.jpg") ;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  color: white;
  padding-top: 37px;
  padding-bottom: 20px;
  height: 720px;
  text-align: left;

  .swiperBlock { height: 500px; display: flex; align-items: center; }
  .first-d {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 56px;
    font-weight: 500;
    letter-spacing: -1px;
    margin-bottom: 14px;
    margin-top: 0;
  }

  span {
    display: block;
    font-size: 16px;
    color: #a5a5a5;
    margin-bottom: 38px;
  }

}

.auth-btn {
  text-decoration: none;
  width: 150px;
  line-height: 45px;
  height: 45px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  background-color: #2A85FF;
  cursor: pointer;
  border-radius: 2px;
  transition: all .2s ease;
  vertical-align: top;
  font-size: 16px;
  margin: 0 10px;
  margin-bottom: 10px;

  &:hover {
    color: white !important;
    background: #4A4ED5;
  }
}

.features-block {
  width: 100%;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.markets-block {

  table {
    width: 100%;
    text-align: left;
    padding: 0 20px;
    font-size: 16px;

    p {
      margin: 0;
      padding: 0;
    }

    .coinName {
      display: flex;
      align-items: center;

      .captions {
        display: inherit;
        align-items: baseline;

        .caption {
          color: #1E2026;
          font-weight: 500;
          margin-right: 7px;
        }

        .name {
          color: #838A96;
          font-size: 14px;
        }
      }
    }

    thead {
      border-bottom: 1px solid #F2F2F2;
    }

    th {
      border: none !important;
      font-size: 14px;
      padding: 16px 20px;
      color: #888;
      font-weight: 400;
    }

    tr {
      &:hover .trade-btn {
        color: #fff !important;
        background-color: #7536cf;
        border-color: #7536cf;
      }
    }

    td {
      padding: 16px 20px;
      vertical-align: middle !important;
      border-bottom: 1px solid #e6e6e6;

      .trade-btn {
        display: inline-block;
        width: 120px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #6f13c2;
        text-align: center;
        background-color: rgba(123, 19, 194, 0.1);
        border-radius: 2px;
        font-weight: 500;
        transition: all .2s ease;
      }

      &.change {
        font-size: 16px;

        &.plus {
          color: rgb(46, 189, 133);
        }

        &.minus {
          color: rgb(224, 41, 74);
        }
      }

      img {
        margin-right: 1rem;
      }
    }

    tr {
      transition: .3s;
      cursor: pointer;

      &:hover {
        border-radius: 2px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  table.mobile {
    padding: 0 !important;
    font-size: 16px;

    td {
      padding: 0 0 !important;

      &:first-child {
        padding: 0 0 0 10px !important;
      }
    }

    .rates {
      height: 64px;
      text-align: right;
      padding: 0 10px 0 0 !important;

      p {
        margin: 0;
        padding: 0;

        &.change {

          &.plus {
            color: rgb(46, 189, 133);
          }

          &.minus {
            color: rgb(224, 41, 74);
          }
        }
      }
    }

  }

}

.trade-block {
  background-image : linear-gradient(to bottom, #04091c, #050a1e, #050b20, #060c22, #060d24);
  color: white;
  padding: 30px 0;

  .information {

    .title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.8;
      display: inline-block;
    }
    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.778;
    }

    .features {
      padding: 11px 27px 17px 24px;
      border: 1px solid transparent;
      border-radius: 2px;
      margin-bottom: 12px;

      .name {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.778;
        margin: 0;
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        color: #939aa2;
        line-height: 1.625;
        margin: 0;
      }
    }
  }

}

.choice-block {

  .title {
    margin-bottom: 12px;
    font-weight: 500;
  }

  .description {
    font-size: 16px;
    color: #666;
  }

  .reasons {
    padding-top: 40px;

    .reason {
      height: 280px;
      margin-bottom: 20px;

      img {
        width: 25%;
      }
      .name {
        font-size: 24px;
        color: #111;
        margin: 20px 0 16px;
      }
      .text {
        font-size: 16px;
        color: #666;
      }
    }
  }
}

.start-block {
  background-image: linear-gradient(to bottom, #060d24, #060c22, #050b20, #050a1e, #04091c);
  text-align: center;
  padding-bottom: 40px;

  .title {
    color: white;
    margin-bottom: 32px;
    font-weight: 500;
  }

  .description {
    font-size: 16px;
    color: #888;
    margin-top: 16px;

    a {
      color: #4A4ED5;
    }
  }
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

</style>
