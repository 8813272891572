<template>

  <div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Кастомная монета</h4>
          </div>
          <div class="p-4 row m-0">

            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Активно</label>
              <SwitchButton
                  :is-checked="domainInfo.customCoin.isActive"
                  @switch-change="domainInfo.customCoin.isActive = !domainInfo.customCoin.isActive"
              />
            </div>

            <InputBox
                label="Название"
                type="text"
                placeholder="Название кастомного токена"
                class="col-lg-6"
                v-model="domainInfo.customCoin.name"
            />

            <InputBox
                label="Код"
                type="text"
                placeholder="Название кастомного токена"
                class="col-lg-6"
                v-model="domainInfo.customCoin.code"
            />

            <InputBox
                label="Лого"
                type="text"
                placeholder="Лого кастомного токена"
                class="col-lg-12"
                v-model="domainInfo.customCoin.logo"
            />

            <div class="col-lg-12 mt-3">
              <Button
                  class="w-100 primary"
                  :callback="updateCustomCoin"
                  :is-disabled="!domainInfo.customCoin.code || !domainInfo.customCoin.name || !domainInfo.customCoin.logo"

              >
                Обновить
              </Button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Памп и дамп</h4>
          </div>
          <div class="p-4 row m-0">



            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Изменение курса всех монет</label>
              <SwitchButton
                  :is-checked="newPumpDump.allPairs"
                  @switch-change="newPumpDump.allPairs = !newPumpDump.allPairs"
              />
            </div>
            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Глобальное изменение курса монеты</label>
              <SwitchButton
                  :is-checked="newPumpDump.allPairsForCoin"
                  @switch-change="newPumpDump.allPairsForCoin = !newPumpDump.allPairsForCoin"
              />
            </div>

            <div v-show="!newPumpDump.allPairs">
              <div class="form-control col-lg-6 col-md-6">
                <label for="">Монета</label>
                <select v-model="newPumpDump.coin">
                  <option :value="newPumpDump.allPairsForCoin ? pair.code : pair.coin" v-for="pair, pairIndex in newPumpDump.allPairsForCoin ? coinList : coinPair.filter(x => x.market === newPumpDump.market)" :key="'pair for price change' + pair + pairIndex">{{ newPumpDump.allPairsForCoin ? $coinName(pair.code) : $coinName(pair.coin)  }}</option>
                </select>
              </div>

              <div class="form-control col-lg-6 col-md-6" v-show="!newPumpDump.allPairsForCoin">
                <label for="">Маркет</label>
                <select v-model="newPumpDump.market" @change="onMarketChange($event)">
                  <option :value="market" v-for="market, marketIndex in marketList" :key="'market for price change' + market + marketIndex">{{ market }}</option>
                </select>
              </div>
            </div>

            <InputBox
                label="Процент изменения курса"
                type="number"
                placeholder="Введите значение в % (пример: 1 для +1% или -1 для -1%)"
                class="col-lg-6"
                v-model="newPumpDump.percent"
            />

            <InputBox
                label="Коэффициент цены"
                type="number"
                placeholder="Введите значение выше нуля"
                class="col-lg-6"
                v-model="newPumpDump.coefficient"
            />

            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Статичное повышение курса (курс изменяется на указанный процент навсегда)</label>
              <SwitchButton
                  :is-checked="newPumpDump.isStatic"
                  @switch-change="newPumpDump.isStatic = !newPumpDump.isStatic"
              />
            </div>
            <div v-show="!newPumpDump.isStatic">
              <InputBox
                  label="Начало пампа"
                  type="datetime-local"
                  placeholder="Введите значение в %"
                  class="col-lg-6 col-md-6"
                  v-model="newPumpDump.dateStart"
              />

              <InputBox
                  label="Конец пампа"
                  type="datetime-local"
                  placeholder="Введите значение в %"
                  class="col-lg-6 col-md-6"
                  v-model="newPumpDump.dateEnd"
              />
              <InputBox
                  label="Продолжительность конца пампа (время в минутах за которое курс придет в норму)"
                  type="number"
                  placeholder="Введите значение в минутах"
                  class="col-lg-6 col-md-6"
                  v-model="newPumpDump.endTime"
              />
            </div>


            <div class="col-lg-12 mt-3">
              <Button
                  class="w-100 primary"
                  :callback="createNewPumpDump"
                  :is-disabled="!newPumpDump.percent"

              >
                Создать
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>История пампов и дампов</h4>
          </div>
          <div class="p-0 row m-0">
            <Table
                ref="pumpsTableComponent"
                parse-url="/backoffice/user/domain/getPumpsDumps"
                :post-data="{ domainId: $route.params.domainId }"
                :columnList="pumpsTableColumns"
                default-sort="_id"
                searchPlaceholderText="Введите монету или маркет"
                @handle-data="pumpsTableData = $event"
            >
              <tr v-for="pump, index in pumpsTableData" :key="'pump and dump history' + index">
                <td>{{ pump.allPairsForCoin ? $coinName(pump.coin) : pump.allPairs ? "Глобальный курс" : $coinName(pump.coin) + "/" + pump.market}}</td>
                <td :class="pump.percent > 0 ? 'color-success' : 'color-danger'">{{ pump.percent > 0 ? "+" : "-" }}{{pump.percent}}% / {{ pump.coefficient.toFixed(2) }}</td>
                <td>{{ pump.dateStart | toDate }}</td>
                <td :class="{ 'color-info': pump.isStatic }">{{ pump.isStatic ? 'Статичное изменение' : $options.filters.toDate(pump.dateEnd) }}</td>
                <td :class="{ 'color-warning': pump.isRunning && pump.dateStart <= Date.now(), 'color-info': pump.isRunning && pump.dateStart >= Date.now(), 'color-success': !pump.isRunning }">
                  {{ pump.isEndState ? 'Возвращение курса' : pump.isRunning ? pump.dateStart >= Date.now() ? 'Ожидает старта' : `В работе` : "Закончен" }} <br>
                  <countdown :end-date="pump.dateEnd" :start-date="pump.dateStart" v-if="pump.isRunning && pump.dateStart <= Date.now() && !pump.isStatic && !pump.isEndState"/>
                </td>
                <td class="no-animate" style="text-align: center;">
                  <span @click="stopPumpDump(pump._id)" class="delete-btn color-danger" v-if="pump.isRunning">
                    <font-awesome-icon class="icon" :icon="['fas', 'stop']" />
                  </span>
                  <span class="color-success" v-else>
                    <font-awesome-icon class="icon" :icon="['fas', 'check']" />
                  </span>
                </td>
              </tr>
            </Table>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import axios from "@/plugins/axios";
import SimpleTable from "@/components/backoffice/UI/SimpleTable";
import Table from "@/components/backoffice/UI/Table.vue";
import countdown from "@/components/backoffice/UI/countdown.vue";

export default {
  name: "DomainGeneral",
  props: {
    domainInfo: Object,
    coinList: Array,
    marketList: Array,
    coinPair: Array
  },
  components: {
    Table,
    Button,
    InputBox,
    SwitchButton,
    countdown
    //SimpleTable
  },
  data() {
    return {
      pumpsTableColumns: [
        {
          name: 'coin',
          title: 'Пара',
          isSortable: true
        },
        {
          name: 'percent',
          title: 'Процент / КОЭФ',
          isSortable: true
        },
        {
          name: 'dateStart',
          title: 'Дата начала',
          isSortable: true
        },
        {
          name: 'dateEnd',
          title: 'Дата окончания',
          isSortable: true
        },
        {
          name: 'isRunning',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        },
      ],
      pumpsTableData: [],
      newPumpDump: {
        market: 'USDT',
        coin: 'BTC',
        percent: null,
        dateStart: this.toISOLocal(new Date(Date.now())),
        dateEnd: this.toISOLocal(new Date(Date.now() + 1000 * 60 * 10)),
        isStatic: false,
        allPairs: false,
        allPairsForCoin: false,
        endTime: 1,
        coefficient: 1
      }
    }
  },
  methods: {
    toISOLocal(d) {
      const time = (d.getTime() - d.getTimezoneOffset()*60000).toString().slice(0, -3) + '000';
      d.setTime(time);
      return d.toISOString().slice(0, -1);
    },
    onMarketChange(event) {
      this.newPumpDump.coin = this.coinPair.find(x => x.market === event.target.value).coin;
    },
    async updateCustomCoin() {
      try {
        const fetchedData = await axios.post('backoffice/user/domain/customCoin', {
          domainId: this.$route.params.domainId,
          customCoin: this.domainInfo.customCoin
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async stopPumpDump(pumpDumpId) {
      try {
        const fetchedData = await axios.post('backoffice/user/domain/deletePumpDump', {
          pumpDumpId
        });

        if (!fetchedData.data.error) this.$refs.pumpsTableComponent.fetchTableData();
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async createNewPumpDump() {
      try {
        const pumpDumpData = {...this.newPumpDump};
        pumpDumpData.dateStart = new Date(this.newPumpDump.dateStart).getTime();
        pumpDumpData.dateEnd = new Date(this.newPumpDump.dateEnd).getTime();

        const fetchedData = await axios.post('backoffice/user/domain/createPumpDump', {
          domainId: this.$route.params.domainId,
          pumpDumpData: pumpDumpData
        });
        if (!fetchedData.data.error) this.$refs.pumpsTableComponent.fetchTableData();
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateTradeSettings() {
      try {
        const fetchedData = await axios.post('backoffice/user/domain/tradeSettings', {
          domainId: this.$route.params.domainId,
          tradeSettings: this.domainInfo.tradeSettings
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  }
}
</script>

<style scoped>

.delete-btn {
  cursor: pointer;
}

</style>