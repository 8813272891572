<template>
  <Page
      :title="`Добро пожаловать, ${$store.state.Backoffice.spammerInfo.login} :)`"
      style="position: relative; height: 100vh;"
  >
    <div class="no-chat">
      Выберите страницу в меню для перехода
    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";

export default {
  name: "Default",
  components: {
    Page
  },
}
</script>

<style scoped>
div.no-chat {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 24px;
  background: #262A2F;
  border-radius: 15px;
}
</style>