<template>
  <Page
      title="Юзеры"
      parse-url="/backoffice/admin/users"
      @loaded-data="dataLoading($event)"
  >
    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header">
          <h4>Статистика месяца</h4>
        </div>
        <div style="text-align: center;" class="row m-0">
          <div class="border dashed start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.totalUsers }}</p>
            <p class="text-muted m-0">Всего</p>
          </div>
          <div class="border dashed end start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.currentMonth }}</p>
            <p class="text-muted m-0">Текущий месяц</p>
          </div>
        </div>
        <div style="overflow-y: auto;" class="chart p-4">
          <Bar
              :chart-options="chartOptions"
              :chart-data="chartData"
              :styles="{ 'min-width': '800px' }"
              :height="200"
              v-if="isStatisticsLoaded"
          />
          <div @click="loadStatistics" class="d-flex align-items-center justify-content-center text-muted" style="cursor: pointer; font-size: 12px;" v-else>
            {{ statisticText }}
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Привязанные юзеры</h4>
        </div>
        <Table
            ref="usersTableComponent"
            parse-url="/backoffice/admin/spammerUsers"
            :columnList="usersTableColumns"
            default-sort="settings.registrationDate"
            searchPlaceholderText="Введите айди, почту, адрес, страну, промокод или домен"
            @handle-data="usersTableData = $event"
        >
          <tr v-for="user, index in usersTableData" :key="'user' + index">
            <td>
              <router-link :to="'/back-office/admin/users/' + user._id">
                {{ user.email }}
              </router-link>
            </td>
            <td>{{ user.settings.registrationDate | toDate }}</td>
            <td>{{ user.attachedDate | toDate }}</td>
            <td>{{ user.settings.lastActiveDate | toDate('recent') }}</td>
            <td>
              <router-link :to="'/back-office/admin/domains/' + user.spammerId" v-if="user.spammerId">
                {{ user.spammerId }}
              </router-link>
              <span v-else>Нет</span>
            </td>
            <td>
              <span style="font-size: 13px;" class="d-flex align-items-center">
                <img class="mr-2" width="25px" height="15px" :src="'/country/' + user.country.toLowerCase() + '.svg'" />{{ user.country }}
              </span>
            </td>
            <td>{{ user.hostname }}</td>
            <td class="no-animate" style="text-align: center;">
            <span @click="unlinkUser(user._id)" class="delete-btn">
              <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
            </span>
            </td>
          </tr>
        </Table>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Filler, Tooltip, Legend, BarElement, LineElement, LineController, PointElement, CategoryScale, LinearScale, LogarithmicScale, LogarithmicScaleOptions } from 'chart.js'
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";

ChartJS.register(Title, Tooltip, Legend, Filler, BarElement, LineElement, CategoryScale, LineController, PointElement, LinearScale, LogarithmicScale)
export default {
  name: "Users",
  components: {
    Page,
    Bar,
    Table
  },
  data() {
    return {
      isStatisticsLoaded: false,
      isStatisticsLoading: false,
      statisticText: 'Нажмите, чтобы загрузить статистику',
      attachUserInput: '',
      findUserInput: '',
      findUserResult: null,
      usersTableData: [],
      usersTableColumns: [
        {
          name: 'email',
          title: 'Почта',
          isSortable: true
        },
        {
          name: 'settings.registrationDate',
          title: 'Дата регистрации',
          isSortable: true
        },
        {
          name: 'attachedDate',
          title: 'Дата привязки',
          isSortable: true
        },
        {
          name: 'settings.lastActiveDate',
          title: 'Посл. активность',
          isSortable: true
        },
        {
          name: 'spammerId',
          title: 'ID спаммера',
          isSortable: true
        },
        {
          name: 'country',
          title: 'Страна',
          isSortable: true
        },
        {
          name: 'hostname',
          title: 'Домен',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        },
      ],
      fetchedData: {
        users: {
          total: 0,
          data: [],
          percentDifference: 0
        }
      },
      chartData: {
        labels: Array.from({ length: new Date(new Date().getFullYear(), 1, 0).getDate() }, (_, i) => i + 1),
        datasets: [
          {
            type: 'bar',
            label: 'Юзеры',
            data: [],
            backgroundColor: '#0DA592',
            barThickness: 10,
            order: 2
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },
        scales: {
          yAxes: {
            grace: '10%',
            ticks: {
              precision: 0, // точность округления значений по оси y
              maxTicksLimit: 6, // максимальное количество значений (и рёбер сетки) по оси y
            }
          }
        }
      }
    }
  },
  methods: {
   async unlinkUser(userId) {
     const confirmDelete = confirm('Вы уверены, что хотите отвязать этого юзера?');
     if (!confirmDelete) return;

     try {
       const fetchedData = await axios.post('backoffice/admin/user/unlink', {
         userId
       });
       this.$callNotification(
           this.$i18n.locale,
           fetchedData
       );
       if (!fetchedData.data.error) this.$refs.usersTableComponent.fetchTableData();
     } catch (error) {
       console.log(error)
       this.$callNotification(this.$i18n.locale, error);
     }
   },
   async attachUserHandler() {
      try {
        const fetchedData = await axios.post('backoffice/admin/user/attach', {
          userInfo: this.attachUserInput
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (!fetchedData.data.error) this.$refs.usersTableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async findUserHandler() {
      try {
        const fetchedData = await axios.post('backoffice/admin/user/find', {
          userInfo: this.findUserInput
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );

        if (!fetchedData.data.error) this.findUserResult = fetchedData.data.userInfo;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    dataLoading(data) {
      this.fetchedData = data;
    },
    async loadStatistics() {
      try {
        if (this.isStatisticsLoading) return;
        this.statisticText = "Загрузка данных..."
        this.isStatisticsLoading = true;
        const fetchedData = await axios.get('backoffice/admin/users/statistics');

        if (fetchedData.data.error) return this.statisticText = "Ошибка при загрузке статистики";
        this.chartData.datasets[0].data = fetchedData.data.users.data;
        this.isStatisticsLoaded = true;
        this.isStatisticsLoading = false;
      } catch (error) {
        console.log(error)
        this.isStatisticsLoading = false;
        this.statisticText = "Ошибка при загрузке статистики"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
span.delete-btn {
  text-align: center;
  cursor: pointer;

.icon {
  transition: .1s;
&:hover {
   color: #f06548;
 }
}
}
</style>