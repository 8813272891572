<template>
  <Page
      title="Выплаты"
      parse-url="/backoffice/admin/payouts"
      @loaded-data="dataLoading($event)"
  >
    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header">
          <h4>Статистика месяца</h4>
        </div>
        <div style="text-align: center;" class="row m-0">
          <div class="border dashed start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.totalProfit }}</p>
            <p class="text-muted m-0">Всего выплачено</p>
          </div>
          <div class="border dashed end start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.totalVolume }}</p>
            <p class="text-muted m-0">Общий оборот</p>
          </div>
          <div class="border dashed end start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.payouts.total }}</p>
            <p class="text-muted m-0">Текущий месяц</p>
          </div>
          <div class="border dashed end start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.payouts.volume }}</p>
            <p class="text-muted m-0">Оборот текущего месяца</p>
          </div>
        </div>
        <div style="overflow-y: auto;" class="chart p-4">
          <Bar
              :chart-options="chartOptions"
              :chart-data="chartData"
              :styles="{ 'min-width': '800px' }"
              :height="200"
          />
        </div>
      </div>
    </div>

    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Процент выплат</h4>
        </div>
        <div class="p-4 row m-0">
          <p class="text-muted">Система выплаты с депозитов - {{ $store.state.Backofficeadmin.adminInfo.percent.payoutType === 'fixed' ? `фиксированная` : `половинчатая` }}.</p>
          <p class="text-muted">{{ $store.state.Backofficeadmin.adminInfo.percent.payoutType === 'fixed'
              ? `Вы отдаете ${$store.state.Backofficeadmin.adminInfo.percent.payoutPercent}% с каждого депозита, получая оставшийся процент от свободной суммы. Пример - спаммер получает 75%, вы отдаете фиксированные 10%, и получаете оставшиеся 15%.`
              : `Вы получаете половину от оставшейся суммы депозита. Пример - спаммер получает 85%, оставшийся процент 15% - из него вы получаете 7.5%.` }}
          </p>
        </div>
      </div>
    </div>

    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Выплаты</h4>
        </div>
        <Table
            ref="payoutsTableComponent"
            parse-url="/backoffice/admin/spammersPayouts"
            :columnList="payoutsTableColumns"
            default-sort="date"
            searchPlaceholderText="Введите почту, монету или айди спаммера"
            @handle-data="payoutsTableData = $event"
        >
          <tr v-for="payout, index in payoutsTableData" :key="'payout' + index">
            <td>
              <router-link :to="'/back-office/admin/domains/' + payout.spammerId">
                {{ payout.spammerId }}
              </router-link>
            </td>
            <td>{{ payout.username }}</td>
            <td>
              <div style="display: flex; align-items: center; width: 60px;">
                <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                  <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(payout.currency)" alt="">
                </div>
                {{ payout.currency }}
              </div>
            </td>
            <td>${{payout.amount.toFixed(2) | numberSpace }}</td>
            <td class="d-flex justify-content-space-between" :class="payout.status.isError ? 'color-danger' : payout.status.noWallet ? 'color-warning' : payout.status.isPending || payout.status.isSending ? 'color-info' : 'color-success'">
              {{ payout.status.isError ? 'Ошибка' : payout.status.noWallet ? 'Нет кошелька' : payout.status.isSending ? 'Транзакция отправляется...' : payout.status.isPending ? 'Ожидает конфирмов' : 'Выплачено' }}
              <span @click="resendPayout(payout._id, payout.spammerId)" style="text-decoration: underline; cursor: pointer;" class="color-info" v-if="payout.status.isError || payout.status.noWallet">Повторить отправку</span>
            </td>
            <td>{{ payout.date | toDate }}</td>
          </tr>
        </Table>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Filler, Tooltip, Legend, BarElement, LineElement, LineController, PointElement, CategoryScale, LinearScale, LogarithmicScale, LogarithmicScaleOptions } from 'chart.js'
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import NotificationCard from "@/components/backoffice/UI/NotificationCard";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";

ChartJS.register(Title, Tooltip, Legend, Filler, BarElement, LineElement, CategoryScale, LineController, PointElement, LinearScale, LogarithmicScale)
export default {
  name: "Payouts",
  components: {
    Page,
    Bar,
    Table
  },
  data() {
    return {
      cashPerPercent: 1000,
      payoutsTableData: [],
      payoutsTableColumns: [
        {
          name: 'spammerId',
          title: 'ID спаммера',
          isSortable: true
        },
        {
          name: 'username',
          title: 'Юзер',
          isSortable: true
        },
        {
          name: 'currency',
          title: 'Монета',
          isSortable: true
        },
        {
          name: 'amount',
          title: 'Сумма',
          isSortable: true
        },
        {
          name: 'status.isPending',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
      ],
      fetchedData: {
        payouts: {
          total: 0,
          data: [],
          percentDifference: 0
        }
      },
      chartData: {
        labels: Array.from({ length: new Date(new Date().getFullYear(), 1, 0).getDate() }, (_, i) => i + 1),
        datasets: [
          {
            type: 'bar',
            label: 'Выплаты',
            data: [],
            backgroundColor: '#0DA592',
            barThickness: 10,
            order: 2,
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ': ' +'$'+ context.parsed.y.toLocaleString('en-US')
                }
              }
            },
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },
        scales: {
          yAxes: {
            grace: '10%',
            ticks: {
              precision: 0, // точность округления значений по оси y
              maxTicksLimit: 6, // максимальное количество значений (и рёбер сетки) по оси y
            }
          }
        }
      }
    }
  },
  methods: {
    async resendPayout(payoutId, spammerId) {
      try {
        const fetchedData = await axios.post('backoffice/admin/resendPayout', {
          spammerId,
          payoutId
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) return this.$refs.payoutsTableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    dataLoading(data) {
      this.fetchedData = data;
      this.chartData.datasets[0].data = this.fetchedData.payouts.data;
    }
  }
}
</script>

<style scoped>
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #343B41;
  border-radius: 0.25rem;
}
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #0DA592;
  transition: width .6s ease;
}

.percent_dot {
  display: flex;
  position: relative;
  left: 8.7px;
  justify-content: flex-end;
}

.percent_dot a {
  padding: 0px 2px 2px 2px;
  border-radius: 50px;
  background: #343B41;
  width: 20px;
  display: block;
  font-size: 12px;
  left: 1px;
  top: -2px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.percent_dot.achieved a {
  background: #0DA592;
  color: white;
}

.percent_dot.achieved .arrow-up {
  border-color: transparent transparent #0DA592 transparent;
}

.arrow-up {
  width: 0;
  height: 0;
  position: relative;
  top: 0;
  left: 6px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #343B41;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top: 7px solid #343B41;
}

.ticker {
  text-align: center;
  width: auto;
  display: block;
  padding: 0px 2px 2px 2px;
  border-radius: 50px;
  color: white;
  background: #343B41;
  font-size: 12px;
  position: relative;
}
</style>