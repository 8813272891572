<template>
  <div>

    <div class="col-lg-6 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Добавить айпи в блеклист</h4>
          </div>
          <div class="p-4 row m-0">
            <InputBox
                label="Айпи адрес"
                placeholder="192.0.0.1"
                v-model="newBanIpAddress"
            />
            <div>
              <Button
                  class="w-100 primary"
                  :callback="banIp"
                  :is-disabled="newBanIpAddress === ''"
              >
                Добавить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Блеклист IP</h4>
          </div>
          <Table
              ref="ipsTableComponent"
              parse-url="/backoffice/user/blacklistedIps"
              :columnList="ipsTableColumns"
              default-sort="_id"
              searchPlaceholderText="Введите айпи"
              @handle-data="ipsTableData = $event"
          >
            <tr v-for="ip, index in ipsTableData" :key="'ip' + index">
              <td>{{ ip.ip }}</td>
              <td class="no-animate" style="text-align: center;">
                <span @click="removeBanIp(ip._id)" class="delete-btn">
                  <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
                </span>
              </td>
            </tr>
          </Table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";

export default {
  name: "Domains",
  props: {
    information: Object
  },
  components: {
    InputBox,
    Button,
    Table
  },
  data() {
    return {
      newBanIpAddress: '',
      ipsTableData: [],
      ipsTableColumns: [
        {
          name: 'ip',
          title: 'Айпи',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        },
      ],
    }
  },
  methods: {
    async banIp() {
      try {
        const fetchedData = await axios.post('backoffice/user/banIp', {
          ip: this.newBanIpAddress
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.newBanIpAddress = '';
          this.$refs.ipsTableComponent.fetchTableData();
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async removeBanIp(ipId) {
      try {
        const fetchedData = await axios.post('backoffice/user/removeIp', {
          ipId
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.$refs.ipsTableComponent.fetchTableData();
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}

</style>