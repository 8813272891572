<i18n>
{
  "en": {
    "title": "Login History",
    "device": "Device Name",
    "date": "Authorization Time",
    "country": "Location",
    "ip": "IP Address",
    "action": "Action",
    "terminate": "Terminate",
    "searchPlaceholder": "Search by country or IP address..."
  },
  "zh": {
    "title": "登錄歷史",
    "device": "設備名稱",
    "date": "日期",
    "country": "位置",
    "ip": "IP 地址",
    "action": "行動",
    "terminate": "終止",
    "searchPlaceholder": "按國家或 IP 地址搜索..."
  },
  "kr": {
    "title": "로그인 기록",
    "device": "장치 이름",
    "date": "날짜",
    "country": "위치",
    "ip": "IP 주소",
    "action": "액션",
    "terminate": "삭제",
    "searchPlaceholder": "국가 또는 IP 주소로 검색..."
  }
}
</i18n>
<template>
  <div>
    <h3 class="title">{{ $t('title') }}</h3>
    <OnlineTable
        parse-url="/main/user/getloginhistory"
        :column-list="tableLoginHistory.columns"
        default-sort="date"
        :search-placeholder-text="$t('searchPlaceholder')"
        @handle-data="tableLoginHistory.data = $event"
        style="background: transparent !important;"
    >
      <tr v-for="data, index in tableLoginHistory.data" :key="index">
        <td>{{ data.userAgent }}</td>
        <td style="min-width: 200px;" class="animated">{{ data.authDate | toDate }}</td>
        <td>{{ data.country }}</td>
        <td>{{ data.IP }}</td>
        <td><a @click="terminateSession(data._id)" href="#">{{ $t('terminate') }}</a></td>
      </tr>
    </OnlineTable>
  </div>
</template>

<script>
import OnlineTable from "@/components/main/UI/OnlineTable";
import axios from "@/plugins/axios";

export default {
  name: "login-history",
  components: {
    OnlineTable
  },
  data() {
    return {
      tableLoginHistory: {
        data: [],
        columns: [
          {
            name: 'device',
            title: this.$t('device'),
            isSortable: false
          },
          {
            name: 'date',
            title: this.$t('date'),
            isSortable: true
          },
          {
            name: 'country',
            title: this.$t('country'),
            isSortable: false
          },
          {
            name: 'ip',
            title: this.$t('ip'),
            isSortable: false
          },
          {
            name: 'action',
            title: this.$t('action'),
            isSortable: false
          }
        ]
      },
    }
  },
  methods: {
    async terminateSession(id) {
      try {
        const fetchedData = await axios.post('/main/user/terminatesession', {
          id
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (!fetchedData.data.error) return location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style scoped>

</style>