<template>
  <div class="row m-0">
    <div class="row m-0">
      <h3 class="mt-0">Генерация ордеров</h3>
      <div class="pl-4 pr-4 text-muted mb-4">
        <p style="cursor: pointer" @click="showOrdersHelper = !showOrdersHelper">
          Как работать с генерацией ордеров
          <font-awesome-icon class="ml-3" :icon="showOrdersHelper ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']" />
        </p>
        <div v-show="showOrdersHelper">
          <p>Выберите конкретную дату, например 19.10.2021. Мы видим, что на тот момент самая низкая цена BTC была ~61.000 USDT, а самая высокая ~64.000 USDT, значит выставляем диапозон цены от 61.000 USDT до 64.000 USDT.</p>
          <p>Диапозон даты выставляем от 19.10.2021 00:00 до 19.10.2021 23:59, таким образом мы захватим именно этот день.</p>
          <p>Диапозон количества - это кол-во монет которое будет фигурировать в ордерах, тут нет особой важности, можно ставить значения которые нравятся, например от 0.01 до 0.1 для BTC.</p>
          <p>Необязательно генерировать ордера с диапозоном даты лишь в 1 день, можно брать и несколько дней, и неделю, только для начала узнайте значения монеты которые торговались в этот диапозон даты (наименьшая цена и наибольшая).</p>
        </div>
      </div>
      <InputBox
          label="Количество ордеров (макс. 30 шт)"
          type="number"
          class="col-lg-12"
          :value="newOrdersInfo.quantity"
          @input="newOrdersInfo.quantity = $event"
      />

      <div class="form-control col-lg-4">
        <label>Тип ордера</label>
        <select v-model="newOrdersInfo.type">
          <option value="random">Рандом</option>
          <option value="buy">Покупка</option>
          <option value="sell">Продажа</option>
        </select>
      </div>

      <div class="form-control col-lg-8">
        <label for="">Диапозон даты</label>
        <div class="d-flex">
          <InputBox
              :label-none="true"
              type="datetime-local"
              style="width: 50%;"
              class="mr-2 mb-0"
              :value="newOrdersInfo.dateDiapason[0]"
              @input="newOrdersInfo.dateDiapason[0] = $event"
          />
          <InputBox
              :label-none="true"
              type="datetime-local"
              style="width: 50%;"
              class="ml-2 mb-0"
              :value="newOrdersInfo.dateDiapason[1]"
              @input="newOrdersInfo.dateDiapason[1] = $event"
          />
        </div>
      </div>

      <div class="form-control col-lg-4">
        <label>Рынок</label>
        <select v-model="newOrdersInfo.market">
          <option :value="market" v-for="market, index in markets" :key="'market' + index">{{ market }}</option>
        </select>
      </div>
      <div class="form-control col-lg-8">
        <label for="">Диапозон цены</label>
        <div class="d-flex">
          <InputBox
              :label-none="true"
              type="number"
              style="width: 50%;"
              class="mr-2 mb-0"
              :value="newOrdersInfo.priceDiapason[0]"
              @input="newOrdersInfo.priceDiapason[0] = $event"
          />
          <InputBox
              :label-none="true"
              type="number"
              style="width: 50%;"
              class="ml-2 mb-0"
              :value="newOrdersInfo.priceDiapason[1]"
              @input="newOrdersInfo.priceDiapason[1] = $event"
          />
        </div>
      </div>

      <div class="form-control col-lg-4">
        <label>Монета</label>
        <select v-model="newOrdersInfo.coin">
          <option :value="coin" v-for="coin, index in coinPair.filter(x => x.market === newOrdersInfo.market).map(x => x.coin)" :key="'coin' + index">{{ $coinName(coin) }}</option>
        </select>
      </div>
      <div class="form-control col-lg-8">
        <label for="">Диапозон количества</label>
        <div class="d-flex">
          <InputBox
              :label-none="true"
              type="number"
              style="width: 50%;"
              class="mr-2 mb-0"
              :value="newOrdersInfo.amountDiapason[0]"
              @input="newOrdersInfo.amountDiapason[0] = $event"
          />
          <InputBox
              :label-none="true"
              type="number"
              style="width: 50%;"
              class="ml-2 mb-0"
              :value="newOrdersInfo.amountDiapason[1]"
              @input="newOrdersInfo.amountDiapason[1] = $event"
          />
        </div>
      </div>
      <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-5">
        <Button
            :is-async="true"
            :callback="createOrders"
            class="primary w-100"
        >
          Создать
        </Button>
      </div>
    </div>

    <div class="row m-0">
      <h3 class="mt-0">Ордера</h3>
      <Table
          ref="ordersTableComponent"
          parse-url="/backoffice/user/user/orders"
          :columnList="ordersTableColumns"
          :post-data="{ userId }"
          default-sort="openTime"
          searchPlaceholderText="Введите монету"
          @handle-data="ordersTableData = $event"
          class="col-lg-12 mb-3 pb-5"
      >
        <tr v-for="order, index in ordersTableData" :key="'order' + index">
          <td class="no-animate" :class="order.isBuyerMaker ? 'color-success' : 'color-danger'">{{ order.isBuyerMaker ? 'Buy' : 'Sell' }} {{ order.type }}</td>
          <td class="no-animate">{{ order.coin }} / {{ order.market }}</td>
          <td @click="updateOrderField(order._id, 'middleClosePrice', order.middleClosePrice)">{{ order.middleClosePrice ? order.middleClosePrice.toFixed(5) : '-' }}</td>
          <td @click="updateOrderField(order._id, 'price', order.price)">{{ order.price.toFixed(5) }}</td>
          <td @click="updateOrderField(order._id, 'amount', order.amount)">{{ order.amount.toFixed(5) }}</td>
          <td @click="updateOrderField(order._id, 'openTime', order.openTime)">{{ order.openTime | toDate }}</td>
          <td class="d-flex no-animate justify-content-space-between" :class="order.isExecuted ? 'color-info' : order.isCanceled ? 'color-danger' : 'color-success'">
            {{ order.isExecuted ? 'Исполнен' : order.isCanceled ? 'Отменен' : 'Активен' }}
            <span v-show="!order.isExecuted && !order.isCanceled">
              <font-awesome-icon
                  style="cursor: pointer"
                  :icon="['fas', 'check']"
                  class="color-info mr-3"
                  @click="updateOrder(order._id, 'execute')"
              />
              <font-awesome-icon
                  style="cursor: pointer"
                  :icon="['fas', 'times']"
                  class="color-danger"
                  @click="updateOrder(order._id, 'cancel')"
              />
            </span>
          </td>
          <td class="no-animate" style="text-align: center;">
            <span @click="deleteOrder(order._id)" class="delete-btn">
              <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
            </span>
          </td>
        </tr>
      </Table>
  </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import Table from "@/components/backoffice/UI/Table";

export default {
  name: "UserOrders",
  props: {
    userId: String,
    coinPair: Array,
    markets: Array
  },
  components: {
    Table,
    InputBox,
    Button
  },
  data() {
    return {
      showOrdersHelper: false,
      newOrdersInfo: {
        quantity: 10,
        type: 'random',
        coin: 'BTC',
        market: 'USDT',
        priceDiapason: [50000, 60000],
        amountDiapason: [0.01, 0.05],
        dateDiapason: [new Date(Date.now() - 1000 * 60 * 60 * 24 * 30).toISOString().split('.')[0], new Date(Date.now()).toISOString().split('.')[0]],
      },
      ordersTableData: [],
      ordersTableColumns: [
        {
          name: 'isBuyerMaker',
          title: 'Тип',
          isSortable: true
        },
        {
          name: 'market',
          title: 'Пара',
          isSortable: true
        },
        {
          name: 'middleClosePrice',
          title: 'AVG',
          isSortable: true
        },
        {
          name: 'price',
          title: 'Цена',
          isSortable: true
        },
        {
          name: 'amount',
          title: 'Кол-во',
          isSortable: true
        },
        {
          name: 'openTime',
          title: 'Дата создания',
          isSortable: true
        },
        {
          name: 'isExecuted',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        }
      ],
      months: ['January','February','March','April','May','June','July','August','September','October','November','December']
    }
  },
  methods: {
    formatDate(date) {
      let zeroDay = new Date(date).getDate() <= 9 ? 0 : '';
      return `${this.months[new Date(date).getMonth()]} ${zeroDay}${new Date(date).getDate()} ${new Date(date).getFullYear()}, ${new Date(date).toLocaleTimeString()}`
    },
    async createOrders() {
      try {
        const fetchedData = await axios.post('/backoffice/user/user/createOrders', {
          userId: this.userId,
          ordersInfo: this.newOrdersInfo
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.ordersTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async updateOrder(orderId, action) {
      try {
        const confirmation = confirm(`Вы действительно хотите ${action === 'execute' ? 'исполнить' : 'отменить'} этот ордер?`);
        if (!confirmation) return;

        const fetchedData = await axios.post('/backoffice/user/user/updateOrder', {
          userId: this.userId,
          orderId,
          action
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.ordersTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async deleteOrder(orderId) {
      try {
        const confirmation = confirm('Вы действительно хотите удалить этот ордер?');
        if (!confirmation) return;

        const fetchedData = await axios.post('/backoffice/user/user/deleteOrder', {
          userId: this.userId,
          orderId
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.ordersTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async updateOrderField(orderId, field, current) {
      try {
        let value;
        value = prompt(
            field === 'openTime'
                ? `Введите новую дату. Используйте полные названия месяцев на английском.`
                : `Введите новое значение.`,
            field === 'openTime'
                ? this.formatDate(current)
                : current
        );
        if (!value) return;
        if(field === 'date' && isNaN(new Date(value).getTime())) return this.$callNotification(this.$i18n.locale, { error: true, message: 'Введена неверная дата.' });

        const fetchedData = await axios.post('/backoffice/user/user/updateOrderField', {
          userId: this.userId,
          orderId,
          field,
          value
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.ordersTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    }
  },
}
</script>

<style lang="scss" scoped>

h3 {
  font-weight: 500;
  padding-left: 15px;
}

td:not(.no-animate) {
  transition: .1s;
  cursor: text;

  &:hover {
    background: #2B3035;
  }
}

span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}

</style>