<template>
  <Page
      title="Спаммеры"
      parse-url="/backoffice/admin/spammersPage"
      @loaded-data="dataLoading($event)"
  >
    <div class="row m-0">

      <div class="Card col-lg-6 col-md-6 col-xs-12 mb-5">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Создание спаммера</h4>
          </div>
          <div class="p-4">
            <InputBox
                label="Логин"
                type="text"
                placeholder="Введите логин"
                @input="newSpammerLogin = $event"
            />
            <InputBox
                label="Пароль"
                type="text"
                placeholder="Введите пароль"
                @input="newSpammerPassword = $event"
            />
            <Button
                :is-async="true"
                :callback="createSpammerHandler"
                class="primary w-100"
                :is-disabled="newSpammerLogin.trim() === '' || newSpammerPassword.trim() === ''"
            >
              Создать
            </Button>
          </div>
        </div>
      </div>

      <div class="Card col-lg-6 col-md-6 col-xs-12 mb-5">
        <div class="Card-content">
          <div class="Card-header bordered d-flex justify-content-space-between">
            <h4>Таблица лидеров</h4>
            <h4>Всего заработано: {{ new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD'}).format(fetchedData.totalPayouts) }}</h4>
          </div>
          <div class="p-4 row m-0" style="height: 231.63px; overflow: auto;">
            <tr class="d-flex justify-content-space-between align-items-center mb-2" v-for="spammer, index in fetchedData.spammerList" :key="'spammer payouts' + index">
              <td>{{ spammer.login }}</td>
              <td>{{ new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD'}).format(spammer.profit) }}</td>
            </tr>
          </div>
        </div>
      </div>

    </div>

    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Спаммеры</h4>
        </div>
        <Table
            ref="spammersTableComponent"
            parse-url="/backoffice/admin/adminSpammers"
            :columnList="spammersTableColumns"
            default-sort="_id"
            searchPlaceholderText="Введите логин"
            @handle-data="spammersTableData = $event"
        >
          <tr v-for="spammer, index in spammersTableData" :key="'spammer' + index">
            <td>
              <router-link :to="'/back-office/admin/spammer/' + spammer._id">
                {{ spammer.login }}
              </router-link>
            </td>
            <td>
              <div style="display: flex; align-items: center; justify-content: space-between;" class="mb-2">
                <span>{{ spammer.isSpammer ? 'Спаммерка активна' : 'Спаммерка выключена' }}</span>
                <SwitchButton
                    @switch-change="changeSpammerStatus(spammer._id, $event)"
                    :is-checked="spammer.isSpammer"
                />
              </div>
            </td>
            <td><span :class="{ 'color-success': spammer.supportMember.isActive, 'color-danger': !spammer.supportMember.isActive }">{{ spammer.supportMember.isActive ? 'Да' : 'Нет' }}</span></td>
            <td><span>{{ spammer.lastActiveDate | toDate('recent') }}</span></td>
          </tr>
        </Table>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";

export default {
  name: "Users",
  components: {
    Page,
    Button,
    InputBox,
    Table,
    SwitchButton
  },
  data() {
    return {
      fetchedData: {
        totalPayouts: 0,
        spammerList: []
      },
      newSpammerLogin: '',
      newSpammerPassword: '',
      spammersTableData: [],
      spammersTableColumns: [
        {
          name: 'login',
          title: 'Логин',
          isSortable: true
        },
        {
          name: 'isSpammer',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'supportMember.isActive',
          title: 'Является саппортом',
          isSortable: true
        },
        {
          name: 'lastActiveDate',
          title: 'Последняя активность',
          isSortable: true
        },
      ],
    }
  },
  methods: {
   async changeSpammerStatus(spammerId, value) {
     try {
       const fetchedData = await axios.post('backoffice/admin/changeSpammerStatus', { spammerId, value });
       if (fetchedData.data.error) return this.$callNotification(this.$i18n.locale, fetchedData);
       const spammerInfo = this.spammersTableData.find(x => x._id === spammerId);
       if (spammerInfo) spammerInfo.isSpammer = value;
     } catch (e) {
       console.log(e)
       this.$callNotification(this.$i18n.locale, {
         error: true,
         message: 'Ошибка при изменении прав спаммера'
       });
     }
   },
   async createSpammerHandler() {
      try {
        const fetchedData = await axios.post('backoffice/admin/createSpammer', {
          login: this.newSpammerLogin.trim(),
          password: this.newSpammerPassword.trim(),
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (!fetchedData.data.error) {
          this.$refs.spammersTableComponent.fetchTableData();
          this.newSpammerLogin = '';
          this.newSpammerPassword = '';
          return
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    dataLoading(data) {
     this.fetchedData = data;
    }
  }
}
</script>

<style lang="scss" scoped>
span.delete-btn {
  text-align: center;
  cursor: pointer;

.icon {
  transition: .1s;
&:hover {
   color: #f06548;
 }
}
}
</style>