<template>
  <div class="p-4 row m-0">
    <div class="d-flex align-items-center mb-3" v-for="address, index in walletInfo.addresses" :key="'address' + index">
      <div class="mr-2" style="display: flex; align-items: center; width: 100px;">
        <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
          <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(index)" alt="">
        </div>
        {{ index }}
      </div>
      <InputBox
          :label-none="true"
          type="text"
          class="mb-0 w-100"
          :value="address.address"
          :disabled="true"
      />
      <span title="Нажмите, чтобы синхронизировать кошелек и найти пропущенные транзакции" @click="syncAddress(index)" class="ml-3 p-2 pr-3 pl-3" style="cursor: help; background: #262a2f; border-radius: 4px;" v-show="acceptCurrencies.includes(index)">
          <font-awesome-icon class="icon" :icon="['fas', 'sync']" style="font-size: 12px;" />
        </span>
    </div>
  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import axios from "@/plugins/axios";

export default {
  name: "UserAddresses",
  props: {
    userId: String,
    walletInfo: Object
  },
  components: {
    InputBox
  },
  data() {
    return {
      acceptCurrencies: ['ERC-20', 'BEP-20'],
      isCheckingAddress: false
    }
  },
  methods: {
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.$notify({
        type: 'info',
        text: 'Адрес скопирован'
      });
    },
    async syncAddress(currency) {
      if (this.isCheckingAddress) return;
      try {
        this.isCheckingAddress = true;
        this.$callNotification(this.$i18n.locale, { type: 'info', description: 'Проверяем наличие новых транзакций, может занять некоторое время. Если выдает ошибку, подождите и через некоторое время попробуйте снова' });
        const fetchedData = await axios.post('/backoffice/admin/user/syncAddress', {
          userId: this.userId,
          currency
        })

        this.$callNotification(this.$i18n.locale, fetchedData);
        this.isCheckingAddress = false;
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
        this.isCheckingAddress = false;
      }
    }
  }
}
</script>

<style scoped>

</style>