<template>
  <span>{{ fixed ? currentEarn.toFixed(fixed) : currentEarn }}</span>
</template>

<script>
import * as workerTimers from 'worker-timers';

export default {
  name: "staking",
  props: {
    startDate: Number,
    farmRate: Number,
    lockedAmount: Number,
    earned: Number,
    fixed: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      currentEarn: 0,
      timer: null
    }
  },
  methods: {
    countDownTimer() {
      this.timer = workerTimers.setInterval(() => {
        this.currentEarn = (this.farmRate/3154000000000) * (Date.now() - this.startDate) * this.lockedAmount
      }, 1000);
    }
  },
  mounted() {
    this.currentEarn = (this.farmRate/3154000000000) * (Date.now() - this.startDate) * this.lockedAmount;
    this.countDownTimer()
  },
  destroyed() {
    workerTimers.clearInterval(this.timer);
  }
}
</script>

<style scoped>

</style>
