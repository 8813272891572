<template>
  <Page
      title="Промокоды"
      parse-url="/backoffice/user/promocodes"
      @loaded-data="dataLoading($event)"
  >
    <div class="Card col-lg-6 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Сгенерировать промокоды</h4>
        </div>
        <div class="p-4 row m-0">
          <div class="d-flex align-items-center mb-2">
            <SwitchButton class="mr-2" :is-checked="isCustomPromocode" @switch-change="isCustomPromocode = !isCustomPromocode"></SwitchButton> <span>Кастомный промокод</span>
          </div>
          <p class="text-muted m-0 mb-4">Максимально можно иметь не более 15000 промокодов.</p>

          <div v-if="isCustomPromocode">
            <InputBox
                label="Промокод"
                type="text"
                v-model="customPromocode.code"
            />
            <div class="d-flex">
              <InputBox
                  label="Получаемая сумма в монете"
                  type="number"
                  v-model="customPromocode.currencyAmount"
                  class="w-100 mr-3"
              />
              <div class="form-control">
                <label>Монета</label>
                <select v-model="customPromocode.currency">
                  <option :value="coin.code" v-for="coin, index in fetchedData.coinList" :key="'coin' + index">{{ $coinName(coin.code) }}</option>
                </select>
              </div>
            </div>
            <div class="form-control">
              <label>Ошибка при выводе</label>
              <textarea v-model.trim="customPromocode.message" style="resize: none;" rows="4"></textarea>
            </div>
            <div style="float: right;" class="col-lg-4 p-0">
              <Button
                  class="primary w-100"
                  :callback="generateCustomPromocode"
              >
                Создать
              </Button>
            </div>
          </div>
          <div v-else>
            <InputBox
                label="Количество промокодов"
                type="number"
                v-model="newPromocodesInfo.amount"
            />
            <div class="d-flex">
              <InputBox
                  label="Получаемая сумма в монете"
                  type="number"
                  v-model="newPromocodesInfo.currencyAmount"
                  class="w-100 mr-3"
              />
              <div class="form-control">
                <label>Монета</label>
                <select v-model="newPromocodesInfo.currency">
                  <option :value="coin.code" v-for="coin, index in fetchedData.coinList" :key="'coin' + index">{{ $coinName(coin.code) }}</option>
                </select>
              </div>
            </div>
            <div class="form-control">
              <label>Ошибка при выводе</label>
              <textarea v-model.trim="newPromocodesInfo.message" style="resize: none;" rows="4"></textarea>
            </div>
            <div style="float: right;" class="col-lg-4 p-0">
              <Button
                  class="primary w-100"
                  :callback="generateNewPromocodes"
              >
                Создать
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-6 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Установить сообщение на все промокоды</h4>
        </div>
        <div class="p-4 row m-0">
          <div class="form-control">
            <textarea v-model.trim="messageForAllPromocodes" style="resize: none;" rows="4"></textarea>
          </div>
          <div style="float: right;" class="col-lg-4 p-0">
            <Button
                class="primary w-100"
                :callback="setMessageForAllPromocodes"
            >
              Установить
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-6 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Установить сумму на все промокоды</h4>
        </div>
        <div class="p-4 row m-0">
          <InputBox
              :label-none="true"
              type="number"
              v-model="amountForAllPromocodes"
              class="w-100"
          />
          <div style="float: right;" class="col-lg-4 p-0">
            <Button
                class="primary w-100"
                :callback="setAmountForAllPromocodes"
            >
              Установить
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Промокоды</h4>
        </div>
        <div class="pr-4 pl-4 pt-4">
          <p class="text-muted m-0 mb-4">* Нажмите на значение в таблице, чтобы изменить его</p>
          <Button
              class="danger mb-4 mr-4"
              :callback="deleteAllPromocodes"
          >
            Удалить все промокоды
          </Button>
          <Button
              class="primary mb-4"
              :callback="copyPromocodes"
          >
            Скопировать промокоды из таблицы
          </Button>
        </div>
        <Table
            ref="tableComponent"
            parse-url="/backoffice/user/spammerPromocodes"
            :columnList="tableColumns"
            default-sort="_id"
            searchPlaceholderText="Введите промокод, монету или сообщение"
            @handle-data="tableData = $event"
        >
          <tr v-for="promocode, index in tableData" :key="'promocode' + index">
            <td class="no-animate">{{ promocode.code }}</td>
            <td class="no-animate">
              <div style="display: flex; align-items: center; width: 60px;">
                <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                  <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="'/currencies/'+promocode.currency+'.png'" alt="">
                </div>
                {{ $coinName(promocode.currency) }}
              </div>
            </td>
            <td @click="updatePromocodeField(promocode._id, 'amount', promocode.amount)">{{ promocode.amount.toFixed(6) }}</td>
            <td @click="updatePromocodeField(promocode._id, 'message', promocode.message)" style="width: 600px; word-break: break-word;">{{ promocode.message }}</td>
            <td class="d-flex justify-content-space-between align-items-center">{{ promocode.stats.totalActivate }} <span @click="$modal.show('geo-stats', promocode.stats.country)" style="cursor: pointer;">GEO</span></td>
            <td>{{ promocode.stats.totalDeposit | usdFormat }}</td>
            <td @click="updatePromocodeField(promocode._id, 'comment', promocode.comment)" style="width: 600px; word-break: break-word;">{{ promocode.comment || 'Нет' }}</td>
            <td class="no-animate" style="text-align: center;">
            <span @click="deletePromocode(promocode._id)" style="max-width: 50px !important;" class="delete-btn">
              <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
            </span>
            </td>
          </tr>
        </Table>
      </div>
    </div>

    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>API промокодов</h4>
        </div>
        <div class="p-4 row m-0">
          <ApiContainer
              :title="api.title"
              :url="api.url"
              :method="api.method"
              :query="api.query"
              :responseExample="api.responseExample"
              class="api-container"
              v-for="api, index in apiInfo"
              :key="'api' + index"
          />
        </div>
      </div>
    </div>

    <modal styles="background: #212529; color: white;" name="geo-stats" :adaptive="true" @before-open="e => currentGeoStats = e.params">
      <div class="pt-3 pb-0 row m-0">
        <div style="border-bottom: 1px solid #32383e;" class="pr-4 pl-4 pb-3">
          <span class="mr-2">Сортировка:</span> <span style="font-size: 12px; cursor: pointer;" :class="{ 'color-success': varStat.var === usedSortVar }" class="mr-2" @click="usedSortVar = varStat.var" v-for="varStat, index in sortVars" :key="'var stat' + index">{{ varStat.name }}</span>
        </div>
        <div style="overflow-y: auto; height: 260px;" class="pt-4">
          <div class="col-lg-12 col-md-12 col-xs-12 mb-2 d-flex align-items-center justify-content-space-between" v-for="geo, index in sortedGeoStats(usedSortVar)" :key="'geo index' + index">
            <div style="width: 25%;">
              <img class="mr-2" width="25px" height="15px" :src="'/country/' + geo.c.toLowerCase() + '.svg'" />
              {{ geo.c }}
            </div>
            <div style="width: 25%;">
              <span class="pl-3 pr-3"><font-awesome-icon class="icon" :icon="['fas', 'user']" /> {{ geo.n }}</span>
            </div>
            <div style="width: 25%;">
              {{ geo.d | usdFormat }}
            </div>
            <div style="width: 25%; text-align: right;">
              {{ +geo.d / +geo.n | usdFormat }} / activate
            </div>
          </div>
        </div>
      </div>
    </modal>

  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import ApiContainer from "@/components/backoffice/UI/ApiContainer";

export default {
  name: "Promocodes",
  components: {
    Page,
    Table,
    InputBox,
    Button,
    SwitchButton,
    ApiContainer
  },
  data() {
    return {
      sortVars: [{ name: "Активация", var: "n" }, { name: "Депозит", var: "d" }],
      usedSortVar: "n",
      currentGeoStats: [],
      apiInfo: [
        {
          title: 'Список промокодов',
          method: "GET",
          url: 'promocodes/list',
          query: {
            required: [
              { key: "apiKey", type: "string", example: this.$store.state.Backoffice.spammerInfo.settings.apiKey, description: "Ваш апикей." },
              { key: "amount", type: "number", example: "10", description: "Количество промокодов для возврата, максимальное количество за раз - 1000." },
              { key: "page", type: "number", example: "1", description: "Номер страницы, используется для пропуска промокодов для возможности получить все существующие промокоды в несколько запросов, если номер страницы будет выше 1, то будет пропускаться количество промокодов из поля amount." },
            ],
            optional: [

              { key: "comment", type: "string", example: "test comment", description: "Фильтрует промокоды по полю 'Комментарий'." },
            ]
          },
          responseExample: JSON.stringify({ "promocodesInfo": [ "88knhre9", "test1", "asdasd", "fkyp7s8y", "aym54ux8", "45g2zsc3", "cknayu8m", "testpromocode2", "testpromocode", "b9a5cg6x" ], "error": false, "description": "Done" }, null, 4)
        },
        {
          title: 'Статистика нескольких промокодов',
          method: "GET",
          url: 'promocodes/statistic',
          query: {
            required: [
              { key: "apiKey", type: "string", example: this.$store.state.Backoffice.spammerInfo.settings.apiKey, description: "Ваш апикей." },
              { key: "codes", type: "string", example: "testpromocode1,testpromocode2,testpromocode3", description: "Список промокодов через запятую без пробела, максимальное количество за один запрос - 200." },
            ],
            optional: [

              { key: "sumStats", type: "string", example: "1", description: "Суммирует статистику по всем промокодам, если значение равно 1." },
            ]
          },
          responseExample: JSON.stringify({"promocodesInfo":[{"comment":null,"code":"testpromocode1","total":{"activate":0,"deposit":0,"paid":0},"countryDetails":{}},{"comment":null,"code":"testpromocode2","total":{"activate":3,"deposit":0,"paid":0},"countryDetails":{"NG":{"n":3,"d":0,"p":0}}},{"comment":null,"code":"testpromocode3","total":{"activate":1,"deposit":14.34,"paid":0},"countryDetails":{"US":{"n":1,"d":14.34,"p":0}}}],"totalInfo":{"activate":4,"deposit":14.34,"paid":0,"countryDetails":{"NG":{"n":3,"d":0,"p":0},"US":{"n":1,"d":14.34,"p":0}}},"error":false,"description":"Done"}, null, 4)
        },
        {
          title: 'Информация о промокоде',
          method: "GET",
          url: 'promocode/info',
          query: {
            required: [
              { key: "apiKey", type: "string", example: this.$store.state.Backoffice.spammerInfo.settings.apiKey, description: "Ваш апикей." },
              { key: "code", type: "string", example: "testpromocode", description: "Промокод по которому хотите получить информацию." },
            ],
            optional: []
          },
          responseExample: JSON.stringify({ "promocodeInfo": { "message": "Test error message", "currency": "BTC", "amount": 1, "code": "testpromocode" }, "error": false, "description": "Done" }, null, 4)
        },
        {
          title: 'Генерация промокодов',
          method: "GET",
          url: 'promocode/create',
          query: {
            required: [
              { key: "apiKey", type: "string", example: this.$store.state.Backoffice.spammerInfo.settings.apiKey, description: "Ваш апикей." },
              { key: "amount", type: "number", example: 1, description: "Количество промокодов для создания (через апи можно создать не более 100 промокодов за раз)." },
              { key: "currency", type: "string", example: "BTC", description: "Валюта, в которой начислять бонус после использования промокода (валюта должна быть на сайте)." },
              { key: "currencyAmount", type: "number", example: 1, description: "Сумма которая начислится после применения промокода." },
              { key: "message", type: "string", example: "Test error message", description: "Сообщение дефолтной ошибки, которое установится после применения промокода." },
            ],
            optional: [
              { key: "comment", type: "string", example: "тест коммент", description: "Комментарий, который сразу будет установлен при создании промокодов. ВНИМАНИЕ: комментарий будет установлен на все сгенерированные промокоды!" },
            ]
          },
          responseExample: JSON.stringify({ "createdCodes": [ "newpromocode" ], "error": false, "description": "Создано 1 промокодов" }, null, 4)
        },
        {
          title: 'Создать кастомный промокод',
          method: "GET",
          url: 'promocode/createCustom',
          query: {
            required: [
              { key: "apiKey", type: "string", example: this.$store.state.Backoffice.spammerInfo.settings.apiKey, description: "Ваш апикей." },
              { key: "code", type: "string", example: "testpromocode", description: "Промокод, который хотите создать." },
              { key: "currency", type: "string", example: "BTC", description: "Валюта, в которой начислять бонус после использования промокода (валюта должна быть на сайте)." },
              { key: "currencyAmount", type: "number", example: 1, description: "Сумма которая начислится после применения промокода." },
              { key: "message", type: "string", example: "Test error message", description: "Сообщение дефолтной ошибки, которое установится после применения промокода." },
            ],
            optional: [
              { key: "comment", type: "string", example: "тест коммент", description: "Комментарий, который сразу будет установлен при создании промокода." },
            ]
          },
          responseExample: JSON.stringify({ "promocodeInfo": { "comment": null, "activateCount": 0, "depositAmount": 0, "message": "Test error message", "currency": "BTC", "amount": 1, "code": "testpromocode" }, "error": false, "description": "Промокод успешно создан" }, null, 4)
        },
        {
          title: 'Обновить существующий промокод',
          method: "GET",
          url: 'promocode/update',
          query: {
            required: [
              { key: "apiKey", type: "string", example: this.$store.state.Backoffice.spammerInfo.settings.apiKey, description: "Ваш апикей." },
              { key: "code", type: "string", example: "testpromocode", description: "Промокод который хотите обновить." },
            ],
            optional: [
              { key: "amount", type: "number", example: 1, description: "Сумма которая начислится после применения промокода." },
              { key: "message", type: "string", example: "Test error message", description: "Сообщение дефолтной ошибки, которое установится после применения промокода." },
              { key: "comment", type: "string", example: "test comment", description: "Комментарий к промокоду." },
            ]
          },
          responseExample: JSON.stringify({ "error": false, "description": "У промокода успешно изменены поля: 'amount' 'message' 'comment'" }, null, 4)
        },
        {
          title: 'Статистика промокода',
          method: "GET",
          url: 'promocode/statistic',
          query: {
            required: [
              { key: "apiKey", type: "string", example: this.$store.state.Backoffice.spammerInfo.settings.apiKey, description: "Ваш апикей." },
              { key: "code", type: "string", example: "testpromocode", description: "Промокод по которому хотите получить информацию." },
            ],
            optional: []
          },
          responseExample:`{
  "promocodeInfo": {
    "code": "testpromocode",
    "total": {
      "activate": 1,
      "deposit": 100
    },
    "countryDetails": {
      "NG": {
        "n": 1,
        "d": 100
      }
    }
  },
  "error": false,
  "description": "Done"
}`
        },
        {
          title: 'Удалить промокод',
          method: "GET",
          url: 'promocode/delete',
          query: {
            required: [
              { key: "apiKey", type: "string", example: this.$store.state.Backoffice.spammerInfo.settings.apiKey, description: "Ваш апикей." },
              { key: "code", type: "string", example: "testpromocode", description: "Промокод который хотите удалить." },
            ],
            optional: []
          },
          responseExample: JSON.stringify({ "error": false, "description": "Промокод удален" }, null, 4)
        }
      ],
      isCustomPromocode: false,
      customPromocode: {
        code: '',
        message: '',
        currency: 'BTC',
        currencyAmount: 0.1
      },
      newPromocodesInfo: {
        amount: 10,
        message: '',
        currency: 'BTC',
        currencyAmount: 0.1
      },
      amountForAllPromocodes: '',
      messageForAllPromocodes: '',
      tableData: [],
      tableColumns: [
        {
          name: 'code',
          title: 'Код',
          isSortable: true
        },
        {
          name: 'currency',
          title: 'Монета',
          isSortable: true
        },
        {
          name: 'amount',
          title: 'Сумма',
          isSortable: true
        },
        {
          name: 'message',
          title: 'Ошибка при выводе',
          isSortable: true
        },
        {
          name: 'stats.totalActivate',
          title: 'Активировано',
          isSortable: true
        },
        {
          name: 'stats.totalDeposit',
          title: 'Внесено',
          isSortable: true
        },
        {
          name: 'comment',
          title: 'Комментарий',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        }
      ],
      fetchedData: {
        coinList: []
      },
    }
  },
  methods: {
    sortedGeoStats(sortVar) {
      return [...this.currentGeoStats].sort((a, b) => b[sortVar] - a[sortVar]);
    },
    dataLoading(data) {
      this.fetchedData = data;
    },
    copyPromocodes() {
      let tab = window.open('about:blank', '_blank');
      let codes = '';
      this.tableData.map(x => { codes += x.code + '<br>' });
      tab.document.write(codes); // where 'html' is a variable containing your HTML
      tab.document.close(); // to finish loading the page
    },
    async updatePromocodeField(promocodeId, field, current) {
      try {
        const newValue = prompt(`Введите новое значение.`, current);
        if (!newValue) return;

        const fetchedData = await axios.post('backoffice/user/promocodes/update', {
          promocodeId,
          field,
          newValue
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.tableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async generateCustomPromocode() {
      try {
        if (this.customPromocode === '') return;

        const fetchedData = await axios.post('backoffice/user/promocodes/customCreate', {
          customPromocode: this.customPromocode
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.tableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async generateNewPromocodes() {
      try {
        if (this.newPromocodesInfo.message === '') return;
        if (this.newPromocodesInfo.amount === '') return;
        if (this.newPromocodesInfo.currencyAmount === '') return;

        const fetchedData = await axios.post('backoffice/user/promocodes/create', {
          newPromocodesInfo: this.newPromocodesInfo
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.tableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async deleteAllPromocodes() {
      try {
        const isConfirm = confirm('Вы уверены что хотите удалить все промокоды?');
        if (!isConfirm) return;

        const fetchedData = await axios.post('backoffice/user/promocodes/deleteAll');
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.tableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async deletePromocode(promocodeId) {
      try {
        const isConfirm = confirm('Вы уверены что хотите удалить этот промокод?');
        if (!isConfirm) return;

        const fetchedData = await axios.post('backoffice/user/promocodes/delete', {
          promocodeId
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.tableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async setMessageForAllPromocodes() {
      try {
        if (this.messageForAllPromocodes === '') return;
        const isConfirm = confirm('Вы уверены что хотите изменить сообщение на ВСЕХ промокодах?');
        if (!isConfirm) return;

        const fetchedData = await axios.post('backoffice/user/promocodes/setMessageAll', {
          message: this.messageForAllPromocodes
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.tableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async setAmountForAllPromocodes() {
      try {
        if (this.amountForAllPromocodes === '') return;
        const isConfirm = confirm('Вы уверены что хотите изменить сумму на ВСЕХ промокодах?');
        if (!isConfirm) return;

        const fetchedData = await axios.post('backoffice/user/promocodes/setAmountAll', {
          amount: this.amountForAllPromocodes
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.tableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  },
}
</script>

<style lang="scss" scoped>

td:not(.no-animate) {
  transition: .1s;
  cursor: text;

  &:hover {
    background: #2B3035;
  }
}

span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}

</style>