<i18n>
{
  "en": {
    "title": "Invite friends",
    "posttitle": "Invite friends to trade on {0} through referral code or link",
    "referralCode": "Referral Code",
    "referralLink": "Referral Link",
    "copy": "Copy to clipboard",
    "statistics": {
      "title": "Statistics",
      "earnedusd": "Earned in USD",
      "earnedbtc": "Earned in BTC",
      "currentpercent": "Current earn percent",
      "friends": "Friends invited"
    },
    "referralsTable": {
      "title": "Your referrals",
      "placeholder": "Search by user...",
      "columns": {
        "user": "User",
        "date": "Date"
      }
    },
    "earningsTable": {
      "title": "Your earnings",
      "placeholder": "Search by currency, user...",
      "columns": {
        "currency": "Currency",
        "amount": "Amount",
        "user": "User",
        "date": "Date"
      }
    },
    "copied": "Copied",
    "activate": "Activate referral or promo code",
    "referralCodeLabel": "Code",
    "completed": "Completed"
  },
  "zh": {
    "title": "邀請好友",
    "posttitle": "通過推薦碼或鏈接邀請好友註冊{0}",
    "referralCode": "推薦代碼",
    "referralLink": "推薦鏈接",
    "copy": "複製到剪貼板",
    "statistics": {
      "title": "統計數據",
      "earnedusd": "美元收入",
      "earnedbtc": "獲得的 BTC",
      "currentpercent": "當前收入百分比",
      "friends": "邀請的朋友"
    },
    "referralsTable": {
      "title": "您的推薦人",
      "placeholder": "按用戶搜索..",
      "columns": {
        "user": "用戶",
        "date": "日期"
      }
    },
    "earningsTable": {
      "title": "你的收入",
      "placeholder": "按貨幣搜索，用戶...",
      "columns": {
        "currency": "貨幣",
        "amount": "金額",
        "user": "用戶",
        "date": "日期"
      }
    },
    "copied": "已复制",
    "activate": "激活推荐码",
    "referralCodeLabel": "推荐代码",
    "completed": "Completed"
  },
  "kr": {
    "title": "친구 초대",
    "posttitle": "추천 코드 또는 링크를 통해 {0}에 등록하도록 친구를 초대하려면",
    "referralCode": "추천 코드",
    "referralLink": "추천 링크",
    "copy": "클립보드에 복사",
    "statistics": {
      "title": "통계",
      "earnedusd": "미국 달러로 적립",
      "earnedbtc": "BTC로 적립됨",
      "currentpercent": "현재 수익 퍼센트",
      "friends": "초대된 친구의 수"
    },
    "referralsTable": {
      "title": "내 추천",
      "placeholder": "사용자로 검색...",
      "columns": {
        "user": "사용자",
        "date": "날짜"
      }
    },
    "earningsTable": {
      "title": "수입",
      "placeholder": "통화별 검색, 사용자...",
      "columns": {
        "currency": "통화",
        "amount": "금액",
        "user": "사용자",
        "date": "날짜"
      }
    },
    "copied": "복사됨",
    "activate": "추천 코드 활성화",
    "referralCodeLabel": "추천 코드",
    "completed": "Completed"
  }
}
</i18n>

<template>
  <Page :is-page-loaded="dataLoadingStatus">
    <PageContainer :no-line="true">
      <div class="container" style="margin-bottom: 40px;">

        <div style="margin-bottom: 40px;">
          <h1 style="margin: 0;">{{ $t('title') }}</h1>
          <span class="little-desc">{{ $t('posttitle', [$store.state.Index.websiteName.short]) }}</span>
        </div>



        <div style="margin-bottom: 20px;" class="col-lg-12" v-if="canUseCode">

          <div class="wrapper" style="display: block; margin-bottom: 20px;">
            <h2>{{ $t('activate') }}</h2>
            <InputBox
                v-for="control, index in formControls"
                :key="index"
                :type="control.type"
                :label="$t(control.label)"
                v-model="control.value"
                :validation="control.validation"
                @check-valid="control.isValid = $event"
                :style="{ background: $store.state.Index.isDarkMode ? 'transparent' : 'inherit' }"
            />
            <Button
                :callback="activateReferralCode"
                style="width: 100%;"
                :is-disabled="!$isFormValid(formControls)"
            >
              Redeem code
            </Button>
          </div>

        </div>

        <div class="col-lg-6 col-xs-12 col-md-6">
          <div class="wrapper" style="display: block; ">
            <span class="title">{{ $t('referralCode') }}</span><br>
            <span class="value" style="font-size: 20px;">{{ $store.state.Index.userInfo.referralSystem.referralCode }}</span>
            <div>
              <button class="copy-btn" @click="copyText($store.state.Index.userInfo.referralSystem.referralCode)">{{ $t('copy') }}</button>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-lg-6 col-xs-12 col-md-6">
          <div class="wrapper" style="display: block; word-wrap: break-word;">
            <span class="title">{{ $t('referralLink') }}</span><br>
            <span class="value" style="font-size: 20px;">{{ $store.state.Index.websiteName.full }}/invite/{{ $store.state.Index.userInfo.referralSystem.referralCode }}</span>
            <div>
              <button class="copy-btn" @click="copyText('https://'+$store.state.Index.websiteName.full+'/invite/'+$store.state.Index.userInfo.referralSystem.referralCode)">{{ $t('copy') }}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="container" style="margin-bottom: 40px;">
        <div class="section col-lg-12 col-md-12 col-xs-12">
          <div class="head">
            <h3 class="title">{{ $t('statistics.title') }}</h3>
          </div>

          <div class="stats" style="margin-bottom: 20px;">
            <div class="items">
              <h2>{{ new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'symbol'
              }).format(totalEarnings.usd) }}</h2>
              <span>{{ $t('statistics.earnedusd') }}</span>
            </div>
            <div class="items">
              <h2>{{ totalEarnings.btc.toFixed(6) }} BTC</h2>
              <span>{{ $t('statistics.earnedbtc') }}</span>
            </div>
            <div class="items">
              <h2>{{ $store.state.Index.userInfo.referralSystem.earnFee }}%</h2>
              <span>{{ $t('statistics.currentpercent') }}</span>
            </div>
            <div class="items">
              <h2>{{ totalUsers }}</h2>
              <span>{{ $t('statistics.friends') }}</span>
            </div>
          </div>

        </div>
      </div>

      <div class="container" style="margin-bottom: 20px;">
        <h2>{{ $t('referralsTable.title') }}</h2>
        <OnlineTable
            parse-url="/main/user/referrals"
            :column-list="referralTableColumns"
            default-sort="date"
            :search-placeholder-text="$t('referralsTable.placeholder')"
            @handle-data="referralTableData = $event"
        >
          <tr v-for="data, index in referralTableData" :key="index">
            <td>{{ hideUserEmail(data.email) }}</td>
            <td style="min-width: 200px;">{{ data.settings.registrationDate | toDate }}</td>
          </tr>
        </OnlineTable>
      </div>

      <div class="container">
        <h2>{{ $t('earningsTable.title') }}</h2>
        <OnlineTable
            parse-url="/main/user/earnings"
            :column-list="earningsTableColumns"
            default-sort="date"
            :search-placeholder-text="$t('earningsTable.placeholder')"
            @handle-data="earningsTableData = $event"
        >
          <tr v-for="data, index in earningsTableData" :key="index">
            <td>
              <div style="display: flex; align-items: center; width: 60px;">
                <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                  <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(data.currency)" alt="">
                </div>
                {{ data.currency }}
              </div>
            </td>
            <td>{{ data.amountCurrency.toFixed(6) }}</td>
            <td>{{ data.userEmail }}</td>
            <td style="min-width: 200px;">{{ data.date | toDate }}</td>
          </tr>
        </OnlineTable>
      </div>



      <modal
          name="promocode-complete"
          height="auto"
          width="384px"
          style="background: rgba(0, 0, 0, 0.5);"
          :clickToClose="false"
          :adaptive="true"
      >
        <div class="trade-complete">
          <div v-html="modal">
            <div>
              <img width="150px" src="/done.svg" alt="">
            </div>
            <div style="margin-top: 18px; font-weight: 500; color: rgb(30, 35, 41); font-size: 20px; line-height: 24px;">
              Completed
            </div>
            <div>
              <div style="margin: 32px 0 20px 0; color: rgb(72, 81, 93); font-size: 14px; line-height: 20px; font-weight: 800;">{{ promocodeStatus.message }}</div>
            </div>
          </div>

          <div style="display: flex; justify-content: space-between; margin-top: 20px;">
            <Button
                custom-width="100%"
                style="background: rgb(234, 236, 239); color: rgb(30, 35, 41);"
                :is-async="false"
                :callback="reloadPage"
            >
              {{ $t('close') }}
            </Button>
          </div>
        </div>
      </modal>

    </PageContainer>
  </Page>
</template>

<script>
import PageContainer from "@/components/main/UI/PageContainer";
import Page from "@/components/main/UI/Page";
import axios from "@/plugins/axios";
import OnlineTable from "@/components/main/UI/OnlineTable";
import InputBox from "@/components/main/UI/InputBox";
import Button from "@/components/main/UI/Button";

export default {
  name: "Referral",
  components: {
    PageContainer,
    Page,
    OnlineTable,
    InputBox,
    Button
  },
  data() {
    return {
      promocodeStatus: {
        message: ''
      },
      formControls: {
        referralCodeInput: {
          value: '',
          type: 'text',
          label: 'referralCodeLabel',
          isValid: false,
          validation: {
            required: true
          }
        }
      },
      referralTableData: [],
      referralTableColumns: [
        {
          name: 'email',
          title: this.$t('referralsTable.columns.user'),
          isSortable: true
        },
        {
          name: 'date',
          title: this.$t('referralsTable.columns.date'),
          isSortable: true
        }
      ],
      modal: "",
      earningsTableData: [],
      earningsTableColumns: [
        {
          name: 'currency',
          title: this.$t('earningsTable.columns.currency'),
          isSortable: true
        },
        {
          name: 'amountCurrency',
          title: this.$t('earningsTable.columns.amount'),
          isSortable: true
        },
        {
          name: 'userEmail',
          title: this.$t('earningsTable.columns.user'),
          isSortable: true
        },
        {
          name: 'date',
          title: this.$t('earningsTable.columns.date'),
          isSortable: true
        }
      ],
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      totalEarnings: { btc: 0, usd: 0 },
      totalUsers: 0,
      canUseCode: false
    }
  },
  methods: {
    reloadPage() {
      location.href = 'wallets';
    },
    async loadPage() {
      try {
        let fetchedData = await axios.get('main/user/referral');
        if (fetchedData.data.error) {
          this.dataLoadingStatus.isDone = true;
          this.dataLoadingStatus.isError = true;
          return;
        }
        this.modal = fetchedData.data.modal;
        this.totalUsers = fetchedData.data.totalUsers;
        this.totalEarnings = fetchedData.data.totalEarnings;
        this.canUseCode = fetchedData.data.canUseCode;
        this.dataLoadingStatus.isDone = true;
      } catch (e) {
        console.log(e)
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
      }
    },
    async activateReferralCode() {
      try {
        const fetchedData = await axios.post('/main/user/activateReferral', {
          code: this.formControls.referralCodeInput.value
        });
        if (fetchedData.data.error) {
          this.$callNotification(
              this.$i18n.locale,
              fetchedData
          );
        }
        if (!fetchedData.data.error) {
          this.promocodeStatus.message = fetchedData.data.description;
          this.modal = this.modal.replace(/%promocode_text%/gi, this.promocodeStatus.message);
          this.$modal.show('promocode-complete');
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.$callNotification(this.$i18n.locale, { type: 'info', message: this.$t('copied') })
    },
    hideUserEmail(email) {
      return email.slice(0, 3) + "***" + email.slice(email.indexOf("@"), email.length);
    }
  },
  async mounted() {
    await this.loadPage();
  }
}
</script>

<style lang="scss" scoped>

.dark {
  .little-desc {
    color: rgba(197, 193, 189, 0.6);
  }
  .copy-btn {
    color: rgb(197, 193, 189);
    text-decoration-color: initial;
    background-color: rgb(40, 42, 43);
    border-color: initial;
  }
  .stats .items h2 {
    color: rgb(197, 193, 189);
  }

  .trade-complete {
    box-shadow: rgba(24, 25, 30, 0.1) 0px 0px 1px, rgba(60, 64, 67, 0.04) 0px 3px 6px, rgba(24, 25, 30, 0.04) 0px 1px 2px;
    background-color: rgb(28, 30, 31);
    color: #c4c0bb;

    div { color: #c4c0bb !important; }

  }
}

.little-desc {
  margin-top: 8px;
  color: rgba(10,15,56,.6);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.17647rem;
  letter-spacing: .01em;
}

.copy-btn {
  padding: 14px 20px;
  color: #0a0f38;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  text-decoration: none;
  background-color: #e6e8eb;
  border: none;
  border-radius: 6px;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out;
  transition: .25s ease-out;

  &:hover {
    background-color: #d9dce6;
  }
}

@media (max-width: 992px) {

  .wrapper:last-child {
    margin-top: 20px;
  }

  .stats {
    display: block !important;

    .items {
      flex-direction: row-reverse;
      padding: 12px 0;
      border-right: none !important;
      display: flex;
      justify-content: space-between;
      margin-right: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.stats {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  .items {
    border-right: 1px solid #e8eaf0;
    padding-right: 24px;
    margin-right: 24px;

    h2 {
      color: #0a0f38;
      font-weight: 700;
      margin: 0;
      font-size: 22px;
    }

    span {
      color: rgba(10,15,56,.6);
      font-size: 12px;
      font-weight: 600;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.trade-complete {
  width: 384px;
  padding: 26px 36px 40px;
  box-sizing: border-box;
  min-width: 0px;
  margin: auto;
  display: flex;
  border-radius: 4px;
  box-shadow: rgba(24, 26, 32, 0.1) 0px 0px 1px, rgba(71, 77, 87, 0.04) 0px 3px 6px, rgba(24, 26, 32, 0.04) 0px 1px 2px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-direction: column;
}

.quote-infos {
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .value {
    font-size: 12px;
  }
}

</style>