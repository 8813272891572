<template>

  <div>
    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Стейкинг планы</h4>
          </div>
          <div class="p-0 row m-0">
            <SimpleTable
                :data-length="domainInfo.stakingPlans.length"
                :columns="['Монета', 'Мин. сумма', 'APY %', '']"
                class="col-lg-12 mb-3"
            >
              <tr v-for="plan, index in domainInfo.stakingPlans" :key="'plan' + index">
                <td>
                  <div class="form-control mb-0">
                    <select v-model="domainInfo.stakingPlans[index].currency">
                      <option :value="coin.code" v-for="coin, coinIndex in coinList" :key="'coin for plan' + index + coinIndex">{{ coin.code }}</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-control mb-0">
                    <input v-model="domainInfo.stakingPlans[index].minimalAmount">
                  </div>
                </td>
                <td>
                  <div class="form-control mb-0">
                    <input v-model="domainInfo.stakingPlans[index].farmRate">
                  </div>
                </td>
                <td>
                  <div @click="domainInfo.stakingPlans.splice(index, 1)" style="z-index: 99;">
                    <i class="fal fa-trash-alt"></i>
                  </div>
                </td>
              </tr>
            </SimpleTable>

            <div class="col-lg-12 mt-3 mb-2 d-flex">
              <label @click="domainInfo.stakingPlans.push({ currency: 'BTC', minimalAmount: 0.01, farmRate: 30 })" class="m-auto">+ добавить план</label>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mt-5 mb-4">
              <Button
                  class="w-100 primary"
                  :callback="updateStaking"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Button from "@/components/backoffice/UI/Button";
import axios from "@/plugins/axios";
import SimpleTable from "@/components/backoffice/UI/SimpleTable";

export default {
  name: "DomainStaking",
  props: {
    domainInfo: Object,
    coinList: Array
  },
  components: {
    Button,
    SimpleTable
  },
  methods: {
    async updateStaking() {
      try {
        const fetchedData = await axios.post('backoffice/admin/domain/staking', {
          domainId: this.$route.params.domainId,
          stakingPlans: this.domainInfo.stakingPlans
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style scoped>

</style>