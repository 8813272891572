<template>
  <router-view></router-view>
</template>

<script>
export default {
name: "wallets.vue"
}
</script>

<style scoped>

</style>