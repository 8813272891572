<template>
  <div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Пресеты сообщений</h4>
          </div>
          <div class="p-4 row m-0">
            <div class="mb-2">Сообщения</div>
            <label class="text-muted mb-4" style="display: block; font-size: 12px;">Чтобы поменять пресеты местами зажмите пресет и перенесите на нужное место.</label>
            <draggable v-model="information.settings.notificationPresets">
              <transition-group>
                <div class="terms" v-for="preset, index in information.settings.notificationPresets" :key="' presets ' + index">

                  <div class="d-flex align-items-center mb-2">
                    <div @click="updatePresetName(index)" class="mr-3">
                      <font-awesome-icon style="font-size: 13px; cursor: pointer;" :icon="['fas', 'pen']" />
                    </div>
                    <div @click="$event.target.parentElement.parentElement.classList.toggle('open')" class="section d-flex w-100">
                      {{ preset.title }}
                      <div @click="information.settings.notificationPresets.splice(index, 1)" style="margin-left: auto; z-index: 99;">
                        <i class="fal fa-trash-alt"></i>
                      </div>
                    </div>
                  </div>

                  <div class="content p-3">
                    <div class="form-control">
                      <textarea rows="4" v-model="information.settings.notificationPresets[index].message"></textarea>
                    </div>
                  </div>

                </div>
              </transition-group>
            </draggable>

            <div class="mt-3 mb-2 d-flex">
              <label @click="newPreset" style="margin: auto;">+ добавить пресет</label>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
              <Button
                  class="w-100 primary"
                  :callback="updatePresets"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Отправить уведомления юзерам</h4>
          </div>
          <div class="p-4 row m-0">
            <p class="text-muted">В целях оптимизации, за один раз возможно отправить уведомления не более чем 1000 юзерам, подбирайте фильтры чтобы отправлять только полезные уведомления!</p>
            <h4 class="p-0 m-0 mb-4">Критерии отбора юзера</h4>
            <div class="form-controls mb-3">
              <div class="col-lg-12 mb-3 d-flex align-items-center">
                <label class="mr-3">Дата регистрации</label>
                <SwitchButton
                    :is-checked="userRegisterDate.isActive"
                    @switch-change="userRegisterDate.isActive = !userRegisterDate.isActive"
                />
              </div>
              <div class="pr-4 pl-4 mb-3">
                <span @click="changeRegisterForms(afk)" class="color-warning mr-3" style="cursor: pointer;" v-for="afk, index in afkTimesList.slice(2)" :key="'afk' + index">
                  {{ afk.title }}
                </span>
              </div>
              <InputBox
                  label="От даты регистрации"
                  type="date"
                  class="col-lg-6"
                  v-model="userRegisterDate.minValue"
              />
              <InputBox
                  label="До даты регистрации"
                  type="date"
                  class="col-lg-6"
                  v-model="userRegisterDate.maxValue"
              />
            </div>
            <div class="form-controls mb-3">
              <div class="col-lg-12 mb-3 d-flex align-items-center">
                <label class="mr-3">Последнее время активности</label>
                <SwitchButton
                    :is-checked="userLastActiveDate.isActive"
                    @switch-change="userLastActiveDate.isActive = !userLastActiveDate.isActive"
                />
              </div>
              <div class="pr-4 pl-4 mb-3">
                <span @click="changeLastActiveForms(afk)" class="color-warning mr-3" style="cursor: pointer;" v-for="afk, index in afkTimesList" :key="'afk' + index">
                  {{ afk.title }}
                </span>
              </div>
              <InputBox
                  label="От минут назад"
                  type="number"
                  class="col-lg-6"
                  placeholder="Введите минимальное значение в минутах"
                  v-model="userLastActiveDate.minValue"
              />
              <InputBox
                  label="До минут назад"
                  type="number"
                  class="col-lg-6"
                  placeholder="Введите максимальное значение в минутах"
                  v-model="userLastActiveDate.maxValue"
              />
            </div>
            <div class="form-controls mb-3">
              <div class="col-lg-12 mb-3 d-flex align-items-center">
                <label class="mr-3">Внесено долларов</label>
                <SwitchButton
                    :is-checked="userDeposited.isActive"
                    @switch-change="userDeposited.isActive = !userDeposited.isActive"
                />
              </div>
              <InputBox
                  label="От $"
                  type="number"
                  class="col-lg-6"
                  placeholder="Введите минимальное значение в долларах"
                  v-model="userDeposited.minValue"
              />
              <InputBox
                  label="До $"
                  type="number"
                  placeholder="Введите максимальное значение в долларах"
                  class="col-lg-6"
                  v-model="userDeposited.maxValue"
              />
            </div>
            <div class="form-controls mb-3">
              <div class="col-lg-12 mb-3 d-flex align-items-center">
                <label class="mr-3">Использовал определенный промокод</label>
                <SwitchButton
                    :is-checked="promocode.isActive"
                    @switch-change="promocode.isActive = !promocode.isActive"
                />
              </div>
              <InputBox
                  label="Промокод"
                  type="text"
                  placeholder="Введите промокод"
                  class="col-lg-6"
                  v-model="promocode.text"
              />
            </div>

            <div class="form-controls mb-3">
              <div class="col-lg-12 mb-3 d-flex align-items-center">
                <label class="mr-3">Только на определенном домене</label>
                <SwitchButton
                    :is-checked="domain.isActive"
                    @switch-change="domain.isActive = !domain.isActive"
                />
              </div>
              <InputBox
                  label="Домен"
                  type="text"
                  placeholder="Введите домен"
                  class="col-lg-6"
                  v-model="domain.text"
              />
            </div>

            <h4 class="col-lg-12 p-0 m-0 mb-4 mt-5">Уведомление</h4>
            <div class="col-lg-12 p-0">
              <InputBox
                  label="Заголовок"
                  type="text"
                  class="col-lg-4"
                  :value="newNotificationInfo.title"
                  @input="newNotificationInfo.title = $event"
              />
              <div class="form-control col-lg-12">
                <label>Текст</label>
                <textarea v-model="newNotificationInfo.text" style="resize: vertical;" rows="4"></textarea>
              </div>
            </div>
            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mt-4 mb-4">
              <Button
                  :is-async="true"
                  :callback="createNotificationHandler"
                  :is-disabled="newNotificationInfo.title.trim() === '' || newNotificationInfo.text.trim() === ''"
                  class="primary w-100"
              >
                Отправить
              </Button>
            </div>

            <div class="form-control">
              <select style="font-size: 12px;" class="mt-2" v-model="selectedPreset" @change="selectOnChange($event)">
                <option value="choosePreset" disabled selected>Выберите пресет</option>
                <option v-for="preset, index in information.settings.notificationPresets" :value="preset" :key="'send presets ' + index">{{ preset.title }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import draggable from "vuedraggable";

export default {
  name: "Domains",
  props: {
    information: Object
  },
  components: {
    draggable,
    InputBox,
    Button,
    SwitchButton
  },
  data() {
    return {
      selectedPreset: 'choosePreset',
      newNotificationInfo: {
        title: 'New Notification',
        text: '',
      },
      afkTimesList: [
        {
          title: 'Час',
          minValue: 60,
          maxValue: 70
        },
        {
          title: '12 часов',
          minValue: 60 * 12,
          maxValue: 60 * 13
        },
        {
          title: 'День',
          minValue: 60 * 24,
          maxValue: 60 * 25
        },
        {
          title: '2 дня',
          minValue: 60 * 24 * 2,
          maxValue: 60 * 25 * 2
        },
        {
          title: 'Неделя',
          minValue: 60 * 24 * 7,
          maxValue: 60 * 24 * 8
        },
        {
          title: 'Месяц',
          minValue: 60 * 24 * 30,
          maxValue: 60 * 24 * 31
        }
      ],
      userRegisterDate: {
        isActive: false,
        minValue: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7).toISOString().substring(0,10),
        maxValue: new Date().toISOString().substring(0,10),
      },
      userLastActiveDate: {
        isActive: false,
        minValue: 10,
        maxValue: 120,
      },
      userDeposited: {
        isActive: false,
        minValue: 0,
        maxValue: 100,
      },
      promocode: {
        isActive: false,
        text: null
      },
      domain: {
        isActive: false,
        text: null
      }
    }
  },
  methods: {
    selectOnChange() {
      this.newNotificationInfo.title = this.selectedPreset.title;
      this.newNotificationInfo.text = this.selectedPreset.message;
    },
    updatePresetName(index) {
      const newName = prompt("Введите новое название пресета:", this.information.settings.notificationPresets[index].title);
      if (!newName) return;

      this.information.settings.notificationPresets[index].title = newName;
    },
    newPreset() {
      try {
        const title = prompt("Заголовок пресета:");
        this.information.settings.notificationPresets.push({ title, message: 'Example' });
        console.log(this.information.settings.notificationPresets)
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updatePresets() {
      try {
        const fetchedData = await axios.post('backoffice/user/spammer/notificationPresets', {
          presets: this.information.settings.notificationPresets
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    changeRegisterForms(afk) {
      this.userRegisterDate.minValue = new Date(Date.now() - 1000 * 60 * afk.maxValue).toISOString().substring(0,10);
      this.userRegisterDate.maxValue = new Date(Date.now() - 1000 * 60 * afk.minValue).toISOString().substring(0,10);
    },
    changeLastActiveForms(afk) {
      this.userLastActiveDate.minValue = afk.minValue;
      this.userLastActiveDate.maxValue = afk.maxValue;
    },
    async createNotificationHandler() {
      try {
        const fetchedData = await axios.post('backoffice/user/massiveNotification', {
          notificationInfo: {
            userRegisterDate: this.userRegisterDate,
            userLastActiveDate: this.userLastActiveDate,
            newNotificationInfo: this.newNotificationInfo,
            userDeposited: this.userDeposited,
            promocode: this.promocode,
            domain: this.domain,
          }
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}

.terms .section {
  padding: 5px 25px;
  border-bottom: 1px solid #32383E;
  cursor: pointer;
  transition: .3s;
  font-size: 13px;
  border-radius: 5px;
}


.terms .section:hover {
  background: #343B41;
}

.terms .content {
  display: none;
}

.terms.open .content {
  display: block;
  transition: .3s visibility;
}

.terms .content textarea {
  resize: none;
}

</style>