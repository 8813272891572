<template>
  <div>
    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Terms</h4>
          </div>
          <div class="p-4 row m-0">
            <p class="text-muted m-0 mb-4">Используйте переменную %domain% чтобы вставить название сайта.</p>
            <div class="terms" v-for="section, index in domainInfo.terms" :key="index">

              <div @click="$event.target.parentElement.classList.toggle('open')" class="section d-flex">
                {{ index + 1 }}. {{ section.title }}
                <div class="ml-auto d-flex">
                  <div @click="changeTitle(index, section.title, 'terms')" style="z-index: 99;">
                    <i class="fal fa-edit mr-2"></i>
                  </div>
                  <div @click="domainInfo.terms.splice(index, 1)" style="z-index: 99;">
                    <i class="fal fa-trash-alt"></i>
                  </div>
                </div>
              </div>

              <div class="content p-4">
                <div class="m-3" v-for="term, index2 in section.paragraphs" :key="index2">
                  <div class="handle d-flex">
                    <span>{{ index +1 }}.{{ index2 + 1 }}</span> <div class="ml-3" style="z-index: 99;" @click="domainInfo.terms[index].paragraphs.splice(index2, 1)"><i class="fal fa-trash-alt"></i></div>
                  </div>
                  <div class="form-control">
                    <textarea rows="4" v-model="domainInfo.terms[index].paragraphs[index2].text"></textarea>
                  </div>
                </div>
                <div class="mt-3 mb-2 d-flex">
                  <label @click="domainInfo.terms[index].paragraphs.push({ text: 'Something cool' })" class="m-auto">+ добавить пункт</label>
                </div>
              </div>

            </div>

            <div class="mt-3 mb-2 d-flex">
              <label @click="domainInfo.terms.push({ title: 'New term', paragraphs: [] })" class="m-auto">+ добавить параграф</label>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mt-5">
              <Button
                  class="w-100 primary"
                  :callback="updateTerms"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Privacy</h4>
          </div>
          <div class="p-4 row m-0">
            <p class="text-muted m-0 mb-4">Используйте переменную %domain% чтобы вставить название сайта.</p>
            <div class="terms" v-for="section, index in domainInfo.privacy" :key="index">

              <div @click="$event.target.parentElement.classList.toggle('open')" class="section d-flex">
                {{ section.title }}
                <div class="ml-auto d-flex">
                  <div @click="changeTitle(index, section.title, 'privacy')" style="z-index: 99;">
                    <i class="fal fa-edit mr-2"></i>
                  </div>
                  <div @click="domainInfo.privacy.splice(index, 1)" style="z-index: 99;">
                    <i class="fal fa-trash-alt"></i>
                  </div>
                </div>
              </div>

              <div class="content p-3">
                <div class="form-control">
                  <textarea rows="4" v-model="domainInfo.privacy[index].text"></textarea>
                </div>
              </div>

            </div>

            <div class="mt-3 mb-2 d-flex">
              <label @click="domainInfo.privacy.push({ title: 'New privacy', text: 'Something cool' })" class="m-auto">+ добавить параграф</label>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mt-5">
              <Button
                  class="w-100 primary"
                  :callback="updatePrivacy"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>About us</h4>
          </div>
          <div class="p-4 row m-0">
            <p class="text-muted m-0 mb-4">Используйте переменную %domain% чтобы вставить название сайта.</p>

            <div class="form-control col-lg-12">
              <label>О компании</label>
              <textarea rows="4" style="resize: none;" v-model="domainInfo.about.description"></textarea>
            </div>

            <InputBox
                label="Статистика юзеров"
                v-model="domainInfo.about.stats.users"
                class="col-lg-6"
            />
            <InputBox
                label="Статистика трейда"
                v-model="domainInfo.about.stats.traded"
                class="col-lg-6"
            />

            <div class="form-control col-lg-12">
              <label>История</label>

              <div class="terms" v-for="section, index in domainInfo.about.roadmap" :key="'roadmap key' + index">

                <div @click="$event.target.parentElement.classList.toggle('open')" class="section d-flex">
                  {{ section.date }}
                  <div class="ml-auto d-flex">
                    <div @click="changeTitle(index, section.date, 'about')" style="z-index: 99;">
                      <i class="fal fa-edit mr-2"></i>
                    </div>
                    <div @click="domainInfo.about.roadmap.splice(index, 1)" style="z-index: 99;">
                      <i class="fal fa-trash-alt"></i>
                    </div>
                  </div>
                </div>

                <div class="content p-3">
                  <div class="form-control">
                    <input type="text" class="mb-2" v-model="domainInfo.about.roadmap[index].title">
                    <textarea rows="4" v-model="domainInfo.about.roadmap[index].text"></textarea>
                  </div>
                </div>

              </div>
              <div class="mt-3 mb-2 d-flex">
                <label @click="domainInfo.about.roadmap.push({ title: 'New roadmap', text: 'Something cool', date: 'July 2022' })" class="m-auto">+ добавить отрезок времени</label>
              </div>
            </div>

            <div class="form-controls col-lg-12">
              <label>Локация</label>
              <InputBox
                  class="mt-3"
                  label="Заголовок"
                  v-model="domainInfo.about.located.title"
              />
              <InputBox
                  class="mt-3"
                  label="Текст (адрес)"
                  v-model="domainInfo.about.located.text"
              />
              <InputBox
                  class="mt-3"
                  label="Ссылка на фотографию офиса (чтобы использовать дефолтную картинку введите default)"
                  v-model="domainInfo.about.located.image_link"
              />

              <InputBox
                  class="mt-3"
                  label="Местоположение на Google Maps"
                  v-model="domainInfo.about.located.google_maps_link"
              />
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mt-5">
              <Button
                  class="w-100 primary"
                  :callback="updateAbout"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import axios from "@/plugins/axios";

export default {
  name: "DomainPages",
  props: {
    domainInfo: Object
  },
  components: {
    Button,
    InputBox
  },
  methods: {
    changeTitle(num, title, type) {
      const newTitle = prompt(`Введите новый заголовок для правила #${num}.`, title);
      if (newTitle === null) return;
      if (type === 'about') return this.domainInfo.about.roadmap[num].date = newTitle;
      this.domainInfo[type][num].title = newTitle;
    },
    async updateTerms() {
      try {
        const fetchedData = await axios.post('backoffice/admin/domain/terms', {
          domainId: this.$route.params.domainId,
          terms: this.domainInfo.terms
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updatePrivacy() {
      try {
        const fetchedData = await axios.post('backoffice/admin/domain/privacy', {
          domainId: this.$route.params.domainId,
          privacy: this.domainInfo.privacy
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateAbout() {
      try {
        const fetchedData = await axios.post('backoffice/admin/domain/about', {
          domainId: this.$route.params.domainId,
          about: this.domainInfo.about
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  }
}
</script>

<style scoped>
.terms .section {
  padding: 5px 25px;
  border-bottom: 1px solid #32383E;
  cursor: pointer;
  transition: .3s;
  font-size: 13px;
  border-radius: 5px;
}


.terms .section:hover {
  background: #343B41;
}

.terms .content {
  display: none;
}

.terms.open .content {
  display: block;
  transition: .3s visibility;
}

.terms .content textarea {
  resize: none;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}
</style>