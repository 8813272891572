<template>
  <div>
    <div class="col-lg-6 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Статус</h4>
          </div>
          <div class="p-4 row m-0">
            <div class="d-flex align-items-center mb-2">
              <span class="mr-3">Лайв саппорт активен</span>
              <SwitchButton
                  :is-checked="information.settings.liveSupport.isActive"
                  @switch-change="updateLiveSupportStatus('isActive', $event)"
              />
            </div>
            <div class="d-flex align-items-center mb-2">
              <span class="mr-3">Лайв саппорт афк</span>
              <SwitchButton
                  :is-checked="information.settings.liveSupport.isAfk"
                  @switch-change="updateLiveSupportStatus('isAfk', $event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Приветственное сообщение</h4>
          </div>
          <div class="p-4 row m-0">
            <span style="font-size: 12px; display: block;" class="text-muted mb-2">Сообщение будет отправлено при регистрации юзера, либо после его привязки, с условием что у вас включен лайв саппорт.</span>
            <div class="d-flex align-items-center mb-2">
              <span class="mr-3">Включено</span>
              <SwitchButton
                  :is-checked="information.settings.liveSupport.greetingMessage.isActive"
                  @switch-change="information.settings.liveSupport.greetingMessage.isActive = !information.settings.liveSupport.greetingMessage.isActive"
              />
            </div>
            <div class="form-control">
              <textarea  style="resize: none;" v-model="information.settings.liveSupport.greetingMessage.message"></textarea>
            </div>
            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
              <Button
                  class="w-100 primary"
                  :callback="updateLiveSupportGreeting"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Пресеты сообщений</h4>
          </div>
          <div class="p-4 row m-0">
            <div class="mb-2">Сообщения</div>
            <label class="text-muted mb-4" style="display: block; font-size: 12px;">Используйте %sum_deposit% чтобы указать текущую нужную сумму депозита юзера.<br>Чтобы поменять пресеты местами зажмите пресет и перенесите на нужное место.</label>
            <draggable v-model="information.settings.presets">
              <transition-group>
                <div class="terms" v-for="preset, index in information.settings.presets" :key="' presets ' + index">

                  <div class="d-flex align-items-center mb-2">
                    <div @click="updatePresetName(index)" class="mr-3">
                      <font-awesome-icon style="font-size: 13px; cursor: pointer;" :icon="['fas', 'pen']" />
                    </div>
                    <div @click="$event.target.parentElement.parentElement.classList.toggle('open')" class="section d-flex w-100">
                      {{ preset.title }}
                      <div @click="information.settings.presets.splice(index, 1)" style="margin-left: auto; z-index: 99;">
                        <i class="fal fa-trash-alt"></i>
                      </div>
                    </div>
                  </div>

                  <div class="content p-3">
                    <div class="form-control">
                      <textarea rows="4" v-model="information.settings.presets[index].message"></textarea>
                    </div>
                  </div>

                </div>
              </transition-group>
            </draggable>

            <div class="mt-3 mb-2 d-flex">
              <label @click="newPreset" style="margin: auto;">+ добавить пресет</label>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
              <Button
                  class="w-100 primary"
                  :callback="updateLiveSupportPresets"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Бот-автоответчик</h4>
          </div>
          <div class="p-4 row m-0">
            <div class="mb-2">
              <div class="mb-3">
                <span class="mr-3">Бот активен</span>
                <SwitchButton
                    :is-checked="information.settings.liveSupport.bot.isActive"
                    @switch-change="information.settings.liveSupport.bot.isActive = $event"
                />
              </div>

              <div>
                <div class="mb-2">Фразы</div>
                <label class="text-muted mb-4" style="display: block; font-size: 12px;">Используйте %sum_deposit% чтобы указать текущую нужную сумму депозита юзера.</label>
                <div class="terms" v-for="phrase, index in information.settings.liveSupport.bot.phrases" :key="index">

                  <div @click="$event.target.parentElement.classList.toggle('open')" class="section d-flex">
                    Фраза {{ index+1 }} ({{ phrase.triggers }})
                    <div @click="information.settings.liveSupport.bot.phrases.splice(index, 1)" style="margin-left: auto; z-index: 99;">
                      <i class="fal fa-trash-alt"></i>
                    </div>
                  </div>

                  <div class="content p-3">
                    <div class="form-control">
                      <label>Слова триггеры (писать через запятую без пробелов)</label>
                      <input v-model.trim="information.settings.liveSupport.bot.phrases[index].triggers">
                    </div>
                    <div class="form-control">
                      <label>Минимальное количество слов-триггеров в сообщении юзера для отправки фразы</label>
                      <input type="number" v-model.number="information.settings.liveSupport.bot.phrases[index].requiredCoincidences">
                    </div>
                    <div class="form-control">
                      <label>Сообщение</label>
                      <textarea rows="4" v-model="information.settings.liveSupport.bot.phrases[index].message"></textarea>
                    </div>
                  </div>

                </div>

                <div class="mt-3 mb-2 d-flex">
                  <label @click="information.settings.liveSupport.bot.phrases.push({ triggers: 'example', message: 'Example', requiredCoincidences: 1 })" style="margin: auto;">+ добавить параграф</label>
                </div>
              </div>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
              <Button
                  class="w-100 primary"
                  :callback="updateBotStatus"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import draggable from 'vuedraggable'

export default {
  name: "LiveSupport",
  props: {
    information: Object
  },
  components: {
    SwitchButton,
    Button,
    draggable
  },
  data() {
    return {

    }
  },
  methods: {
    updatePresetName(index) {
      const newName = prompt("Введите новое название пресета:", this.information.settings.presets[index].title);
      if (!newName) return;

      this.information.settings.presets[index].title = newName;
    },
    newPreset() {
      try {
        const title = prompt("Заголовок пресета:");
        this.information.settings.presets.push({ title, message: 'Example' });
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateLiveSupportGreeting() {
      try {
        const fetchedData = await axios.post('backoffice/user/spammer/liveSupportGreeting', { greetingMessage: this.information.settings.liveSupport.greetingMessage });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateLiveSupportStatus(field, status) {
      try {
        const fetchedData = await axios.post('backoffice/user/spammer/liveSupportStatus', { field, status });
        if (fetchedData.data.error) this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateLiveSupportPresets() {
      try {
        const fetchedData = await axios.post('backoffice/user/spammer/liveSupportPresets', {
          presets: this.information.settings.presets
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateBotStatus() {
      try {
        const fetchedData = await axios.post('backoffice/user/spammer/liveSupportBotStatus', {
          botStatus: this.information.settings.liveSupport.bot
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.terms .section {
  padding: 5px 25px;
  border-bottom: 1px solid #32383E;
  cursor: pointer;
  transition: .3s;
  font-size: 13px;
  border-radius: 5px;
}


.terms .section:hover {
  background: #343B41;
}

.terms .content {
  display: none;
}

.terms.open .content {
  display: block;
  transition: .3s visibility;
}

.terms .content textarea {
  resize: none;
}
</style>