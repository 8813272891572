<i18n>
{
  "en": {

  },
  "zh": {
    "searchPlaceholder": "자산 검색.."
  },
  "kr": {
    "searchPlaceholder": "搜索资产.."
  }
}
</i18n>

<template>
  <div style="position: relative;">

    <div>

      <div :class="{ empty: this.dataLoaded.length === 0, dark: $store.state.Index.isDarkMode  }" class="table_div">

        <table class="table" v-if="this.dataLoaded.length > 0">

          <thead>

          <tr>
            <th :style="column.style" :class="[column.classes, column.isSortable ? 'animated' : '' ]" v-for="column, index in columnList" :key="index">
              {{ column.title }}
            </th>
          </tr>

          </thead>

          <tbody>

          <slot></slot>

          </tbody>

        </table>

        <div class="empty" v-else>No data</div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: {
    dataLoaded: Array,
    parseUrl: String,
    columnList: Array,
    noSearch: Boolean,
    defaultSort: String,
    searchPlaceholderText: String
  },
  data() {
    return {
      tableStatus: {
        isLoading: true,
        isError: false
      },
      searchQuery: ''
    }
  }
}
</script>

<style lang="scss" scoped>

.table_div.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.table_div.dark {
  thead {
    border-bottom-color: rgb(54, 59, 61);
  }
  th {
    color: rgba(197, 193, 189, 0.6);
  }
  td {
    border-bottom-color: rgb(54, 59, 61);
  }
}

table {

  width: 100%;
  font-weight: 400;

  thead {
    border-bottom: 1px solid #e8eaf0;

    th {
      padding: 16px 20px;
      color: rgba(10,15,56,.6);
      font-size: 14px;
      vertical-align: middle;
      text-align: left;
    }

  }

  tbody {

    td {
      padding: 16px 20px;
      border-bottom: 1px solid #e8eaf0;
    }

  }

}

td i {
  transition: .3s;
  cursor: pointer;
}

td i.fa-wallet:hover {
  color: #eed48b;
}

td i.fa-user-edit:hover {
  color: #6aa7e9;
}

td i.fa-list-alt:hover {
  color: #67d8eb;
}

td i.fa-user-slash:hover {
  color: #f16572;
}


.arrow {
  float: right;
  transition: .3s;
}

.arrow.up {
  transform: rotate(180deg);
}

.clear_search.fa-times {
  position: absolute;
  right: 5px;
  transition: .3s;
  cursor: pointer;
}

.clear_search.fa-times:hover {
  color: #f16572;
}

.toggle_filters {
  color: #f16572;
  font-size: 12px;
  cursor: pointer;
}

.toggle_filters i {
  position: relative;
  top: 1px;
}

.warn_twins {
  font-size: 12px;
}

.currentPage a {
  background: #2F6EA5;
  color: white;
}

.page-link {
  background: #182533;
  border-color: #2F3947;
  cursor: pointer;
}

.page-item.disabled .page-link {
  background: #182533;
  border-color: #2F3947;
}

</style>
