<template>
  <div style="text-align: center" class="pb-4" v-if="!loadingStatus.isDone || loadingStatus.isError">
    <span v-show="!loadingStatus.isDone && !loadingStatus.isError">Загрузка данных...</span>
    <span v-show="loadingStatus.isError">Ошибка при загрузке данных</span>
  </div>
  <div class="row m-0" v-else>
    <div class="col-lg-12 mb-3 form-control">
      <div class="d-flex align-items-center mb-1">
        <span class="mr-3">Юзер верифицирован</span>
        <SwitchButton
            :is-checked="verificationInfo.isVerified"
            @switch-change="verificationInfo.isVerified = $event"
        />
      </div>
      <div class="d-flex align-items-center mb-1">
        <span class="mr-3">Данные на рассмотрении</span>
        <SwitchButton
            :is-checked="verificationInfo.isConsidering"
            @switch-change="verificationInfo.isConsidering = $event"
        />
      </div>
      <div class="d-flex align-items-center mb-1">
        <span class="mr-3">Документы загружены (выключить, чтобы юзер загрузил документы заного)</span>
        <SwitchButton
            style="min-width: 28px;"
            :is-checked="verificationInfo.isDocumentLoaded"
            @switch-change="verificationInfo.isDocumentLoaded = $event"
        />
      </div>
    </div>
    <InputBox
        label="Имя"
        type="text"
        class="col-lg-6"
        :value="verificationInfo.passport.firstName"
        @input="verificationInfo.passport.firstName = $event"
    />
    <InputBox
        label="Фамилия"
        type="text"
        class="col-lg-6"
        :value="verificationInfo.passport.lastName"
        @input="verificationInfo.passport.lastName = $event"
    />
    <InputBox
        label="Страна"
        type="text"
        class="col-lg-6"
        :value="verificationInfo.passport.country"
        @input="verificationInfo.passport.country = $event"
    />
    <InputBox
        label="Дата рождения"
        type="date"
        class="col-lg-6"
        :value="verificationInfo.passport.birthDate"
        @input="verificationInfo.passport.birthDate = $event"
    />
    <div class="form-control col-lg-12 mt-4 mb-5">
      <div class="d-flex align-items-center mb-1">
        <span class="mr-3">Вкладка депозит верифицирован</span>
        <SwitchButton
            :is-checked="verificationInfo.isDepositVerified"
            @switch-change="verificationInfo.isDepositVerified = $event"
        />
      </div>
      <label>Сообщение на вкладке депозит</label>
      <textarea v-model="verificationText" style="resize: none;" rows="4"></textarea>
    </div>
    <div class="col-lg-4 form-control" v-if="isAskingForDocuments">
      <label>Загруженный документ</label>
      <div class="text-muted" style="font-size: 12px;" v-if="!userDocument">Документ не загружен</div>
      <div v-else>
        <img :src="userDocument" width="200px" alt=""><br>
        <Button
            :is-async="true"
            :callback="deleteVerificationImage"
            class="danger mt-3 w-100"
        >
          Удалить фотографию
        </Button>
      </div>
    </div>
    <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-5">
      <Button
          :is-async="true"
          :callback="verificationUpdateHandler"
          class="primary w-100"
      >
        Обновить
      </Button>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";

export default {
  name: "UserVerification",
  props: {
    userId: String,
    verificationMessage: String
  },
  components: {
    InputBox,
    Button,
    SwitchButton
  },
  data() {
    return {
      verificationInfo: {},
      verificationText: this.verificationMessage,
      userDocument: null,
      isAskingForDocuments: false,
      loadingStatus: { isDone: false, isError: false }
    }
  },
  methods: {
    async deleteVerificationImage() {
      try {
        const fetchedData = await axios.post('/backoffice/user/user/deleteVerification', {
          userId: this.userId
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.loadUserVerification();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async verificationUpdateHandler() {
      try {
        const fetchedData = await axios.post('/backoffice/user/user/updateVerification', {
          userId: this.userId,
          verificationInfo: this.verificationInfo,
          verificationMessage: this.verificationText
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async loadUserVerification() {
      try {
        this.loadingStatus.isDone = false;
        const fetchedData = await axios.post('backoffice/user/user/verification', { userId: this.userId });
        if (fetchedData.data.error) return this.loadingStatus.isError = true;
        this.verificationInfo = fetchedData.data.verificationInfo;
        this.isAskingForDocuments = fetchedData.data.isAskingForDocuments;
        this.userDocument = fetchedData.data.userDocument;
        this.loadingStatus.isDone = true;
      } catch (e) {
        console.log(e)
        this.loadingStatus.isError = true;
      }
    }
  },
  mounted() {
    this.loadUserVerification();
  }
}
</script>

<style scoped>

</style>