<template>
  <trading-vue
      :data="chart"
      :width="width"
      :titleTxt="title"
      :height="700"
  ></trading-vue>
</template>
<script>

import { TradingVue, DataCube } from 'trading-vue-js'

export default {
  components: {TradingVue},
  props: {
    candles: Array,
    title: String,
    width: Number || String
  },
  data() {
    return {
      chart: new DataCube({
        chart: {
          type: 'Candles',
          data: this.candles,
          settings: {}
        },
        onchart: [],
        offchart: []
      }),
    }
  },
}
</script>