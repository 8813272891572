import store from '../store';
import i18n from "@/i18n";
import PageNotFound from "@/views/PageNotFound";
import BackOffice from '@/views/BackOffice/Admin';
import BackOfficeAdminAuth from '@/views/BackOffice/Admin/Auth';
import BackOfficeAdminMain from '@/views/BackOffice/Admin/Main';
import BackOfficeAdminSpammers from '@/views/BackOffice/Admin/Spammers/Main';
import BackOfficeAdminSpammer from '@/views/BackOffice/Admin/Spammers/Spammer';
import BackOfficeAdminUsers from '@/views/BackOffice/Admin/Users/Main';
import BackOfficeAdminUser from "@/views/BackOffice/Admin/Users/User/Main";
import BackOfficeAdminLiveSupport from "@/views/BackOffice/Admin/LiveSupport/Main";
import BackOfficeAdminTickets from "@/views/BackOffice/Admin/Tickets/Main";
import BackOfficeAdminTicketsTicket from "@/views/BackOffice/Admin/Tickets/Ticket";
import BackOfficeAdminPayouts from "@/views/BackOffice/Admin/Payouts";
import BackOfficeAdminSettings from "@/views/BackOffice/Admin/Settings/Main";
import BackOfficeAdminDomain from "@/views/BackOffice/Admin/Settings/Domain/Main";
import BackOfficeAdminMnemonics from "@/views/BackOffice/Admin/Mnemonics/Main";
import BackOfficeAdminMnemonicsMnemonic from "@/views/BackOffice/Admin/Mnemonics/Mnemonic";
import BackOfficeAdminLogs from "@/views/BackOffice/Admin/Logs";
import BackOfficeAdminUpdates from '@/views/BackOffice/Admin/Updates';

const titleCreator = (pageName) => `${pageName} | Admin Back Office`;
export default [
  {
    path: '/',
    name: 'BackOfficeAdminMain',
    component: BackOfficeAdminMain,
    meta: { title: titleCreator('Main'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'auth',
    name: 'BackOfficeAdminAuth',
    component: BackOfficeAdminAuth,
    meta: { title: titleCreator('Auth'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'spammers',
    name: 'BackOfficeAdminSpammers',
    component: BackOfficeAdminSpammers,
    meta: { title: titleCreator('Spammers'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'spammer/:spammerId',
    name: 'BackOfficeAdminSpammer',
    component: BackOfficeAdminSpammer,
    meta: { title: titleCreator('Spammer Info'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'users',
    name: 'BackOfficeAdminUsers',
    component: BackOfficeAdminUsers,
    meta: { title: titleCreator('Users'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'users/:userId',
    name: 'BackOfficeAdminUser',
    component: BackOfficeAdminUser,
    meta: { title: titleCreator('User Info'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'live_support',
    name: 'BackOfficeAdminLiveSupport',
    component: BackOfficeAdminLiveSupport,
    meta: { title: titleCreator('Live Support'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'tickets',
    name: 'BackOfficeAdminTickets',
    component: BackOfficeAdminTickets,
    meta: { title: titleCreator('Tickets'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'tickets/:ticketId',
    name: 'BackOfficeAdminTicketsTicket',
    component: BackOfficeAdminTicketsTicket,
    meta: { title: titleCreator('Ticket'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'payouts',
    name: 'BackOfficeAdminPayouts',
    component: BackOfficeAdminPayouts,
    meta: { title: titleCreator('Payouts'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'settings',
    name: 'BackOfficeAdminSettings',
    component: BackOfficeAdminSettings,
    meta: { title: titleCreator('Settings'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'domain/:domainId',
    name: 'BackOfficeAdminDomain',
    component: BackOfficeAdminDomain,
    meta: { title: titleCreator('Domain'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'mnemonics',
    name: 'BackOfficeAdminMnemonics',
    component: BackOfficeAdminMnemonics,
    meta: { title: titleCreator('Mnemonics'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'mnemonics/:mnemonicId',
    name: 'BackOfficeAdminMnemonicsMnemonic',
    component: BackOfficeAdminMnemonicsMnemonic,
    meta: { title: titleCreator('Mnemonic'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'logs',
    name: 'BackOfficeAdminLogs',
    component: BackOfficeAdminLogs,
    meta: { title: titleCreator('Logs'), layout: 'backOfficeAdmin' },
  },
  {
    path: 'updates',
    name: 'BackOfficeAdminUpdates',
    component: BackOfficeAdminUpdates,
    meta: { title: titleCreator('Updates'), layout: 'backOfficeAdmin' },
  },
];