<template>
  <div>
    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Отправка почты</h4>
          </div>
          <div class="row m-0">

            <p class="pl-4 pr-4 text-muted mb-3">Если функция включена, то юзеру на почту после регистрации придет письмо с конфирмом регистрации, так же можно будет восстановить пароль через почту.</p>

            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Использование и отправка писем на почту юзеров</label>
              <SwitchButton
                  :is-checked="domainInfo.mailSettings.isActive"
                  @switch-change="domainInfo.mailSettings.isActive = !domainInfo.mailSettings.isActive"
              />
            </div>

            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Обязательное подтверждение почты (если выключено, то на аккаунт можно будет зайти, а подтвердить почту позже)</label>
              <SwitchButton
                  :is-checked="domainInfo.mailSettings.accountActivationIsRequired"
                  @switch-change="domainInfo.mailSettings.accountActivationIsRequired = !domainInfo.mailSettings.accountActivationIsRequired"
              />
            </div>

            <InputBox
                label="Сервер"
                type="text"
                placeholder="Введите значение"
                class="col-lg-6"
                v-model="domainInfo.mailSettings.server"
            />
            <InputBox
                label="Порт"
                type="number"
                placeholder="Введите значение"
                class="col-lg-6"
                v-model="domainInfo.mailSettings.port"
            />
            <InputBox
                label="Логин"
                type="text"
                placeholder="Введите значение"
                class="col-lg-6"
                v-model="domainInfo.mailSettings.user"
            />
            <InputBox
                label="Пароль"
                type="password"
                placeholder="Введите значение"
                class="col-lg-6"
                v-model="domainInfo.mailSettings.password"
            />

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-4">

              <Button
                  class="w-100 primary"
                  :callback="updateMailSettings"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Добавить DNS запись</h4>
          </div>
          <div class="p-0 row m-0">
            <div class="row m-0 mb-3 pt-4">
              <p class="text-muted pl-4 pr-4 mt-0 ">Вводите все данные точно по шаблону, иначе DNS запись не добавится.</p>
              <div class="form-control col-lg-4">
                <label>Тип записи</label>
                <select @change="dnsRecordTypeChange($event)">
                  <option :value="recordType" v-for="recordType, index in Object.keys(dnsRecordsList)" :key="'dns record type' + index">{{ recordType }}</option>
                </select>
              </div>
              <InputBox
                  v-for="input, index in dnsRecordsList[newDnsRecord.type]"
                  :key="'input record' + index"
                  :label="input.caption"
                  :placeholder="input.placeholder"
                  v-model="newDnsRecord.data[index]"
                  class="col-lg-4"
              />
              <div class="col-lg-12">
                <Button
                    class="w-100 primary"
                    :callback="addDnsRecord"
                >
                  Добавить
                </Button>
              </div>
            </div>
            <div class="pl-4 pr-4">
              <h3 class="mb-0" style="font-weight: 500;">Записи домена</h3>
            </div>
            <SimpleTable
                :data-length="domainInfo.dnsRecords.length"
                :columns="['Тип записи', 'Дата', '']"
                class="col-lg-12 mb-3"
                :class="{'p-5': domainInfo.dnsRecords.length === 0}"
            >
              <tr v-for="record, index in domainInfo.dnsRecords" :key="'record' + index">
                <td>{{ record.dnsType }}</td>
                <td>{{ record.date | toDate }}</td>
                <td class="no-animate" style="text-align: center;">
                <span @click="deleteDnsRecord(record._id)" class="delete-btn">
                  <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
                </span>
                </td>
              </tr>
            </SimpleTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import axios from "@/plugins/axios";
import SimpleTable from "@/components/backoffice/UI/SimpleTable";

export default {
  name: "DomainDNS",
  props: {
    domainInfo: Object
  },
  components: {
    Button,
    InputBox,
    SwitchButton,
    SimpleTable
  },
  data() {
    return {
      isRecordDeleting: false,
      newDnsRecord: {
        type: 'TXT',
        data: {
          name: '',
          content: ''
        }
      },
      dnsRecordsList: {
        TXT: {
          name: {
            caption: 'Name',
            placeholder: 'example.com',
            value: '',
          },
          content: {
            caption: 'Content',
            placeholder: 'Текст',
            value: '',
          }
        },
        MX: {
          name: {
            caption: 'Name',
            placeholder: 'example.com',
            value: '',
          },
          content: {
            caption: 'Mail server',
            placeholder: 'example.com',
            value: '',
          },
          priority: {
            caption: 'Priority (0 - 65535)',
            placeholder: '0 - 65535 (только число)',
            value: '',
          },
        },
        SRV: {
          name: {
            caption: 'Name',
            placeholder: 'example.com',
            value: '',
          },
          port: {
            caption: 'Port (0 - 65535)',
            placeholder: '0 - 65535 (только число)',
            value: '',
          },
          priority: {
            caption: 'Priority (0 - 65535)',
            placeholder: '0 - 65535 (только число)',
            value: '',
          },
          proto: {
            caption: 'Protocol',
            placeholder: '_tcp или _udp или _tls',
            value: '_tcp',
          },
          service: {
            caption: 'Service',
            placeholder: '_example, обязательно наличие _ в начале',
            value: '',
          },
          target: {
            caption: 'Target',
            placeholder: 'example.com',
            value: '',
          },
          weight: {
            caption: 'Weight (0 - 65535)',
            placeholder: '0 - 65535 (только число)',
            value: '',
          }
        }
      }
    }
  },
  methods: {
    async updateMailSettings() {
      try {
        const fetchedData = await axios.post('backoffice/user/domain/mail', {
          domainId: this.$route.params.domainId,
          mailSettings: this.domainInfo.mailSettings
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async deleteDnsRecord(recordId) {
      try {
        if (this.isRecordDeleting) return;
        this.$callNotification(this.$i18n.locale, {
          type: 'info',
          message: 'Запись удаляется, пожалуйста подождите'
        });
        this.isRecordDeleting = true;
        const fetchedData = await axios.post('backoffice/user/domain/deleteDns', {
          domainId: this.$route.params.domainId,
          dnsId: recordId
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.domainInfo = fetchedData.data.domainInfo;
        this.isRecordDeleting = false;
      } catch (error) {
        this.isRecordDeleting = false;
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async addDnsRecord() {
      try {
        const fetchedData = await axios.post('backoffice/user/domain/dns', {
          domainId: this.$route.params.domainId,
          dnsInfo: this.newDnsRecord
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.domainInfo = fetchedData.data.domainInfo;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    dnsRecordTypeChange(data) {
      const type = data.target.value;
      for (const field of Object.keys(this.dnsRecordsList[type])) {
        this.newDnsRecord.data[field] = this.dnsRecordsList[type][field].value;
      }
      this.newDnsRecord.type = type;
    },
  }
}
</script>

<style scoped>

</style>