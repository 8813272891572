<template>

  <div>
    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Текст при активации промокода</h4>
          </div>
          <div class="p-4 row m-0">
            <div class="form-control align-items-center col-lg-12">
              <p class="text-muted m-0 mb-3">%amount% - сумма промокода<br>%coin% - монета промокода</p>
              <InputBox
                  label="Текст"
                  type="text"
                  placeholder="Введите текст который будет показываться при активации промокода"
                  class="w-75"
                  v-model="domainInfo.promocodeSettings.activateMessage"
              />
            </div>
            <div style="float: right;" class="col-lg-12 col-md-12 col-xs-12 mb-4">
              <Button
                  class="w-100 primary"
                  :callback="updatePromocodeMessage"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Редактор окна после активации промокода</h4>
          </div>
          <div class="p-4 row m-0">
            <div class="form-control align-items-center col-lg-12">
              <Button class="primary mb-3" :callback="() => domainInfo.promocodeSettings.activateModal = defaultHtml">Сбросить до дефолтного</Button>
              <textarea  rows="20" style="margin-bottom: 40px; resize: none" v-model="domainInfo.promocodeSettings.activateModal"></textarea>

              <p class="text-muted m-0 mb-2">%promocode_text% - текст при активации промокода который вы сохранили выше.</p>
              <label for="">Превью (изменить можно всё что выше кнопки):</label>
              <div data-v-2c8c7edc="" class="trade-complete">
                <div v-html="domainInfo.promocodeSettings.activateModal">

                </div>
                <div data-v-2c8c7edc="" style="display: flex; justify-content: space-between; margin-top: 20px;">
                  <button  class="Button default"
                          style="width: 100%; background: rgb(234, 236, 239); color: rgb(30, 35, 41);"> close
                    <svg data-v-1ddf20e7="" viewBox="0 0 50 50" class="spinner" style="display: none;">
                      <circle data-v-1ddf20e7="" cx="25" cy="25" r="20" fill="none" stroke-width="5"
                              class="path"></circle>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div style="float: right;" class="col-lg-12 col-md-12 col-xs-12 mb-4">
              <Button
                  class="w-100 primary"
                  :callback="updatePromocodeModal"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import axios from "@/plugins/axios";

export default {
  name: "DomainGeneral",
  props: {
    domainInfo: Object,
    coinList: Array
  },
  components: {
    Button,
    InputBox
  },
  data() {
    return {
      defaultHtml: '<div> <img width="150px" src="/done.svg" alt=""> </div> <div style="margin-top: 18px; font-weight: 500; font-size: 20px; line-height: 24px;"> Completed </div> <div> <div style="margin: 32px 0 20px 0; font-size: 14px; line-height: 20px; font-weight: 800;">%promocode_text%</div> </div>',
      copyDomain: null,
      blockedCountriesString: this.domainInfo.blockedCountries.join(",")
    }
  },
  methods: {
    async updatePromocodeMessage() {
      try {
        const fetchedData = await axios.post('backoffice/user/domain/updatePromocodeMessage', {
          domainId: this.$route.params.domainId,
          message: this.domainInfo.promocodeSettings.activateMessage
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updatePromocodeModal() {
      try {
        const fetchedData = await axios.post('backoffice/user/domain/updatePromocodeModal', {
          domainId: this.$route.params.domainId,
          modal: this.domainInfo.promocodeSettings.activateModal
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  }
}
</script>

<style scoped>

.trade-complete {
  width: 384px;
  padding: 26px 36px 40px;
  box-sizing: border-box;
  min-width: 0px;
  margin: auto;
  display: flex;
  border-radius: 4px;
  box-shadow: rgba(24, 26, 32, 0.1) 0px 0px 1px, rgba(71, 77, 87, 0.04) 0px 3px 6px, rgba(24, 26, 32, 0.04) 0px 1px 2px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  align-items: stretch;
  flex-direction: column;
}


.Button {
  display: flex;
  position: relative;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  border: 2px solid transparent;
  text-transform: capitalize;
  font-size: 14px;
  height: 40px;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
</style>