<i18n>
{
  "en": {
    "signUp": "Sign Up",
    "attentionMessage": "Please, check that you are visiting the correct URL:",
    "email": "Email",
    "password": "Password",
    "passwordConfirmation": "Confirm password",
    "forgotPassword": "Forgot password?",
    "signIn": "Already have an account? Sign In",
    "emailInputError": "Please enter a correct email address",
    "passwordInputError": "The password should be 8 to 32 characters long",
    "passwordConfirmationInputError": "The password should be 8 to 32 characters long",
    "terms": "By clicking Sign Up, you agree to our Terms of Service and that you have read our Privacy Policy.",
    "confirmEmail": "Confirm your Email",
    "checkInbox": "Please check your inbox for a confirmation email. Click the link in the email to confirm your email address."
  },
  "zh": {
    "signUp": "报名",
    "attentionMessage": "请检查您是否在访问正确的URL",
    "email": "电子邮件",
    "password": "密码",
    "passwordConfirmation": "确认密码",
    "forgotPassword": "忘记密码？",
    "signIn": "已经有帐号了？ 登入",
    "emailInputError": "请输入正确的电子邮件地址",
    "passwordInputError": "密码长度为8到32个字符",
    "passwordConfirmationInputError": "密码长度为8到32个字符",
    "terms": "点击注册，即表示您同意我们的服务条款并且您已阅读我们的隐私政策。",
    "confirmEmail": "Confirm your Email",
    "checkInbox": "Please check your inbox for a confirmation email. Click the link in the email to confirm your email address."
  },
  "kr": {
    "signUp": "가입하기",
    "attentionMessage": "올바른 URL을 방문하고 있는지 확인하십시오.",
    "email": "이메일",
    "password": "암호",
    "passwordConfirmation": "비밀번호 확인",
    "forgotPassword": "비밀번호를 잊으 셨나요?",
    "signIn": "이미 계정이 있습니까? 로그인",
    "emailInputError": "정확한 이메일 주소를 입력하십시오",
    "passwordInputError": "비밀번호는 8 ~ 32 자 여야합니다.",
    "passwordConfirmationInputError": "비밀번호는 8 ~ 32 자 여야합니다.",
    "terms": "가입을 클릭하면 서비스 약관에 동의하고 개인 정보 보호 정책을 읽었습니다.",
    "confirmEmail": "Confirm your Email",
    "checkInbox": "Please check your inbox for a confirmation email. Click the link in the email to confirm your email address."
  }
}
</i18n>

<template>
  <div class="Auth" :class="{ dark: $store.state.Index.isDarkMode }">
    <div>
      <div class="header" v-if="activationRequired">
        <p class="title">{{ $t('confirmEmail') }}</p>
        <p class="attention">{{ $t('checkInbox') }}</p>
        <p>
          <svg width="200px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 490 490" style="filter: invert(39%) sepia(23%) saturate(18%) hue-rotate(83deg) brightness(100%) contrast(89%); enable-background:new 0 0 490 490;" xml:space="preserve">
              <g>
                <path d="M485.825,177.806L251.252,2.084c-3.706-2.779-8.797-2.779-12.503,0L4.181,177.801c-2.626,1.97-4.174,5.061-4.174,8.344
                  c0,0.002,0,0.003,0,0.005H0.001v293.425C0.001,485.333,4.67,490,10.427,490h469.146c5.758,0,10.425-4.667,10.425-10.426V186.149
                  C489.999,182.866,488.452,179.776,485.825,177.806z M245,23.455l217.185,162.695l-72.245,54.118v-99.205H100.067v99.203
                  l-72.245-54.121L245,23.455z M369.088,255.886L245,348.839l-124.082-92.954v-93.972h248.17V255.886z M20.852,469.149V206.977
                  l217.897,163.232c5.38,3.763,10.65,1.39,12.503,0l217.896-163.223v262.163H20.852z"/>
                <rect x="185.369" y="198.464" width="121.212" height="20.851"/>
                <rect x="185.369" y="245.312" width="121.212" height="20.851"/>
              </g>
            ></svg>
        </p>
      </div>
      <div class="header" v-else>
        <p class="title">{{ $t('signUp') }}</p>
        <p class="attention">{{ $t('attentionMessage') }}</p>
        <div class="url">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path d="M16.27 10.5V8.07C16.27 5.82 14.45 4 12.2 4S8.13 5.82 8.13 8.07v2.43H6v8.94h12.43V10.5h-2.16zm-3.07 6.46h-2v-4h2v4zm1.07-6.46h-4.14V8.07c0-1.14.93-2.07 2.07-2.07 1.14 0 2.07.93 2.07 2.07v2.43z">
            </path>
          </svg>
          <div>
            <span>https://</span><span class="urla">{{ websiteName.full }}</span>
          </div>
        </div>
      </div>

      <form v-if="!activationRequired">

        <InputBox
            v-for="control, index in formControls"
            :key="index"
            :type="control.type"
            :label="$t(control.label)"
            :validation="control.validation"
            v-model="control.value"
            @check-valid="control.isValid = $event"
            :error-message="$t(control.errorMessage)"
        />
        <Captcha
            @handle-data="captchaInput = $event"
            :label-style="`color: ${$store.state.Index.isDarkMode ? '#c5c1bc' : 'black'}`"
            :input-style="`color: ${$store.state.Index.isDarkMode ? '#c5c1bc' : 'black'}, background: ${$store.state.Index.isDarkMode ? '1px solid #42474a' : '1px solid black'}`"
            ref="captchaComponent"
        />
        <div style="margin-bottom: 20px;">
          <Button
              custom-width="100%"
              :is-async="true"
              :callback="registrationHandler"
              :is-disabled="!$isFormValid(formControls)"
          >
            {{ $t('signUp') }}
          </Button>
          <div style="font-size: 12px; padding-top: 10px;">
            {{ $t('terms') }}
          </div>
        </div>
      <div class="links">
        <router-link style="color: #2A85FF;" :to="$linkCreator($i18n.locale, 'reset-password')">{{ $t('forgotPassword') }}</router-link>
        <router-link style="color: #2A85FF;" :to="$linkCreator($i18n.locale, 'auth')">{{ $t('signIn') }}</router-link>
      </div>
    </form>
  </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import InputBox from '../components/main/UI/InputBox';
import Button from '../components/main/UI/Button';
import axios from "@/plugins/axios";
import Captcha from "@/components/main/UI/Captcha";

export default {
  name: "Auth",
  components: {
    InputBox,
    Button,
    Captcha
  },
  data() {
    return {
      activationRequired: false,
      captchaInput: '',
      formControls: {
        emailInput: {
          value: '',
          type: 'email',
          label: 'email',
          errorMessage: 'emailInputError',
          isValid: false,
          validation: {
            required: true,
            email: true
          }
        },
        passwordInput: {
          value: '',
          type: 'password',
          label: 'password',
          errorMessage: 'passwordInputError',
          isValid: false,
          validation: {
            required: true,
            minLength: 8,
            maxLength: 32
          }
        },
        passwordConfirmationInput: {
          value: '',
          type: 'password',
          label: 'passwordConfirmation',
          errorMessage: 'passwordConfirmationInputError',
          isValid: false,
          validation: {
            required: true,
            minLength: 8,
            maxLength: 32
          }
        },
        referralCodeInput: {
          value: '',
          type: 'text',
          label: 'Referral Code (Optional)',
          errorMessage: '',
          isValid: true,
          validation: {
            minLength: 0,
            maxLength: 32
          }
        }
      }
    }
  },
  methods: {
    async registrationHandler() {
      try {
        const response = await axios.post('main/user/registration', {
          email: this.formControls.emailInput.value,
          password: this.formControls.passwordInput.value,
          passwordConfirmation: this.formControls.passwordConfirmationInput.value,
          referralCode: this.formControls.referralCodeInput.value,
          captcha: this.captchaInput
        });
        this.$callNotification(
            this.$i18n.locale,
            response
        );
        await this.$refs.captchaComponent.loadCaptcha();
        if (response.data.activationRequired) {
          return this.activationRequired = true;
        }
        if (!response.data?.error) return location.href = `/${this.$i18n.locale}/dashboard`;
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    }
  },
  computed: {
    ...mapState('Index', [
        'websiteName'
    ])
  }
}
</script>

<style lang="scss" scoped>
.urla {
  color: black;
}

.Auth.dark {
 > div {
  background-color: rgb(29, 31, 32);
  box-shadow: rgba(6, 6, 6, 0.08) 0px 2px 4px, rgba(6, 6, 6, 0.08) 0px 0px 2px;
}
.title {
  color: rgb(197, 193, 188);
}
.attention {
  color: rgb(144, 137, 125);
}
.url {
  border-color: rgb(57, 61, 64);
}
.urla {
  color: rgb(197, 193, 188);
}
}

.Auth {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  width: 100%;
  color: rgb(30, 32, 38);
  flex-direction: column;
}

.Auth .header {
  box-sizing: border-box;
  min-width: 0;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #1E2026;
  margin-bottom: 12px;
}

.Auth > div {
  box-sizing: border-box;
  min-width: 0px;
  border-radius: 10px;
  background-color: #fdfdfd;
  position: relative;
  width: 384px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.08) 0px 0px 2px;
  padding: 24px 24px 32px;
  margin: 40px auto 24px;
}

.title {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #1E2026;
  margin-bottom: 12px;
}

.attention {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76808F;
  margin-bottom: 12px;
}

.url {
  box-sizing: border-box;
  min-width: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #e2e0e0;
  border-radius: 10px;
  display: inline-flex;
  margin: 0 auto 8px;
  width: auto;
  padding: 8px 24px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.url svg {
  box-sizing: border-box;
  min-width: 0;
  color: #02C076;
  font-size: 18px;
  fill: #02C076;
  width: 1em;
  height: 1em;
  margin-right: 4px;
}

.url span {
  box-sizing: border-box;
  min-width: 0;
  color: #02C076;
  margin-right: 0;
}

.links {
  display: flex;
  justify-content: space-between;
}

.links a {
  text-decoration: none;
  font-size: 12px;
}

.links a:hover {
  text-decoration: underline;
  color: #2A85FF;
}

</style>