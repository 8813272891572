<template>
  <div>

  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "ReferralLink",
  async mounted() {
    try {
      const fetchedData = await axios.post('/main/exchange/referral', { code: this.$route.params.referralLink });
      this.$router.push(this.$linkCreator(this.$i18n.locale, fetchedData.data?.logged ? 'dashboard' : 'sign-up'));
    } catch (e) {
      this.$router.push(this.$linkCreator(this.$i18n.locale, 'dashboard'));
    }
  }
}
</script>

<style scoped>

</style>