<i18n>
{
  "en": {
    "resetPassword": "Reset Password",
    "email": "Email",
    "signIn": "Sign In",
    "createAccount": "Create a free account",
    "emailInputError": "Please enter a correct email address",
    "passwordInputError": "Please enter a correct password",
    "confirmEmail": "Check your Email",
    "checkInbox": "Please check your inbox for email with information about password reset."
  },
  "zh": {
    "resetPassword": "重设密码",
    "email": "电子邮件",
    "signIn": "登录",
    "createAccount": "创建一个免费账户",
    "emailInputError": "请输入正确的电子邮件地址",
    "passwordInputError": "请输入正确的密码",
    "confirmEmail": "Check your Email",
    "checkInbox": "Please check your inbox for email with information about password reset."
  },
  "kr": {
    "resetPassword": "암호를 재설정",
    "email": "이메일",
    "signIn": "로그인",
    "createAccount": "무료 계정을 생성하십시오",
    "emailInputError": "정확한 이메일 주소를 입력하십시오",
    "passwordInputError": "정확한 비밀번호를 입력하세요",
    "confirmEmail": "Check your Email",
    "checkInbox": "Please check your inbox for email with information about password reset."
  }
}
</i18n>

<template>
  <div class="Auth"  :class="{ dark: $store.state.Index.isDarkMode }">
    <div>
      <div class="header">
        <div v-if="activationRequired">
          <p class="title">{{ $t('confirmEmail') }}</p>
          <p class="attention">{{ $t('checkInbox') }}</p>
          <p>
            <svg width="200px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 490 490" style="filter: invert(39%) sepia(23%) saturate(18%) hue-rotate(83deg) brightness(100%) contrast(89%); enable-background:new 0 0 490 490;" xml:space="preserve">
              <g>
                <path d="M485.825,177.806L251.252,2.084c-3.706-2.779-8.797-2.779-12.503,0L4.181,177.801c-2.626,1.97-4.174,5.061-4.174,8.344
                  c0,0.002,0,0.003,0,0.005H0.001v293.425C0.001,485.333,4.67,490,10.427,490h469.146c5.758,0,10.425-4.667,10.425-10.426V186.149
                  C489.999,182.866,488.452,179.776,485.825,177.806z M245,23.455l217.185,162.695l-72.245,54.118v-99.205H100.067v99.203
                  l-72.245-54.121L245,23.455z M369.088,255.886L245,348.839l-124.082-92.954v-93.972h248.17V255.886z M20.852,469.149V206.977
                  l217.897,163.232c5.38,3.763,10.65,1.39,12.503,0l217.896-163.223v262.163H20.852z"/>
                <rect x="185.369" y="198.464" width="121.212" height="20.851"/>
                <rect x="185.369" y="245.312" width="121.212" height="20.851"/>
              </g>
              ></svg>
          </p>
        </div>
        <p class="title" v-else>{{ $t('resetPassword') }}</p>
      </div>

      <form v-if="!activationRequired">

        <InputBox
            v-for="control, index in formControls"
            :key="index"
            :type="control.type"
            :label="$t(control.label)"
            :validation="control.validation"
            v-model="control.value"
            @check-valid="control.isValid = $event"
            :error-message="$t(control.errorMessage)"
        />
        <Captcha
            @handle-data="captchaInput = $event"
            ref="captchaComponent"
        />
        <div style="margin-bottom: 20px;">
          <Button
              custom-width="100%"
              :is-async="true"
              :callback="resetHandler"
              :is-disabled="!$isFormValid(formControls)"
          >
            {{ $t('resetPassword') }}
          </Button>
        </div>
      <div class="links">
        <router-link style="color: #2A85FF;" :to="$linkCreator($i18n.locale, 'auth')">{{ $t('signIn') }}</router-link>
        <router-link style="color: #2A85FF;" :to="$linkCreator($i18n.locale, 'sign-up')">{{ $t('createAccount') }}</router-link>
      </div>
    </form>
  </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import InputBox from '../components/main/UI/InputBox';
import Button from '../components/main/UI/Button';
import TwoFactorModal from "@/mixins/two-factor-modal";
import axios from "@/plugins/axios";
import Captcha from "@/components/main/UI/Captcha";

export default {
  name: "Auth",
  components: {
    InputBox,
    Button,
    Captcha
  },
  mixins: [TwoFactorModal],
  data() {
    return {
      activationRequired: false,
      isTwoFactorRequired: false,
      captchaInput: '',
      formControls: {
        emailInput: {
          value: '',
          type: 'email',
          label: 'email',
          errorMessage: 'emailInputError',
          isValid: false,
          validation: {
            required: true,
            email: true
          }
        },
      }
    }
  },
  methods: {
    async resetHandler() {
      try {
        const response = await axios.post('main/user/resetPassword', {
          email: this.formControls.emailInput.value,
          captcha: this.captchaInput
        });
        this.$callNotification(
            this.$i18n.locale,
            response
        );
        await this.$refs.captchaComponent.loadCaptcha();
        this.formControls.emailInput.value = '';
        if (!response.data.error) this.activationRequired = true;
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    }
  },
  computed: {
    ...mapState('Index', [
        'websiteName'
    ])
  }
}
</script>

<style lang="scss" scoped>
.urla {
  color: black;
}

.Auth.dark {
  > div {
    background-color: rgb(29, 31, 32);
    box-shadow: rgba(6, 6, 6, 0.08) 0px 2px 4px, rgba(6, 6, 6, 0.08) 0px 0px 2px;
  }
  .title {
    color: rgb(197, 193, 188);
  }
  .attention {
    color: rgb(144, 137, 125);
  }
  .url {
    border-color: rgb(57, 61, 64);
  }
  .urla {
    color: rgb(197, 193, 188);
  }
}
.Auth {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  width: 100%;
  color: rgb(30, 32, 38);
  flex-direction: column;
}

.Auth .header {
  box-sizing: border-box;
  min-width: 0;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #1E2026;
  margin-bottom: 12px;
}

.Auth > div {
  box-sizing: border-box;
  min-width: 0px;
  border-radius: 2px;
  background-color: #fdfdfd;
  position: relative;
  width: 384px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.08) 0px 0px 2px;
  padding: 24px 24px 32px;
  margin: 40px auto 24px;
}

.title {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #1E2026;
  margin-bottom: 12px;
}

.attention {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76808F;
  margin-bottom: 12px;
}

.url {
  box-sizing: border-box;
  min-width: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #e2e0e0;
  border-radius: 2px;
  display: inline-flex;
  margin: 0 auto 8px;
  width: auto;
  padding: 8px 24px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.url svg {
  box-sizing: border-box;
  min-width: 0;
  color: #02C076;
  font-size: 18px;
  fill: #02C076;
  width: 1em;
  height: 1em;
  margin-right: 4px;
}

.url span {
  box-sizing: border-box;
  min-width: 0;
  color: #02C076;
  margin-right: 0;
}

.links {
  display: flex;
  justify-content: space-between;
}

.links a {
  text-decoration: none;
  font-size: 12px;
}

.links a:hover {
  text-decoration: underline;
  color: #2A85FF;
}

.hidden {
  display: none;
}
</style>