import Vue from 'vue'
import * as moment from 'moment';

Vue.filter('uppercase', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('toDate', function (value, arg) {
  if (!value) return ''
  var a = new Date(value);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = ('0' + a.getHours()).slice(-2);
  var min = ('0' + a.getMinutes()).slice(-2);
  var sec = ('0' + a.getSeconds()).slice(-2);
  if(arg === 'noTime'){
    return moment(value).format('DD MMM YYYY')
  }
  if(arg === 'noDate'){
    return moment(value).format('HH:mm:ss')
  }
  if(arg === 'recent'){
    return moment(value).fromNow()
  }
  if(arg === 'mm:ss'){
    return moment(value).format('mm:ss')
  }
  return moment(value).format('DD MMM YYYY HH:mm:ss')
})

Vue.filter('numberSpace', function(value) {
  var parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
})
