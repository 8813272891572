<template>
  <div class="loading" :class="{ mobile: $store.state.Index.isMobile }">
    <div style="text-align: center;" class="img">
      <img style="margin-bottom: 15px; width: 64px; height: 64px;" :src="$store.state.Index.logo" alt=""><br>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataLoading"
}
</script>

<style lang="scss" scoped>

div.loading {
  width: 100%;
  height: calc(100vh - 61px);
  display: flex;
  align-items: center;
  justify-content: center;

  &.mobile {
    height: calc(100vh - 163px);
  }
}


img {
  animation: spin 2s linear infinite !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>