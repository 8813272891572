<template>
  <div class="input-drop" :class="{ dark: $store.state.Index.isDarkMode }">
    <div class="head" @click="isMenuOpened = !isMenuOpened">
      <img class="coin-img" :src="$coinImage(currentItem.code)" alt="">
      <div class="coin-info">
        <div class="label">
          <span class="code">{{ currentItem.code }}</span>
          <span class="name">{{ currentItem.data.name }}</span>
        </div>
        <p>{{ balance[currentItem.code][type].toFixed(8) }} <span style="font-weight:400;color: grey; font-size: 12px;">
            ~{{ new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'symbol'
        }).format(balance[currentItem.code][type] * coinsObject.find(x => x.code === currentItem.code).usdPrice) }}
          </span></p>
      </div>
      <div class="arrow" :class="{ opened: isMenuOpened }">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4815 2.614C10.9795 2.538 11.4905 2.5 12.0005 2.5C17.5145 2.5 22.0005 6.986 22.0005 12.5C22.0005 18.014 17.5145 22.5 12.0005 22.5C6.48649 22.5 2.00049 18.014 2.00049 12.5C2.00049 9.372 3.42349 6.483 5.90249 4.573C6.22049 4.328 6.67849 4.388 6.92249 4.705C7.16849 5.023 7.10749 5.479 6.79049 5.726C4.66949 7.357 3.45449 9.827 3.45449 12.5C3.45449 17.212 7.28749 21.046 12.0005 21.046C16.7125 21.046 20.5455 17.212 20.5455 12.5C20.5455 7.788 16.7125 3.954 12.0005 3.954C11.5635 3.954 11.1265 3.987 10.7015 4.052C10.3055 4.112 9.93449 3.84 9.87349 3.443C9.80749 3.044 10.0845 2.675 10.4815 2.614ZM11.9997 15.2942C11.8007 15.2942 11.6097 15.2152 11.4687 15.0732L7.99769 11.5872C7.70469 11.2932 7.70569 10.8182 7.99969 10.5262C8.29369 10.2342 8.76769 10.2342 9.05969 10.5282L11.9997 13.4812L14.9397 10.5282C15.2317 10.2342 15.7067 10.2342 16.0007 10.5262C16.2937 10.8182 16.2947 11.2932 16.0027 11.5872L12.5307 15.0732C12.3907 15.2152 12.1997 15.2942 11.9997 15.2942Z" fill="#808191"/>
        </svg>

      </div>
    </div>

    <transition name="fade">
      <div class="body" v-show="isMenuOpened">
        <div @click="changeCurrentItem(key)" class="head item-coin" v-for="coin, key in sortBalance" :key="key">
          <img class="coin-img" :src="$coinImage(key)" alt="">
          <div class="coin-info">
            <div class="label">
              <span class="code">{{ key }}</span>
              <span class="name">{{ coin.code }}</span>
            </div>
            <p>{{ balance[key][type].toFixed(8) }} <span style="font-weight:400;color: grey; font-size: 12px;">
            ~{{ new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              currencyDisplay: 'symbol'
            }).format(balance[key][type] * coinsObject.find(x => x.code === key).usdPrice) }}
          </span></p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "CoinsDropInput",
  props: {
    coinsObject: Array,
    balance: Object,
    type: {
      type: String,
      default: 'fundingWallet'
    },
    onItemChange: Function
  },
  data() {
    return {
      isMenuOpened: false,
      currentItem: {
        code: this.$route.params.coin || 'BTC',
        data: this.$route.params.coin ? this.coinsObject.find(x => x.code === this.$route.params.coin) : this.coinsObject.find(x => x.code === 'BTC')
      },
    }
  },
  computed: {
    sortBalance() {
      return Object.fromEntries(
          Object.entries(this.balance).sort(([a,a1],[b,b1]) => (b1[this.type]*this.coinsObject.find(x => x.code === b).usdPrice) - (a1[this.type]*this.coinsObject.find(x => x.code === a).usdPrice))
      )
    }
  },
  methods: {
    changeCurrentItem(coin) {
      this.onItemChange(coin);
      this.currentItem = {
        code: coin,
        data: this.coinsObject.find(x => x.code === coin)
      };
      this.isMenuOpened = false;
    }
  }
}
</script>

<style lang="scss" scoped>

.input-drop {
  position: relative;

  &.dark {
    .head {
      border-color: initial;
      background-color: rgb(33, 34, 35) !important;
      outline-color: initial;

      .coin-info {
        color: rgba(197, 193, 189, 0.7);

        p {
          color: rgb(197, 193, 189);

          span { color: #90877b !important; }
        }
      }
    }
    .body {
      box-shadow: rgba(41, 46, 93, 0.1) 0px 5px 20px;
    }
  }

  .head {
    width: 100%;
    padding: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    background: var(--card, #F6F9FD);
    border-radius: 10px;
    cursor: pointer;
    outline: 0;

    &.item-coin {
      box-shadow: none !important;
      border-radius: 0;
    }

    .coin-img {
      margin-right: 12px;
      width: 32px;
      height: 32px;
    }

    .coin-info {
      color: rgba(10,15,56,.7);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: .01em;

      .code {
        margin-right: 4px;
        font-weight: 500;
      }

      p {
        color: #0a0f38;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }
    }

    .arrow {
      margin-left: auto;
      transition: .3s;
      height: 25px;

      &.opened {
        transform: rotate(180deg);
      }
    }
  }

  .body {
    margin-top: 15px;
    max-height: 380px;
    overflow-y: auto;
    -webkit-box-shadow: 0 5px 20px rgba(48,55,120,.1);
    box-shadow: 0 5px 20px rgba(48,55,120,.1);
    position: absolute;
    width: 100%;
    z-index: 99999;
  }

}

</style>