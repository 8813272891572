<template>
  <div class="row m-0">
    <div class="row m-0">
      <h3 class="mt-0">Новая транзакция</h3>
      <div class="pl-4 pr-4 text-muted mb-4">* Если Refill включен, то:
        <p class="m-0 ml-3">- "Депозит": юзеру начислится на баланс;</p>
        <p class="m-0 ml-3">- "Вывод": у юзера снимется с баланса;</p>
        <p class="m-0 ml-3">- "Трансфер другому юзеру": у юзера снимется с баланса;</p>
        <p class="m-0 ml-3">- "Трансфер этому юзеру": юзеру начислится на баланс;</p>
      </div>
      <div class="form-control col-lg-4">
        <label>Тип транзакции</label>
        <select v-model="newTransactionInfo.type">
          <option value="deposit">Депозит</option>
          <option value="withdraw">Вывод</option>
          <option value="transferFrom">Трансфер другому юзеру</option>
          <option value="transferTo">Трансфер этому юзеру</option>
        </select>
      </div>
      <div class="form-control col-lg-4">
        <label>Монета</label>
        <select v-model="newTransactionInfo.currency">
          <option :value="currency" v-for="currency, index in coinList" :key="'currenicues' + index">{{ $coinName(currency) }}</option>
        </select>
      </div>
      <InputBox
          label="Дата транзакции"
          type="datetime-local"
          class="col-lg-4"
          @input="newTransactionInfo.date = $event"
      />
      <InputBox
          label="Сумма в крипте"
          type="number"
          class="col-lg-6"
          @input="newTransactionInfo.amount = $event"
      />
      <InputBox
          label="Юзер партнер (почта)"
          type="text"
          class="col-lg-6"
          @input="newTransactionInfo.partnerUser = $event"
          v-if="newTransactionInfo.type === 'transferTo' || newTransactionInfo.type === 'transferFrom'"
      />
      <InputBox
          label="Хеш транзакции"
          type="text"
          class="col-lg-6"
          @input="newTransactionInfo.hash = $event"
          v-else
      />
      <div class="col-lg-12 d-flex align-items-center mb-3">
        <div class="mr-3">Refill*</div>
        <SwitchButton
          :is-checked="newTransactionInfo.refill"
          @switch-change="newTransactionInfo.refill = $event"
        />
      </div>
      <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-5">
        <Button
            :is-async="true"
            :callback="createTransaction"
            :is-disabled="isInputsFilled"
            class="primary w-100"
        >
          Создать
        </Button>
      </div>
    </div>

    <h3 class="mt-0">Транзакции</h3>
    <p class="pl-4 pr-4 text-muted">* Нажмите на значение в таблице, чтобы изменить его</p>
    <div class="tabs d-flex pl-4 pr-4">
      <div @click="currentTab = type" :class="{ active: currentTab === type }" class="tab" v-for="type, index in ['Депозиты', 'Выводы', 'Трансферы']" :key="'types' + index">{{ type }}</div>
    </div>

    <div class="row m-0" v-show="currentTab === 'Депозиты'">
      <Table
          ref="depositsTableComponent"
          parse-url="/backoffice/user/user/transactions/deposits"
          :columnList="depositsTableColumns"
          :post-data="{ userId }"
          default-sort="date"
          searchPlaceholderText="Введите монету или хеш"
          @handle-data="depositsTableData = $event"
          class="col-lg-12 mb-3 pb-5"
      >
        <tr v-for="tx, index in depositsTableData" :key="'deposit' + index">
          <td @click="updateTransactionField(tx._id, 'deposits', 'currency', tx.currency)">
            <div style="display: flex; align-items: center; width: 60px;">
              <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(tx.currency)" alt="">
              </div>
              {{ $coinName(tx.currency) }}
            </div>
          </td>
          <td class="color-success" @click="updateTransactionField(tx._id, 'deposits', 'amountCurrency', tx.amountCurrency)">+{{ tx.amountCurrency }}</td>
          <td @click="updateTransactionField(tx._id, 'deposits', 'date', tx.date)">{{ tx.date | toDate }}</td>
          <td @click="updateTransactionField(tx._id, 'deposits', 'hash', tx.hash)" class="hash">{{ tx.hash }}</td>
          <td class="no-animate" style="text-align: center;">
            <span :title="`Транзакция ${tx.isVisible ? 'отображается' : 'скрыта'}`" @click="updateTransactionVisible(tx._id, 'deposits')" class="mr-5 visible-btn">
              <font-awesome-icon class="icon" :icon="['fas', tx.isVisible ? 'eye' : 'eye-slash']" />
            </span>
            <span @click="deleteTransaction(tx._id, 'deposits')" class="ml-5 delete-btn">
              <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
            </span>
          </td>
        </tr>
      </Table>
    </div>

    <div class="row m-0" v-show="currentTab === 'Выводы'">
      <Table
          ref="withdrawsTableComponent"
          parse-url="/backoffice/user/user/transactions/withdraws"
          :columnList="withdrawsTableColumns"
          :post-data="{ userId }"
          default-sort="date"
          searchPlaceholderText="Введите монету, заметку или хеш"
          @handle-data="withdrawsTableData = $event"
          class="col-lg-12 mb-3 pb-5"
      >
        <tr v-for="tx, index in withdrawsTableData" :key="'withdraw' + index">
          <td @click="updateTransactionField(tx._id, 'withdraws', 'currency', tx.currency)">
            <div style="display: flex; align-items: center; width: 60px;">
              <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(tx.currency)" alt="">
              </div>
              {{ tx.currency }}
            </div>
          </td>
          <td class="color-danger" @click="updateTransactionField(tx._id, 'withdraws', 'amount', tx.amount)">-{{ tx.amount }}</td>
          <td @click="updateTransactionField(tx._id, 'withdraws', 'date', tx.date)">{{ tx.date | toDate }}</td>
          <td @click="updateTransactionField(tx._id, 'withdraws', 'comment', tx.comment)">{{ tx.comment }}</td>
          <td @click="updateTransactionField(tx._id, 'withdraws', 'hash', tx.hash)" class="hash">{{ tx.hash }}</td>
          <td class="no-animate">
            <div style="font-size: 12px;" class="d-flex justify-content-space-between align-items-center">
              Ожидает
              <SwitchButton
                  @switch-change="updateTransactionField(tx._id, 'withdraws', 'isPending', $event)"
                  :is-checked="tx.isPending"
              />
            </div>

            <div style="font-size: 12px;" class="d-flex justify-content-space-between align-items-center">
              Ошибка
              <SwitchButton
                  @switch-change="updateTransactionField(tx._id, 'withdraws', 'isError', $event)"
                  :is-checked="tx.isError"
              />
            </div>
          </td>
          <td class="no-animate" style="text-align: center;">
            <span @click="deleteTransaction(tx._id, 'withdraws')" class="delete-btn">
              <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
            </span>
          </td>
        </tr>
      </Table>
    </div>

    <div class="row m-0" v-show="currentTab === 'Трансферы'">
      <Table
          ref="transfersTableComponent"
          parse-url="/backoffice/user/user/transactions/transfers"
          :columnList="transfersTableColumns"
          :post-data="{ userId }"
          default-sort="date"
          searchPlaceholderText="Введите монету или хеш"
          @handle-data="transfersTableData = $event"
          class="col-lg-12 mb-3 pb-5"
      >
        <tr v-for="tx, index in transfersTableData" :key="'transfer' + index">
          <td @click="updateTransactionField(tx._id, 'transfers', 'currency', tx.currency)">
            <div style="display: flex; align-items: center; width: 60px;">
              <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(tx.currency)" alt="">
              </div>
              {{ tx.currency }}
            </div>
          </td>
          <td @click="updateTransactionField(tx._id, 'transfers', 'amount', tx.amount)" :class="tx.userInfo.id === userId ? 'color-danger' : 'color-success'">{{ tx.userInfo.id === userId ? '-' + tx.amount : '+' + tx.amount }}</td>
          <td @click="updateTransactionField(tx._id, 'transfers', 'date', tx.date)">{{ tx.date | toDate }}</td>
          <td @click="updateTransactionField(tx._id, 'transfers', tx.userInfo.id === userId ? 'recipientInfo.id' : 'userInfo.id', tx.userInfo.id === userId ? tx.recipientInfo.email : tx.userInfo.email)">
            {{ tx.userInfo.id === userId ? tx.recipientInfo.email : tx.userInfo.email }}
          </td>
          <td class="no-animate" style="text-align: center;">
            <span @click="deleteTransaction(tx._id, 'transfers')" class="delete-btn">
              <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
            </span>
          </td>
        </tr>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";

export default {
  name: "UserTransactions",
  props: {
    userId: String,
    coinList: Array
  },
  components: {
    Table,
    SwitchButton,
    InputBox,
    Button
  },
  data() {
    return {
      currentTab: 'Депозиты',
      transfersTableData: [],
      transfersTableColumns: [
        {
          name: 'currency',
          title: 'Монета',
          isSortable: true
        },
        {
          name: 'amount',
          title: 'Сумма',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
        {
          name: 'senders',
          title: 'Получатель / Отправитель',
          isSortable: false
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        }
      ],
      withdrawsTableData: [],
      withdrawsTableColumns: [
        {
          name: 'currency',
          title: 'Монета',
          isSortable: true
        },
        {
          name: 'amount',
          title: 'Сумма',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
        {
          name: 'comment',
          title: 'Заметка',
          isSortable: true
        },
        {
          name: 'hash',
          title: 'Хеш',
          isSortable: true
        },
        {
          name: 'status',
          title: 'Статус',
          isSortable: false
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        }
      ],
      depositsTableData: [],
      depositsTableColumns: [
        {
          name: 'currency',
          title: 'Монета',
          isSortable: true
        },
        {
          name: 'amountCurrency',
          title: 'Сумма',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
        {
          name: 'hash',
          title: 'Хеш',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        },
      ],
      newTransactionInfo: {
        hash: '',
        amount: '',
        date: '',
        partnerUser: '',
        type: 'deposit',
        currency: 'BTC',
        refill: true,
      },
      months: ['January','February','March','April','May','June','July','August','September','October','November','December']
    }
  },
  computed: {
    isInputsFilled() {
      return this.newTransactionInfo.type === 'transferFrom' || this.newTransactionInfo.type === 'transferTo'
        ? this.newTransactionInfo.partnerUser === '' || this.newTransactionInfo.date === ''
        : this.newTransactionInfo.hash === '' || this.newTransactionInfo.amount === '' || this.newTransactionInfo.date === ''
    }
  },
  methods: {
    formatDate(date) {
      let zeroDay = new Date(date).getDate() <= 9 ? 0 : '';
      return `${this.months[new Date(date).getMonth()]} ${zeroDay}${new Date(date).getDate()} ${new Date(date).getFullYear()}, ${new Date(date).toLocaleTimeString()}`
    },
    async createTransaction() {
      try {
        const fetchedData = await axios.post('/backoffice/user/user/createTransaction', {
          userId: this.userId,
          transactionInfo: this.newTransactionInfo
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          const type = this.newTransactionInfo.type === 'deposit' ? ['deposits', 'Депозиты'] : this.newTransactionInfo.type === 'withdraw' ? ['withdraws', 'Выводы'] : ['transfers', 'Трансферы'] ;
          this.currentTab = type[1];
          this.$refs[type[0] + 'TableComponent'].fetchTableData();
        }
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async deleteTransaction(transactionId, type) {
      try {
        const confirmation = confirm('Вы действительно хотите удалить эту транзакцию?');
        if (!confirmation) return;

        const fetchedData = await axios.post('/backoffice/user/user/deleteTransaction', {
          userId: this.userId,
          transactionId,
          type
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs[type + 'TableComponent'].fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async updateTransactionVisible(transactionId, type) {
      try {
        const fetchedData = await axios.post('/backoffice/user/user/visibleTransaction', {
          userId: this.userId,
          transactionId,
          type
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs[type + 'TableComponent'].fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async updateTransactionField(transactionId, type, field, current) {
      try {
        let value;
        if (field !== 'isPending' && field !== 'isError') {
          value = prompt(
              field === 'date'
                  ? `Введите новую дату. Используйте полные названия месяцев на английском.`
                  : `Введите новое значение.`,
              field === 'date'
                  ? this.formatDate(current )
                  : current
          );
        } else {
          value = current;
        }
        if (field !== 'isPending' && field !== 'isError' && !value) return;
        if(field === 'date' && isNaN(new Date(value).getTime())) return this.$callNotification(this.$i18n.locale, { error: true, message: 'Введена неверная дата.' });

        const fetchedData = await axios.post('/backoffice/user/user/updateTransaction', {
          userId: this.userId,
          transactionId,
          type,
          field,
          value
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs[type + 'TableComponent'].fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    }
  },
}
</script>

<style lang="scss" scoped>

div.tabs {

  div.tab {
    padding: 5px 15px;
    transition: .1s;
    cursor: pointer;
    border-radius: 2px;

    &.active {
      background: #405189;
    }
  }
}

.hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

h3 {
  font-weight: 500;
  padding-left: 15px;
}

td:not(.no-animate) {
  transition: .1s;
  cursor: text;

  &:hover {
    background: #2B3035;
  }
}

span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}

span.visible-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #405189;
    }
  }
}

</style>