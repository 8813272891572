<i18n>
{
  "en": {
    "buy": "Buy {0}",
    "sell": "Sell {0}",
    "limit": "Limit",
    "market": "Market",
    "leverage": "Leverage",
    "markets": "Markets",
    "price24h": "24H PRICE",
    "high24h": "24H HIGH",
    "low24h": "24H LOW",
    "volume24h": "24H VOLUME",
    "search": "Search",
    "tradingFees": "Trading fees",
    "orderBook": "Order Book",
    "price": "Price {0}",
    "amount": "Amount {0}",
    "total": "Total {0}",
    "fees": "Fees",
    "tradeHistory": "Trade History",
    "tradeSize": "Trade Size",
    "time": "Time",
    "actions": "Actions",
    "updateOrder": "Update Order",
    "open": "Open Orders",
    "history": "History Orders",
    "executed": "Executed",
    "canceled": "Canceled",
    "fullHistory": "Click to view full history",
    "editOrder": "ORDER EDIT",
    "cancelOrder": "ORDER CANCEL",
    "executeOrder": "ORDER EXECUTE",
    "openOrdersTable": {
      "columns": {
        "side": "Side",
        "amount": "Amount",
        "price": "Price",
        "pair": "Pair",
        "date": "Date"
      }
    },
    "historyOrdersTable": {
      "columns": {
        "side": "Side",
        "amount": "Amount",
        "price": "Price",
        "pair": "Pair",
        "avg": "AVG",
        "status": "Status",
        "date": "Inactive Date"
      }
    }
  },
  "zh": {
    "buy": "买 {0}",
    "sell": "卖 {0}",
    "limit": "限制",
    "market": "市场",
    "leverage": "杠杆",
    "markets": "市场",
    "price24h": "价格24小时",
    "high24h": "高24小时",
    "low24h": "低24小时",
    "volume24h": "音量 24 小时",
    "search": "搜索",
    "tradingFees": "交易费",
    "orderBook": "订单簿",
    "price": "价格 {0}",
    "amount": "金额 {0}",
    "total": "总计 {0}",
    "fees": "费用",
    "tradeHistory": "贸易历史",
    "tradeSize": "交易规模",
    "time": "时间",
    "actions": "行动",
    "updateOrder": "更新订单",
    "open": "未结订单",
    "history": "历史订单",
    "executed": "已执行",
    "canceled": "取消",
    "fullHistory": "点击查看完整历史",
    "editOrder": "订单编辑",
    "cancelOrder": "订单取消",
    "executeOrder": "命令执行",
    "openOrdersTable": {
      "columns": {
        "side": "边",
        "amount": "金额",
        "price": "价格",
        "pair": "对",
        "date": "日期"
      }
    },
    "historyOrdersTable": {
      "columns": {
        "side": "边",
        "amount": "金额",
        "price": "价格",
        "pair": "对",
        "avg": "平均",
        "status": "状态",
        "date": "非活动日期"
      }
    }
  },
  "kr": {
    "buy": "{0} 구매",
    "sell": "{0} 판매",
    "limit": "한계",
    "market": "시장",
    "leverage": "레버리지",
    "markets": "시장",
    "price24h": "24시간 가격",
    "high24h": "높은 24시간",
    "low24h": "낮은 24시간",
    "volume24h": "볼륨 24시간",
    "search": "검색",
    "tradingFees": "거래 수수료",
    "orderBook": "주문서",
    "price": "가격 {0}",
    "amount": "금액 {0}",
    "total": "총 {0}",
    "fees": "수수료",
    "tradeHistory": "무역 내역",
    "tradeSize": "거래 규모",
    "time": "시간",
    "actions": "액션",
    "updateOrder": "업데이트 주문",
    "open": "주문 열기",
    "history": "주문 내역",
    "executed": "실행됨",
    "canceled": "취소됨",
    "fullHistory": "전체 기록을 보려면 클릭하세요.",
    "editOrder": "주문 수정",
    "cancelOrder": "주문 취소",
    "executeOrder": "주문 실행",
    "openOrdersTable": {
      "columns": {
        "side": "측면",
        "amount": "금액",
        "price": "가격",
        "pair": "쌍",
        "date": "날짜"
      }
    },
    "historyOrdersTable": {
      "columns": {
        "side": "측면",
        "amount": "금액",
        "price": "가격",
        "pair": "쌍",
        "avg": "평균",
        "status": "상태",
        "date": "비활성 날짜"
      }
    }
  }
}
</i18n>
<template>
  <Page style="padding: 0 !important;" :no-line="true" :is-page-loaded="dataLoadingStatus">
    <div class="terminal" v-if="dataLoadingStatus.isDone">

      <div class="terminal-header">

        <div class="markets">
          <div class="showed-body" @click="isMarketsOpen = !isMarketsOpen">
            <div style="display: flex; align-items: center;">
              <div style="width: 20px; height: 20px; margin-right: 5px;">
                <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(currentPair.coin)" alt="">
              </div>
              <span>{{ currentPair.coin }} / {{ currentPair.market }}</span>
            </div>

            <div style="margin-left: auto; font-size: 12px;">
              <span style="margin-right: 5px;">{{ $t('markets') }}</span>
              <font-awesome-icon :icon="['fal', isMarketsOpen ? 'chevron-up' : 'chevron-down']" />
            </div>
          </div>

          <transition name="fade">
            <div class="hidden-body" v-show="isMarketsOpen">
              <div class="search">
                <input v-model="searchCoinMarket" type="text" :placeholder="$t('search')">
                <font-awesome-icon
                    :icon="['fas', 'search']"
                    class="icon"
                    style="color: #f7f9fa;"
                />
              </div>
              <div style="padding: 0 16px;">
                <span class="enable-markets" :class="currentMarket === market ? 'current' : null" @click="currentMarket = market" v-for="market in markets" :key="market">{{ market }}</span>
              </div>
              <div class="pairs">
                <div @click="changePair(pair.coin + '_' + pair.market)" class="pair" v-for="pair, index in filteredMarketPairs" :key="pair.coin+index">
                  <div class="name">
                    <div style="width: 20px; height: 20px; margin-right: 5px;">
                      <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(pair.coin)" alt="">
                    </div>
                    <span>{{ pair.coin }} / {{ pair.market }}</span>
                  </div>
                  <div>
                    <span class="value" :class="currentPair.priceChange.percent > 0 ? 'plus' : 'minus'">{{ pair.priceChange.percent > 0 ? '+'+pair.priceChange.percent : pair.priceChange.percent }}%</span>
                    <span class="description">{{ $t('price24h') }}</span>
                  </div>
                  <div>
                    <span class="value">{{ pair.price }}</span>
                    <span class="description">≈${{ (pair.price * coinList.find(x => x.code === pair.market).usdPrice).toFixed(2) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>

        <div class="pair-info">
          <div class="info">
            <span class="value">{{ currentPair.price }}</span>
            <span class="description">≈${{ (+(currentPair.price * coinList.find(x => x.code === currentPair.market).usdPrice).toFixed(2)).toLocaleString('en-US') }}</span>
          </div>
          <div class="info">
            <span class="value" :class="currentPair.priceChange.percent > 0 ? 'plus' : 'minus'">{{ currentPair.priceChange.percent > 0 ? '+'+currentPair.priceChange.percent : currentPair.priceChange.percent }}%</span>
            <span class="description">{{ $t('price24h') }}</span>
          </div>
          <div class="info">
            <span class="value">{{ currentPair.priceHigh24h.toLocaleString('en-US') }}</span>
            <span class="description">{{ $t('high24h') }}</span>
          </div>
          <div class="info">
            <span class="value">{{ currentPair.priceLow24h.toLocaleString('en-US') }}</span>
            <span class="description">{{ $t('low24h') }}</span>
          </div>
          <div class="info">
            <span class="value">{{ (+(currentPair.volume).toFixed(6)).toLocaleString('en-US') }} {{ currentPair.coin }}</span>
            <span class="description">{{ $t('volume24h') }}</span>
          </div>
        </div>

      </div>

      <div class="body row">

        <div class="left-side col-lg-8 col-md-12 col-xs-12">

          <div ref="tradeChart">
            <tradingChart
                :title="currentPair.coin + currentPair.market"
                :width="tradeChartWidth"
                :candles="this.ticks"
            ></tradingChart>
          </div>

          <div class="orders">

            <div class="header">
              <span @click="typeOfOrderList = ordersType" :class="{ current: typeOfOrderList === ordersType }" class="order-list-type" v-for="ordersType, index in ['open', 'history']" :key="'ordersType' + index">
                {{ $t(ordersType) }}
              </span>
            </div>

            <div class="body">
              <div style="text-align: center;  font-size: 12px; top: 50%; position: absolute; width: calc(100% - 16px);" v-if="userInfo === null">
                <p style="letter-spacing: 1px;">
                  <router-link style="color: #2688ed;" :to="$linkCreator($i18n.locale, 'auth')">Log In </router-link>
                  or
                  <router-link style="color: #2688ed;" :to="$linkCreator($i18n.locale, 'sign-up')"> Sign Up </router-link>
                  <span>to start trading</span>
                </p>
              </div>
              <div v-else>
                <div v-show="typeOfOrderList === 'history'">
                  <table>
                    <thead>
                    <tr>
                      <th>{{ $t('historyOrdersTable.columns.side') }}</th>
                      <th>{{ $t('historyOrdersTable.columns.pair') }}</th>
                      <th>{{ $t('historyOrdersTable.columns.avg') }}</th>
                      <th>{{ $t('historyOrdersTable.columns.price') }}</th>
                      <th>{{ $t('historyOrdersTable.columns.amount') }}</th>
                      <th>{{ $t('historyOrdersTable.columns.status') }}</th>
                      <th>{{ $t('historyOrdersTable.columns.date') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr  class="class" v-for="order, index in userHistory" :key="'order' + index">
                      <th :class="order.isBuyerMaker ? 'plus' : 'minus'">{{ order.isBuyerMaker ? 'Buy' : 'Sell' }}</th>
                      <th>{{ order.coin }}/{{ order.market }}</th>
                      <th>{{ order.middleClosePrice  }}</th>
                      <th>{{ order.price }}</th>
                      <th>{{ order.amount.toFixed(6) }}</th>
                      <th :class="order.isCanceled ? 'minus' : 'info'">{{ order.isCanceled ? $t('canceled') : $t('executed') }}</th>
                      <th>{{ order.closeTime | toDate }}</th>
                    </tr>
                    </tbody>
                  </table>
                  <div style="text-align: center; padding: 7px 0; font-size: 12px;">
                    <router-link style="color: white;" :to="$linkCreator($i18n.locale, 'dashboard/orders')">{{ $t('fullHistory') }}</router-link>
                  </div>
                </div>
                <table v-show="typeOfOrderList === 'open'">
                  <thead>
                  <tr>
                    <th>{{ $t('openOrdersTable.columns.side') }}</th>
                    <th>{{ $t('openOrdersTable.columns.pair') }}</th>
                    <th>{{ $t('openOrdersTable.columns.price') }}</th>
                    <th>{{ $t('openOrdersTable.columns.amount') }}</th>
                    <th>{{ $t('openOrdersTable.columns.date') }}</th>
                    <th>{{ $t('actions') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr  class="class" v-for="order, index in userOrders" :key="'order' + index">
                    <th :class="order.isBuyerMaker ? 'plus' : 'minus'">{{ order.isBuyerMaker ? 'Buy' : 'Sell' }}</th>
                    <th>{{ order.coin }}/{{ order.market }}</th>
                    <th>{{ order.price }}</th>
                    <th>{{ order.amount.toFixed(6) }}</th>
                    <th>{{ order.openTime | toDate }}</th>
                    <th>
                      <span class="icon" @click="openEditOrderModal(order._id, order.price, order.amount)">
                        <font-awesome-icon
                            :icon="['fas', 'pencil']"
                            class="fai"
                        />
                      </span>
                      <span class="icon" @click="editOrder(order._id, true)">
                        <font-awesome-icon
                            :icon="['fas', 'times']"
                            class="fai"
                        />
                      </span>
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>

        </div>

        <div class="right-side col-lg-4 col-md-12 col-xs-12">

          <div class="market-info col-lg-12 col-md-12 col-xs-12">
            <div class="order-book col-lg-6 col-md-12 col-xs-12">
              <div class="header">
                <span class="title">{{ $t('orderBook') }}</span>
              </div>
              <div class="columns">
                <div class="column">{{ $t('price', [currentPair.market]) }}</div>
                <div class="column">{{ $t('amount', [currentPair.coin]) }}</div>
                <div class="column">{{ $t('total', [currentPair.market]) }}</div>
              </div>
              <div class="content">
                <div class="sell">
                  <div class="order" v-for="order, index in reversedDepth.asks" :key="'asks' + index">
                    <span class="price minus">{{ (+order[0]).toFixed(5) }}</span>
                    <span class="amount">{{ (+order[1]).toFixed(5) }}</span>
                    <span class="total">{{ (+order[0] * +order[1]).toFixed(2) }}</span>
                    <div class="depth minus" :style="{ width: +order[1] / widthOfDepth.asks * 100 + '%' }"></div>
                  </div>
                </div>
                <div class="price">
                  <span class="live-price">
                    <span :class="this.priceTrend ? 'plus' : 'minus'">
                      {{ currentPair.price }}
                      <font-awesome-icon
                        :icon="['fal', this.priceTrend ? 'arrow-up' : 'arrow-down']"
                        style="font-size: 10px;"
                      />
                    </span>
                    <span class="usd">≈${{ (currentPair.price * coinList.find(x => x.code === currentPair.market).usdPrice).toFixed(2).toLocaleString('en-US')  }}</span>
                  </span>
                  <span class="percent" :class="currentPair.priceChange.percent > 0 ? 'plus' : 'minus'">{{ currentPair.priceChange.percent > 0 ? '+'+currentPair.priceChange.percent : currentPair.priceChange.percent }}%</span>
                </div>
                <div class="buy">
                  <div class="order" v-for="order, index in reversedDepth.bids" :key="'bids' + index">
                    <span class="price plus">{{ (+order[0]).toFixed(5) }}</span>
                    <span class="amount">{{ (+order[1]).toFixed(5) }}</span>
                    <span class="total">{{ (+order[0] * +order[1]).toFixed(2) }}</span>
                    <div class="depth plus" :style="{ width: +order[1] / widthOfDepth.bids * 100 + '%' }"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="trade-history col-lg-6 col-md-12 col-xs-12">
              <div class="header">
                <span class="title">{{ $t('tradeHistory') }}</span>
              </div>
              <div class="columns">
                <div class="column">{{ $t('tradeSize') }}</div>
                <div class="column">{{ $t('price', [currentPair.market]) }}</div>
                <div class="column">{{ $t('time') }}</div>
              </div>
              <div class="content">
                <div class="order" v-for="trade, index in reversedTrades" :key="'trade' + index">
                  <span class="price" :class="trade.b ? 'plus' : 'minus'">{{ (+trade.q).toFixed(5) }}</span>
                  <span class="amount" :class="trade.b ? 'plus' : 'minus'">
                    {{ (+trade.p).toFixed(5) }}
                  </span>
                  <span class="total" :class="trade.b ? 'plus' : 'minus'">{{ trade.t | toDate('noDate') }}</span>
                  <div class="depth" :class="trade.b ? 'plus' : 'minus'" style="width: 100%;"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="trade-window col-lg-12 col-md-12 col-xs-12">

            <div class="type">
              <span @click="changeTradingType(tradingType)" :class="{ current: typeOfTrading === tradingType, disabled: tradingType === 'leverage' }" class="trading-type" v-for="tradingType, index in ['limit', 'market', 'leverage']" :key="'tradingType' + index">
                {{ $t(tradingType) }}
              </span>

              <router-link tag="span" :to="$linkCreator($i18n.locale, 'fees')" class="trading-type trading-fees">{{ $t('tradingFees') }}</router-link>
            </div>

            <div class="process">

              <div class="col-lg-6 col-md-6 col-xs-12" :class="orderType" v-for="orderType, index in ['buy', 'sell']" :key="orderType + index">
                <div class="balance">
                  <span class="coin">{{ $t(orderType, [currentPair.coin]) }}</span>

                  <span class="value">
                    <font-awesome-icon
                      :icon="['fas', 'wallet']"
                      class="icon"
                    />

                    {{ userInfo !== null ? userInfo.balance[orderType === 'buy' ? currentPair.market : currentPair.coin].spotWallet.toFixed(6) : 0 }}
                    {{ orderType === 'buy' ? currentPair.market : currentPair.coin }}
                  </span>
                </div>

                <div class="inputs">
                  <div class="price">
                    <span>{{ $t('price', ['']) }}</span>
                    <input :type="typeOfTrading === 'limit' ? 'number' : 'text'" v-model="$data[orderType + 'PriceInput']" :disabled="typeOfTrading === 'market' || userInfo === null" />
                    <span class="coin">{{ currentPair.market }}</span>
                  </div>
                  <div class="amount">
                    <span>{{ $t('amount', ['']) }}</span>
                    <input
                        type="number"
                        v-model="$data[orderType + 'AmountInput']"
                        :disabled="userInfo === null"
                        :step="0.01"
                    />
                    <span class="coin">{{ currentPair.coin }}</span>
                  </div>
                  <div class="amount">
                    <span>{{ $t('total', ['']) }}</span>
                    <input
                        type="number"
                        :value="totalTradeValue(orderType)"
                        :disabled="true"
                    />
                    <span class="coin">{{ currentPair.market }}</span>
                  </div>
                  <div class="slider">
                    <input
                        type="range"
                        v-model="$data[orderType + 'AmountInput']"
                        min="0"
                        :max=
                            "
                              userInfo !== null
                                ? orderType === 'buy'
                                    ? userInfo.balance[currentPair.market].spotWallet / (typeOfTrading === 'market' ? currentPair.price : $data.buyPriceInput)
                                    : userInfo.balance[typeOfTrading === 'market' ? orderType === 'buy' ? currentPair.market : currentPair.coin : currentPair.coin].spotWallet
                                : 0
                            "
                        :step=
                            "
                              userInfo !== null
                                ? orderType === 'buy'
                                    ? userInfo.balance[currentPair.market].spotWallet / (typeOfTrading === 'market' ? currentPair.price : $data.buyPriceInput) / 100
                                    : userInfo.balance[typeOfTrading === 'market' ? orderType === 'buy' ? currentPair.market : currentPair.coin : currentPair.coin].spotWallet / 100
                                : 0
                            "
                        class="range"
                        :disabled="userInfo === null">
                    <div class="dots">
                      <div class="dot" v-for="percent, index in [0, 25, 50, 75, 100]" :key="'percent' + index">
                        <p
                            @click=
                                "
                                  userInfo !== null
                                    ? orderType === 'buy'
                                        ? $data.buyAmountInput = userInfo.balance[currentPair.market].spotWallet / (typeOfTrading === 'market' ? currentPair.price : $data.buyPriceInput) / 100 * percent
                                        : $data[orderType + 'AmountInput'] =  userInfo.balance[typeOfTrading === 'market' ? orderType === 'buy' ? currentPair.market : currentPair.coin : currentPair.coin].spotWallet / 100 * percent
                                    : 0
                                "
                        ><span>{{  percent  }}%</span></p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="fees">
                  <span class="text">{{ $t('fees') }}</span>

                  <span class="fee">
                    {{
                      typeOfTrading === 'limit'
                          ? orderType === 'buy'
                            ? (fees.maker / 100 * totalTradeValue(orderType)).toFixed(6)
                            : (fees.maker / 100 * $data[orderType + 'AmountInput']).toFixed(6)
                          : orderType === 'buy'
                            ? (fees.taker / 100 * totalTradeValue(orderType)).toFixed(6)
                            : (fees.taker / 100 * $data[orderType + 'AmountInput']).toFixed(6)
                    }}
                    {{ orderType === 'buy' ? currentPair.market : currentPair.coin }}
                  </span>
                </div>

                <div class="button">
                  <button @click="createOrder(orderType === 'buy')" :disabled="userInfo === null || createOrderBtnDisabled" :class="orderType">{{ $t(orderType, [currentPair.coin]) }}</button>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

    <modal
        name="editOrder"
        :maxWidth="400"
        height="auto"
        transition="none"
        :adaptive="true"
        style="background: rgb(0 0 0 / 0.58);"
        styles="background: #0F1822; color: white; font-size: 12px; padding: 30px 10px; box-shadow: none;"
        @before-open="(event) => orderEditId = event.params.id"
    >
      <div>
        <div style="position: absolute; right: 5px; top: 5px; cursor: pointer;" @click="$modal.hide('editOrder')">
          <i class="fas fa-times"></i>
        </div>
        <div>
          <label for="amountInput">{{ $t('amount', [currentPair.coin]) }}</label>
          <input v-model="orderEditAmountInput" type="number" id="amountInput">
        </div>
        <div>
          <label for="priceInput">{{ $t('price', [currentPair.market]) }}</label>
          <input v-model="orderEditPriceInput" type="number" id="priceInput">
        </div>
        <div>
          <button id="editButton" @click="editOrder(orderEditId)">{{ $t('updateOrder') }}</button>
        </div>
      </div>
    </modal>

  </Page>
</template>

<script>
import Page from "@/components/main/UI/Page";
import axios from "@/plugins/axios";
import { TradingVue, DataCube } from 'trading-vue-js'
import tradingChart from "@/views/Exchange/trading-chart.vue";
import {log} from "qrcode/lib/core/galois-field";


export default {
  name: "exchange",
  components: {
    Page,
    //TradingVue,
    tradingChart
  },
  /*metaInfo: {
    script: [
      { src: 'https://s3.tradingview.com/tv.js', async: true, defer: true }
    ],
  },*/
  data() {
    return {
      ro: null,
      createOrderBtnDisabled: false,
      orderEditPriceInput: 0,
      orderEditAmountInput: 0,
      orderEditId: '',
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      typeOfOrderList: 'open',
      typeOfTrading: 'limit',
      isMarketsOpen: false,
      currentPair: {},
      userOrders: [],
      userHistory: [],
      searchCoinMarket: '',
      currentMarket: '',
      sellPriceInput: 0,
      buyPriceInput: 0,
      sellAmountInput: 0,
      buyAmountInput: 0,
      markets: [],
      pairs: [],
      coinList: [],
      chartWidth: 0,
      priceTrend: false,
      chartSettings: {
        chart: {
          type: "Candles",
          data: [],
        }
      },
      userInfo: null,
      isActivated: false,
      tradeChartWidth: 700,
      tradeData: new DataCube({
        chart: {
          type: 'Candles',
          data: [
            [ 1551128400000, 33,  37.1, 14,  14,  196 ],
            [ 1551132000000, 13.7, 30, 6.6,  30,  206 ],
            [ 1551135600000, 29.9, 33, 21.3, 21.8, 74 ],
            [ 1551139200000, 21.7, 25.9, 18, 24,  140 ],
            [ 1551142800000, 24.1, 24.1, 24, 24.1, 29 ],
          ],
          settings: {}
        },
        onchart: [],
        offchart: []
      }),
      ticks: []
    }
  },
  methods: {
    async openEditOrderModal(id, price, amount) {
      this.isMarketsOpen = false;
      this.orderEditPriceInput = price;
      this.orderEditAmountInput = amount;
      this.$modal.show('editOrder', { id });
    },
    async editOrder(id, cancel) {
      try {
        let fetchedData = await axios.post('main/user/edit_order', {
          id,
          price: cancel ? 1 : this.orderEditPriceInput,
          amount: cancel ? 1 : this.orderEditAmountInput,
          cancel
        });

        if (fetchedData.data?.error) return this.$callNotification(this.$i18n.locale, fetchedData);
        this.$modal.hide('editOrder');
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async createOrder(isBuyerMaker) {
      try {
        if (this.createOrderBtnDisabled) return;

        this.createOrderBtnDisabled = true;
        const type = isBuyerMaker ? 'buy' : 'sell';
        if (this[type + 'AmountInput'] <= 0 || this[type + 'PriceInput'] <= 0) {
          this.createOrderBtnDisabled = false;
          return
        }
        let fetchedData = await axios.post('main/user/order', {
          price: this[type + 'PriceInput'],
          amount: this[type + 'AmountInput'],
          type: this.typeOfTrading,
          isBuyerMaker,
          coin: this.currentPair.coin,
          market: this.currentPair.market
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
        setTimeout(() => this.createOrderBtnDisabled = false, 2000);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
        this.createOrderBtnDisabled = false;
      }
    },
    async changePair(pair) {
      if (pair === this.$route.params.pair) return;
      this.searchCoinMarket = '';
      this.sellPriceInput = 0;
      this.buyPriceInput = 0;
      this.sellAmountInput = 0;
      this.buyAmountInput = 0;
      this.isMarketsOpen = false;
      this.websocketManager(`${this.isActivated ? document.domain + "_" : ""}` + this.parsedCoinName + this.$route.params.pair.split('_')[1], 'leave');
      await this.getPairInfo(pair);
      this.websocketManager(`${this.isActivated ? document.domain + "_" : ""}` + this.parsedCoinName + pair.split('_')[1], 'enter');
      this.$router.push({ path: pair, params: pair }).catch(err => null);
      //this.updateTradingViewChart(pair.split('_')[0] + pair.split('_')[1]);
    },
    updateTradingViewChart(pair) {
      new window.TradingView.widget(
          {
            "width": "100%",
            "height": this.$store.state.Index.isMobile ? 486 : 972,
            "symbol": pair,
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": "Dark",
            "style": "1",
            "locale": this.$i18n.locale === 'en' ? "en" : this.$i18n.locale === 'zh' ? 'zh_CN' : this.$i18n.locale === 'kr' ? 'kr' : "en",
            "toolbar_bg": "#131722",
            "enable_publishing": false,
            "withdateranges": true,
            "hide_side_toolbar": false,
            "allow_symbol_change": false,
            "show_popup_button": true,
            "popup_width": "1000",
            "popup_height": "650",
            "container_id": "TradingViewContainer"
          }
      );
    },
    async updateUserBalance() {
      if (this.userInfo === null) return;
      try {
        let fetchedData = await axios.get('main/user/getUserBalance');
        this.userInfo.balance = fetchedData.data.balance;
        this.userOrders = fetchedData.data.userOrders;
        this.userHistory = fetchedData.data.userHistory;
      } catch (e) {
        console.log(e)
      }
    },
    async getPairInfo(pair) {
      this.dataLoadingStatus.isDone = false;
      this.dataLoadingStatus.isError = false;
      try {
        if (!pair) return location.href = this.$linkCreator(this.$i18n.locale, 'exchange/BTC_USDT');
        let fetchedData = await axios.post('main/exchange/terminal', {
          pair
        });
        if (fetchedData.data.error) {
          this.dataLoadingStatus.isDone = true;
          this.dataLoadingStatus.isError = true;
          return;
        }
        this.isActivated = fetchedData.data.isActivated;
        this.currentPair = fetchedData.data.pairInfo;
        this.ticks = this.currentPair.ticks;
        console.log(this.ticks[this.ticks.length - 1])
        this.buyPriceInput = this.currentPair.price;
        this.sellPriceInput = this.currentPair.price;
        this.markets = fetchedData.data.markets;
        this.pairs = fetchedData.data.pairs;
        this.currentMarket = fetchedData.data.pairInfo.market;
        this.coinList = fetchedData.data.coinList;
        this.userInfo = fetchedData.data.userInfo;
        this.userOrders = fetchedData.data.userOrders;
        this.userHistory = fetchedData.data.userHistory;
        this.fees = fetchedData.data.fees;
        this.updatePageTitle(this.currentPair.price, `${this.currentPair.coin}/${this.currentPair.market}`);
        this.dataLoadingStatus.isDone = true;
      } catch (e) {
        if (e.response?.status === 404) return this.$router.push('/');
        console.log(e)
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
      }
    },
    websocketManager(pair, action) {
      const room = action + 'ExchangeRoom';
      this.$socket.emit(room, pair);
    },
    changeTradingType(type) {
      if (type === this.typeOfTrading) return;
      if (type === 'leverage') return;
      if (type === 'market') {
        this.buyPriceInput = this.$t('market');
        this.sellPriceInput = this.$t('market');
      } else if (type === 'limit') {
        this.buyPriceInput = this.currentPair.price;
        this.sellPriceInput = this.currentPair.price;
      }
      this.sellAmountInput = 0;
      this.buyAmountInput = 0;
      this.typeOfTrading = type;
    },
    updatePageTitle(price, pair) {
      document.title = `${price} | ${pair} | ${this.$store.state.Index.websiteName.short}`;
    },
    totalTradeValue(type) {
      return this.typeOfTrading === 'limit'
          ? (this[type + 'AmountInput'] * this[type + 'PriceInput']).toFixed(6)
          : (this[type + 'AmountInput'] * this.currentPair.price).toFixed(6);
    },
    onResizeTradeChart() {
      this.tradeChartWidth = this.$refs.tradeChart.offsetWidth;
    }
  },
  computed: {
    widthOfDepth() {
      return {
        bids: +this.reversedDepth.bids[0][1],
        asks: +this.reversedDepth.asks[this.reversedDepth.bids.length-1][1],
      }
    },
    reversedTrades() {
      const newArrayObj = [...this.currentPair.trades];
      return newArrayObj.reverse();
    },
    reversedDepth() {
      const newArrayObj = Object.assign({}, this.currentPair.depth);
      newArrayObj.bids.sort((a, b) => +b[1] - +a[1]);
      newArrayObj.asks.sort((a, b) => +a[1] - +b[1]);
      newArrayObj.bids.length = 12;
      newArrayObj.asks.reverse();
      newArrayObj.asks.length = 12;
      newArrayObj.asks.reverse();
      return newArrayObj;
    },
    filteredMarketPairs() {
      return this.pairs.filter(x => x.market === this.currentMarket && x.coin.toLowerCase().includes(this.searchCoinMarket.toLowerCase()));
    },
    parsedCoinName() {
      return this.currentPair.superPair ? "APT" : this.currentPair.coin;
    }
  },
  /*watch: {
    ticks: {
      deep: true, handler() {
        this.$nextTick(() => {
          this.$refs.tradingVue.render();
        });
      }
    }
  },*/
  sockets: {
    recycle: function (data) {
      if (this.isActivated !== data) {
        this.isActivated = data;
        this.websocketManager(`${data ? "" : document.domain + "_" }` + this.parsedCoinName + this.currentPair.market, 'leave');
        this.websocketManager( `${data ? document.domain + "_"  : "" }` + this.parsedCoinName + this.currentPair.market, 'enter');
      }
    },
    trade: function (data) {
      this.currentPair.trades.shift();
      this.currentPair.trades.push(data);
      /*const dLevel = this.currentPair.depth[data.b ? 'bids' : 'asks'].find(x => +x[0] === +data.p);
      if (dLevel) {
        dLevel[1] = +dLevel[1] + +data.q;
      } else {
        //this.currentPair.depth[data.b ? 'bids' : 'asks'].push([+data.p, +data.q]);
      }
      this.currentPair.depth[data.b ? 'bids' : 'asks'].sort((a, b) => data.b ? +b[1] - +a[1] : +a[1] - +b[1]);
      this.currentPair.depth[data.b ? 'bids' : 'asks'].length = 12;*/
    },
    depth: function (data) {
      this.currentPair.depth.bids = data.bids;
      this.currentPair.depth.asks = data.asks;
    },
    '24hrTicker': async function (data) {
      this.priceTrend = this.currentPair.price <= +data.c;
      this.currentPair.priceChange.amount = +data.p;
      this.currentPair.priceChange.percent = +data.P;
      this.currentPair.price = +data.c;
      this.currentPair.volume = +data.v;
      this.currentPair.priceLow24h = +data.l;
      this.currentPair.priceHigh24h = +data.h;
      /*1499040000000,      // Kline open time
          "0.01634790",       // Open price
          "0.80000000",       // High price
          "0.01575800",       // Low price
          "0.01577100",       // Close price
          "148976.11427815",  // Volume*/
      //+x[0], +x[1], +x[2], +x[3], +x[4], +x[5]
      this.$set(this.ticks, this.ticks.length - 1, [/*+data.E*/this.ticks[this.ticks.length - 1][0], +data.o, +data.h, +data.h, +data.c, +data.v]);
      this.updatePageTitle(this.currentPair.price, `${this.currentPair.coin}/${this.currentPair.market}`);
    },
    executeOrder: function (data) {
      this.$callNotification(
          this.$i18n.locale,
          {
            position: 'top right',
            type: 'info',
            title: this.$t('executeOrder'),
            message: `${data.amount} ${data.coin} > ${data.middleClosePrice} ${data.market}`,
            timeout: 10000
          }
      );
      this.updateUserBalance();
    },
    newOrder: function (data) {
      this.$callNotification(
          this.$i18n.locale,
          {
            position: 'top right',
            type: 'info',
            title: `NEW ${data.type.toUpperCase()} ${data.isBuyerMaker ? 'BUY' : 'SELL'} ORDER`,
            message: `${data.amount} ${data.coin} > ${data.price} ${data.market}`
          }
      );
      this.updateUserBalance();
    },
    cancelOrder: function (data) {
      this.$callNotification(
          this.$i18n.locale,
          {
            position: 'top right',
            type: 'info',
            title: this.$t('cancelOrder'),
            message: `${data.amount} ${data.coin} > ${data.price} ${data.market}`
          }
      );
      this.updateUserBalance();
    },
    editOrder: function (data) {
      this.$callNotification(
          this.$i18n.locale,
          {
            position: 'top right',
            type: 'info',
            title: this.$t('editOrder'),
            message: `${data.amount} ${data.coin} > ${data.price} ${data.market}`
          }
      );
      this.updateUserBalance();
    }
  },
  async mounted() {
    await this.getPairInfo(this.$route.params.pair);
    this.websocketManager( `${document.domain}_trade`, 'enter');
    this.websocketManager( `${this.isActivated ? document.domain + "_" : ""}` + this.parsedCoinName + this.currentPair.market, 'enter');
    this.ro = new ResizeObserver(this.onResizeTradeChart)
    this.ro.observe(this.$refs.tradeChart);
    //this.updateTradingViewChart(this.currentPair.coin + this.currentPair.market);
  },
  beforeDestroy() {
    this.ro.unobserve(this.$refs.tradeChart)
    this.websocketManager( `${document.domain}_trade`, 'leave');
    this.websocketManager(`${this.isActivated ? document.domain + "_" : ""}` + this.parsedCoinName + this.currentPair.market, 'leave');
  },
}
</script>

<style lang="scss" scoped>

@media (max-width: 1030px) {
  .terminal .terminal-header {
    flex-direction: column;
  }

  .terminal .terminal-header .markets {
    width: 100% !important;
    max-width: 2000px !important;
    margin-bottom: 2px;
  }

  .terminal .terminal-header .pair-info {
    padding: 14px 0 14px 14px !important;
    flex-wrap: wrap;
  }

  .terminal .order-book, .terminal .trade-history {
    padding: 0 15px !important;
  }
  .left-side { padding-right: 15px !important; }

  .terminal .trade-history .content { max-height: 400px !important; }
}

.terminal {
  .plus { color: #01aa78 !important; }
  .minus { color: #e25050!important; }
  .info { color: #74a1d1 !important; }

  .terminal-header {
    margin: 0 !important;
    background: #131E2A;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .markets {
      position: relative;
      max-width: 360px;
      width: 100%;
      height: 63.7px;
      cursor: pointer;

      .showed-body {
        position: relative;
        padding: 14px 16px;
        display: flex;
        align-items: center;
        height: 100%;
      }

      .hidden-body {
        position: absolute;
        z-index: 9999;
        top: 100%;
        width: 100%;
        left: 0;
        padding: 16px 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        border-top: 2px solid #0d151d;
        background-color: #131e2a;
        -webkit-transition: .18s ease-out;
        -o-transition: .18s ease-out;
        transition: .18s ease-out;

        .search {
          position: relative;
          padding: 0 16px;

          .icon {
            position: absolute;
            top: 11px;
            left: 30px;
            color: #f7f9fa;
          }
          input {
            position: relative;
            width: 100%;
            display: block;
            margin-bottom: 16px;
            padding: 8px 12px 8px 36px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border: none;
            border-radius: 10px;
            background-color: #0d151d;
            color: #f7f9fa;
            font-weight: 500;
            line-height: 20px;
            outline: 0;
          }
        }

        span.enable-markets {
          margin-right: 16px;
          display: inline-block;
          padding: 0;
          border: none;
          border-radius: 0;
          background-color: transparent;
          color: #697d94;
          font-size: 14px;
          font-weight: 600;
          line-height: 25px;
          text-transform: uppercase;
          outline: 0;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          cursor: pointer;
          -webkit-transition: .1s ease-out;
          -o-transition: .1s ease-out;
          transition: .1s ease-out;
          margin-bottom: 5px;

          &.current {
            color: white;
            border-bottom: 2px solid white;
          }
        }
        .pairs {
          max-height: 240px;
          overflow-y: auto;
          margin-top: 15px;

          .pair {
            display: flex;
            margin-bottom: 10px;
            cursor: pointer;
            transition: .3s;
            padding: 0 16px;

            &:hover { background: #192b3e; }

            div.name { font-size: 12px; display: flex; align-items: center; width: 200px; }
            span {
              display: block;
              width: 80px;

              &.value {
                color: #f7f9fa;
                font-size: 14px;
                font-weight: 500;
              }
              &.description {
                color: #697d94;
                font-size: 11px;
                font-weight: 500;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }

    .pair-info {
      width: 100%;
      border-left: 2px solid #0D151D;
      padding: 14px 32px;
      display: flex;
      align-items: center;

      .info {
        margin-right: 38px;

        span {
          display: block;

          &.value {
            color: #f7f9fa;
            font-size: 14px;
            font-weight: 500;
          }
          &.description {
            color: #697d94;
            font-size: 11px;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .body {
    .left-side {
      padding-right: 0;

      .orders {
        height: 229px;

        div.header {
          width: 100%;
          padding: 12px 16px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;

          span.order-list-type {
            display: inline-block;
            padding: 0;
            border: none;
            border-radius: 0;
            background-color: transparent;
            color: #697d94;
            font-size: 14px;
            margin-right: 32px;
            outline: 0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            -webkit-transition: .1s ease-out;
            -o-transition: .1s ease-out;
            transition: .1s ease-out;

            &.current {
              color: #f7f9fa;
              -webkit-box-shadow: 0 4px 0 0 #131e2a, 0 6px 0 0 #7b93ae;
              box-shadow: 0 4px 0 0 #131e2a, 0 6px 0 0 #7b93ae;
            }
          }
        }

        div.body {
          position: relative;
          height: calc(100% - 40px);
          overflow-y: auto;
          padding: 0 16px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }
        table {
          width: 100%;
          overflow: auto hidden;
          min-width: 1000px;

          th {
            padding: 12px 0;
            color: #f7f9fa;
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            text-align: left;

            span.icon {
              border: 1px solid white;
              font-size: 10px;
              padding: 2px 5px;
              margin-right: 5px;
              width: 25px;
              display: inline-block;
              text-align: center;
              transition: .2s;
              cursor: pointer;

              &:hover {
                color: #2F4A68;
                border-color: #2F4A68;
              }
            }
          }
        }
      }
    }
    .right-side {
      padding: 0;
      background: #131E2A;

      .market-info {
        width: 100%;
        padding: 0;
        height: -webkit-calc(100% - 385px);
        height: calc(100% - 385px);
        min-height: 250px;
        border-top: 2px solid #0d151d;
        border-bottom: 2px solid #0d151d;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-flex-shrink: 0;
        flex-shrink: 0;

        .header {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding: 10px 16px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          span.title {
            display: inline-block;
            margin-right: 8px;
            color: #f7f9fa;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-transform: capitalize;
            -webkit-flex-shrink: 0;
            flex-shrink: 0;
          }
        }

        .order-book, .trade-history {
          padding: 0;
        }

        .columns {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          height: 36px;

          .column {
            padding: 12px 0;
            color: #697d94;
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            text-transform: uppercase;
            letter-spacing: .05em;
            width: 33%;
            text-align: right;

            &:last-child  { padding-right: 12px; }

            &:first-child  { padding-left: 12px; }
          }
        }
        .trade-history { border-left: 2px solid #0d151d; padding-right: 15px; }
        .trade-history .content { overflow-y: auto; max-height: 736px; }
        .content {
          .sell, .buy {
            overflow-y: hidden; max-height: 343px;
          }

          div.price {
            background: #0D151D;
            cursor: pointer;
            padding: 14px 12px 14px 16px;
            display: flex;
            align-items: center;

            .live-price {
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;

              .usd {
                margin-left: 5px;
                font-size: 12px;
                color: #697d94;
              }
            }

            .percent {
              margin-left: auto;
              font-size: 12px;
            }
          }

          .order {
            position: relative;
            display: flex;
            padding: 6px 0;
            align-items: center;

            .depth {
              position: absolute;
              width: 100%;
              height: 29.14px;

              &.plus { background: #01aa7829; }
              &.minus { background: #e250501a; }
            }
            span {
              display: block;
              font-size: 12px;
              width: 33%;
              text-align: right;

              &:last-child  { padding-right: 12px; }

              &:first-child  { padding-left: 12px; }
            }
          }
        }
      }

      .trade-window {
        width: 100%;
        background-color: #131e2a;
        padding: 14px 8px 38px 8px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        div.type {
          padding: 0 16px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: block;
          height: 30px;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;

          span.trading-type {
            float: left;
            border: none;
            border-radius: 0;
            background-color: transparent;
            color: #697d94;
            font-size: 14px;
            line-height: 30px;
            outline: 0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            -webkit-transition: .1s ease-out;
            -o-transition: .1s ease-out;
            position: relative;
            transition: 0.2s;
            padding-left: 10px;
            padding-right: 10px;
            margin-right: 24px;

            &.current {
              color: #f7f9fa;
              -webkit-box-shadow: 0 2px 0 0 #131e2a, 0 4px 0 0 #4785ff;
              box-shadow: 0 2px 0 0 #131e2a, 0 4px 0 0 #4785ff;
            }

            &.disabled {
              color: #424242;
            }
          }

          span.trading-fees {
            float: right;
            margin-right: 0 !important;
            font-size: 12px !important;
          }
        }

        div.process {


          div.buy, div.sell {
            padding-top: 10px;

            div.balance {
              height: 40px;
              display: flex;
              justify-content: space-between;

              span.coin {
                font-size: 14px;
                line-height: 40px;
              }
              span.value {
                font-size: 13px;
                line-height: 40px;
                color: #697D94;
                .icon {
                  margin-right: 5px;
                }
              }
            }
            div.inputs {
              div.price, div.amount {
                display: grid;
                grid-template-columns: 50px 2fr 0fr;
                border-bottom: 1px solid #333d48;
                font-size: 12px;
                color: #697D94;
                height: 40px;
                align-items: center;

                span.main {

                }
                input {
                  min-width: 20px;
                  background: none;
                  border: 0;
                  outline: 0 !important;
                  color: white;

                 &:disabled { color: #697D94; }
                }
                span.coin {}
              }
              div.slider {
                display: flex;
                justify-content: space-between;
                align-self: center;
                padding-top: 12px;
                padding-bottom: 12px;
                margin: 10px 0 30px 0;
                position: relative;

                div.dots {
                  position: absolute;
                  justify-content: space-between;
                  display: flex;
                  align-items: center;
                  width: calc(100% - 15px);
                  background: #1B2A3B;
                  height: 5px;
                  padding: 0 5px 0 0;

                  div.dot {
                    position: relative;

                    &:hover { p { background: white; } }
                    p {
                      cursor: pointer;
                      position: absolute;
                      top: -18px;
                      left: 5px;
                      width: 10px;
                      height: 10px;
                      border: 2px solid white;
                      transform: translateX(-50%) rotate(45deg);
                      transition: .3s;

                      span {
                        position: absolute;
                        font-size: 10px;
                        top: 7px;
                        left: 17px;
                        transform: translateX(-50%) rotate(-45deg) !important;
                      }
                    }
                  }
                }

                input {
                  position: relative;
                  -webkit-appearance: none;
                  padding: 0 0 0 5px;
                  appearance: none;
                  border-radius: 15px;
                  outline: none;
                  -webkit-transition: .2s;
                  transition: opacity .2s;
                  width: 100%;
                  z-index: 999;
                  margin-top: 5px;
                  background: transparent;
                  height: 0;
                  top: -1px;

                  &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    cursor: grab;
                    height: 15px;
                    width: 15px;
                    border: 2px solid white;
                    background: white;
                    transform: translateX(-50%) rotate(45deg);
                    border-radius: 1px;
                    left: 5px;
                  }

                  &:disabled {
                    color: rgb(197, 197, 197);
                    border-color: rgba(118, 118, 118, 0.3);
                  }
                }
                p.percent {
                  float: right;
                  width: 50px;
                  text-align: right;
                  font-size: 12px;
                  margin: 0;
                  padding: 0;
                  border: 0;
                  vertical-align: baseline;
                }
              }
            }
            div.fees {
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
              font-size: 13px;

              span.text { color: #697D94; }
              span.fee {}
            }
            div.button {

              button {
                margin-left: 25px;
                margin-right: 25px;
                width: calc(100% - 25px - 25px);
                height: 30px;
                margin-top: 20px;
                font-size: 13px;
                background-color: transparent;
                border: 0;
                border-radius: 4px;
                color: white;
                cursor: pointer;

                &.buy { background-color: #01AA78; }
                &.sell { background-color: #EB5058; }
                &:disabled {
                  background: #4e4e4e !important;
                  color: #7d7d7d !important;
                  cursor: not-allowed !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

#priceInput, #amountInput {
  display: block;
  width: 100%;
  color: white !important;
  border: 1px solid #2e4b66;
  padding: 5px;
  background: transparent !important;
  outline: none;
  margin: 10px 0;

  &:focus {
    border-color: #3f6b91;
  }
}

#editButton {
  margin-left: 25px;
  margin-right: 25px;
  width: calc(100% - 25px - 25px);
  height: 30px;
  margin-top: 20px;
  font-size: 13px;
  background-color: #3F6B91;
  border: 0;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

</style>