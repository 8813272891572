import axios from "@/plugins/axios";

const state = {
  isActive: false,
  isAuthed: false,
  isDarkMode: localStorage.getItem('dark') === 'true',
  userInfo: null,
  isFetched: { isDone: false, isError: null },
  isLiveSupportEnabled: false,
  isLiveSupportOpened: false,
  logo: "/favicon_default.ico",
  customPageSettings: {
    isActive: false,
    defaultNavbar: true,
    defaultFooter: true,
  },
  accessDenied: {
    isActive: false
  },
  isNotificationWindowVisible: false,
  exchangeInfo: { isAdvancedExchange: true, pixelId: null, },
  coinList: null,
  websiteName: {
    short: document.domain.includes("www.")
      ? document.domain.replace("www.", "").charAt(0).toUpperCase() + document.domain.replace("www.", "").substring(0, document.domain.replace("www.", "").indexOf(".") > 0 ? document.domain.replace("www.", "").indexOf(".") : document.domain.replace("www.", "").length).slice(1)
      : document.domain.charAt(0).toUpperCase() + document.domain.substring(0, document.domain.indexOf(".") > 0 ? document.domain.indexOf(".") : document.domain.length).slice(1),
    full: document.domain.includes("www.") ? document.domain.replace("www.", "") : document.domain
  },
  isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth <= 992
}

const getters = {
}

const mutations = {
  SET_CUSTOM_PAGE_STATUS(state, data) {
    state.customPageSettings = data;
  },
  SET_LOGO(state, data) {
    state.logo = data;
    
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = state.logo;
  },
  SET_MOBILE_STATUS(state, data) {
    state.isMobile = data;
  },
  SET_DARKMODE_STATUS(state, data) {
    state.isDarkMode = data;
  },
  hideNotificationWindow(state) {
    state.isNotificationWindowVisible = false;
    state.userInfo.notifications = []
  },
  SOCKET_MUTATION_newNotification(state, data) {
    state.userInfo.notifications.push(data);
    state.isNotificationWindowVisible = true;
  },
  SET_FETCH_INFO(state, data) {
    if (data.isBackOfficePage) return state.isFetched = { isDone: true, isError: false };
    if (!data.success) return state.isFetched = { isDone: true, isError: true };
    if (data.data.error) return state.accessDenied = Object.assign(data.data, { isActive: true });
    if (data.data.accessDenied?.isActive) return state.accessDenied = data.data.accessDenied;
    state.userInfo = data.data.userInfo;
    if (data.data.userInfo) {
      state.isAuthed = true;
      state.isLiveSupportEnabled = state.userInfo.isLiveSupportEnabled;
      if (state.userInfo.notifications?.length !== 0) state.isNotificationWindowVisible = true;
    }
    state.exchangeInfo = data.data.exchangeInfo;
    state.coinList = data.data.coinList;
    state.isFetched.isDone = true;
  },
  SET_STORE_STATUS(state, data) {
    state.isActive = data;
  },
  SET_USER_INFO(state, data) {
    for (const field of Object.keys(data.data)) {
      state.userInfo[field] = data.data[field];
    }
  },
  STATUS_LIVE_SUPPORT(state, status) {
    state.isLiveSupportEnabled = status;
  },
  OPEN_LIVE_SUPPORT(state, status) {
    state.isLiveSupportOpened = status;
  }
}

const actions = {
  customPage({ commit }, status) {
    commit('SET_CUSTOM_PAGE_STATUS', status);
  },
  checkMobile({ commit, state }, width) {
    const status = width <= 992;
    if (state.isMobile !== status) commit('SET_MOBILE_STATUS', status);
  },
  setStoreStatus({ commit }, status) {
    commit('SET_STORE_STATUS', status);
  },
  setDarkModeStatus({ commit }, status) {
    commit('SET_DARKMODE_STATUS', status);
  },
  statusLiveSupport({ commit }, status) {
    commit('STATUS_LIVE_SUPPORT', status);
  },
  openLiveSupport({ commit }, status) {
  commit('OPEN_LIVE_SUPPORT', status);
},
  setNotificationWindowStatus({ commit }) {
    commit('hideNotificationWindow');
  },
  async fetchExchangeInfo({ commit }, isBackOfficePage) {
    try {
      if (isBackOfficePage) return commit('SET_FETCH_INFO', { isBackOfficePage } );
      const fetchedData = await axios.get('main/exchange/fetchInfo');
      commit('SET_FETCH_INFO', { success: true, data: fetchedData.data, status: fetchedData.status });
      if (!localStorage.getItem('dark')) commit('SET_DARKMODE_STATUS', fetchedData.data.exchangeInfo.isDarkThemeDefault);
      commit('SET_LOGO', fetchedData.data.exchangeInfo.favicon === 'default' || !fetchedData.data.exchangeInfo.favicon ? '/favicon_default.ico' : fetchedData.data.exchangeInfo.favicon);
    } catch (error) {
      console.log(error)
      commit('SET_FETCH_INFO', { success: false });
    }
  },
  async fetchUserInfo({ commit }) {
    try {
      const fetchedData = await axios.get('main/user/fetchInfo');
      commit('SET_USER_INFO', { success: true, data: fetchedData.data.userInfo });
    } catch (error) {
      console.log(error)
    }
  },
  async authentication({ commit }, data) {
    try {
      const responseData = await axios.post('main/user/authentication', data);
      return responseData;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async registration({ commit }, data) {
    try {
      const responseData = await axios.post('main/user/registration', data);
      return responseData;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async SOCKET_ACTION_reloadPage() {
    window.location.reload();
  },
  async SOCKET_ACTION_nextPage({ commit }, data) {
    const lang = localStorage.getItem("locale") || "en";
    window.location.href = '/' + lang + data.page;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}