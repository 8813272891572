<template>
  <Page
      :title="'Домен ' + domainInfo.domain"
      parse-url="/backoffice/user/domainInfo"
      :post-data="{ domainId: $route.params.domainId }"
      @loaded-data="dataLoading($event)"
  >
    <div v-if="isLoaded">

      <div class="container">
        <div class="Card">
          <div class="Card-content">
            <div class="Card-header p-0 pl-4 pr-4 mb-4">
              <div style="flex-wrap: wrap;" class="d-flex">
                <span @click="domainMenu.currentTab = tab" class="tabs-link" :class="{ active: tab === domainMenu.currentTab }" v-for="tab, index in userTabs()" :key="'tab' + index">{{ tab }}</span>
              </div>
            </div>

            <DomainGeneral
                v-show="domainMenu.currentTab === 'Общие'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
                :coin-list="coinList"
            />

            <DomainPages
                v-show="domainMenu.currentTab === 'Страницы'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
            />

            <DomainDNS
                v-if="domainMenu.currentTab === 'DNS и почтовые данные'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
            />

            <DomainStaking
                v-if="domainMenu.currentTab === 'Стейкинг'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
                :coin-list="coinList"
            />

            <DomainTrading
                v-if="domainMenu.currentTab === 'Трейдинг'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
                :coin-list="coinList"
                :market-list="marketList"
                :coin-pair="coinPair"
            />

            <DomainCustomPages
                v-if="domainMenu.currentTab === 'Кастомные страницы' && superUser"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
                :editable-pages="editablePages"
                :coin-list="coinList"
            />

            <DomainPromocodes
                v-if="domainMenu.currentTab === 'Промокоды'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
            />

            <DomainCryptoProviders
                v-if="domainMenu.currentTab === 'Платежные системы'"
                :domain-id="$route.params.domainId"
                :domain-info="domainInfo"
            />

          </div>
        </div>
      </div>

    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import DomainGeneral from "@/views/BackOffice/User/Settings/Domain/DomainGeneral";
import DomainPages from "@/views/BackOffice/User/Settings/Domain/DomainPages";
import DomainCustomPages from "@/views/BackOffice/User/Settings/Domain/DomainCustomPages";
import DomainDNS from "@/views/BackOffice/User/Settings/Domain/DomainDNS";
import DomainStaking from "@/views/BackOffice/User/Settings/Domain/DomainStaking";
import DomainTrading from "@/views/BackOffice/User/Settings/Domain/DomainTrading";
import DomainPromocodes from "@/views/BackOffice/User/Settings/Domain/DomainPromocodes";
import DomainCryptoProviders from "@/views/BackOffice/User/Settings/Domain/DomainCryptoProviders.vue";

export default {
  name: "Domain",
  components: {
    DomainCryptoProviders,
    Page,
    DomainGeneral,
    DomainPages,
    DomainCustomPages,
    DomainDNS,
    DomainStaking,
    DomainTrading,
    DomainPromocodes
  },
  data() {
    return {
      domainMenu: {
        currentTab: 'Общие',
      },
      domainInfo: {},
      coinList: [],
      isLoaded: false,
      superUser: false,
      editablePages: {},
      marketList: [],
      coinPair: [],
    }
  },
  methods: {
    userTabs() {
      const tabsList = [
        'Общие',
        'Страницы',
        'DNS и почтовые данные',
        'Стейкинг',
        'Трейдинг',
        'Промокоды',
        'Платежные системы',
      ];
      if (this.superUser) tabsList.push('Кастомные страницы');

      return tabsList;
    },
    dataLoading(data) {
      this.domainInfo = data.domainInfo;
      this.coinList = data.coinList;
      this.marketList = data.marketList;
      this.coinPair = data.coinPair;
      this.superUser = data.superUser;
      this.editablePages = data.editablePages;
      this.isLoaded = true;
    }
  },
}
</script>

<style lang="scss" scoped>

span.tabs-link {
  display: block;
  padding: 16px 16px;
  position: relative;
  color: var(--vz-link-color);
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;

  &.active {
    color: #405189;

    &::after {
      content: "";
      background: #405189;
      height: 1px;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      -webkit-transition: all 250ms ease 0s;
      transition: all 250ms ease 0s;
      -webkit-transform: scale(0);
      transform: scale(1);
    }
  }
}

@media (min-width: 992px) {
  .container {
    width: calc(970px - 250px);
  }
}

@media (min-width: 768px){
  .container {
    width: 750px;
  }
}
@media (min-width: 1200px){
  .container {
    width: calc(1170px - 250px);
  }
}

</style>