<template>
  <Page
      title="Обновления"
      parse-url="/backoffice/user/getUpdates"
      @loaded-data="dataLoading($event)"
  >
    <div class="Card col-lg-12 mb-5" v-for="update, index in fetchedData.updates" :key="'update' + index">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Обновление от {{ update.date | toDate('noTime') }}</h4>
        </div>
        <div class="p-4" v-html="update.text"></div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";

export default {
  name: "Main",
  components: {
    Page
  },
  data() {
    return {
      fetchedData: {
        updates: []
      },
      logsTableData: [],
      logsTableColumns: [
        {
          name: 'username',
          title: 'Юзер',
          isSortable: true
        },
        {
          name: 'action',
          title: 'Действие',
          isSortable: false
        },
        {
          name: 'ip',
          title: 'Айпи',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
      ],
    }
  },
  methods: {
    dataLoading(data) {
      this.fetchedData = data;
    }
  },
}
</script>

<style scoped>
.hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
</style>