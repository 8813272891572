<template>
  <div @click="switchHandler" class="switch-input">
    <input type="checkbox" v-model="switchModel">
    <span class="slider round"></span>
  </div>
</template>

<script>
export default {
  name: "SwitchButton",
  props: {
    isChecked: Boolean
  },
  data() {
    return {
      switchModel: this.isChecked
    }
  },
  methods: {
    switchHandler() {
      this.switchModel = !this.switchModel;
      this.$emit('switch-change', this.switchModel);
    }
  }
}
</script>

<style scoped>
.switch-input {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 12px;
}

.switch-input input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0ab35c;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0ab35c;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>
