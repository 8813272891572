<template>
  <div :class="{ loading: !isFetched.isDone, dark: $store.state.Index.isDarkMode }" id="app">
    <AccessDenied v-if="accessDenied.isActive" />
    <div v-else>
      <Loader v-if="loaderDisplay" />
      <div v-else>
        <NotificationWindow
            v-if="isActive && isAuthed && isNotificationWindowVisible"
        />
        <LiveSupport
            v-if="isActive && isAuthed"
        />
        <vue-extend-layouts />
      </div>
    </div>
    <div ref="pixelHtmlBlock">

    </div>
    <noscript>
      <img height="1" width="1" style="display:none" ref="noScriptPixelBlock" />
    </noscript>
  </div>
</template>

<script>
import AccessDenied from './components/main/AccessDenied';
import Loader from './components/main/Loader';
import NotificationWindow from './components/main/NotificationWindow';
import { mapState } from 'vuex';
import '/public/bootstrap.css';
import VueExtendLayouts from "vue-extend-layout"
import '/public/fa/css/all.min.css';
import '/public/fa/js/all.min.js';
import LiveSupport from "@/components/main/LiveSupport";


export default {
  name: 'App',
  components: {
    LiveSupport,
    AccessDenied,
    Loader,
    VueExtendLayouts,
    NotificationWindow
  },
  data() {
    return {
      mode: 'dark'
    }
  },
  methods: {
    onResize() {
      this.$store.dispatch('Index/checkMobile', window.innerWidth)
    },
    setInnerHTML(elm, html) {
      elm.innerHTML = html;

      Array.from(elm.querySelectorAll("script"))
          .forEach( oldScriptEl => {
            const newScriptEl = document.createElement("script");

            Array.from(oldScriptEl.attributes).forEach( attr => {
              newScriptEl.setAttribute(attr.name, attr.value)
            });

            const scriptText = document.createTextNode(oldScriptEl.innerHTML);
            newScriptEl.appendChild(scriptText);

            oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
          });
    }
  },
  computed: {
    ...mapState('Index', [
        'isFetched',
        'isActive',
        'isAuthed',
        'accessDenied',
        'userInfo',
        'websiteName',
        'isLiveSupportEnabled',
        'isNotificationWindowVisible',
        'isMobile'
    ]),
    loaderDisplay() {
      return  !this.isFetched.isDone && this.isFetched.isError === null && this.isActive ||
              !this.$store.state.Backoffice.isFetched.isDone && this.$store.state.Backoffice.isFetched.isError === null && this.$store.state.Backoffice.isActive ||
              this.isFetched.isError ||
              this.$store.state.Backoffice.isFetched.isError
    }
  },
  async mounted() {
    window.addEventListener('resize', this.onResize);
    while (!this.$store.state.Index.isFetched.isDone) {
      await new Promise(resolve => setTimeout(resolve, 500));
    }
    if (this.$store.state.Index.exchangeInfo.pixelId) {
      const pixelScript = `<script>`+
          ` !function(f,b,e,v,n,t,s)`+
          `    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?`+
          `  n.callMethod.apply(n,arguments):n.queue.push(arguments)};`+
          `   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';`+
          `   n.queue=[];t=b.createElement(e);t.async=!0;`+
          `    t.src=v;s=b.getElementsByTagName(e)[0];`+
          `   s.parentNode.insertBefore(t,s)}(window, document,'script',`+
          `   'https://connect.facebook.net/en_US/fbevents.js');`+
          ` fbq('init', ${this.$store.state.Index.exchangeInfo.pixelId});`+
          ` fbq('track', 'PageView');`+
          "</scr" + "ipt>";

      const pixelHtmlBlock = this.$refs.pixelHtmlBlock;
      const noScriptPixelBlock = this.$refs.noScriptPixelBlock;
      noScriptPixelBlock.src = `https://www.facebook.com/tr?id=${this.$store.state.Index.exchangeInfo.pixelId}&ev=PageView&noscript=1`;
      this.setInnerHTML(pixelHtmlBlock, pixelScript);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;400&display=swap');

html:has(#app.dark) { background: #18191a; }

.dark {
  background: #18191a;
  color: white;

  ::-webkit-scrollbar {
    background-color: #232627;
    color: #a19a90;
  }

  ::-webkit-scrollbar-corner {
    background-color: #1c1e1f;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #45494c;
  }

  a {
    color: rgb(216, 214, 211);

    &:hover {
      color: rgb(216, 214, 211) !important;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  background: white;
  font-size: 14px;
  box-sizing: border-box;
}

a:visited { text-decoration: none; }
a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #2A85FF !important;
}

body, input, textarea, button { font-family: 'Manrope', sans-serif !important; }

#app.loading {
  overflow-y: hidden;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}
</style>
