<i18n>
{
  "en": {
    "title": "Security",
    "loginHistory": "Login History",
    "lastLogin": "Last login: {0}",
    "email": "Email",
    "password": "Password",
    "lastPassword": "Updated: {0}",
    "google": "Google Authentication",
    "walletconnect": "Wallet Connect",
    "set": "Set",
    "notset": "Not set"
  },
  "zh": {
    "title": "安全",
    "loginHistory": "登錄歷史",
    "lastLogin": "上次登錄：{0}",
    "email": "電子郵件",
    "password": "密碼",
    "lastPassword": "更新：{0}",
    "google": "谷歌認證",
    "walletconnect": "錢包連接",
    "set": "设置",
    "notset": "未設置"
  },
  "kr": {
    "title": "보안",
    "loginHistory": "로그인 기록",
    "lastLogin": "마지막 로그인: {0}",
    "email": "이메일",
    "password": "비밀번호",
    "lastPassword": "업데이트됨: {0}",
    "google": "구글 인증",
    "walletconnect": "지갑 연결",
    "set": "세트",
    "notset": "설정되지 않음"
  }
}
</i18n>
<template>
  <Page style="padding: 0 !important;" :is-page-loaded="dataLoadingStatus">
    <PageContainer :no-line="true" v-if="dataLoadingStatus.isDone">
      <div class="sc-menu" :class="{ mobile: $store.state.Index.isMobile }">
        <Menu
            :links="links"
            :title="$t('title')"
            class="col-lg-4 col-md-4 col-xs-12"
        />
        <div style="margin-top: 76px;" class="col-lg-8 col-md-8 col-xs-12">
          <router-view></router-view>
        </div>
      </div>
    </PageContainer>
  </Page>
</template>

<script>
import Page from "@/components/main/UI/Page";
import Menu from "@/views/Dashboard/Settings/Menu";
import PageContainer from "@/components/main/UI/PageContainer";

export default {
  name: "securityLinksTemplate",
  components: {
    Page,
    Menu,
    PageContainer
  },
  data() {
    return {
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      links: [
        {
          name: this.$t('loginHistory'),
          link: "dashboard/settings/security/login-history",
          info: this.$t('lastLogin', [this.$options.filters.toDate(this.$store.state.Index.userInfo.settings.lastAuthDate)]),
          icon: ['fal', 'user']
        },
        {
          name: this.$t('email'),
          link: "dashboard/settings/security/email",
          info: this.$store.state.Index.userInfo.email,
          icon: ['fal', 'envelope']
        },
        {
          name: this.$t('password'),
          link: "dashboard/settings/security/password",
          info: this.$t('lastPassword', [this.$options.filters.toDate(this.$store.state.Index.userInfo.settings.lastPasswordChangeDate)]),
          icon: ['fal', 'key']
        },
        {
          name: this.$t('google'),
          link: "dashboard/settings/security/google-authentication",
          info: this.$store.state.Index.userInfo.settings.twoFactorAuthorization.isActive ? this.$t('set') : this.$t('notset'),
          icon: ['fab', 'google'],
          style: this.$store.state.Index.userInfo.settings.twoFactorAuthorization.isActive ? 'color: #01aa78 !important;' : 'color: #e25050 !important;'
        },
        {
          name: this.$t('walletconnect'),
          link: "dashboard/settings/security/wallet-connect",
          icon: ['fal', 'wallet']
        },
      ]
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('Index/fetchUserInfo');
      if (this.$store.state.Index.userInfo.settings.isWalletConnectEnabled === false) this.links.splice(4, 1);
      this.dataLoadingStatus.isDone = true;
    } catch (e) {
      this.dataLoadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>

.dark {
  .sc-menu {
    background-color: #18191A;
  }
}

.sc-menu {
  padding: 40px 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: calc(100vh - 60px);
  background-color: #fff;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out;
  transition: .25s ease-out;
}

.sc-menu.mobile {
  padding: 0 !important;

  a {
    margin: 0 !important;
  }
}

</style>