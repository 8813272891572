<i18n>
{
  "en": {
    "submit": "Submit"
  },
  "zh": {
    "submit": "提交"
  },
  "kr": {
    "submit": "제출하다"
  }
}
</i18n>
<template>
  <button
      class="Button"
      :class="{[customType]: customType, default: !customType, loading: isLoading, dark: $store.state.Index.isDarkMode}"
      :disabled="isDisabled || isLoading"
      :style="{ width: customWidth || 'auto' }"
      @click="processClick"
  >
      {{ $slots.default ? $slots.default[0].text : $t('submit') }}
      <svg class="spinner" viewBox="0 0 50 50" v-show="isLoading">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    isDisabled: Boolean,
    isAsync: Boolean,
    customWidth: String,
    customType: String,
    callback: Function,
    answerDelay: Number
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    async processClick() {
      if (!this.isAsync) return this.callback();
      this.isLoading = true;
      await new Promise(resolve => setTimeout(resolve, this.answerDelay));
      await this.callback();
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>

.Button.dark {
  background-image: initial;
  color: rgb(216, 214, 211);
  border-color: transparent;
}

.Button {
  display: flex;
  position: relative;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  border: 2px solid transparent;
  text-transform: capitalize;
  font-size: 14px;
  height: 40px;
  transition: all .3s ease;
  cursor: pointer;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.Button:focus {
  outline: none;
}

.Button:disabled {
  cursor: not-allowed;
}

.Button:disabled:hover {
  background: #B7B6BC;
}

.error {
  background: #dc3545;
}

.error:hover {
  background: #c82333;
}

.success {
  background: #28a745;
}

.success:hover {
  background: #218838;
}

.default {
  background: #2A85FF;
}

.default:hover {
  background: #0069d9;
}

.primary {
  background: #007bff;
}

.primary:hover {
  background: #0069d9;
}

.loading {
  color: transparent !important;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  left: 47.5%;
  height: 15px;
}

.spinner .path {
  stroke: #fff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>