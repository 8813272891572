<i18n>
{
  "en": {
    "signIn": "Sign In",
    "attentionMessage": "Please, check that you are visiting the correct URL:",
    "email": "Email",
    "password": "Password",
    "forgotPassword": "Forgot password?",
    "createAccount": "Create a free account",
    "emailInputError": "Please enter a correct email address",
    "passwordInputError": "Please enter a correct password"
  },
  "zh": {
    "signIn": "登录",
    "attentionMessage": "请检查您是否在访问正确的URL",
    "email": "电子邮件",
    "password": "密码",
    "forgotPassword": "忘记密码？",
    "createAccount": "创建一个免费账户",
    "emailInputError": "请输入正确的电子邮件地址",
    "passwordInputError": "请输入正确的密码"
  },
  "kr": {
    "signIn": "로그인",
    "attentionMessage": "올바른 URL을 방문하고 있는지 확인하십시오.",
    "email": "이메일",
    "password": "암호",
    "forgotPassword": "비밀번호를 잊으 셨나요?",
    "createAccount": "무료 계정을 생성하십시오",
    "emailInputError": "정확한 이메일 주소를 입력하십시오",
    "passwordInputError": "정확한 비밀번호를 입력하세요"
  }
}
</i18n>

<template>
  <div class="Auth" :class="{ dark: $store.state.Index.isDarkMode }">
    <div>
      <div class="header">
        <p class="title">{{ $t('signIn') }}</p>
        <p class="attention">{{ $t('attentionMessage') }}</p>
        <div class="url">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path d="M16.27 10.5V8.07C16.27 5.82 14.45 4 12.2 4S8.13 5.82 8.13 8.07v2.43H6v8.94h12.43V10.5h-2.16zm-3.07 6.46h-2v-4h2v4zm1.07-6.46h-4.14V8.07c0-1.14.93-2.07 2.07-2.07 1.14 0 2.07.93 2.07 2.07v2.43z">
            </path>
          </svg>
          <div>
            <span>https://</span><span class="urla">{{ websiteName.full }}</span>
          </div>
        </div>
      </div>

      <form>

        <InputBox
            v-for="control, index in formControls"
            :key="index"
            :type="control.type"
            :label="$t(control.label)"
            :validation="control.validation"
            v-model="control.value"
            @check-valid="control.isValid = $event"
            :error-message="$t(control.errorMessage)"
        />
        <Captcha
            @handle-data="captchaInput = $event"
            :label-style="`color: ${$store.state.Index.isDarkMode ? '#c5c1bc' : 'black'}`"
            :input-style="`color: ${$store.state.Index.isDarkMode ? '#c5c1bc' : 'black'}, background: ${$store.state.Index.isDarkMode ? '1px solid #42474a' : '1px solid black'}`"
            ref="captchaComponent"
        />
        <div style="margin-bottom: 20px;">
          <Button
              custom-width="100%"
              :is-async="true"
              :callback="loginHandler"
              :is-disabled="!$isFormValid(formControls)"
          >
            {{ $t('signIn') }}
          </Button>
        </div>
      <div class="links">
        <router-link style="color: #2A85FF;" :to="$linkCreator($i18n.locale, 'reset-password')">{{ $t('forgotPassword') }}</router-link>
        <router-link style="color: #2A85FF;" :to="$linkCreator($i18n.locale, 'sign-up')">{{ $t('createAccount') }}</router-link>
      </div>
    </form>
  </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import InputBox from '../components/main/UI/InputBox';
import Button from '../components/main/UI/Button';
import TwoFactorModal from "@/mixins/two-factor-modal";
import axios from "@/plugins/axios";
import Captcha from "@/components/main/UI/Captcha";

export default {
  name: "Auth",
  components: {
    InputBox,
    Button,
    Captcha
  },
  mixins: [TwoFactorModal],
  data() {
    return {
      isConfirmationCodeRequired: false,
      captchaInput: '',
      formControls: {
        emailInput: {
          value: '',
          type: 'email',
          label: 'email',
          errorMessage: 'emailInputError',
          isValid: false,
          validation: {
            required: true,
            email: true
          }
        },
        passwordInput: {
          value: '',
          type: 'password',
          label: 'password',
          errorMessage: 'passwordInputError',
          isValid: false,
          validation: {
            required: true,
            minLength: 6
          }
        }
      }
    }
  },
  methods: {
    async loginHandler(twoFactorCode) {
      try {
        const response = await axios.post('main/user/authentication', {
          email: this.formControls.emailInput.value,
          password: this.formControls.passwordInput.value,
          twoFactorCode,
          captcha: this.captchaInput
        });
        this.$callNotification(
            this.$i18n.locale,
            response
        );
        response.data.status = response.status;
        if (response.status === 207) return this.isConfirmationCodeRequired = true;
        if (response.status === 206) return this.openTwoFactorConfirmation(this.loginHandler);
        await this.$refs.captchaComponent.loadCaptcha();
        if (!response.data?.error) return location.href = `/${this.$i18n.locale}/dashboard`;
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    }
  },
  computed: {
    ...mapState('Index', [
        'websiteName'
    ])
  }
}
</script>

<style lang="scss" scoped>
.urla {
  color: black;
}

.Auth.dark {
  > div {
    background-color: rgb(29, 31, 32);
    box-shadow: rgba(6, 6, 6, 0.08) 0px 2px 4px, rgba(6, 6, 6, 0.08) 0px 0px 2px;
  }
  .title {
    color: rgb(197, 193, 188);
  }
  .attention {
    color: rgb(144, 137, 125);
  }
  .url {
    border-color: rgb(57, 61, 64);
  }
  .urla {
    color: rgb(197, 193, 188);
  }
}

.Auth {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  width: 100%;
  color: rgb(30, 32, 38);
  flex-direction: column;
}

.Auth .header {
  box-sizing: border-box;
  min-width: 0;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #1E2026;
  margin-bottom: 12px;
}

.Auth > div {
  box-sizing: border-box;
  min-width: 0px;
  border-radius: 10px;
  background-color: #fdfdfd;
  position: relative;
  width: 384px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.08) 0px 0px 2px;
  padding: 24px 24px 32px;
  margin: 40px auto 24px;
}

.title {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #1E2026;
  margin-bottom: 12px;
}

.attention {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76808F;
  margin-bottom: 12px;
}

.url {
  box-sizing: border-box;
  min-width: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #e2e0e0;
  border-radius: 10px;
  display: inline-flex;
  margin: 0 auto 8px;
  width: auto;
  padding: 8px 24px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.url svg {
  box-sizing: border-box;
  min-width: 0;
  color: #02C076;
  font-size: 18px;
  fill: #02C076;
  width: 1em;
  height: 1em;
  margin-right: 4px;
}

.url span {
  box-sizing: border-box;
  min-width: 0;
  color: #02C076;
  margin-right: 0;
}

.links {
  display: flex;
  justify-content: space-between;
}

.links a {
  text-decoration: none;
  font-size: 12px;
}

.links a:hover {
  text-decoration: underline;
  color: #2A85FF;
}

.hidden {
  display: none;
}
</style>