<template>
  <span>{{ percent.toFixed(2) }}%</span>
</template>

<script>
import * as workerTimers from 'worker-timers';

export default {
  name: "countdown",
  props: {
    startDate: Number,
    endDate: Number
  },
  data() {
    return {
      percent: 0,
      timer: null
    }
  },
  methods: {
    countDownTimer() {
      this.timer = workerTimers.setInterval(() => {
        if (this.percent >= 100) return workerTimers.clearInterval(this.timer);
        this.percent = 100 - (this.endDate - Date.now()) / (this.endDate - this.startDate) * 100;
      }, 1000);
    }
  },
  mounted() {
    this.percent = 100 - (this.endDate - Date.now()) / (this.endDate - this.startDate) * 100;
    this.countDownTimer()
  },
  destroyed() {
    workerTimers.clearInterval(this.timer);
  }
}
</script>

<style scoped>

</style>
