<template>
  <div>
    <div @dragenter.prevent="mouseMove(true)" @dragleave.prevent="mouseMove(false)" @dragover.prevent="mouseMove(true)" @drop.prevent="dragFile" class="drop-zone" :style="{ 'background-color': mouseEntered ? '#edf0f5' : 'transparent' }">
      <div class="upload">
        Upload
        <input type="file" accept=".jpg,.jpeg,.png" id="file-input" multiple @change="uploadFile"/>
      </div>
      <div class="drag">
        <span class="drag-text">Drag & Drop Files</span>

      </div>
    </div>
    <div class="loaded-files" v-if="File.length">
      <div class="preview" v-for="file, index in filesUrl" :key="index">
        <div>
          <img :src="file.link" alt="">
        </div>
        <span>{{ file.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropZone.vue",
  data() {
    return {
      File: [],
      mouseEntered: false,
      filesUrl: []
    }
  },
  methods: {
    mouseMove(state) {
      this.mouseEntered = state;
    },
    async uploadFile(e) {
      this.filesUrl = [];
      this.File = e.target.files;
      for (const file of this.File) {
        this.filesUrl.push({
          link: await this.readURL(file),
          name: file.name
        })
      }
      this.$emit('handle-data', this.File);
    },
    async dragFile(e) {
      this.filesUrl = [];
      this.File = e.dataTransfer.files;
      for (const file of this.File) {
        this.filesUrl.push({
          link: await this.readURL(file),
          name: file.name
        })
      }
      this.$emit('handle-data', this.File);
    },
    readURL(file) {
      return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = e => res(e.target.result);
        reader.onerror = e => rej(e);
        reader.readAsDataURL(file);
      });
    }
  }
}
</script>

<style scoped>

div.upload {
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #2A85FF;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  transition: .1s;
  font-weight: 500;
  border-radius: 6px;
  padding: 12px 20px;
  font-size: 14px;
  width: 130px!important;
  height: 40px!important;
  margin-right: 20px;
  color: #fff;
}

div.drop-zone {
  width: 100%;
  display: flex;
  border: 1px solid #2A85FF;
  padding: 16px;
  z-index: 999;
  transition: .3s;
}

div.drag {
  color: black;
}

span.drag-text {
  display: block;
  font-size: 17px;
  color: #c9cbd0;
}

#file-input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  width: 100%;
  height: 100%;
  left: 0px;
  z-index: 100;
  opacity: 0;

}

.loaded-files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.loaded-files .preview {
  margin: 0 15px 15px 0;
  width: 25%;
  text-align: left;
  box-sizing: border-box;
  font-size: 12px;
  word-wrap: break-word;
}

.loaded-files .preview img {
  width: 100%;
  height: auto;
}

</style>