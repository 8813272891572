<template>
  <Page
      :no-header="true"
      parse-url="/backoffice/admin/liveSupport"
      :container-style="{ padding: '0 !important' }"
      @loaded-data="dataLoading($event)"
      style="padding: 74px 4px 4px 4px;"
  >
    <div style="position: relative; overflow: hidden;" class="d-flex">
      <div class="chat-leftside">
        <div class="chat-header pr-4 pl-4 pt-4 mb-4">
          <div style="position: relative;" class="p-3">
            <h3 class="m-0">Лайв саппорт</h3>
            <InputBox
                placeholder="Поиск юзера"
                class="mb-3"
                v-model.trim="searchUser"
            />
            <transition name="fade">
              <div style="position: relative; z-index: 9999;" v-show="searchUser">
                <div class="search-result">
                  <div class="no-users" v-if="searchResult.length === 0">Юзеры не найдены</div>
                  <div v-else>
                  <span class="choose-user" @click="openChat(user._id)" v-for="user, index in searchResult" :key="'search result' + index">
                    {{ user.email }}
                  </span>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="chat-body">
          <div class="d-flex align-items-center">
            <p class="text-muted pr-0 mr-3" style="padding: 0 25px;">Последние сообщения</p>
            <font-awesome-icon
                class="mr-2 update-icon"
                style="font-size: 11px; cursor: pointer"
                :class="{ rotating: isChatsLoading }"
                :icon="['fas', 'sync']"
                @click="updateChats()"
            />
          </div>
          <div style="overflow-y: auto;  background-color: #212529; max-height: calc(100vh - 48px - 116px - 70px);">
            <div @click="openChat(chat.userId)" class="chat" :class="{ current: currentUserId === chat.userId }" v-for="chat, index in fetchedData.chats" :key="'chat' + index">
              <span style="text-overflow: ellipsis; max-width: 190px; overflow: hidden; white-space: nowrap;">{{ chat.email }}</span>
              <span class="text-muted" style="font-size: 11px;">{{ chat.date | toDate('recent') }}</span>
              <span style="border-radius: 100%; position: absolute; left: 0;" class="ml-3 p-1" :class="chat.isRead ? 'bg-success' : 'bg-warning'"></span>
            </div>
            <Button
                class="w-100 primary mt-2"
                :callback="loadChats"
            >
              Загрузить еще чаты
            </Button>
          </div>
        </div>
      </div>
      <div class="chat-rightside" :class="{ show: currentUserId && $store.state.Index.isMobile }">
        <span
            class="back-btn"
            :class="{ show: currentUserId && $store.state.Index.isMobile }"
            @click="currentUserId = null"
        >
          <font-awesome-icon
              :icon="['fas', 'angle-left']"
          />
        </span>
        <Chat
            :user-id="currentUserId"
            @update-chats="updateChats()"
        />
      </div>
    </div>
  </Page>
</template>

<script>
import Chat from "@/views/BackOffice/Admin/LiveSupport/Chat";
import Page from "@/components/backoffice/Page";
import InputBox from "@/components/backoffice/UI/InputBox";
import axios from "@/plugins/axios";
import Button from "@/components/backoffice/UI/Button.vue";

export default {
  name: "Main",
  components: {
    Page,
    InputBox,
    Chat,
    Button
  },
  data() {
    return {
      fetchedData: {
        chats: []
      },
      currentOffset: 0,
      isChatsLoading: false,
      searchUser: '',
      searchResult: [],
      currentUserId: null
    }
  },
  watch: {
    searchUser(newSearch) {
      if (newSearch === '') return;
      this.searchUserHandler();
    }
  },
  methods: {
    async searchUserHandler() {
      try {
        const fetchedData = await axios.post("/backoffice/admin/liveSupport/findUser", { email: this.searchUser });
        this.searchResult = fetchedData.data.users;
      } catch (error) {
        console.log(error)
      }
    },
    openChat(userId) {
      if (this.currentUserId === userId) return;
      this.currentUserId = userId;
      this.searchUser = '';
    },
    dataLoading(data) {
      this.fetchedData = data;
    },
    async loadChats() {
      try {
        if (this.isChatsLoading) return;
        this.isChatsLoading = true;

        this.currentOffset += 100;
        const fetchedData = await axios.get(`/backoffice/admin/liveSupport?offset=${this.currentOffset}`);
        if (!fetchedData.data.error) {
          this.fetchedData.chats = this.fetchedData.chats.concat(fetchedData.data.chats);
        }
        this.isChatsLoading = false;
      } catch (e) {
        console.log(e)
        this.isChatsLoading = false;
      }
    },
    async updateChats() {
      try {
        if (this.isChatsLoading) return;
        this.isChatsLoading = true;

        const fetchedData = await axios.get("/backoffice/admin/liveSupport");
        if (!fetchedData.data.error) this.fetchedData = fetchedData.data;
        this.isChatsLoading = false;
      } catch (e) {
        console.log(e)
        this.isChatsLoading = false;
      }
    }
  },
  mounted() {
    if (this.$route.params.userId) this.currentUserId = this.$route.params.userId;
  }
}
</script>

<style lang="scss" scoped>

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@media (max-width: 992px) {
  .chat-leftside {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .chat-rightside {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: calc(100% - 3px) !important;
    visibility: hidden !important;
    -webkit-transform: translateX(100%) !important;
    transform: translateX(100%) !important;
    z-index: 99 !important;
  }
}

.chat-leftside {
  position: relative;
  background-color: #212529;
  min-width: 350px;
  max-width: 350px;
  height: calc(100vh - 78px);

  div.chat {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 24px;
    color: #ced4da;
    -webkit-transition: all .4s;
    transition: all .4s;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    word-break: break-word;

    &:hover {
      background: #32383E;
    }

    &.current {
      background-color: rgba(10,179,156, 0.15);
      color: #0ab39c;
    }
  }
}

.chat-rightside {
  position: relative;
  background: url("/chat-bg-pattern.png");
  background-color: #1A1D21;
  width: 100%;
  height: calc(100vh - 78px);

  &.show {
    visibility: visible !important;
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }

  .back-btn {
    display: none;
    position: absolute;
    z-index: 100;
    top: 23px;
    left: 15px;
    font-size: 14px;

    &.show {
      display: block;
    }
  }
}

.search-result {
  position: absolute;
  width: 100%;
  min-height: 50px;
  padding: 0 5px 10px 5px;
  background: #1A1D21;
  border-radius: 5px;
  left: 0;
  word-break: break-word;
  top: -5px;
  overflow-y: auto;
  max-height: 500px;
}

.no-users {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.choose-user {
  display: block;
  padding: 10px 10px;
  cursor: pointer;
  transition: .1s;

  &:hover {
    background: #1E2026;
  }
}

</style>