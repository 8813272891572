<i18n>
{
  "en": {
    "createTicket": "Create Ticket",
    "myTickets": "My Tickets",
    "inputs": {
      "category": "Category",
      "subject": "Subject",
      "yourMessage": "Message",
      "typeMessage": "Describe your problem",
      "withdraw": "Withdraw",
      "deposit": "Deposit",
      "trading": "Trading",
      "account": "Account",
      "staking": "Staking",
      "none": "None"
    },
    "tablePlaceholder": "Search by id, subject...",
    "tableColumns": {
      "id": "Ticket ID",
      "category": "Category",
      "status": "Status",
      "date": "Date"
    },
    "status": {
      "closed": "Closed",
      "answered": "Answered",
      "waiting": "Waiting"
    }
  },
  "zh": {
    "createTicket": "创建票证",
    "myTickets": "我的门票",
    "inputs": {
      "category": "类别",
      "subject": "主题",
      "yourMessage": "消息",
      "typeMessage": "描述你的问题",
      "withdraw": "撤回",
      "deposit": "存款",
      "trading": "交易",
      "account": "帐户",
      "staking": "质押",
      "none": "没有任何"
    },
    "tablePlaceholder": "按 票号、主题搜索...",
    "tableColumns": {
      "id": "票号",
      "category": "类别",
      "status": "状态",
      "date": "日期"
    },
    "status": {
      "closed": "关闭",
      "answered": "已回答",
      "waiting": "等待"
    }
  },
  "kr": {
    "createTicket": "티켓 만들기",
    "myTickets": "내 티켓",
    "inputs": {
      "category": "카테고리",
      "subject": "주제",
      "yourMessage": "메시지",
      "typeMessage": "문제 설명",
      "withdraw": "철회",
      "deposit": "예금",
      "trading": "거래",
      "account": "계정",
      "staking": "스테이킹",
      "none": "없음"
    },
    "tablePlaceholder": "ID, 제목으로 검색...",
    "tableColumns": {
      "id": "티켓 ID",
      "category": "카테고리",
      "status": "상태",
      "date": "날짜"
    },
    "status": {
      "closed": "닫힌",
      "answered": "대답",
      "waiting": "기다려"
    }
  }
}
</i18n>
<template>
  <Page class="dashboard">
    <PageContainer :no-line="true">
      <div class="container" style="margin-bottom: 40px;">
        <h2>{{ $t('createTicket') }}</h2>
        <div class="row">
          <SelectBox
              class="col-lg-6 col-md-6 col-xs-6"
              :options="categorySelect.options"
              :label="$t(categorySelect.label)"
              :validation="categorySelect.validation"
              v-model="categorySelect.value"
              @check-valid="categorySelect.isValid = $event"
          />
          <InputBox
              class="col-lg-6 col-md-6 col-xs-6"
              :type="subjectInput.type"
              :label="$t(subjectInput.label)"
              v-model="subjectInput.value"
              :validation="subjectInput.validation"
              @check-valid="subjectInput.isValid = $event"
          />
        </div>
        <div style="margin-bottom: 24px;" class="col-lg-12">
          <label style="box-sizing: border-box;  margin: 0; min-width: 0; font-size: 12px; line-height: 16px; color: #474D57;">{{ $t('inputs.yourMessage') }}</label>
          <textarea
              maxlength="1500"
              v-model.trim="newMessage"
              :placeholder="$t('inputs.typeMessage')"
              style="resize: none;" rows="5"
          ></textarea>
        </div>
        <div style="float: right" class="col-lg-4">
          <Button :callback="createTicket" style="width: 100%;" />
        </div>
      </div>

      <div class="container">
        <h2>{{ $t('myTickets') }}</h2>
        <OnlineTable
            parse-url="/main/user/tickets"
            :column-list="tableColumns"
            default-sort="date"
            :search-placeholder-text="$t('tablePlaceholder')"
            @handle-data="tableData = $event"
        >
          <tr v-for="data, index in tableData" :key="index">
            <td style="text-decoration: underline;" class="hash">
              <router-link :to="$linkCreator($i18n.locale, 'dashboard/tickets/' + data._id)">
                {{ data.id }}
              </router-link>
            </td>
            <td>{{ $t('inputs.' + data.category) }}
            <td :class="data.isClosed ? 'closed' : data.isAnswered ? 'answered' : 'waiting'">{{ data.isClosed ? $t('status.closed') : data.isAnswered ? $t('status.answered') : $t('status.waiting') }}</td>
            <td  style="min-width: 200px;">{{ data.date | toDate }}</td>
          </tr>
        </OnlineTable>
      </div>
    </PageContainer>
  </Page>
</template>

<script>
import Page from "@/components/main/UI/Page";
import PageContainer from "@/components/main/UI/PageContainer";
import InputBox from "@/components/main/UI/InputBox";
import SelectBox from "@/components/main/UI/SelectBox";
import Button from "@/components/main/UI/Button";
import OnlineTable from "@/components/main/UI/OnlineTable";
import axios from "@/plugins/axios";

export default {
  name: "Main",
  components: {
    Page,
    PageContainer,
    InputBox,
    SelectBox,
    Button,
    OnlineTable
  },
  data() {
    return {
      tableData: [],
      tableColumns: [
        {
          name: 'id',
          title: this.$t('tableColumns.id'),
          isSortable: true
        },
        {
          name: 'category',
          title: this.$t('tableColumns.category'),
          isSortable: true
        },
        {
          name: 'isAnswered',
          title: this.$t('tableColumns.status'),
          isSortable: true
        },
        {
          name: 'date',
          title: this.$t('tableColumns.date'),
          isSortable: true
        },
      ],
      newMessage: '',
      subjectInput: {
        value: '',
        type: 'text',
        label: 'inputs.subject',
        isValid: false,
        validation: {
          required: true
        }
      },
      categorySelect: {
        value: 'withdraw',
        label: 'inputs.category',
        isValid: true,
        validation: {
          required: true
        },
        options: [
          {
            title: this.$t('inputs.withdraw'),
            value: 'withdraw'
          },
          {
            title: this.$t('inputs.deposit'),
            value: 'deposit'
          },
          {
            title: this.$t('inputs.trading'),
            value: 'trading'
          },
          {
            title: this.$t('inputs.account'),
            value: 'account'
          },
          {
            title: this.$t('inputs.staking'),
            value: 'staking'
          },
        ]
      }
    }
  },
  methods: {
    async createTicket() {
      try {
        if (this.subjectInput.value.trim() === '' || this.newMessage === '') {
          return this.$callNotification( this.$i18n.locale, { error: true, message: `Fill ${this.subjectInput.value.trim() === '' ? 'Subject' : 'Message'} field` });
        }
        const fetchedData = await axios.post('/main/user/createTicket', {
          category: this.categorySelect.value,
          subject: this.subjectInput.value,
          message: this.newMessage
        });

        this.$callNotification( this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) return location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.dark {
  textarea {
    background-image: initial;
    background-color: rgb(29, 31, 32);
    border-color: rgb(66, 71, 74);
    outline-color: initial;
  }
}

textarea {
  background: #fdfdfd;
  display: block;
  box-sizing: border-box;
  border: 1px solid #bebebe;
  border-radius: 2px;
  padding: 7px;
  margin: 5px 0;
  width: 100%;
  outline: none;
  transition: all .2s ease;
}

td.hash {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  white-space: nowrap;
}

.answered {
  color: #00871e;
}

.closed {
  color: #d90518;
}

.waiting {
  color: #e9b10f;
}
</style>