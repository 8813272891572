<template>
  <Page style="padding: 0;">
    <div class="loading" :class="{ mobile: $store.state.Index.isMobile }">
      <div style="text-align: center;" class="img">
        <img style="margin-bottom: 15px;" :src="$store.state.Index.logo" alt="">
        <div v-show="this.dataLoadingStatus.isError">{{ 'Error on account activation' }}</div>
        <div>
          {{ this.dataLoadingStatus.isError ? this.customError : 'Account is activated' }}
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import axios from "@/plugins/axios";
import Page from "@/components/main/UI/Page";

export default {
  name: "AccountActivation",
  components: {
    Page
  },
  data() {
    return {
      dataLoadingStatus: {
        isDone: false,
        isError: false,
        customError: ''
      }
    }
  },
  async mounted() {
    try {
      let fetchedData = await axios.get('main/user/activation/' + this.$route.params.verificationLink);

      if (fetchedData.data.error) {
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
        this.customError = fetchedData.data.description;
        return;
      }
      this.dataLoadingStatus.isDone = true;
      location.href = `/${this.$i18n.locale}/dashboard`;
    } catch (e) {
      console.log(e)
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>

div.loading {
  width: 100%;
  height: calc(100vh - 61px);
  display: flex;
  align-items: center;
  justify-content: center;

  &.mobile {
    height: calc(100vh - 163px);
  }
}


img {
  animation: spin 2s linear infinite;
}

</style>