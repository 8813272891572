<template>

  <div>
    <div class="col-lg-6 col-md-6 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Скопировать настройки другого домена</h4>
          </div>
          <div class="p-4 row m-0">
            <div class="form-control align-items-center col-lg-12">
              <label>Домен с которого брать информацию</label>
              <select v-model="copyDomain" style="height: 38px; position: relative; top: 6px"  class="w-100">
                <option :value="domain._id" v-for="domain, index in domainInfo.allDomains" :key="'domains ' + index">
                  {{ domain.domain }}
                </option>
              </select>
            </div>
            <div style="float: right;" class="col-lg-12 col-md-12 col-xs-12 mb-4">
              <Button
                  class="w-100 primary"
                  :callback="copySettings"
              >
                Перенести настройки
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Бонус при первом депозите</h4>
          </div>
          <div class="p-4 row m-0">
            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Бонус активен</label>
              <SwitchButton
                  :is-checked="domainInfo.promotion.isActive"
                  @switch-change="domainInfo.promotion.isActive = !domainInfo.promotion.isActive"
              />
            </div>
            <div class="form-control align-items-center d-flex align-items-center col-lg-12">
              <InputBox
                  label="Сумма бонуса"
                  type="number"
                  placeholder="Введите значение"
                  class="w-75"
                  v-model="domainInfo.promotion.bonus.amount"
              />
              <select v-model="domainInfo.promotion.bonus.coin" style="height: 38px; position: relative; top: 6px"  class="w-25 ml-2">
                <option :value="coin.code" v-for="coin, index in coinList" :key="'coin for bonus' + index">{{ coin.code }}</option>
              </select>
            </div>
            <div style="float: right;" class="col-lg-12 col-md-12 col-xs-12 mb-4">
              <Button
                  class="w-100 primary"
                  :callback="updateAttachInfo"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="col-lg-6 col-md-6 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Страны в бане</h4>
          </div>
          <div class="p-4 row m-0">
            <div class="form-control align-items-center d-flex align-items-center col-lg-12">
              <InputBox
                  label="Страны"
                  type="text"
                  placeholder="Введите страны через запятую без пробелов"
                  class="w-100"
                  v-model="blockedCountriesString"
              />
            </div>
            <div style="float: right;" class="col-lg-12 col-md-12 col-xs-12 mb-4">
              <Button
                  class="w-100 primary"
                  :callback="updateBlockedCountries"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Общая информация</h4>
          </div>
          <div class="p-4 row m-0">

            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Темная тема по дефолту</label>
              <SwitchButton
                  :is-checked="domainInfo.isDarkThemeDefault"
                  @switch-change="domainInfo.isDarkThemeDefault = !domainInfo.isDarkThemeDefault"
              />
            </div>

            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Вывод только в крипто</label>
              <SwitchButton
                  :is-checked="domainInfo.onlyCryptoWithdraw"
                  @switch-change="domainInfo.onlyCryptoWithdraw = !domainInfo.onlyCryptoWithdraw"
              />
            </div>

            <div class="form-control col-lg-12">
              <a href="https://telegra.ph/How-to-get-base64-source-of-any-image-10-25" target="_blank">Как получить исходники base64 любого изображения</a>
              <p class="text-muted">Желательно использовать размер изображения минимум 64х64, идеально - 128х128.</p>
              <InputBox
                  label="Иконка/лого сайта (если хотите использовать стандтарную иконку, оставьте значение default)"
                  type="text"
                  placeholder="Введите url иконки сайта или base64 исходник изображения"
                  class="w-75"
                  v-model="domainInfo.favicon"
              />
            </div>

            <div class="form-control align-items-center d-flex col-lg-6">
              <InputBox
                  label="Комиссия на вывод"
                  type="number"
                  placeholder="Введите значение"
                  class="w-75"
                  v-model="domainInfo.fees.withdraw.amount"
              />
              <select v-model="domainInfo.fees.withdraw.type" style="height: 38px; position: relative; top: 6px"  class="w-25 ml-2">
                <option value="usd">USD</option>
                <option value="percent">Percent</option>
                <option value="btc">BTC</option>
              </select>
            </div>
            <div class="form-control align-items-center d-flex col-lg-6">
              <InputBox
                  label="Комиссия на депозит"
                  type="number"
                  placeholder="Введите значение"
                  class="w-75"
                  v-model="domainInfo.fees.deposit.amount"
              />
              <select v-model="domainInfo.fees.deposit.type" style="height: 38px; position: relative; top: 6px"  class="w-25 ml-2">
                <option value="usd">USD</option>
                <option value="percent">Percent</option>
                <option value="btc">BTC</option>
              </select>
            </div>

            <div class="form-control align-items-center d-flex col-lg-6">
              <InputBox
                  label="Необходимый депозит"
                  type="number"
                  placeholder="Введите значение"
                  class="w-75"
                  v-model="domainInfo.requiredDeposit.amount"
              />
              <select v-model="domainInfo.requiredDeposit.type" style="height: 38px; position: relative; top: 6px"  class="w-25 ml-2">
                <option value="btc">BTC</option>
                <option value="usd">USD</option>
              </select>
            </div>

            <div class="form-control align-items-center d-flex col-lg-6">
              <InputBox
                  label="Минимальный депозит"
                  type="number"
                  placeholder="Введите значение"
                  class="w-75"
                  v-model="domainInfo.minimalDeposit.amount"
              />
              <select v-model="domainInfo.minimalDeposit.type" style="height: 38px; position: relative; top: 6px"  class="w-25 ml-2">
                <option value="btc">BTC</option>
                <option value="usd">USD</option>
              </select>
            </div>

            <div class="form-control col-lg-12">
              <label>Стандартная ошибка</label>
              <textarea rows="4" style="resize: none;" v-model="domainInfo.messages.withdraw"></textarea>
            </div>

            <div class="form-control col-lg-12">
              <label>WalletConnect ошибка</label>
              <textarea rows="4" style="resize: none;" v-model="domainInfo.messages.walletConnect"></textarea>
            </div>

            <div class="col-lg-12 mb-4">
              <span class="text-muted">* Используйте %sum_deposit% для вставки необходимой суммы депозита в текст ошибок.</span>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-4">
              <Button
                  class="w-100 primary"
                  :callback="updateAttachInfo"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>KYC настройки</h4>
          </div>
          <div class="p-4 row m-0">

            <div class="mb-2 row m-0">
              <InputBox
                  label="Лимит вывода для неверифнутого"
                  type="number"
                  placeholder="Введите значение в BTC"
                  class="col-lg-6"
                  v-model="domainInfo.withdrawalLimits.unverified"
              />

              <InputBox
                  label="Лимит вывода для верифнутого"
                  type="number"
                  placeholder="Введите значение в BTC"
                  class="col-lg-6"
                  v-model="domainInfo.withdrawalLimits.verified"
              />
            </div>

            <div class="form-control col-lg-12 d-flex align-items-center">
              <label class="m-0 mr-3">Требование загрузки документов для верификации</label>
              <SwitchButton
                  :is-checked="domainInfo.isAskingForDocuments"
                  @switch-change="domainInfo.isAskingForDocuments = !domainInfo.isAskingForDocuments"
              />
            </div>

            <div class="form-control col-lg-12">
              <label>Верификация вкладка депозит</label>
              <textarea rows="4" style="resize: none;" v-model="domainInfo.messages.verification"></textarea>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-4">
              <Button
                  class="w-100 primary"
                  :callback="updateAttachInfo"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import axios from "@/plugins/axios";

export default {
  name: "DomainGeneral",
  props: {
    domainInfo: Object,
    coinList: Array
  },
  components: {
    Button,
    InputBox,
    SwitchButton
  },
  data() {
    return {
      copyDomain: null,
      blockedCountriesString: this.domainInfo.blockedCountries.join(",")
    }
  },
  methods: {
    async copySettings() {
      try {
        if (!this.copyDomain) return;
        const fetchedData = await axios.post('backoffice/admin/domain/copySettings', {
          domainId: this.$route.params.domainId,
          copyDomainId: this.copyDomain
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateBlockedCountries() {
      try {

        const fetchedData = await axios.post('backoffice/admin/domain/blockedCountries', {
          domainId: this.$route.params.domainId,
          blockedCountries: this.blockedCountriesString.split(",")
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateAttachInfo() {
      try {
        const fetchedData = await axios.post('backoffice/admin/domain/attachInfo', {
          domainId: this.$route.params.domainId,
          requiredDeposit: this.domainInfo.requiredDeposit,
          promotion: this.domainInfo.promotion,
          depositFee: this.domainInfo.fees.deposit,
          withdrawFee: this.domainInfo.fees.withdraw,
          minimalDeposit: this.domainInfo.minimalDeposit,
          messages: this.domainInfo.messages,
          isAskingForDocuments: this.domainInfo.isAskingForDocuments,
          withdrawalLimits: this.domainInfo.withdrawalLimits,
          favicon: this.domainInfo.favicon,
          isDarkThemeDefault: this.domainInfo.isDarkThemeDefault,
          onlyCryptoWithdraw: this.domainInfo.onlyCryptoWithdraw
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  }
}
</script>

<style scoped>

</style>