<template>
  <div style="text-align: center" class="pb-4" v-if="!loadingStatus.isDone || loadingStatus.isError">
    <span v-show="!loadingStatus.isDone && !loadingStatus.isError">Загрузка данных...</span>
    <span v-show="loadingStatus.isError">Ошибка при загрузке данных</span>
  </div>
  <div v-else>
    <div class="col-lg-6 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Добавить новый домен</h4>
          </div>
          <div class="p-4 row m-0">
            <InputBox
                label="Домен"
                placeholder="example.com"
                v-model="newDomainInput"
            />
            <div class="form-control align-items-center col-lg-12 mb-5 p-0">
              <label>Дизайн</label>
              <select v-model="selectedDesign" style="height: 38px; position: relative; top: 6px"  class="w-100">
                <option :value="design._id" v-for="design, index in fetchedData.designs" :key="'design ' + index">
                  {{ design.name }}
                </option>
              </select>
            </div>
            <div>
              <Button
                  class="w-100 primary"
                  :callback="addDomain"
                  :is-disabled="newDomainInput === ''"
              >
                Добавить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Мои домены</h4>
          </div>
          <span class="text-muted pl-4 pr-4 pt-3 d-flex">Если домен не работает спустя час после добавления, попробуйте исправить его нажав на иконку разводного ключа.</span>
          <Table
              ref="domainsTableComponent"
              parse-url="/backoffice/user/spammerDomains"
              :columnList="domainsTableColumns"
              default-sort="date"
              searchPlaceholderText="Введите домен или DNS"
              @handle-data="domainsTableData = $event"
          >
            <tr v-for="domain, index in domainsTableData" :key="'domain' + index">
              <td>
                <router-link :to="'/back-office/domain/' + domain._id">
                  {{ domain.domain }}
                </router-link>
              </td>
              <td>{{ domain.dns_1 }}<br>{{ domain.dns_2 }}</td>
              <td>{{ domain.date | toDate }}</td>
              <td class="no-animate" style="text-align: center;">
                <span @click="repairDomain(domain._id)" class="repair-btn mr-4">
                  <font-awesome-icon class="icon" :icon="['fas', 'wrench']" />
                </span>
                <span @click="deleteDomain(domain._id)" class="delete-btn">
                  <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
                </span>
              </td>
            </tr>
          </Table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";

export default {
  name: "Domains",
  props: {
    information: Object
  },
  components: {
    InputBox,
    Button,
    Table
  },
  data() {
    return {
      selectedDesign: "default",
      fetchedData: {},
      loadingStatus: { isDone: false, isError: false },
      isDomainDeleting: false,
      isDomainRepairing: false,
      newDomainInput: '',
      domainsTableData: [],
      domainsTableColumns: [
        {
          name: 'domain',
          title: 'Домен',
          isSortable: true
        },
        {
          name: 'dns',
          title: 'DNS',
          isSortable: false
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        },
      ],
    }
  },
  methods: {
    async addDomain() {
      try {

        this.$callNotification(this.$i18n.locale, {
          type: 'info',
          message: 'Домен добавляется, пожалуйста подождите (НЕ ОБНОВЛЯЙТЕ СТРАНИЦУ)'
        });
        const fetchedData = await axios.post('backoffice/user/spammer/addDomain', {
          domain: this.newDomainInput,
          design: this.selectedDesign,
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.newDomainInput = '';
          this.$refs.domainsTableComponent.fetchTableData();
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async deleteDomain(domainId) {
      try {
        if (this.isDomainDeleting) return;
        this.$callNotification(this.$i18n.locale, {
          type: 'info',
          message: 'Домен удаляется, пожалуйста подождите'
        });
        this.isDomainDeleting = true;
        const fetchedData = await axios.post('backoffice/user/spammer/deleteDomain', {
          domainId
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.domainsTableComponent.fetchTableData();
        this.isDomainDeleting = false;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
        this.isDomainDeleting = false;
      }
    },
    async repairDomain(domainId) {
      try {
        if (this.isDomainRepairing) return;
        this.$callNotification(this.$i18n.locale, {
          type: 'info',
          message: 'Домен исправляется, пожалуйста подождите несколько минут'
        });
        this.isDomainRepairing = true;
        const fetchedData = await axios.post('backoffice/user/spammer/repairDomain', {
          domainId
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
        this.isDomainRepairing = false;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
        this.isDomainRepairing = false;
      }
    }
  },
  async mounted() {
    try {
      this.loadingStatus.isDone = false;
      const fetchedData = await axios.get('backoffice/user/domain/designs');
      if (fetchedData.data.error) return this.loadingStatus.isError = true;
      this.fetchedData = fetchedData.data;
      this.selectedDesign = this.fetchedData.designs.find(x => x.name === "Стандартный")._id;
      this.loadingStatus.isDone = true;
    } catch (e) {
      console.log(e)
      this.loadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>

span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}

span.repair-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f0a448;
    }
  }
}

</style>