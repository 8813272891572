<i18n>
{
  "en": {
    "title": "Two-Factor confirmation is required",
    "code": "Code"
  },
  "zh": {
    "title": "需要雙因素確認",
    "code": "代碼"
  },
  "kr": {
    "title": "2단계 확인이 필요합니다",
    "code": "코드"
  }
}
</i18n>
<template>
  <div name="two-factor-confirmation" :class="{ 'dark': $store.state.Index.isDarkMode }" style="padding: 10px;">
    <h3 class="title">{{ $t('title') }}</h3>
    <InputBox
        v-for="control, index in formControls"
        :key="index"
        :type="control.type"
        :label="$t(control.label)"
        :validation="control.validation"
        v-model="control.value"
        @check-valid="control.isValid = $event"
        :error-message="$t(control.errorMessage)"
    />
    <Button
        custom-width="100%"
        :is-async="true"
        :callback="twoFactorConfirmationHandler"
        :is-disabled="!$isFormValid(formControls)"
        :answer-delay="2000"
    />
  </div>
</template>

<script>
import InputBox from "@/components/main/UI/InputBox";
import Button from "@/components/main/UI/Button";

export default {
  name: "TwoFactorConfirmation",
  components: {
    InputBox,
    Button
  },
  props: {
    callback: Function
  },
  data() {
     return {
       formControls: {
         codeInput: {
           value: '',
           type: 'number',
           label: 'code',
           isValid: false,
           validation: {
             required: true,
             minLength: 6,
             maxLength: 6,
           }
         },
       }
     }
  },
  methods: {
    async twoFactorConfirmationHandler() {
      this.callback(this.formControls.codeInput.value);
    }
  }
}
</script>

<style scoped>

</style>