import Vue from 'vue';
import Router from 'vue-router';
import BackOfficeRoutes from './backoffice-router';
import MainRoutes from './main-router';
import store from '../store';
import i18n from "@/i18n";

Vue.use(Router)

const routeOnlyForLogged = [
  'Dashboard',
  'Payment',
  'Ticket',
  'Tickets'
];

const routeOnlyForUnlogged = [
  'Auth',
  'Registration'
];

const router = new Router({
  scrollBehavior(to, from, savedPosition) { return { x: 0, y: 0 } },
  mode: 'history',
  routes: [].concat(BackOfficeRoutes, MainRoutes)
});

router.beforeEach(async (to, from, next) => {
  if (to.path.includes('back-office')) {
    if (to.path.includes('admin')) {
      if (!store.state.Backofficeadmin.isFetched.isDone) {
        await store.dispatch('Backoffice/setStoreStatus', false);
        await store.dispatch('Index/setStoreStatus', false);
        await store.dispatch('Backofficeadmin/setStoreStatus', true);
        await store.dispatch('Backofficeadmin/fetchPanelInfo');
      }
      
      if (to.name === 'BackOfficeAdminAuth' && store.state.Backofficeadmin.isAuthed) return next(`/back-office/admin`);
      if (!store.state.Backofficeadmin.isAuthed && to.path !== `/back-office/admin/auth`) return next(`/back-office/admin/auth`);
    } else {
      if (!store.state.Backoffice.isFetched.isDone) {
        await store.dispatch('Index/setStoreStatus', false);
        await store.dispatch('Backofficeadmin/setStoreStatus', false);
        await store.dispatch('Backoffice/setStoreStatus', true);
        await store.dispatch('Backoffice/fetchPanelInfo');
      }
  
      if (to.name === 'BackOfficeAuth' && store.state.Backoffice.isAuthed) return next(`/back-office`);
      if (!store.state.Backoffice.isAuthed && to.path !== `/back-office/auth`) return next(`/back-office/auth`);
    }
  } else {
    if (!to.params.locale || !Object.keys(i18n.messages).includes(to.params.locale)) to.params.locale = 'en';
    i18n.locale = to.params.locale;
  
    if (!store.state.Index.isFetched.isDone) {
      await store.dispatch('Backofficeadmin/setStoreStatus', false);
      await store.dispatch('Backoffice/setStoreStatus', false);
      await store.dispatch('Index/setStoreStatus', true);
      await store.dispatch('Index/fetchExchangeInfo');
    }
  
    if (to.matched.some(x => routeOnlyForLogged.includes(x.name)) && !store.state.Index.isAuthed) return next(`/${i18n.locale}/auth`);
    if (to.matched.some(x => routeOnlyForUnlogged.includes(x.name)) && store.state.Index.isAuthed) return next(`/${i18n.locale}`);
  }
  
  
  next()
})

const DEFAULT_TITLE = store.state.Index.websiteName.short;
router.afterEach((to, from) => {
  
  Vue.nextTick(() => document.title = to.meta.title || DEFAULT_TITLE);
});
export default router;