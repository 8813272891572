<template>
  <Page
      title="Настройки"
      parse-url="/backoffice/admin/settings"
      @loaded-data="dataLoading($event)"
  >

    <div class="container p-0">
      <div class="col-lg-12 col-md-12 col-xs-12">
        <div class="Card">
          <div class="Card-content">
            <div class="Card-header p-0 pl-4 pr-4 mb-4">
              <div style="flex-wrap: wrap;" class="d-flex">
                <span @click="currentTab = tab" class="tabs-link" :class="{ active: tab === currentTab }" v-for="tab, index in tabs" :key="'tab' + index">{{ tab }}</span>
              </div>
            </div>

            <SettingsGeneral
                v-show="currentTab === 'Общие'"
                :information="fetchedData.adminInfo"
            />

            <SettingsSecurity
                v-show="currentTab === 'Безопасность и кошельки'"
                :information="fetchedData.adminInfo"
            />

            <SettingsDomains
                v-if="currentTab === 'Домены'"
                :information="fetchedData.adminInfo"
            />

            <SettingsCloudflare
                v-show="currentTab === 'Cloudflare аккаунты'"
                :information="fetchedData.adminInfo"
            />

          </div>
        </div>
      </div>
    </div>


  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import SettingsGeneral from "./General";
import SettingsSecurity from "./Security";
import SettingsDomains from "./Domains";
import SettingsCloudflare from "./Cloudflare";

export default {
  name: "Settings",
  components: {
    Page,
    SettingsGeneral,
    SettingsSecurity,
    SettingsDomains,
    SettingsCloudflare
  },
  data() {
    return {
      currentTab: 'Общие',
      tabs: [
        'Общие',
        'Безопасность и кошельки',
        'Домены',
        'Cloudflare аккаунты'
      ],
      fetchedData: {
        spammerInfo: {
          settings: {},
          addresses: {}
        }
      },
    }
  },
  methods: {
    dataLoading(data) {
      this.fetchedData = data;
    }
  },
}
</script>

<style lang="scss" scoped>

span.tabs-link {
  display: block;
  padding: 16px 16px;
  position: relative;
  color: var(--vz-link-color);
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;

  &.active {
    color: #405189;

    &::after {
      content: "";
      background: #405189;
      height: 1px;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      -webkit-transition: all 250ms ease 0s;
      transition: all 250ms ease 0s;
      -webkit-transform: scale(0);
      transform: scale(1);
    }
  }
}

@media (min-width: 992px) {
  .container {
    width: calc(970px - 250px);
  }
}

@media (min-width: 768px){
  .container {
    width: 750px;
  }
}
@media (min-width: 1200px){
  .container {
    width: calc(1170px - 250px);
  }
}



</style>