import { render, staticRenderFns } from "./Spammer.vue?vue&type=template&id=5b6ab649&scoped=true&"
import script from "./Spammer.vue?vue&type=script&lang=js&"
export * from "./Spammer.vue?vue&type=script&lang=js&"
import style0 from "./Spammer.vue?vue&type=style&index=0&id=5b6ab649&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b6ab649",
  null
  
)

export default component.exports