<template>
  <Page
      title="Заявки на KYC"
  >
    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Список юзеров отправивших документы на проверку</h4>
        </div>
        <Table
            parse-url="/backoffice/user/kyc"
            :columnList="ticketsTableColumns"
            default-sort="_id"
            searchPlaceholderText="Введите почту"
            @handle-data="ticketsTableData = $event"
        >
          <tr v-for="user, index in ticketsTableData" :key="'user' + index">
            <td class="hash">
              <router-link :to="'/back-office/users/' + user._id">
                {{ user.email }}
              </router-link>
            </td>
            <td>{{ user.settings.registrationDate | toDate }}</td>
          </tr>
        </Table>
      </div>
    </div>
  </Page>
</template>

<script>
import Table from "@/components/backoffice/UI/Table";
import Page from "@/components/backoffice/Page";

export default {
  name: "Main",
  components: {
    Table,
    Page
  },
  data() {
    return {
      ticketsTableData: [],
      ticketsTableColumns: [
        {
          name: 'username',
          title: 'Почта',
          isSortable: true
        },
        {
          name: 'settings.registrationDate',
          title: 'Дата регистрации',
          isSortable: true
        },
      ],
    }
  }
}
</script>

<style scoped>
.hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
</style>