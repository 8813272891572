<i18n>
{
  "en": {
    "showEntries": "Showing {0} to {1} of {2} entries",
    "clearSort": "Clear sort",
    "dataLoading": "Data loading...",
    "dataError": "Data loading error. Page reload is required.",
    "dataEmpty": "No data"
  },
  "zh": {
    "showEntries": "显示 {2} 个条目中的 {0} 到 {1} 个",
    "clearSort": "清除排序",
    "dataLoading": "数据加载...",
    "dataError": "数据加载错误。 需要重新加载页面。",
    "dataEmpty": "没有数据"
  },
  "kr": {
    "showEntries": "항목 {2}개 중 {0}~{1} 표시",
    "clearSort": "정렬 지우기",
    "dataLoading": "데이터 로드 중...",
    "dataError": "데이터 로드 오류입니다. 페이지 새로고침이 필요합니다.",
    "dataEmpty": "데이터 없음"
  }
}
</i18n>

<template>
  <div :class="{ dark: $store.state.Index.isDarkMode }">
    <div style="position:relative;" class="">
      <div style="margin-bottom: 15px;">
        <div @click="clearSort" class="toggle_filters" v-show="sortQuery && sortQuery !== defaultSort">
          <span style="margin-right: 5px;">{{ $t('clearSort') }}</span>
          <font-awesome-icon
              :icon="['fal', 'times']"
              size="sm"
          />
        </div>
        <div style="position:relative; display: flex; align-items: center; justify-content: space-between;">
          <div>
            <select style="width: auto !important; border-radius: 50px; padding: 3px 6px; font-size: 12px; outline: none; border: 1px solid #F0F0F0;" class="mr-auto" v-model="limitQuery">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>


          <div style="position:relative;" v-if="!noSearch">
            <div class="search-input" :class="{ mobile: $store.state.Index.isMobile }">
              <input v-model.lazy="searchQuery" type="text" :placeholder="searchPlaceholderText">
              <div class="placeholder">
                <font-awesome-icon
                    :icon="['fal', 'search']"
                />
              </div>
              <font-awesome-icon
                  :icon="['fal', 'times']"
                  @click="searchQuery = ''"
                  v-show="searchQuery"
                  class="clear_search"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
    <div>

      <div style="overflow-x: auto;" :class="{ empty: tableStatus.isLoading || tableStatus.isError || tableDataLength === 0 }" class="table_div">

        <div :style="{ height: tableEmptyBlockHeight+'px' }" class="empty" v-show="tableStatus.isLoading && !tableStatus.isError">{{ $t('dataLoading') }}</div>
        <div :style="{ height: tableEmptyBlockHeight+'px' }" class="empty" v-show="!tableStatus.isLoading && tableStatus.isError">{{ $t('dataError') }}</div>
        <div :style="{ height: tableEmptyBlockHeight+'px' }" class="empty" v-show="!tableStatus.isLoading && !tableStatus.isError && tableDataLength === 0">{{ $t('dataEmpty') }}</div>

        <table class="table" v-show="!tableStatus.isLoading && !tableStatus.isError && tableDataLength > 0">

          <thead>

          <tr>
            <th @click="column.isSortable ? changeSort(column) : null" :class="[column.classes, column.isSortable ? 'animated' : '' ]" v-for="column, index in columnList" :key="index">{{ column.title }}
              <font-awesome-icon
                  :icon="['fas', 'sort-up']"
                  @click="searchQuery = ''"
                  v-show="sortQuery === column.name"
                  class="arrow"
                  :class="ascendingQuery ? 'up' : 'down'"
              />
            </th>
          </tr>

          </thead>

          <tbody>

          <slot></slot>

          </tbody>

        </table>

      </div>

      <div style="margin-top: 10px; display: flex; justify-content: space-between; align-items: center;" v-show="tableDataLength > 0">
        <span style="color: #757d91; font-size: 11.3833px;">{{ $t('showEntries', [(pageQuery-1)*limitQuery+1, (pageQuery-1)*limitQuery+tableDataLength, totalDocumentsCount]) }}</span>
        <ul class="pagination justify-content-end">
          <button @click="changePage(pageQuery-1)" :class="{ disabled: pageQuery === 1 }" class="page-item">
            ❮
          </button>
          <button @click="changePage(item)" :class="{ currentPage: item === pageQuery }" class="page-item" v-for="item, index in paginationButtons" :key="index">{{ item }}</button>
          <button @click="changePage(pageQuery+1)" :class="{ disabled: pageQuery === totalPages }" class="page-item">
              ❯
          </button>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "SpammerTable",
  props: {
    parseUrl: String,
    columnList: Array,
    noSearch: Boolean,
    defaultSort: String,
    searchPlaceholderText: String
  },
  data() {
    return {
      tableStatus: {
        isLoading: true,
        isError: false
      },
      totalPages: 0,
      tableDataLength: 0,
      totalDocumentsCount: 0,
      searchQuery: '',
      sortQuery: this.defaultSort || '',
      ascendingQuery: true,
      limitQuery: 10,
      pageQuery: 1
    }
  },
  watch: {
    searchQuery(newVal, oldVal) {
      if (newVal == oldVal) return;
      this.pageQuery = 1;
      this.fetchTableData();
    },
    limitQuery(newVal, oldVal) {
      if (newVal == oldVal) return;
      this.pageQuery = 1;
      this.fetchTableData();
    }
  },
  methods: {
    clearSort() {
      if (this.sortQuery === '') return;
      this.sortQuery = this.defaultSort;
      this.ascendingQuery = true;
      this.pageQuery = 1;
      this.fetchTableData();
    },
    changeSort(column) {
      this.sortQuery = column.name;
      this.ascendingQuery = !this.ascendingQuery;
      this.pageQuery = 1;
      this.fetchTableData();
      console.log(this.sortQuery)
    },
    changePage(page) {
      if (!Number.isInteger(page)) return;
      if (this.pageQuery === page) return;
      if (page < 1 || page > this.totalPages) return;
      this.pageQuery = page;
      this.fetchTableData();
    },
    async fetchTableData() {
      this.tableStatus.isLoading = true;
      try {
        let tableData = await axios.get(`${this.parseUrl}?searchQuery=${this.searchQuery}&sortQuery=${this.sortQuery}&limitQuery=${this.limitQuery}&pageQuery=${this.pageQuery}&ascendingQuery=${this.ascendingQuery}`);
        this.tableDataLength = tableData.data.documents.length;
        this.totalDocumentsCount = tableData.data.totalDocumentsCount;
        this.totalPages = Math.ceil(tableData.data.totalDocumentsCount / this.limitQuery);
        this.$emit('handle-data', tableData.data.documents);
        this.tableStatus.isLoading = false;
      } catch (e) {
        console.log(e)
        this.tableStatus.isLoading = false;
        this.tableStatus.isError = true;
      }
    },
    async reloadData(event) {
      event.target.disabled = true;
      await this.fetchTableData();
      event.target.disabled = false;
    }
  },
  computed: {
    paginationButtons() {
      let total = Array.from({length: this.totalPages}, (_, i) => i + 1);
      const step = 4;
      if (this.pageQuery < step) {
        total = total.slice(0, step)
        if (this.totalPages >= step + 1) {
          total.push('...')
          total.push(this.totalPages)
        }
      } else if ((this.pageQuery >= step && this.pageQuery + step <= this.totalPages) || (this.pageQuery + step - 1 === this.totalPages)) {
        total = total.slice(total.indexOf(this.pageQuery) - 1, total.indexOf(this.pageQuery) + step - 2)
        total.unshift('...')
        total.unshift(1)
        total.push('...')
        total.push(this.totalPages)
      } else if (this.totalPages <= step) {
        total = total.slice(0, this.totalPages)
      } else {
        total = total.slice(total.indexOf(this.totalPages - step + 1), this.totalPages)
        total.unshift('...')
        total.unshift(1)
      }
      return total
    },
    tableEmptyBlockHeight() {
      return 65 + 52.5 + (this.tableDataLength * 53);
    }
  },
  mounted() {
    this.fetchTableData();
  }
}
</script>

<style lang="scss" scoped>

.table_div.empty {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dark {
  select {
    color: rgb(197, 193, 189);
    background: transparent;
    border-color: #363b3d !important;
  }

  .search-input {
    border-color: #363b3d;
    input {
      color: rgb(197, 193, 189);
      border-color: #363b3d;
      outline-color: initial;
    }
  }
  table thead {
    border-bottom-color: rgb(54, 59, 61);
  }
  th {
    color: rgba(197, 193, 189, 0.6);
  }
  tr {
    border-bottom-color: rgb(54, 59, 61);
  }
}

table {

  width: 100%;
  font-weight: 400;

  thead {
    border-bottom: 1px solid #e8eaf0;

    th {
      padding: 16px 20px;
      font-size: 14px;
      vertical-align: middle;
      text-align: left;
    }

  }

  tbody {

    tr {
      border-bottom: 1px solid #e8eaf0;

      td {
        padding: 16px 20px;
      }
    }

  }

}

td i {
  transition: .3s;
  cursor: pointer;
}

td i.fa-wallet:hover {
  color: #eed48b;
}

td i.fa-user-edit:hover {
  color: #6aa7e9;
}

td i.fa-list-alt:hover {
  color: #67d8eb;
}

td i.fa-user-slash:hover {
  color: #f16572;
}


.arrow {
  margin-left: 5px;
  transition: .3s;
}

.arrow.up {
  transform: rotate(180deg);
}

.clear_search.fa-times {
  position: absolute;
  right: 10px;
  top: 17px;
  transition: .3s;
  cursor: pointer;
}

.clear_search.fa-times:hover {
  color: #f16572;
}

.toggle_filters {
  color: #f16572;
  font-size: 12px;
  cursor: pointer;
}

.toggle_filters i {
  position: relative;
  top: 1px;
}

.warn_twins {
  font-size: 12px;
}

ul {
  list-style-type: none;

  li {
    display: inline-block;
  }
}

.currentPage {
  background: #2A85FF !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: white;
}

.page-item {
  cursor: pointer;
  font-size: 14px;
  border-radius: .125rem;
  position: relative;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  border: none;
  background: none;
}

.page-link:hover {
  color: white;
}

.page-item.disabled .page-link {

}

.search-input {
  float: right;
  position: relative;
  display: inline-block;
  width: 350px;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid var(--Stoke, #F0F0F0);

  &.mobile {
    width: 214px;
  }
}

.search-input .placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}

.search-input input {
  height: 100%;
  width: 100%;
  padding: 14px 16px 14px calc(14px + 20px + 10px);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
  border: none;
  background: transparent;
  border-radius: 2px;
  outline: 0;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out;
  transition: .25s ease-out;
  font-size: 12px;
}

</style>
