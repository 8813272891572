import TwoFactorConfirmation from "@/components/main/UI/TwoFactorConfirmation";

export default {
  methods: {
    openTwoFactorConfirmation(callback) {
      this.$modal.show(
        TwoFactorConfirmation,
        {
          callback
        },
        {
          height: 'auto',
          width: '410px'
        }
      )
    },
    hideTwoFactorConfirmation() {
      this.$modal.hideAll()
    }
  }
}