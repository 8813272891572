<template>
  <div class="form-control">
    <label :for="htmlFor" v-if="!labelNone">{{ label }}</label>
    <input
        :type="type"
        :id="htmlFor"
        :placeholder="placeholder"
        @input="onChangeHandler"
        :disabled="disabled"
        :value="value"
        @keyup.enter.exact="useOnEnter"
    />
  </div>
</template>

<script>
export default {
  name: "InputBox",
  props: {
    label: String,
    labelNone: Boolean,
    type: String,
    onEnterUse: Function,
    placeholder: String,
    validation: Object,
    value: {
      type: [String, Number, Date]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      htmlFor: Math.random().toString(36).substring(2, 8),
      isValid: false,
      isTouched: false
    }
  },
  methods: {
    useOnEnter() {
      if (!this.onEnterUse) return;
      this.onEnterUse();
    },
    onChangeHandler (event) {
      this.$emit('input', event.target.value);
    }
  }
}
</script>

<style scoped>

</style>