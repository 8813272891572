<i18n>
{
  "en": {
    "title": "Fees",
    "tradingFees": "Trading Fees",
    "level": "Level",
    "volume": "Trade Volume",
    "makerTaker": "Maker / Taker",
    "transactionsFees": "Transactions Fees",
    "txFeesDescription": "For each withdrawal, a flat fee is paid by users to cover the transaction costs of moving the cryptocurrency out of their {0} account. Withdrawals rates are determined by the blockchain network and can fluctuate without notice due to factors such as network congestion. Please check the most recent data listed on each withdrawal page.",
    "coin": "Coin",
    "deposit": "Deposit Fee",
    "withdraw": "Withdrawal Fee",
    "transfer": "Transfer Fee",
    "free": "Free"
  },
  "zh": {
    "title": "费率",
    "tradingFees": "交易费",
    "level": "等级",
    "volume": "成交量",
    "makerTaker": "Maker / Taker",
    "transactionsFees": "交易费用",
    "txFeesDescription": "对于每次提款，用户支付固定费用以支付将加密货币从其 {0} 帐户中移出的交易成本。提款率由区块链网络决定，可能会因以下因素而波动，恕不另行通知 由于网络拥塞。请查看每个提款页面上列出的最新数据。",
    "coin": "硬币",
    "deposit": "存款费用",
    "withdraw": "提款费",
    "transfer": "转会费",
    "free": "免费"
  },
  "kr": {
    "title": "수수료",
    "tradingFees": "거래 수수료",
    "level": "레벨",
    "volume": "거래량",
    "makerTaker": "Maker / Taker",
    "transactionsFees": "거래 수수료",
    "txFeesDescription": "각 인출에 대해 사용자는 계정 {0}에서 암호화폐를 이동하는 거래 비용을 충당하기 위해 고정 수수료를 지불합니다. 출금율은 블록체인 네트워크에 의해 결정되며 통지 없이 변동될 수 있습니다. 이것은 네트워크 정체로 인해 발생합니다. 각 출금 페이지에 나열된 최신 데이터를 확인하십시오.",
    "coin": "동전",
    "deposit": "예금 수수료",
    "withdraw": "인출 수수료",
    "transfer": "이체 수수료",
    "free": "무료"
  }
}
</i18n>
<template>
  <Page style="padding: 0;" :is-page-loaded="dataLoadingStatus">

    <div class="top row">
      <div class="container">
        <h1 style="margin: 45px 0;">{{ $t('title') }}</h1>
      </div>
    </div>

    <div class="container">

      <div>
        <h2>{{ $t('tradingFees') }}</h2>

        <div class="staking-table">
          <div class="header">
            <div class="td">{{ $t('level') }}</div>
            <div class="td">{{ $t('volume') }}</div>
            <div class="td">{{ $t('makerTaker') }}</div>
          </div>

          <div class="body">
            <div class="tr" v-for="plan, index in fees" :key="'fees' + index">
              <div class="td">Lv.{{ index + 1 }}</div>
              <div class="td">{{ index ===  0 ? '&lt;' : '&geq;' }} {{ plan.volume }} BTC</div>
              <div class="td">{{ plan.maker }}% / {{ plan.taker }}%</div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2>{{ $t('transactionsFees') }}</h2>

        <h4 style="color: #707A8A; font-weight: 400;">
          {{ $t('txFeesDescription', [$store.state.Index.websiteName.short]) }}
        </h4>

        <div class="staking-table">
          <div style="padding-right: 36px;" class="header">
            <div class="td">{{ $t('coin') }}</div>
            <div class="td">{{ $t('deposit') }}</div>
            <div class="td">{{ $t('withdraw') }}</div>
            <div class="td">{{ $t('transfer') }}</div>
          </div>

          <div class="body" style="max-height: 600px; overflow-y: auto;">
            <div class="tr" v-for="coin, index in coinList" :key="'coins' + index">
              <div class="td">
                <div style="align-items: center; display:flex; width: 16px; height: 16px;">
                  <img style="margin-right: 5px; width: 100%; height: 100%;" :src="'/currencies/'+coin.code+'.png'" alt="">
                  {{ coin.code }}
                </div>
              </div>
              <div class="td">{{ coin.fees.deposit.amount.toFixed(coin.fees.deposit.type === 'usd' || coin.fees.deposit.type === 'btc' ? 5 : 1) }}{{ coin.fees.deposit.type === 'usd' || coin.fees.deposit.type === 'btc' ? '' : '%' }}</div>
              <div class="td">{{ coin.fees.withdraw.amount.toFixed(coin.fees.withdraw.type === 'usd' || coin.fees.withdraw.type === 'btc' ? 5 : 1) }}{{ coin.fees.withdraw.type === 'usd' || coin.fees.withdraw.type === 'btc' ? '' : '%' }}</div>
              <div class="td">{{ $t('free') }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Page>
</template>

<script>
import axios from "@/plugins/axios";
import Page from "@/components/main/UI/Page";

export default {
  name: "Fees",
  components: {
    Page
  },
  data() {
    return {
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      fees: [],
      coinList: []
    }
  },
  async mounted() {
    try {
      let fetchedData = await axios.get('main/exchange/fees');
      if (fetchedData.data.error) {
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
        return;
      }
      this.fees = fetchedData.data.fees;
      this.coinList = fetchedData.data.coinList;
      this.dataLoadingStatus.isDone = true;
    } catch (e) {
      console.log(e)
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>

.dark {
  .top {
    background-image: linear-gradient(0deg, #212325 0px, #212324 6.67%, #212324 13.33%, #212324 20%, #212324 26.67%, #212223 33.33%, #202122 40%, #1f2121 46.67%, #1f2121 53.33%, #1e2021 60%, #1d2021 66.67%, #1d1f20 73.33%, #1d1f20 80%, #1c1e1f 86.67%, #1c1e1f 93.33%, #1c1e1f 100%);
    background-color: initial;
  }
  .staking-table .header {
    background-image: initial;
    background-color: rgb(31, 33, 33);
    border-color: rgb(52, 56, 58);
  }
  .staking-table .body {
    border-left-color: rgb(52, 56, 58);
    border-right-color: rgb(52, 56, 58);
  }
  .staking-table .body div.tr div.preview div.td {
    color: rgb(144, 136, 125);
  }
  .staking-table .body div.tr .information {
    border-top-color: transparent;
    background-image: initial;
    background-color: rgb(31, 33, 33);
    color: rgb(144, 136, 125);
  }
  .staking-table .body div.tr {
    border-bottom: 1px solid rgb(52, 56, 58);
  }
  .staking-table .body div.tr.open .information {
    height: auto;
    padding: 15px 16px;
    border-top: 1px solid rgb(52, 56, 58);
  }
}

.staking-table {

  margin-bottom: 40px;

  .header {
    display: flex;
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    padding: 5px 16px;

    div.td {
      color: rgb(112, 122, 138);
      font-size: 14px;
      width: 33%;
    }
  }

  .body {
    border-left: 1px solid #F5F5F5;
    border-right: 1px solid #F5F5F5;

    div.tr {
      border-bottom: 1px solid rgb(245, 245, 245);

      display: flex;
      align-items: center;
      padding: 16px 16px;

      div.td {
        width: 33%;
        color: rgb(112, 122, 138);
        font-size: 14px;
      }
    }
  }

}

.top {
  display: block;
  position: relative;
  background: linear-gradient(0deg,#f2f3f7 0,#f3f3f7 6.67%,#f3f4f8 13.33%,#f3f4f8 20%,#f4f5f8 26.67%,#f5f6f9 33.33%,#f7f7fa 40%,#f8f9fb 46.67%,#f9fafc 53.33%,#fbfbfc 60%,#fcfcfd 66.67%,#fdfdfe 73.33%,#fefefe 80%,#fff 86.67%,#fff 93.33%,#fff 100%);
  margin-bottom: 10px;

  div.category {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: calc(100% + 16px);
    height: 32px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #848E9C;
  }

  .coin {
    cursor: pointer;
    transition: .3s;

    &:hover {
      background:  #E7E8EA;
    }

    .data {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 8px 8px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-weight: 700;
      display: flex;
      align-items: center;
      font-size: 13px;

      &.gainer { background: #eff9f5; }
      &.loser { background: #fdf5f5; }

      div.title {
        width: 120px;
        color: #0a0f38;
        display: flex;
        align-items: center;
      }

      div.value {
        width: 95px;
      }

      div.percent {
        width: 95px;
        margin-left: 4px;
        text-align: right;

        &.plus { color: #01aa78; }
        &.minus { color: #e25050; }
      }
    }

  }
}

</style>