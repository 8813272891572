<i18n>
{
  "en": {
    "error": "Error to load captcha.",
    "captcha": "Captcha Verification"
  },
  "zh": {
    "error": "加载验证码出错。",
    "captcha": "验证码验证"
  },
  "kr": {
    "error": "보안 문자를 로드하는 동안 오류가 발생했습니다.",
    "captcha": "보안 문자 확인"
  }
}
</i18n>
<template>
  <div style="font-size: 12px; margin: 10px 0;">
    <span v-if="isError">{{ $t('error') }}</span>
    <div v-else>
      <label :style="labelStyle">{{ $t('captcha') }}</label><br>
      <span @click="loadCaptcha" style="cursor: pointer" :style="svgStyle" v-html="captcha"></span><br>
      <input v-model="captchaText" :style="inputStyle" type="number">
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "captcha",
  props: {
    inputStyle: String,
    svgStyle: String,
    labelStyle: String
  },
  data() {
    return {
      captcha: null,
      captchaText: '',
      isError: false
    }
  },
  watch: {
    captchaText(text) {
      this.$emit('handle-data', text);
    }
  },
  methods: {
    async loadCaptcha(isAdmin) {
      try {
        const fetchedData = await axios.get(`/backoffice/${isAdmin ? 'admin' : 'user'}/captcha`);
        this.captcha = fetchedData.data;
      } catch (e) {
        this.isError = true;
        console.log(e)
      }
    }
  },
  mounted() {
    this.loadCaptcha();
  }
}
</script>

<style scoped>

input {
  outline: none;
  padding: 4px 12px;
}

</style>