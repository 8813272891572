<template>
  <Page
      title="Выплаты"
      parse-url="/backoffice/user/payouts"
      @loaded-data="dataLoading($event)"
  >
    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header">
          <h4>Статистика месяца</h4>
        </div>
        <div style="text-align: center;" class="row m-0">
          <div class="border dashed start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.payoutsTotal }}</p>
            <p class="text-muted m-0">Всего</p>
          </div>
          <div class="border dashed end start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.payouts.total }}</p>
            <p class="text-muted m-0">Текущий месяц</p>
          </div>
        </div>
        <div style="overflow-y: auto;" class="chart p-4">
          <Bar
              :chart-options="chartOptions"
              :chart-data="chartData"
              :styles="{ 'min-width': '800px' }"
              :height="200"
          />
        </div>
      </div>
    </div>

    <div class="Card col-lg-12 mb-5" v-if="!$store.state.Backoffice.spammerInfo.supportMember.isActive">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Процент выплат</h4>
        </div>
        <div class="p-4 row m-0">
          <p class="text-muted" v-if="$store.state.Backoffice.spammerInfo.percent.start !== $store.state.Backoffice.spammerInfo.percent.max">За каждые 1000$ вы получаете +1% к выплате на следующий месяц.</p>
          <p class="text-muted">Максимальный процент {{ $store.state.Backoffice.spammerInfo.percent.max }}. Ваш процент выплат до {{ nextMonth }} - {{ $store.state.Backoffice.spammerInfo.percent.current }}%.</p>

          <div style="position: relative; width: 100%;" class="pt-5 pb-5">
            <div style="position: absolute; top: 0; width: 70px;" :style="{left: fetchedData.currentProfit >= maxCash ? 'calc(100% - 35px)' : 'calc(' + currentPercent.width + '% - 35px)'}">
              <span class="ticker">${{ fetchedData.currentProfit > 0 ? fetchedData.currentProfit.toFixed(2) : '0.00' }}</span>
              <div class="arrow-down"></div>
            </div>
            <div class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{width: fetchedData.currentProfit >= maxCash ? '100%' : currentPercent.width + '%'}"></div>
              <div style="position: absolute; height: 16px; width: 100%;color:black;" class="progress_div d-flex">
                <div class="percent_progress" :style="{ width: 100 / ($store.state.Backoffice.spammerInfo.percent.max - $store.state.Backoffice.spammerInfo.percent.start) + '%' }" style="position: static; border-right: 1px solid black; text-align: right;" v-for="item, index in percentObject" :key="'percent object' + index">
                  ᅠ
                </div>
              </div>
            </div>
            <div style="position: absolute; width: 100%; color:black;" class="d-flex">
              <div style="position: absolute;" class="percent_dot achieved">
                <div style="position: absolute; right: 100%;">
                  <div class="arrow-up"></div>
                  <a style="cursor: help;" title="Ваш начальный процент.">{{ $store.state.Backoffice.spammerInfo.percent.start }}</a>
                </div>
              </div>
              <div :class="{ achieved: fetchedData.currentProfit >= item }" :style="{ width: 100 / ($store.state.Backoffice.spammerInfo.percent.max - $store.state.Backoffice.spammerInfo.percent.start) + '%' }" class="percent_dot" v-for="item, i in percentObject" :key="'percent object 2' + i">
                <div>
                  <div class="arrow-up"></div>
                  <a style="cursor: help;" :title="item - fetchedData.currentProfit > 0 ? `Заработайте ещё $${(item - fetchedData.currentProfit).toFixed(2)} для получения этого процента.` : 'Процент достигнут'">{{ i }}</a>
                </div>
              </div>
            </div>
          </div>

          <p class="text-muted" v-if="$store.state.Backoffice.spammerInfo.percent.start !== $store.state.Backoffice.spammerInfo.percent.max">На данный момент ваш процент в следующем месяце будет равен {{ Math.floor(currentPercent.normal) >= $store.state.Backoffice.spammerInfo.percent.max ? $store.state.Backoffice.spammerInfo.percent.max : Math.floor(currentPercent.normal) }}%.</p>
        </div>
      </div>
    </div>

    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Выплаты</h4>
        </div>
        <Table
            ref="usersTableComponent"
            parse-url="/backoffice/user/spammerPayouts"
            :columnList="payoutsTableColumns"
            default-sort="date"
            searchPlaceholderText="Введите почту или монету"
            @handle-data="payoutsTableData = $event"
        >
          <tr v-for="payout, index in payoutsTableData" :key="'payout' + index">
            <td>{{ payout.username }}</td>
            <td>
              <div style="display: flex; align-items: center; width: 60px;">
                <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                  <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(payout.currency)" alt="">
                </div>
                {{ payout.currency }}
              </div>
            </td>
            <td>${{payout.amount.toFixed(2) | numberSpace }}</td>
            <td :class="payout.status.isError ? 'color-danger' : payout.status.noWallet ? 'color-warning' : payout.status.isPending || payout.status.isSending || payout.fullBack ? 'color-info' : 'color-success'">
              {{ payout.status.isError ? 'Ошибка' : payout.status.noWallet ? 'Нет кошелька' : payout.status.isSending ? 'Транзакция отправляется...' : payout.status.isPending ? 'Ожидает конфирмов' : payout.fullBack ? 'Арбитражная выплата' : 'Выплачено' }}
            </td>
            <td>{{ payout.date | toDate }}</td>
          </tr>
        </Table>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Filler, Tooltip, Legend, BarElement, LineElement, LineController, PointElement, CategoryScale, LinearScale, LogarithmicScale, LogarithmicScaleOptions } from 'chart.js'
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import NotificationCard from "@/components/backoffice/UI/NotificationCard";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";

ChartJS.register(Title, Tooltip, Legend, Filler, BarElement, LineElement, CategoryScale, LineController, PointElement, LinearScale, LogarithmicScale)
export default {
  name: "Payouts",
  components: {
    Page,
    Bar,
    Table
  },
  data() {
    return {
      cashPerPercent: 1000,
      payoutsTableData: [],
      payoutsTableColumns: [
        {
          name: 'username',
          title: 'Юзер',
          isSortable: true
        },
        {
          name: 'currency',
          title: 'Монета',
          isSortable: true
        },
        {
          name: 'amount',
          title: 'Сумма',
          isSortable: true
        },
        {
          name: 'status.isPending',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
      ],
      fetchedData: {
        payouts: {
          total: 0,
          data: [],
          percentDifference: 0
        }
      },
      chartData: {
        labels: Array.from({ length: new Date(new Date().getFullYear(), 1, 0).getDate() }, (_, i) => i + 1),
        datasets: [
          {
            type: 'bar',
            label: 'Выплаты',
            data: [],
            backgroundColor: '#0DA592',
            barThickness: 10,
            order: 2,
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ': ' +'$'+ context.parsed.y.toLocaleString('en-US')
                }
              }
            },
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },
        scales: {
          yAxes: {
            grace: '10%',
            ticks: {
              precision: 0, // точность округления значений по оси y
              maxTicksLimit: 6, // максимальное количество значений (и рёбер сетки) по оси y
            }
          }
        }
      }
    }
  },
  methods: {
    dataLoading(data) {
      this.fetchedData = data;
      this.chartData.datasets[0].data = this.fetchedData.payouts.data;
    }
  },
  computed: {
    currentPercent() {
      const percent = { width: 0, normal: this.$store.state.Backoffice.spammerInfo.percent.start }
      for (var i = 0; i < Object.keys(this.percentObject).length; i++) {
        if (this.fetchedData.currentProfit >= Object.values(this.percentObject)[i]) {
          percent.width += 100 / (this.$store.state.Backoffice.spammerInfo.percent.max - this.$store.state.Backoffice.spammerInfo.percent.start)
          percent.normal += 1
        } else {
          i == 0 ? percent.width += (this.fetchedData.currentProfit / Object.values(this.percentObject)[i]) * (100 / (this.$store.state.Backoffice.spammerInfo.percent.max - this.$store.state.Backoffice.spammerInfo.percent.start)) : percent.width += ((this.fetchedData.currentProfit - Object.values(this.percentObject)[i - 1])/this.cashPerPercent) *(100 / (this.$store.state.Backoffice.spammerInfo.percent.max - this.$store.state.Backoffice.spammerInfo.percent.start));
          break;
        }
      }
      return percent
    },
    maxCash() {
      var max_cash = (this.$store.state.Backoffice.spammerInfo.percent.max - this.$store.state.Backoffice.spammerInfo.percent.start) * this.cashPerPercent
      return max_cash
    },
    percentObject() {
      var percent_object = {}
      for (var i = 1; i < this.$store.state.Backoffice.spammerInfo.percent.max - this.$store.state.Backoffice.spammerInfo.percent.start + 1; i++) {
        percent_object[this.$store.state.Backoffice.spammerInfo.percent.start + i] = this.maxCash - (this.$store.state.Backoffice.spammerInfo.percent.max - this.$store.state.Backoffice.spammerInfo.percent.start - i) * this.cashPerPercent
      }
      return percent_object
    },
    nextMonth() {
      return new Date(new Date().getFullYear(), new Date().getMonth()+1, 1).toLocaleDateString("ru-RU");
    }
  }
}
</script>

<style scoped>
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #343B41;
  border-radius: 0.25rem;
}
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #0DA592;
  transition: width .6s ease;
}

.percent_dot {
  display: flex;
  position: relative;
  left: 8.7px;
  justify-content: flex-end;
}

.percent_dot a {
  padding: 0px 2px 2px 2px;
  border-radius: 50px;
  background: #343B41;
  width: 20px;
  display: block;
  font-size: 12px;
  left: 1px;
  top: -2px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.percent_dot.achieved a {
  background: #0DA592;
  color: white;
}

.percent_dot.achieved .arrow-up {
  border-color: transparent transparent #0DA592 transparent;
}

.arrow-up {
  width: 0;
  height: 0;
  position: relative;
  top: 0;
  left: 6px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #343B41;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top: 7px solid #343B41;
}

.ticker {
  text-align: center;
  width: auto;
  display: block;
  padding: 0px 2px 2px 2px;
  border-radius: 50px;
  color: white;
  background: #343B41;
  font-size: 12px;
  position: relative;
}
</style>