<template>
  <div class="Table" :class="{ bordered: bordered }">
    <table v-if="dataLength > 0">
      <thead>
      <tr>
        <th v-for="column, index in columns" :key="'column' + index">
          {{ column }}
        </th>
      </tr>
      </thead>
      <tbody>
        <slot></slot>
      </tbody>
    </table>
    <div class="empty text-muted" v-else>
      Нет данных
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleTable",
  props: {
    columns: Array,
    dataLength: Number,
    bordered: Boolean
  }
}
</script>

<style lang="scss" scoped>

div.Table.bordered {

  table tbody tr, table thead tr {
    border-top: 1px solid #32383E;
    border-bottom: 1px solid #32383E;
  }

  table tbody tr td{
    padding: 10px 10px !important;
    border-left: 1px solid #32383E;
    border-right: 1px solid #32383E;
  }

  table thead tr th {
    border-left: 1px solid #32383E!important;
    border-right: 1px solid #32383E!important;
    padding: 10px 10px !important;
  }
}

div.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  font-size: 12px;
}

</style>