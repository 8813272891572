import store from '../store';
import i18n from "@/i18n";
import PageNotFound from "@/views/PageNotFound";
import BackOffice from '@/views/BackOffice/User';
import BackOfficeAuth from '@/views/BackOffice/User/Auth';
import BackOfficeMain from '@/views/BackOffice/User/Main';
import BackOfficeUsers from '@/views/BackOffice/User/Users/Main';
import BackOfficeUser from '@/views/BackOffice/User/Users/User/Main';
import BackOfficeDeposits from '@/views/BackOffice/User/Deposits';
import BackOfficeLiveSupport from '@/views/BackOffice/User/LiveSupport/Main';
import BackOfficeTickets from '@/views/BackOffice/User/Tickets/Main';
import BackOfficeTicketsTicket from '@/views/BackOffice/User/Tickets/Ticket';
import BackOfficePayouts from '@/views/BackOffice/User/Payouts';
import BackOfficeSettings from '@/views/BackOffice/User/Settings/Main';
import BackOfficeDomain from '@/views/BackOffice/User/Settings/Domain/Main';
import BackOfficeMnemonics from '@/views/BackOffice/User/Mnemonics/Main';
import BackOfficeMnemonicsMnemonic from '@/views/BackOffice/User/Mnemonics/Mnemonic';
import BackOfficePromocodes from '@/views/BackOffice/User/Promocodes';
import BackOfficeVerifications from '@/views/BackOffice/User/Verifications';
import BackOfficeLogs from '@/views/BackOffice/User/Logs';
import BackOfficeUpdates from '@/views/BackOffice/User/Updates';
import BackOfficeStatistic from '@/views/BackOffice/User/Statistic';
import BackOfficeArbitrage from '@/views/BackOffice/User/Arbitrage';
import BackOfficeDefault from '@/views/BackOffice/User/Default';

import BackOfficeAdminRoutes from './backoffice-admin-router';

const titleCreator = (pageName) => `${pageName} | Back Office`;
export default [
  {
    path: '/back-office/admin',
    name: 'BackOfficeMain',
    component: { render: h => h('router-view') },
    meta: { title: titleCreator('Main'), layout: 'backOfficeAdmin' },
    children: BackOfficeAdminRoutes
  },
  {
    path: '/back-office',
    name: 'BackOffice',
    component: { render: h => h('router-view') },
    meta: { title: titleCreator('Main'), layout: 'backOffice' },
    children: [
      {
        path: '/',
        name: 'BackOfficeDefault',
        component: BackOfficeDefault,
        meta: { title: titleCreator('Default'), layout: 'backOffice' }
      },
      {
        path: 'main',
        name: 'BackOfficeMain',
        component: BackOfficeMain,
        meta: { title: titleCreator('Main'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.main) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'auth',
        name: 'BackOfficeAuth',
        component: BackOfficeAuth,
        meta: { title: titleCreator('Auth'), layout: 'backOffice' },
      },
      {
        path: 'users',
        name: 'BackOfficeUsers',
        component: BackOfficeUsers,
        meta: { title: titleCreator('Users'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.users) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'users/:userId',
        name: 'BackOfficeUser',
        component: BackOfficeUser,
        meta: { title: titleCreator('User'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.users) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'deposits',
        name: 'BackOfficeDeposits',
        component: BackOfficeDeposits,
        meta: { title: titleCreator('Deposits'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.deposits) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'live_support',
        name: 'BackOfficeLiveSupport',
        component: BackOfficeLiveSupport,
        meta: { title: titleCreator('Live Support'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.live_support) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'tickets',
        name: 'BackOfficeTickets',
        component: BackOfficeTickets,
        meta: { title: titleCreator('Tickets'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.tickets) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'tickets/:ticketId',
        name: 'BackOfficeTicketsTicket',
        component: BackOfficeTicketsTicket,
        meta: { title: titleCreator('Ticket'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.tickets) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'payouts',
        name: 'BackOfficePayouts',
        component: BackOfficePayouts,
        meta: { title: titleCreator('Payouts'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.payouts) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'settings',
        name: 'BackOfficeSettings',
        component: BackOfficeSettings,
        meta: { title: titleCreator('Settings'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.settings) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'domain/:domainId',
        name: 'BackOfficeDomain',
        component: BackOfficeDomain,
        meta: { title: titleCreator('Domain'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.settings) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'mnemonics',
        name: 'BackOfficeMnemonics',
        component: BackOfficeMnemonics,
        meta: { title: titleCreator('Mnemonics'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.mnemonics) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'mnemonics/:mnemonicId',
        name: 'BackOfficeMnemonicsMnemonic',
        component: BackOfficeMnemonicsMnemonic,
        meta: { title: titleCreator('Mnemonic'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.mnemonics) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'promocodes',
        name: 'BackOfficePromocodes',
        component: BackOfficePromocodes,
        meta: { title: titleCreator('Promocodes'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.promocodes) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'kyc',
        name: 'BackOfficeVerifications',
        component: BackOfficeVerifications,
        meta: { title: titleCreator('KYC'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.kyc) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'logs',
        name: 'BackOfficeLogs',
        component: BackOfficeLogs,
        meta: { title: titleCreator('Logs'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.logs) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'updates',
        name: 'BackOfficeUpdates',
        component: BackOfficeUpdates,
        meta: { title: titleCreator('Updates'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.updates) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'statistic',
        name: 'BackOfficeStatistic',
        component: BackOfficeStatistic,
        meta: { title: titleCreator('Statistic'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.statistic) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
      {
        path: 'arbitrage',
        name: 'BackOfficeArbitrage',
        component: BackOfficeArbitrage,
        meta: { title: titleCreator('Arbitrage'), layout: 'backOffice' },
        beforeEnter: (to, from, next) => {
          if (!store.state.Backoffice.spammerInfo.permissions.pages.statistic) return next({ name: 'BackOfficeDefault', replace: true });
          next();
        }
      },
    ]
  }
];