<template>
  <div class="p-4">
    <div style="display: flex; align-items: center; justify-content: space-between;" class="mb-2" v-for="permission, index in Object.keys(permissionLabels)" :key="'permission' + index">
      <span>{{ permissionLabels[permission] }}</span>
      <SwitchButton
          @switch-change="changeUserPermission(permission, $event)"
          :is-checked="getObjectValueByString(permission)"
      />
    </div>
    <div class="pb-4"></div>
    <div style="display: flex; align-items: center; justify-content: space-between;" class="mb-2" v-for="info, index in userInformation" :key="'user total info' + index">
      <span>{{ info.text }}</span>
      <span style="font-size: 13px;" class="d-flex align-items-center"><img class="mr-2" width="25px" height="15px" :src="'/country/' + info.value + '.svg'" v-show="info.text === 'Страна'" />{{ info.value }}</span>
    </div>
    <div style="text-align: center;" class="mt-3">
      <router-link :to="{ name: 'BackOfficeLiveSupport', params: { userId: userInfo._id } }">Открыть лайв саппорт</router-link>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";

export default {
  name: "UserTotal",
  props: {
    userInfo: Object
  },
  components: {
    SwitchButton,
  },
  data() {
    return {
      permissionLabels: {
        'settings.accountActivation.isActivated': 'Аккаунт активирован',
        'settings.accountActivation.isEmailConfirmed': 'Почта подтверждена',
        'settings.isPremium': 'Премиум',
        'permission.isBanned': 'Забанен',
        'permission.multiDomain': 'Может заходить на любые домены',
        'permission.hideNewDeposits': 'Скрывать новые депозиты',
        'settings.isWelcomeBonusUsed': 'Бонус к депозиту использован',
        'permission.canAbusePromocode': 'Может абузить промокод',
        'permission.canTrade': 'Может трейдить',
        'permission.canUseCode': 'Может активировать код',
        'permission.canTransferToUser': 'Может переводить другому юзеру',
        'permission.canChangeAccountDetails': 'Может менять данные аккаунта',
        'permission.fakeWithdraw': 'Фейк вывод',
        'settings.twoFactorAuthorization.isActive': 'Двухфакторная аутентификация',
        'settings.isWalletConnectEnabled': 'Включен WalletConnect'
      },
      userInformation: [
        { text: 'Айди', value: this.userInfo.id },
        { text: 'Страна', value: this.userInfo.country ? this.userInfo.country.toLowerCase() : 'unk' },
        { text: 'Домен', value: this.userInfo.hostname },
        { text: 'Всего внесено', value: '$'+this.userInfo.deposited.toFixed(2) },
        { text: 'Использованный промокод', value: this.userInfo.referralSystem.activatedCode || 'Нет' },
        { text: 'Последняя активность', value: this.$options.filters.toDate(this.userInfo.settings.lastActiveDate) },
        { text: 'Дата регистрации', value: this.$options.filters.toDate(this.userInfo.settings.registrationDate) },
        { text: 'Дата привязки', value: this.$options.filters.toDate(this.userInfo.attachedDate) },
        { text: 'Последний IP входа', value: this.userInfo.settings.lastAuthIp },
        { text: 'IP регистрации', value: this.userInfo.settings.registrationIp },
      ]
    }
  },
  methods: {
    async changeUserPermission(permission, value) {
      try {
        const fetchedData = await axios.post('backoffice/user/user/permission', { userId: this.userInfo._id, permission, value });
        if (fetchedData.data.error) {
          this.$callNotification(this.$i18n.locale, fetchedData);
        }
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, {
          error: true,
          message: 'Ошибка при изменении прав юзера'
        });
      }
    },
    getObjectValueByString(path) {
      return path.split('.').reduce((p,c)=>p&&p[c]||null, this.userInfo);
    }
  }
}
</script>

<style scoped>

span { word-break: break-word; }

span:nth-child(2) {
  text-align: right;
}

</style>