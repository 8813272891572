<template>
  <router-view></router-view>
</template>

<script>
export default {
name: "main-template.vue"
}
</script>

<style scoped>

</style>