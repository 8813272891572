<template>
  <div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Схема смарт депозитов</h4>
          </div>
          <div class="p-4 row m-0">

            <div class="d-flex align-items-center mb-4">
              <span class="mr-3">Смарт депозиты активны</span>
              <SwitchButton
                  :is-checked="information.settings.smartDeposit.isActive"
                  @switch-change="information.settings.smartDeposit.isActive = $event"
              />
            </div>

            <div class="mb-2">Сообщения</div>
            <label class="text-muted mb-4" style="display: block; font-size: 12px;">Можно использовать переменные в сообщениях.<br>Чтобы поменять схемы местами зажмите схему и перенесите на нужное место.</label>
            <p class="text-muted">*Если включено "Учитывать комиссию", то сумма депозита будет учитываться с комиссией. Пример: юзер внес 0.01 BTC, комиссия на бирже стоит 1%, по итогу сумма депозита составляет 0.009, в схеме депозита указан точный депозит 0.01 - 0.01 в BTC, если "Учитывать комиссию" включено, то условие будет ложным и смарт депозит не сработает, т.к. депозит с учетом комиссии 0.009 &lt; 0.01, а если выключено, то депозит юзера без комиссии составляет 0.01 = 0.01, смарт депозит сработает.</p>
            <draggable v-model="information.settings.smartDeposit.scheme">
              <transition-group>
                <div class="terms" v-for="scheme, index in information.settings.smartDeposit.scheme" :key="' smart deposit scheme ' + index">

                  <div class="d-flex align-items-center mb-2">
                    <div @click="updatePresetName(index)" class="mr-3">
                      <font-awesome-icon style="font-size: 13px; cursor: pointer;" :icon="['fas', 'pen']" />
                    </div>
                    <div @click="$event.target.parentElement.parentElement.classList.toggle('open')" class="section d-flex w-100">
                      {{ scheme.title }}
                      <div @click="information.settings.smartDeposit.scheme.splice(index, 1)" style="margin-left: auto; z-index: 99;">
                        <i class="fal fa-trash-alt"></i>
                      </div>
                    </div>
                  </div>

                  <div class="content p-3">
                    <div class="pb-4">
                      <p>Условия активации</p>
                      <div class="pl-3 pr-3">
                        <div>
                          <div class="d-flex align-items-center mb-2">
                            <span class="mr-3">Общая сумма депозитов в USD (с учетом нового депозита)</span>
                            <SwitchButton
                                :is-checked="scheme.conditions.deposit.total.isActive"
                                @switch-change="scheme.conditions.deposit.total.isActive = $event"
                            />
                          </div>
                          <div class="d-flex" v-show="scheme.conditions.deposit.total.isActive">
                            <InputBox
                                label="От"
                                type="number"
                                v-model="scheme.conditions.deposit.total.amount[0]"
                                class="col-lg-6"
                            />
                            <InputBox
                                label="До"
                                type="number"
                                v-model="scheme.conditions.deposit.total.amount[1]"
                                class="col-lg-6"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="d-flex align-items-center mt-4">
                            <span class="mr-3 d-flex align-items-center">
                              Сумма депозита в
                              <div class="form-control ml-2 p-0 mb-0">
                                <select v-model="scheme.conditions.deposit.once.type">
                                  <option value="usd">USD</option>
                                  <option value="btc">BTC</option>
                                </select>
                              </div>
                            </span>
                            <SwitchButton
                                :is-checked="scheme.conditions.deposit.once.isActive"
                                @switch-change="scheme.conditions.deposit.once.isActive = $event"
                            />
                          </div>
                          <div class="mb-4" v-show="scheme.conditions.deposit.once.isActive">
                            <div class="d-flex align-items-center mb-2">
                              <span style="font-size: 12px;" class="mr-3">Учитывать комиссию*</span>
                              <SwitchButton
                                  :is-checked="scheme.conditions.deposit.once.countCommission"
                                  @switch-change="scheme.conditions.deposit.once.countCommission = $event"
                              />
                            </div>
                            <div class="d-flex">

                              <InputBox
                                  label="От"
                                  type="number"
                                  v-model="scheme.conditions.deposit.once.amount[0]"
                                  class="col-lg-6"
                              />
                              <InputBox
                                  label="До"
                                  type="number"
                                  v-model="scheme.conditions.deposit.once.amount[1]"
                                  class="col-lg-6"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-items-center mb-2 mt-4">
                          <span class="mr-3">KYC верифицирован</span>
                          <SwitchButton
                              :is-checked="scheme.conditions.isVerified.isActive"
                              @switch-change="scheme.conditions.isVerified.isActive = $event"
                          />
                        </div>
                        <div class="d-flex align-items-center mb-2">
                          <span class="mr-3">Вкладка депозит верифицирован</span>
                          <SwitchButton
                              :is-checked="scheme.conditions.isDepositVerified.isActive"
                              @switch-change="scheme.conditions.isDepositVerified.isActive = $event"
                          />
                        </div>
                        <div class="d-flex align-items-center mb-2">
                          <span class="mr-3">Премиум активирован</span>
                          <SwitchButton
                              :is-checked="scheme.conditions.isPremium.isActive"
                              @switch-change="scheme.conditions.isPremium.isActive = $event"
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <p>Действия при активации</p>
                      <div class="pl-3 pr-3">

                        <div class="form-control mb-0">
                          <div class="d-flex align-items-center mb-2">
                            <span style="font-size: 14px;" class="mr-3">Изменить ошибку при выводе</span>
                            <SwitchButton
                                :is-checked="scheme.actions.messages.defaultWithdraw.isActive"
                                @switch-change="scheme.actions.messages.defaultWithdraw.isActive = $event"
                            />
                          </div>
                          <textarea class="mb-3" rows="4" v-model="scheme.actions.messages.defaultWithdraw.message" v-show="scheme.actions.messages.defaultWithdraw.isActive"></textarea>
                        </div>

                        <div class="form-control mb-0">
                          <div class="d-flex align-items-center mb-2">
                            <span style="font-size: 14px;" class="mr-3">Изменить ошибку WalletConnect</span>
                            <SwitchButton
                                :is-checked="scheme.actions.messages.walletConnect.isActive"
                                @switch-change="scheme.actions.messages.walletConnect.isActive = $event"
                            />
                          </div>
                          <textarea class="mb-3" rows="4" v-model="scheme.actions.messages.walletConnect.message" v-show="scheme.actions.messages.walletConnect.isActive"></textarea>
                        </div>

                        <div class="form-control mb-0">
                          <div class="d-flex align-items-center mb-2">
                            <span style="font-size: 14px;" class="mr-3">Изменить сообщение на вкладке верификация-депозит</span>
                            <SwitchButton
                                :is-checked="scheme.actions.messages.verification.isActive"
                                @switch-change="scheme.actions.messages.verification.isActive = $event"
                            />
                          </div>
                          <textarea class="mb-3" rows="4" v-model="scheme.actions.messages.verification.message" v-show="scheme.actions.messages.verification.isActive"></textarea>
                        </div>

                        <div class="d-flex align-items-center mb-2">
                          <span class="mr-3">Верифицировать вкладку депозит</span>
                          <SwitchButton
                              :is-checked="scheme.actions.isDepositVerified.isActive"
                              @switch-change="scheme.actions.isDepositVerified.isActive = $event"
                          />
                        </div>
                        <div class="d-flex align-items-center mb-2">
                          <span class="mr-3">Включить WalletConnect</span>
                          <SwitchButton
                              :is-checked="scheme.actions.isWalletConnectEnabled.isActive"
                              @switch-change="scheme.actions.isWalletConnectEnabled.isActive = $event"
                          />
                        </div>
                        <div class="d-flex align-items-center mb-2">
                          <span class="mr-3">Активировать премиум</span>
                          <SwitchButton
                              :is-checked="scheme.actions.isPremium.isActive"
                              @switch-change="scheme.actions.isPremium.isActive = $event"
                          />
                        </div>
                        <div class="d-flex align-items-center mb-2">
                          <span class="mr-3">Включить фейк вывод</span>
                          <SwitchButton
                              :is-checked="scheme.actions.fakeWithdraw.isActive"
                              @switch-change="scheme.actions.fakeWithdraw.isActive = $event"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </transition-group>
            </draggable>

            <div class="mt-3 mb-2 d-flex">
              <label @click="newPreset" style="margin: auto;">+ добавить cхему</label>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
              <Button
                  class="w-100 primary"
                  :callback="updateSmartDepositStatus"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import axios from "@/plugins/axios";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import draggable from 'vuedraggable'

export default {
  name: "LiveSupport",
  props: {
    information: Object
  },
  components: {
    SwitchButton,
    Button,
    draggable,
    InputBox
  },
  data() {
    return {

    }
  },
  methods: {
    updatePresetName(index) {
      const newName = prompt("Введите новое название схемы:", this.information.settings.smartDeposit.scheme[index].title);
      if (!newName) return;

      this.information.settings.smartDeposit.scheme[index].title = newName;
    },
    newPreset() {
      try {
        this.information.settings.smartDeposit.scheme.push({
          title: "Новая схема",
          conditions: {
            deposit: {
              total: { isActive: false, amount: [0, 100] },
              once: { isActive: false, amount: [0, 100], type: 'usd', countCommission: false },
            },
            isVerified: { isActive: false },
            isDepositVerified: { isActive: false },
            isPremium: { isActive: false },
          },
          actions: {
            messages: {
              defaultWithdraw: { isActive: false, message: "New withdraw message" },
              walletConnect: { isActive: false, message: "New wallet connect message" },
              verification: { isActive: false, message: "New verification message" },
            },
            isDepositVerified: { isActive: false },
            isWalletConnectEnabled: { isActive: false },
            isPremium: { isActive: false },
            fakeWithdraw: { isActive: false },
          }
        });
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateSmartDepositStatus() {
      try {
        const fetchedData = await axios.post('backoffice/user/spammer/updateSmartDepositStatus', {
          smartDeposit: this.information.settings.smartDeposit
        });

        if (!fetchedData.data.error) await this.$store.dispatch('Backoffice/setSmartDepositStatus', this.information.settings.smartDeposit);
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.terms .section {
  padding: 5px 25px;
  border-bottom: 1px solid #32383E;
  cursor: pointer;
  transition: .3s;
  font-size: 13px;
  border-radius: 5px;
}


.terms .section:hover {
  background: #343B41;
}

.terms .content {
  display: none;
}

.terms.open .content {
  display: block;
  transition: .3s visibility;
}

.terms .content textarea {
  resize: none;
}
</style>