<template>
  <div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Настройки администратора</h4>
          </div>
          <div class="p-4 row m-0">

            <div>
              <p class="text-muted m-0 mb-2">Процент выплат при создании спаммера</p>
              <div class="form-control col-lg-6">
                <label>Начальный процент</label>
                <input type="number" v-model="information.percent.spammerStartPercent">
              </div>
              <div class="form-control col-lg-6">
                <label>Максимальный процент</label>
                <input type="number" v-model="information.percent.spammerMaxPercent">
              </div>
            </div>

            <div>
              <p class="text-muted m-0 mb-2">Телеграм-бот команды</p>
              <div class="form-control col-lg-12" v-show="information.settings.telegramBot.isActive">
                <input type="text" disabled="true" placeholder="myteambot" v-model="information.settings.telegramBot.link">
              </div>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
              <Button
                  class="w-100 primary"
                  :callback="updateGeneralSettings"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Button from "@/components/backoffice/UI/Button";
import axios from "@/plugins/axios";

export default {
  name: "General",
  props: {
    information: Object
  },
  components: {
    Button
  },
  methods: {
    async updateGeneralSettings(){
      try {
        const fetchedData = await axios.post('backoffice/admin/spammer/general', { information: this.information });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>