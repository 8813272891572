<i18n>
{
  "en": {
    "title": "F.A.Q."
  },
  "zh": {
    "title": "F.A.Q."
  },
  "kr": {
    "title": "F.A.Q."
  }
}
</i18n>
<template>
  <Page style="padding: 0;" :is-page-loaded="dataLoadingStatus">

    <div class="top row">
      <div class="container">
        <h1 style="margin: 45px 0;">{{ $t('title') }}</h1>
      </div>
    </div>

    <div class="container" style="margin-bottom: 40px;">
      <div id="faq">

        <div style="padding: 20px;" data-aos="fade-up" class="container" v-for="section in sections" :key="section.title">
          <div style="margin-bottom: 10px;">
            <strong>{{ section.title }}</strong>
          </div>
          <div class="questions">
            <div class="question" v-for="question in section.questions" :key="question.title">
              <div @click="openQuestion" class="title">
                <div class="arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" class="BaseCollapse__headerBtnArrow"><path fill="#2A85FF" d="M40.4 121.3c-.8.8-1.8 1.2-2.9 1.2s-2.1-.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8 0l53.9 53.9c1.6 1.6 1.6 4.2 0 5.8l-53.9 53.9z"></path></svg>
                </div>{{ question.title }}
              </div>
              <div class="body">
                <div v-html="question.body" class="sb">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </Page>
</template>

<script>
import axios from "@/plugins/axios";
import Page from "@/components/main/UI/Page";

export default {
  name: "Terms",
  components: {
    Page
  },
  data() {
    return {
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      sections: [
        {
          title: 'Authorization',
          questions: [
            {
              title: 'How to log in to your account?',
              body: `<h6>Go to <a href="auth" target="_blank" rel="noopener">/sign-in</a> . In this window, enter your e-mail address, password, and captcha. Click the Login button.</h6><h6>You logged in to your account. </h6><h6>Now you can make a <a href="dashboard/wallets/deposit" target="_blank" rel="noopener">deposit</a> or start trading.<br>We also recommend you to log into your personal account and enable the two-factor authentication.<br>More information can be found at the link: <a href="about" target="_blank" rel="noopener">/about</a></h6>`
            },
            {
              title: 'How to register an account?',
              body: '<h6>Go to <a href="sign-up" target="_blank" rel="noopener">/sign-up</a> . In this window, enter your e-mail address, password, password confirmation, captcha and agree to the Terms of Use. Click the Sign Up button. </h6><h6>Check your e-mail in several minutes. Click the activation link.</h6><h6>Welcome to the Exchange!</h6>'
            },
            {
              title: `What should I do if I haven't received the confirmation letter?`,
              body: '<h6>Please check the Spam folder first. If you still can not find our e-mails, please contact <a href="dashboard/tickets" target="_blank" rel="noopener">support.</a></h6>'
            },
            {
              title: 'How can I change my personal data if I do not have access to my account (forgot my password) and to my mail?',
              body: `<h6>Pin-code is our main security element, so its recovery takes place privately.</h6><h6>Please write your e-mail address and the reason you cannot access it to our e-mail <a href="mailto:support@` + this.$store.state.Index.websiteName.full + `" target="_blank" rel="noopener">support@` + this.$store.state.Index.websiteName.full + `</a>, indicate your latest deposits and actions on the account, how replenishments were made, their amount and screenshots.</h6><h6>Also, you can use the link for password recovery - <a href="reset-password" target="_blank" rel="noopener">/forgot-password</a></h6>`
            },
            {
              title: 'What do I do if I need to make changes to the data entered during the verification process?',
              body: `<h6>You need to send a written request to our mail - <a href="mailto:support@` + this.$store.state.Index.websiteName.full + `" target="_blank" rel="noopener">support@` + this.$store.state.Index.websiteName.full + `</a></h6>`
            },
            {
              title: 'Can I delete my account?',
              body: `<h6>This option is not available. You can block the account.</h6><h6>To do this, you can create  a corresponding <a href="dashboard/tickets" target="_blank" rel="noopener">ticket</a> in the technical support, where you need to specify a valid e-mail as well as the KYC data. You can create a ticket here: <a href="dashboard/tickets" target="_blank" rel="noopener">tickets</a></h6>`
            }
          ]
        },
        {
          title: 'Deposit / Withdrawal',
          questions: [
            {
              title: 'How to make a currency withdrawal on the exchange?',
              body: `<h6> Go to the <a href="dashboard/wallets" target="_blank" rel="noopener">Balance</a> tab and click on the "Withdraw" next to the desired coin, enter the address and the amount.</h6>`
            },
            {
              title: 'On what terms are the requests for withdrawal being processed?',
              body: `<h6>After the identity verification, all applications will be made within 24 hours.</h6>`
            },
            {
              title: 'How do I cancel a withdrawal request?',
              body: `<h6>This option is not available. You can still contact our <a href="dashboard/tickets" target="_blank" rel="noopener">support</a>, we will do everything possible to help you solve this situation. </h6>`
            },
            {
              title: 'How do I replenish my balance?',
              body: `<h6>Go to the <a href="dashboard/wallets/deposit" target="_blank" rel="noopener">Balance</a> tab at the top of the page. To replenish your balance, click "Deposit" next to the desired coin.</h6>`
            }
          ]
        },
        {
          title: '' + this.$options.filters.capitalize(this.$store.state.Index.websiteName.short) + ' Referral Program',
          questions: [
            {
              title: 'What is ' + this.$options.filters.capitalize(this.$store.state.Index.websiteName.short) + ' Referral Program?',
              body: `<h6><strong>Referral Program</strong><span style="font-weight: 400;"> is an opportunity to make money with your friends!</span></h6><h6><span style="font-weight: 400;">Referral - a member of the affiliate program, registered by the recommendation of another member.</span></h6>`
            },
            {
              title: 'How does the Referral Program work?',
              body: `<h6><span style="font-weight: 400;">- Tell your friends about us;</span></h6><h6><span style="font-weight: 400;">- They need to register by your referral link;</span></h6><h6><span style="font-weight: 400;">- You will receive 40% of the amount of fees paid by them;</span></h6><h6><span style="font-weight: 400;">- Your trade commission will be 0.1%.</span></h6>`
            },
            {
              title: 'How to become a member of Referral Program?',
              body: `<h6><span style="font-weight: 400;">Select the “<a href="dashboard/referral" target="_blank" rel="noopener">Referral Program</a>” section</span><span style="font-weight: 400;"> In your account. Here you can find the information about your referrals (invited users), as well as your personal referral link is displayed here.</span></h6><h6></h6><h6><span style="font-weight: 400;">To invite a new referral, just share this link (by copying it or making a repost in social networks). After your referral registers with your link, information about it will be displayed in the “Details about referrals” section (your referrals).</span></h6><h6> </h6><h6><span style="font-weight: 400;">Congratulations! You have become a member of the ` + this.$options.filters.capitalize(this.$store.state.Index.websiteName.short) + ` Referral Program. Now you will receive a 40% cashback from the commission paid for each transaction made by your referral!</span></h6><h6> </h6>`
            }
          ]
        },
        {
          title: '' + this.$options.filters.capitalize(this.$store.state.Index.websiteName.short) + ' Staking Service',
          questions: [
            {
              title: 'What is ' + this.$options.filters.capitalize(this.$store.state.Index.websiteName.short) + ' Staking Service?',
              body: `<h6>Staking is the process of holding funds in a cryptocurrency wallet to support the operations of a blockchain network. For more on staking, please refer to Binance Academy.</h6>`,
            },
            {
              title: 'How to order this service?',
              body: `<h6>To create an staking order, you must go to the <a href="dashboard/staking" target="_blank" rel="noopener">Staking</a> page and select a coin, the desired plan and pay for the order.</h6>`,
            },
            {
              title: 'How long does one order last?',
              body: `<h6>The duration of the order is unlimited. You can cancel staking order at any time.</h6>`,
            }
          ]
        },
        {
          title: 'General Questions',
          questions: [
            {
              title: 'How to apply to list?',
              body: `<h6>At the bottom of the page, select the <a href="listing-with-us" target="_blank" rel="noopener">List Now</a> section:</h6><h6>Then the online application form will open.</h6><h5><strong>Requirements to list your coin/token</strong></h5><h6>1. Your project should have a website containing your contact information and a detailed description of the idea. Also official Reddit and Bitcointalk threads for your project are required, as these are respectable and popular discussions platforms.</h6><h6>2. Your request will be reviewed within a week, and you will receive our reply to the email that you indicated in the form. Please note this email should have the same domain as the official project website.</h6><h6>Expect an answer within a week.</h6>`
            },
            {
              title: 'What are the criteria for cryptocurrency to be listed on the exchange?',
              body: `<h6>Before entering the exchange, each Cryptocurrency must prove its usefulness, value and liquidity in order to further support the customers' demand.</h6><h6>In addition, the coin that applies to be listed must be reliably protected. The project code must be public and verified.</h6>`
            },
            {
              title: 'How long does it take to receive a response from the support team? How does it work?',
              body: `<h6>For the convenience of our users, we have a customer support service that works 7 days a week and 24 hours a day without breaks and holidays. If you have any questions, wishes or suggestions, our specialists are always ready to help you with any problem, and also to hear your suggestions for improving the service. We will do our best to reply ASAP.</h6>`
            },
            {
              title: 'Can I connect a bank account to my account?',
              body: `<h6>Unfortunately, this option is currently unavailable on our exchange. </h6>`
            }
          ]
        },
        {
          title: 'Secure your funds',
          questions: [
            {
              title: 'How to enable the two-factor authentication?',
              body: `<h6>Download the Google Authenticator application from Play Market or AppStore. Go to your<a href="dashboard/settings/security/google-authentication" target="_blank" rel="noopener"> Account </a>(to do this you need to click on your email in the top right corner)</h6><h6>and then choose the <a href="dashboard/settings/security" target="_blank" rel="noopener">Security </a>section.</h6><h6> </h6><h6>Scan the QR code in the downloaded application or enter the number manually. After the "` + this.$options.filters.capitalize(this.$store.state.Index.websiteName.short) + `" tab appears in the app, enter the authentication code in the “Please Enter Key” field. All set, you enabled the two-factor authentication!</h6>`
            },
            {
              title: 'What if I lost the code / key to two-factor authentication?',
              body: `<h6>You need to provide KYC data, account login, as well as data of the latest currency deposits and withdrawals and send this information to <a href="mailto:support@` + this.$store.state.Index.websiteName.full + `" target="_blank" rel="noopener">support@` + this.$store.state.Index.websiteName.full + `</a>.</h6>`
            },
            {
              title: 'Is it safe to use ' + this.$options.filters.capitalize(this.$store.state.Index.websiteName.short) + ' exchange?',
              body: `<h6>Yes! The main priority in our work is the security of our clients' personal data and security. We use advanced methods and technologies in the field of security. We implemented two-factor authentication technology to protect your accounts, as well as Anti Phishing, which helps to ensure the reliability of our exchange. More than 95% of all currency is stored on cold wallets. WAF (Web Application Firewall) - a protective screen of a Web application that detects and blocks hacker attacks.</h6>`
            },
            {
              title: 'What is two-factor authentication?',
              body: `<h6>Two-factor authentication is an additional resource that allows you to access your account, even if someone else has access to your password. When you log in to your account, you will be offered two pieces of information: your password and a 6-digit authentication code. The authentication app generates new authentication code every 30 seconds. We strongly recommend that all our users enable this security feature to prevent any potential unauthorized access to their accounts. This function is necessary to ensure the security of your assets, so please take the time to set it up as soon as possible!</h6>`
            },
            {
              title: 'What is KYC and why is it needed?',
              body: `<h6>Know your customer (abbreviated KYC) is the term of banking and exchange regulation for financial institutions and bookmakers, as well as other companies working with private money, meaning that they must identify and establish the identity of the counterparty before conducting a financial transaction.<br>This requirement extends to obtaining reasonably complete information about counterparties-legal entities, the nature of their business and certain business transactions for which a financial transaction is being conducted.</h6>`
            }
          ]
        },
        {
          title: 'Currency operations',
          questions: [
            {
              title: `I purchased currency several days ago, but it still hasn't been received. What do I do?`,
              body: `<h6>Please <a href="dashboard/tickets" target="_blank" rel="noopener">send us</a> a hash of your transaction and we will check all the information.</h6>`
            },
            {
              title: 'I can not cancel the Order / Tokens were not credited to the account',
              body: `<h6>Please send us an email to <a href="mailto:support@` + this.$store.state.Index.websiteName.full + `" target="_blank" rel="noopener">support@` + this.$store.state.Index.websiteName.full + `</a> with a screenshot of the order, or of the errors attached. We will try to resolve your issue as soon as possible.</h6>`
            },
            {
              title: 'Can I cancel an Order?',
              body: `<h6>Yes, to cancel an order, please go to the <a href="exchange" target="_blank" rel="noopener">Exchange page</a> at the top of the page.</h6><h6>Please note that you can only cancel those orders that have not yet been accepted, since once the order is executed, such transaction is irreversible and can not be canceled.</h6>`
            },
            {
              title: 'Where can I see the history of my orders?',
              body: `<h6>You can see the history of your transactions on the <a href="dashboard/orders" target="_blank" rel="noopener">Orders page</a>.</h6>`
            },
            {
              title: 'When will my Order be processed?',
              body: `<h6>Please pay attention that the administration of the site does not accept, control or interfere with trading on the exchange. Please wait until another user accepts your Order. Alternatively, you can cancel an open order and submit a new order with a more competitive price.</h6>`
            },
            {
              title: 'I placed an order, but for some reason, it is not accepted, although I can see cheaper Buy Orders being already processed. Why?',
              body: `<h6>Orders can only be purchased by users of the exchange, the administration of the site does not accept, control or interfere with trading on the exchange. If you want to speed up the process, you can cancel the open Order and submit a new order with a more competitive price.</h6>`
            },
            {
              title: 'Where can I see Buy Orders?',
              body: `<h6>Buy Orders will be displayed on the main page, or in the <a href="exchange" target="_blank" rel="noopener">Trade </a>tab.</h6>`
            },
            {
              title: 'How to create an Order?',
              body: `<h6>To create an order, go to the <a href="exchange" target="_blank" rel="noopener">Trade</a> section of the main page. You can select the cryptocurrency in the "Market" window by double-clicking on the desired coin.</h6><h6>On this page you can see the forms for buying and selling cryptocurrency.</h6><h6>Please pay attention that the Order to buy or sell can not exceed your balance.</h6><h6><br>On this page, you can also see windows showing actual Buy/Sell Orders of other users of our exchange. Note that the rate is not fixed, since ` + this.$options.filters.capitalize(this.$store.state.Index.websiteName.short) + ` is an exchange, not an exchanger, so the rates are formed based on the orders placed by other users on the <a href="exchange" target="_blank" rel="noopener">Trade</a> page.</h6><h6>After placing an order, you can see it either on the <a href="exchange" target="_blank" rel="noopener">Trade</a> page, in the "My Orders" window:</h6><h6>Or on the <a href="dashboard/orders" target="_blank" rel="noopener">Orders</a> page.</h6>`
            },
            {
              title: 'What is the trading fee?',
              body: `<h6>You can find detailed information about fees on our website by clicking the <a href="fees" target="_blank" rel="noopener">Fee Schedule</a> tab, which is located at the bottom of the page.</h6><h6> For a specific type of orders, the Transaction fee may differ from those published on the <a href="fees" target="_blank" rel="noopener">Fee Schedule</a> page. You will see the fee for your transaction before you execute the order.</h6>`
            },
            {
              title: 'Is there any transaction limit? What is the maximum / minimum amount of the transaction?',
              body: `<h6>The min. and max. order amount varies for each trading pair and can be seen at the <a href="exchange" target="_blank" rel="noopener">Trade Page </a>when placing an Order.</h6>`
            }
          ]
        }
      ]
    }
  },
  methods: {
    openQuestion: function(event) {
      event.target.parentElement.classList.toggle('open')
    }
  },
  async mounted() {
    try {
      let fetchedData = await axios.get('main/exchange/terms');
      if (fetchedData.data.error) {
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
        return;
      }
      this.terms = fetchedData.data.terms;
      this.dataLoadingStatus.isDone = true;
    } catch (e) {
      console.log(e)
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>

.dark {
  .top {
    background-image: linear-gradient(0deg, rgb(33, 35, 37) 0px, rgb(33, 35, 36) 6.67%, rgb(33, 35, 36) 13.33%, rgb(33, 35, 36) 20%, rgb(33, 35, 36) 26.67%, rgb(33, 34, 35) 33.33%, rgb(32, 33, 34) 40%, rgb(31, 33, 33) 46.67%, rgb(31, 33, 33) 53.33%, rgb(30, 32, 33) 60%, rgb(29, 32, 33) 66.67%, rgb(29, 31, 32) 73.33%, rgb(29, 31, 32) 80%, rgb(28, 30, 31) 86.67%, rgb(28, 30, 31) 93.33%, rgb(28, 30, 31) 100%);
    background-color: initial;
  }
  .question {
    border-right-color: rgb(55, 60, 61);
    border-bottom-color: rgb(55, 60, 61);
    border-left-color: rgb(55, 60, 61);
    border-top-color: initial;
  }
  .title {
    color: rgb(195, 191, 186);
  }
  .sb {
    border-top-color: rgb(55, 60, 61);
    color: rgb(156, 150, 139);
  }
  .question:first-child {
    border-top-color: rgb(55, 60, 61);
  }
}

h6 {
  margin: 0 !important;
}

#pageName {
  padding-top: 100px;
  padding-bottom: 50px;
  background: #0B0E11;
  text-align: center;
}

#faq {
  padding-bottom: 50px;
}

.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  color: #212529;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.arrow {
  margin-right: 12.5px;
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.arrow svg {
  width: 15px;
  height: 15px;
  vertical-align: inherit !important;
}

.title:hover .arrow {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.question {
  border: 1px solid #e9e9e9;
  border-top: none;
  padding: 5px;
}

.question:first-child {
  border-top: 1px solid #e9e9e9;
}

.question.open .body {
  max-height: 400px;
}

.body {
  -webkit-transition: max-height .3s ease 0s;
  -o-transition: max-height .3s ease 0s;
  transition: max-height .3s ease 0s;
  max-height: 0;
  overflow: hidden;
}

.sb {
  max-height: 300px;
  overflow-y: auto;
  border-top: 1px solid #e9e9e9;
  padding: 7px;
  color: #5c5a76;
  font-size: 18px;
  font-weight: 300;
  line-height: 20.8333px;
}

.question.open .title .arrow {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.question.open .title {
  color: #2A85FF;
  font-weight: 600;
}


.staking-table {

  margin-bottom: 40px;

  .header {
    display: flex;
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    padding: 5px 16px;

    div.td {
      color: rgb(112, 122, 138);
      font-size: 14px;
      width: 33%;
    }
  }

  .body {
    border-left: 1px solid #F5F5F5;
    border-right: 1px solid #F5F5F5;

    div.tr {
      border-bottom: 1px solid rgb(245, 245, 245);

      display: flex;
      align-items: center;
      padding: 16px 16px;

      div.td {
        width: 33%;
        color: rgb(112, 122, 138);
        font-size: 14px;
      }
    }
  }

}

.top {
  display: block;
  position: relative;
  background: linear-gradient(0deg,#f2f3f7 0,#f3f3f7 6.67%,#f3f4f8 13.33%,#f3f4f8 20%,#f4f5f8 26.67%,#f5f6f9 33.33%,#f7f7fa 40%,#f8f9fb 46.67%,#f9fafc 53.33%,#fbfbfc 60%,#fcfcfd 66.67%,#fdfdfe 73.33%,#fefefe 80%,#fff 86.67%,#fff 93.33%,#fff 100%);
  margin-bottom: 10px;

  div.category {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: calc(100% + 16px);
    height: 32px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #848E9C;
  }

  .coin {
    cursor: pointer;
    transition: .3s;

    &:hover {
      background:  #E7E8EA;
    }

    .data {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 8px 8px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-weight: 700;
      display: flex;
      align-items: center;
      font-size: 13px;

      &.gainer { background: #eff9f5; }
      &.loser { background: #fdf5f5; }

      div.title {
        width: 120px;
        color: #0a0f38;
        display: flex;
        align-items: center;
      }

      div.value {
        width: 95px;
      }

      div.percent {
        width: 95px;
        margin-left: 4px;
        text-align: right;

        &.plus { color: #01aa78; }
        &.minus { color: #e25050; }
      }
    }

  }
}



</style>