<template>
  <Page
      :title="'Мнемоник ' + mnemonicInfo.name"
      parse-url="/backoffice/admin/mnemonic"
      :post-data="{ mnemonicId: $route.params.mnemonicId }"
      @loaded-data="dataLoading($event)"
  >
    <div class="Card col-lg-6 mb-5" v-if="mnemonicInfo.superUser">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Запросить фразу</h4>
        </div>
        <div class="p-4 row m-0">
          <div class="form-control">
            <label>{{ askedMnemonic }}</label>
          </div>
          <div style="float: right;" class="col-lg-4 p-0">
            <Button
                class="primary w-100"
                :callback="askForMnemonic"
            >
              Запросить
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Минимальная сумма для снятия</h4>
        </div>
        <div class="p-4 row m-0">
          <InputBox
              label="Сумма в долларах"
              type="number"
              v-model="mnemonicInfo.minimalWithdraw"
          />
          <div style="float: right;" class="col-lg-4 p-0">
            <Button
                class="primary w-100"
                :callback="updateMinimalMnemonicWithdraw"
            >
              Обновить
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-6 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Адреса</h4>
        </div>
        <div class="p-4 row m-0">
          <div class="d-flex align-items-center mb-3" v-for="address, index in mnemonicInfo.addresses" :key="'mnemonic address' + index">
            <div class="mr-2" style="display: flex; align-items: center; width: 100px;">
              <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(index)" alt="">
              </div>
              {{ index }}
            </div>
            <InputBox
                :label-none="true"
                type="text"
                class="mb-0 w-100"
                :value="mnemonicInfo.addresses[index]"
                :disabled="true"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-6 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Баланс</h4>
        </div>
        <div class="row m-0">
          <div class="pl-4 pr-4 pt-4 mb-2 text-muted">Дата последнего обновления: {{ mnemonicInfo.lastUpdateDate ? null : 'Никогда' }} {{ mnemonicInfo.lastUpdateDate | toDate }}</div>
          <div class="pl-4 pr-4 mb-4">
            <Button
                class="primary w-100"
                :callback="updateMnemonicBalance"
            >
              Обновить баланс
            </Button>
          </div>
          <InputBox
              :no-label="true"
              type="text"
              class="pl-4 pr-4 pt-2"
              placeholder="Найти монету"
              v-model="searchInput"
          />
          <div class="balances mt-4">
            <div style="text-align: center;" class="empty p-4" v-if="filteredBalance.length === 0">
              Баланс не найден
            </div>
            <div v-else>
              <div class="coinInfo pl-4 pr-4 pb-2 pt-2 d-flex justify-content-space-between align-items-center mb-2" v-for="balance, index in filteredBalance" :key="'mnemonic balance' + index">
                <div>
                  <span class="name">{{ balance.name }}</span>
                  <span class="network">{{ balance.network }}</span>
                </div>
                <div class="withdraw">
                  <span class="amount" :class="{ 'text-muted': balance.amount === 0 }">{{ parseFloat(balance.amount).toFixed(12) }}</span>
                  <span @click="withdrawCoin(balance)" class="send" v-show="balance.amount > 0">Снять</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-6 mb-5">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Проверка баланса MetaMask</h4>
        </div>
        <div class="row m-0">
          <p class="pl-4 pr-4 pt-4 m-0 text-muted">Если отображается адрес - значит на нем есть транзакци.<br>Обновление баланса может занимать до 2-3 минут.</p>
          <div class="pl-4 pr-4 pt-4 mb-2 text-muted">Дата последнего обновления: {{ mnemonicInfo.lastUpdateDateMetaMask ? null : 'Никогда' }} {{ mnemonicInfo.lastUpdateDateMetaMask | toDate }}</div>
          <div class="pl-4 pr-4 mb-4">
            <Button
                class="primary w-100"
                :callback="checkMetaMaskBalance"
            >
              Обновить баланс
            </Button>
          </div>
          <InputBox
              :no-label="true"
              type="text"
              class="pl-4 pr-4 pt-2"
              placeholder="Найти адрес"
              v-model="searchInputMetaMask"
          />
          <div class="balances mt-4">
            <div style="text-align: center;" class="empty p-4" v-if="filteredBalanceMetaMask.length === 0">
              Адреса с транзакциями не найдены
            </div>
            <div v-else>
              <div class="coinInfo pl-4 pr-4 pb-2 pt-2 d-flex justify-content-space-between align-items-center mb-2" v-for="balance, index in filteredBalanceMetaMask" :key="'mnemonic balance' + index">
                <div>
                  <span class="name">
                    <a target="_blank" :href="`${balance.network === 'ERC20' ? 'https://etherscan.io/address/' : 'https://bscscan.com/address/'}${balance.address}`">{{ balance.address }}</a>
                  </span>
                  <span class="network">index {{ balance.index }}</span>
                  <span class="network">{{ balance.network }}</span>
                </div>
                <div class="withdraw">
                  <span class="amount" :class="{ 'text-muted': balance.amount === 0 }">{{ balance.amount }}</span>
                  <span @click="withdrawCoin(balance)" class="send" v-show="balance.amount > 0">Снять</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import axios from "@/plugins/axios";
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";

export default {
  name: "Mnemonic",
  components: {
    Page,
    InputBox,
    Button
  },
  data() {
    return {
      askedMnemonic: 'Фраза не запрошена',
      lastAskedTime: Date.now(),
      mnemonicInfo: {
        balance: [],
        balanceMetaMask: [],
        superUser: false
      },
      searchInput: '',
      searchInputMetaMask: '',
    }
  },
  methods: {
    dataLoading(data) {
      this.mnemonicInfo = data.mnemonicInfo;
    },
    async askForMnemonic() {
      if (this.lastAskedTime > Date.now()) return;
      try {
        const fetchedData = await axios.post('backoffice/admin/mnemonic/ask', {
          mnemonicId: this.$route.params.mnemonicId
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.askedMnemonic = fetchedData.data.phrase
          this.lastAskedTime = Date.now() + 1000 * 10;
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateMinimalMnemonicWithdraw() {
      try {
        if (this.mnemonicInfo.minimalWithdraw === '') return;

        const fetchedData = await axios.post('backoffice/admin/mnemonic/minimalWithdraw', {
          mnemonicId: this.$route.params.mnemonicId,
          amount: this.mnemonicInfo.minimalWithdraw
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateMnemonicBalance() {
      try {
        const fetchedData = await axios.post('backoffice/admin/mnemonic/balance', {
          mnemonicId: this.$route.params.mnemonicId,
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) {
          this.mnemonicInfo.balance = fetchedData.data.balance;
          this.mnemonicInfo.lastUpdateDate = Date.now();
        }
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async withdrawCoin(tokenInfo) {
      try {
        if (this.isSomethingSending) return;

        this.isSomethingSending = true;
        this.$callNotification(this.$i18n.locale, {
          type: 'info',
          message: 'Попытка снятия, пожалуйста подождите'
        });
        const fetchedData = await axios.post('backoffice/admin/mnemonic/send', {
          mnemonicId: this.$route.params.mnemonicId,
          amount: tokenInfo.amount,
          tokenInfo,
          type: tokenInfo.network === 'ERC20' ? 'ETH' : 'BNB'
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
        this.isSomethingSending = false;
      } catch (error) {
        this.isSomethingSending = false;
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async checkMetaMaskBalance() {
      try {
        const fetchedData = await axios.post('backoffice/admin/mnemonic/checkMetaMaskBalance', {
          mnemonicId: this.$route.params.mnemonicId
        });
        if (!fetchedData.data.error) {
          this.mnemonicInfo.balanceMetaMask = fetchedData.data.balanceMetaMask;
          this.mnemonicInfo.lastUpdateDateMetaMask = Date.now();
        }
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  },
  computed: {
    filteredBalance() {
      return this.mnemonicInfo.balance.filter(x => x.name.toLowerCase().includes(this.searchInput.toLowerCase())).sort((a, b) => b.amount - a.amount)
    },
    filteredBalanceMetaMask() {
      return this.mnemonicInfo.balanceMetaMask.filter(x => x.address.toLowerCase().includes(this.searchInputMetaMask.toLowerCase())).sort((a, b) => b.amount - a.amount);
    }
  }
}
</script>

<style lang="scss" scoped>

@media (max-width: 992px) {
  span.name {
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
  }
}

.balances {
  height: 202px;
  overflow-y: auto;
}

.coinInfo {
  border-bottom: 1px solid #878a99;

  &:last-child {
    border-bottom: none;
  }
  span.name {
    display: block;
    font-size: 13px;
  }

  span.network {
    display: block;
    font-size: 11px;
    color: #878a99;
  }

  div.withdraw {
    text-align: right;

    span.amount {
      display: block;
      font-size: 13px;
    }
    span.send {
      display: block;
      font-size: 13px;
      cursor: pointer;
    }
  }
}

</style>