<i18n>
{
  "en": {
    "title": "Change email",
    "email": "New email",
    "password": "Current password"
  },
  "zh": {
    "title": "更改電子郵件",
    "email": "新郵件",
    "password": "當前密碼"
  },
  "kr": {
    "title": "이메일을 바꾸다",
    "email": "새 이메일",
    "password": "현재 비밀번호"
  }
}
</i18n>
<template>

  <div>
    <h3 class="title">{{ $t('title') }}</h3>
    <InputBox
        v-for="control, index in formControls"
        :key="index"
        :type="control.type"
        :label="$t(control.label)"
        :validation="control.validation"
        v-model="control.value"
        @check-valid="control.isValid = $event"
        :error-message="$t(control.errorMessage)"
        style="max-width: 450px;"
    />
    <Button
        custom-width="100%"
        :is-async="true"
        :callback="emailChangeHandler"
        :is-disabled="!$isFormValid(formControls)"
        style="max-width: 450px;"
    >
    </Button>
  </div>
</template>

<script>
import InputBox from "@/components/main/UI/InputBox";
import Button from "@/components/main/UI/Button";
import axios from "@/plugins/axios";
import TwoFactorModal from "@/mixins/two-factor-modal";

export default {
  name: "email",
  components: {
    InputBox,
    Button
  },
  mixins: [TwoFactorModal],
  data() {
    return {
      formControls: {
        emailInput: {
          value: '',
          type: 'email',
          label: 'email',
          isValid: false,
          validation: {
            required: true,
            email: true
          }
        },
        passwordInput: {
          value: '',
          type: 'password',
          label: 'password',
          isValid: false,
          validation: {
            required: true
          }
        },
      },
    }
  },
  methods: {
    async emailChangeHandler(twoFactorCode) {
      try {
        const fetchedData = await axios.post('/main/user/emailchange', {
          emailNew: this.formControls.emailInput.value,
          userPassword: this.formControls.passwordInput.value,
          twoFactorCode
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (fetchedData.status === 206) return this.openTwoFactorConfirmation(this.emailChangeHandler);
        if (!fetchedData.data.error) return location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
  }
}
</script>

<style scoped>

</style>