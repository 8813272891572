<template>
  <Page
      title="Депозиты"
      parse-url="/backoffice/user/deposits"
      @loaded-data="dataLoading($event)"
  >
    <div class="Card col-lg-12 mb-5">
      <div class="Card-content">
        <div class="Card-header">
          <h4>Статистика месяца</h4>
        </div>
        <div style="text-align: center;" class="row m-0">
          <div class="border dashed start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.depositsTotal }}</p>
            <p class="text-muted m-0">Всего</p>
          </div>
          <div class="border dashed end start col-lg-6 col-md-6 col-xs-6 p-3">
            <p class=" m-0 mb-1">{{ fetchedData.deposits.total }}</p>
            <p class="text-muted m-0">Текущий месяц</p>
          </div>
        </div>
        <div style="overflow-y: auto;" class="chart p-4">
          <Bar
              :chart-options="chartOptions"
              :chart-data="chartData"
              :styles="{ 'min-width': '800px' }"
              :height="200"
              v-if="isStatisticsLoaded"
          />
          <div @click="loadStatistics" class="d-flex align-items-center justify-content-center text-muted" style="cursor: pointer; font-size: 12px;" v-else>
            {{ statisticText }}
          </div>
        </div>
      </div>
    </div>

    <div class="Card col-lg-12">
      <div class="Card-content">
        <div class="Card-header bordered">
          <h4>Депозиты</h4>
        </div>
        <Table
            ref="usersTableComponent"
            parse-url="/backoffice/user/spammerDeposits"
            :columnList="depositsTableColumns"
            default-sort="date"
            searchPlaceholderText="Введите почту, хеш или монету"
            @handle-data="depositsTableData = $event"
        >
          <tr v-for="deposit, index in depositsTableData" :key="'deposit' + index">
            <td>
              <router-link :to="'/back-office/users/' + deposit.userId">
                {{ deposit.username }}
              </router-link>
            </td>
            <td>
              <div style="display: flex; align-items: center; width: 60px;">
                <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                  <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="'/currencies/'+deposit.currency+'.png'" alt="">
                </div>
                {{ deposit.currency }}
              </div>
            </td>
            <td>{{ deposit.amountCurrency.toFixed(5) }} <span class="text-muted" style="font-size: 12px; float: right;">~ ${{deposit.amount.toFixed(2) | numberSpace}}</span></td>
            <td class="hash">{{ deposit.hash }}</td>
            <td>{{ deposit.date | toDate }}</td>
          </tr>
        </Table>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/backoffice/Page";
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Filler, Tooltip, Legend, BarElement, LineElement, LineController, PointElement, CategoryScale, LinearScale, LogarithmicScale, LogarithmicScaleOptions } from 'chart.js'
import Button from "@/components/backoffice/UI/Button";
import InputBox from "@/components/backoffice/UI/InputBox";
import NotificationCard from "@/components/backoffice/UI/NotificationCard";
import Table from "@/components/backoffice/UI/Table";
import axios from "@/plugins/axios";

ChartJS.register(Title, Tooltip, Legend, Filler, BarElement, LineElement, CategoryScale, LineController, PointElement, LinearScale, LogarithmicScale)
export default {
  name: "Deposits",
  components: {
    Page,
    Bar,
    Table
  },
  data() {
    return {
      isStatisticsLoaded: false,
      isStatisticsLoading: false,
      statisticText: 'Нажмите, чтобы загрузить статистику',
      depositsTableData: [],
      depositsTableColumns: [
        {
          name: 'email',
          title: 'Почта',
          isSortable: true
        },
        {
          name: 'currency',
          title: 'Монета',
          isSortable: true
        },
        {
          name: 'amount',
          title: 'Сумма',
          isSortable: true
        },
        {
          name: 'hash',
          title: 'Хеш',
          isSortable: true
        },
        {
          name: 'date',
          title: 'Дата',
          isSortable: true
        },
      ],
      fetchedData: {
        deposits: {
          total: 0,
          data: [],
          percentDifference: 0
        }
      },
      chartData: {
        labels: Array.from({ length: new Date(new Date().getFullYear(), 1, 0).getDate() }, (_, i) => i + 1),
        datasets: [
          {
            type: 'bar',
            label: 'Депозиты',
            data: [],
            backgroundColor: '#0DA592',
            barThickness: 10,
            order: 2,
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ': ' +'$'+ context.parsed.y.toLocaleString('en-US')
                }
              }
            },
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },
        scales: {
          yAxes: {
            grace: '10%',
            ticks: {
              precision: 0, // точность округления значений по оси y
              maxTicksLimit: 6, // максимальное количество значений (и рёбер сетки) по оси y
            }
          }
        }
      }
    }
  },
  methods: {
    dataLoading(data) {
      this.fetchedData = data;
      this.chartData.datasets[0].data = this.fetchedData.deposits.data;
    },
    async loadStatistics() {
      try {
        if (this.isStatisticsLoading) return;
        this.statisticText = "Загрузка данных..."
        this.isStatisticsLoading = true;
        const fetchedData = await axios.get('backoffice/user/deposits/statistics');

        if (fetchedData.data.error) return this.statisticText = "Ошибка при загрузке статистики";
        this.fetchedData = fetchedData.data;
        this.chartData.datasets[0].data = this.fetchedData.deposits.data;
        this.isStatisticsLoaded = true;
        this.isStatisticsLoading = false;
      } catch (error) {
        console.log(error)
        this.isStatisticsLoading = false;
        this.statisticText = "Ошибка при загрузке статистики"
      }
    }
  }
}
</script>

<style scoped>
.hash {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>