<i18n>
{
  "en": {
    "title": "Buy Crypto"
  },
  "zh": {
    "title": "购买加密货币"
  },
  "kr": {
    "title": "未找到"
  }
}
</i18n>
<template>
  <Page style="padding: 0;" :is-page-loaded="dataLoadingStatus">

    <div class="top row">
      <div class="container">
        <h1 style="margin: 45px 0;">{{ $t('title') }}</h1>
      </div>
    </div>

    <div class="container" style="margin: 40px auto;">

      <div class="wrapper" style="width: 100%; border-radius: 10px; display: inline-block; flex-direction: inherit; margin-bottom: 20px;" v-for="provider, index in providers" :key="'provider ' + index">
        <div class="col-lg-2 col-xs-12 col-md-12" style="margin: 10px 0;">
          <div style="color: white; border-radius: 8px; width: 150px; height: 45px; display: flex; justify-content: center; align-items: center; font-size: 18px;" v-if="provider.img === 'none'" :style="{ background: getRandomColor() }">
            <span>{{ provider.title }}</span>
          </div>
          <img :src="`/partners/${provider.img}`" style="border-radius: 5px; width: 150px; height: 60px;" v-else>

        </div>
        <div style="margin: 10px 0;" class="col-lg-7 col-xs-12 col-md-12">
          {{ provider.description }}
        </div>
        <span
            @click="navigateLink(provider.link, provider.title)"
            class="col-lg-3 col-xs-12 col-md-12 buy-btn"
            style="float: right; display: block; margin: 10px 0; text-align: center;"
        >Go to {{ provider.title }}</span>
      </div>
    </div>
  </Page>
</template>

<script>
import axios from "@/plugins/axios";
import Page from "@/components/main/UI/Page";
import BuyCrypto from "@/components/main/BuyCrypto";

export default {
  name: "Terms",
  components: {
    Page
  },
  data() {
    return {
      providers: [],
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      currentCoin: this.$route.params.coin || 'BTC'
    }
  },
  methods: {
    async navigateLink(link, provider) {
      await axios.post('/main/user/openBuyCrypto', { provider });
      const linkNode = document.createElement("a");
      linkNode.referrerPolicy = "no-referrer";
      linkNode.rel = "noreferrer";

      linkNode.href = link;
      linkNode.click();
      //window.location.href = link2;
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  },
  async mounted() {
    try {
      let fetchedData = await axios.get('main/exchange/buyCrypto');
      if (fetchedData.data.error) {
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
        return;
      }
      this.providers = fetchedData.data.providers;
      this.dataLoadingStatus.isDone = true;
    } catch (e) {
      console.log(e)
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>


.dark {
  .top {
    background-image: linear-gradient(0deg, rgb(33, 35, 37) 0px, rgb(33, 35, 36) 6.67%, rgb(33, 35, 36) 13.33%, rgb(33, 35, 36) 20%, rgb(33, 35, 36) 26.67%, rgb(33, 34, 35) 33.33%, rgb(32, 33, 34) 40%, rgb(31, 33, 33) 46.67%, rgb(31, 33, 33) 53.33%, rgb(30, 32, 33) 60%, rgb(29, 32, 33) 66.67%, rgb(29, 31, 32) 73.33%, rgb(29, 31, 32) 80%, rgb(28, 30, 31) 86.67%, rgb(28, 30, 31) 93.33%, rgb(28, 30, 31) 100%);
    background-color: initial;
  }
  .question {
    border-right-color: rgb(55, 60, 61);
    border-bottom-color: rgb(55, 60, 61);
    border-left-color: rgb(55, 60, 61);
    border-top-color: initial;
  }
  .title {
    color: rgb(195, 191, 186);
  }
  .sb {
    border-top-color: rgb(55, 60, 61);
    color: rgb(156, 150, 139);
  }
  .question:first-child {
    border-top-color: rgb(55, 60, 61);
  }

  .wrapper {
    background-color: rgb(33, 34, 35) !important;
    span.value {
      color: rgb(197, 193, 189)!important;

      b { color: rgba(197, 193, 189, 0.7) !important; }
    }
    span.title {
      color: rgb(197, 193, 189)!important;
    }
  }
}

h6 {
  margin: 0 !important;
}

#pageName {
  padding-top: 100px;
  padding-bottom: 50px;
  background: #0B0E11;
  text-align: center;
}

#faq {
  padding-bottom: 50px;
}

.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  color: #212529;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.arrow {
  margin-right: 12.5px;
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.arrow svg {
  width: 15px;
  height: 15px;
  vertical-align: inherit !important;
}

.title:hover .arrow {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.question {
  border: 1px solid #e9e9e9;
  border-top: none;
  padding: 5px;
}

.question:first-child {
  border-top: 1px solid #e9e9e9;
}

.question.open .body {
  max-height: 400px;
}

.body {
  -webkit-transition: max-height .3s ease 0s;
  -o-transition: max-height .3s ease 0s;
  transition: max-height .3s ease 0s;
  max-height: 0;
  overflow: hidden;
}

.sb {
  max-height: 300px;
  overflow-y: auto;
  border-top: 1px solid #e9e9e9;
  padding: 7px;
  color: #5c5a76;
  font-size: 18px;
  font-weight: 300;
  line-height: 20.8333px;
}

.question.open .title .arrow {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.question.open .title {
  color: #2A85FF;
  font-weight: 600;
}


.staking-table {

  margin-bottom: 40px;

  .header {
    display: flex;
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    padding: 5px 16px;

    div.td {
      color: rgb(112, 122, 138);
      font-size: 14px;
      width: 33%;
    }
  }

  .body {
    border-left: 1px solid #F5F5F5;
    border-right: 1px solid #F5F5F5;

    div.tr {
      border-bottom: 1px solid rgb(245, 245, 245);

      display: flex;
      align-items: center;
      padding: 16px 16px;

      div.td {
        width: 33%;
        color: rgb(112, 122, 138);
        font-size: 14px;
      }
    }
  }

}

.top {
  display: block;
  position: relative;
  background: linear-gradient(0deg,#f2f3f7 0,#f3f3f7 6.67%,#f3f4f8 13.33%,#f3f4f8 20%,#f4f5f8 26.67%,#f5f6f9 33.33%,#f7f7fa 40%,#f8f9fb 46.67%,#f9fafc 53.33%,#fbfbfc 60%,#fcfcfd 66.67%,#fdfdfe 73.33%,#fefefe 80%,#fff 86.67%,#fff 93.33%,#fff 100%);
  margin-bottom: 10px;

  div.category {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: calc(100% + 16px);
    height: 32px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #848E9C;
  }

  .coin {
    cursor: pointer;
    transition: .3s;

    &:hover {
      background:  #E7E8EA;
    }

    .data {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 8px 8px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-weight: 700;
      display: flex;
      align-items: center;
      font-size: 13px;

      &.gainer { background: #eff9f5; }
      &.loser { background: #fdf5f5; }

      div.title {
        width: 120px;
        color: #0a0f38;
        display: flex;
        align-items: center;
      }

      div.value {
        width: 95px;
      }

      div.percent {
        width: 95px;
        margin-left: 4px;
        text-align: right;

        &.plus { color: #01aa78; }
        &.minus { color: #e25050; }
      }
    }

  }
}

.wrapper {
  position: relative;
  background-color: #f4f5f9;
  border-radius: 2px;
  overflow: hidden;
  z-index: 10;
  padding: 16px 20px 20px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  span.title {
    margin-bottom: 4px;
    color: #0a0f38;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
  }
  span.value {
    color: #0a0f38;
    font-size: 22px;
    font-weight: 700;
    line-height: 37px;
  }
}

.buy-btn {
  text-decoration: none;
  line-height: 45px;
  height: 45px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  background-color: #2A85FF;
  cursor: pointer;
  border-radius: 10px;
  transition: all .2s ease;
  vertical-align: top;
  margin: 0 10px;
  font-size: 14px;
  margin-bottom: 10px;

  &:hover {
    color: white !important;
    background: #66a7ff;
  }
}

</style>