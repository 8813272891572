import { Store } from 'vuex';

const translation = {
  "error": {
    "en": "Error",
    "zh": "错误",
    "kr": "오류",
  },
  "success": {
    "en": "Success",
    "zh": "成功",
    "kr": "성공",
  },
  "info": {
    "en": "Information",
    "zh": "信息",
    "kr": "정보",
  },
  "warn": {
    "en": "Warning",
    "zh": "警告",
    "kr": "경고",
  },
  "unknown": {
    "en": "Unknown error. Please, try again later.",
    "zh": "未知错误。 请稍后再试。",
    "kr": "알수없는 오류. 나중에 다시 시도하십시오.",
  },
  "429": {
    "en": "Too many requests, please try again later.",
    "zh": "请求过多，请稍后重试。",
    "kr": "요청이 너무 많습니다. 나중에 다시 시도해 주세요.",
  },
  "500": {
    "en": "Server error.",
    "zh": "服務器錯誤。",
    "kr": "서버 오류.",
  },
  "clickToClose": {
    "en": "<br><br>Click to close this window.",
    "zh": "<br><br>单击以关闭此窗口。",
    "kr": "<br><br>이 창을 닫으려면 클릭하십시오.",
  }
}

const logo = "";

const typeOfCodes = {
  "400": 'error',
  "404": 'error',
  "500": 'error',
  "502": 'error',
  "504": 'error',
  "200": 'success',
  "206": 'info',
  "207": 'info',
  "429": 'warn',
}

export default {
  install (Vue, store) {
    Vue.prototype.$isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth <= 992;
    Vue.prototype.$linkCreator = (locale, route) => {
      let result = '/' + locale + '/';
      if (route === '/') return result;
      result += route;
      return result
    };
    Vue.prototype.$callNotification = (locale, data) => {
      try {
        const enteredData = data.response || data.data || data;
        const code = enteredData.error === true ? 400 : enteredData.error === false ? 200 : enteredData.status || 'unknown';
        const type = code !== 'unknown' ? typeOfCodes[code.toString()] : enteredData.type || 'error';
        const timeout = enteredData.timeout ? enteredData.timeout : 5000;
        let message = enteredData.description || enteredData.message || translation[code][locale] || translation.unknown[locale];
        timeout === -1 ? message = message + translation.clickToClose[locale] : null;
        const title = enteredData.title || translation[type][locale];
        Vue.notify({
          type,
          text: message,
          duration: timeout
        })
      } catch (e) {
        console.log(e)
        Vue.notify({
          type: 'error',
          title: translation.error[locale],
          text: translation.unknown[locale],
          duration: 2000
        })
      }
    };
    
    Vue.prototype.$copyText = (item) => {
      navigator.clipboard.writeText(item);
      Vue.notify({
        type: 'info',
        title: "Info",
        text: "Copied",
        duration: 2000
      })
    };
    Vue.prototype.$coinImage = coin => {
      if (store.state.Index.isActive && coin === 'APT') return store.state.Index.exchangeInfo.superLogo;
      const index = store.state.Index.isActive ? store.state.Index.coinList.findIndex(x => x.code === coin && x.superCoin) : -1;
      return index !== -1 ? store.state.Index.exchangeInfo.superLogo : '/currencies/' + coin + '.png';
    };
    Vue.prototype.$coinName = coin => {
      return coin === 'APT' ? "Кастом токен" : coin;
    };
    Vue.prototype.$isFormValid = forms => Object.entries(forms).every(value => value[1].isValid);
    Vue.filter('usdFormat', value => (value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) );
  }
}
// todo редактирование кастомного токена в спаммерке, проверка трейда при пампе, возврщении. раздача кастомного токена