<template>
  <div class="api-container" style="position: relative;">
    <div style="font-size: 15px;" class="m-0 mb-3">{{ title }}</div>
    <div style="position: relative;" class="d-flex align-items-center mb-4">
      <div style="position: absolute; border-radius: 5px;" class="pr-3 pl-3 pt-2 pb-2 bg-primary">{{ method }}</div>
      <InputBox
          :label-none="true"
          type="text"
          :disabled="true"
          :value="apiLinkCreator()"
          class="w-100 mb-0"
          style="margin-left: 55px;"
      />
    </div>
    <div>
      <div style="font-size: 13px;" class="mb-3">Параметры запроса</div>
      <div style="font-size: 12px;" class="pl-3 pr-3 mb-3" v-for="parameter, index in query.required" :key="'required ' + index">
        <div class="d-flex align-items-center justify-content-space-between">
          <div>
            <span class="mr-2">{{ parameter.key }}</span>
            <span class="text-muted">{{ parameter.type }}</span>
          </div>
          <div class="ml-3 color-danger">обязательный</div>
        </div>
        <p class="mt-1 mb-2">{{ parameter.description }}</p>
        <p class="m-0">Пример: <span class="bg-primary p-1">{{ parameter.example }}</span></p>
      </div>

      <div style="font-size: 12px;" class="pl-3 pr-3 mb-3" v-for="parameter, index in query.optional" :key="'optional ' + index">
        <div class="d-flex align-items-center justify-content-space-between">
          <div>
            <span class="mr-2">{{ parameter.key }}</span>
            <span class="text-muted">{{ parameter.type }}</span>
          </div>
          <div class="ml-3 color-info">опциональный</div>
        </div>
        <p class="mt-1 mb-2">{{ parameter.description }}</p>
        <p class="m-0">Пример: <span class="bg-primary p-1">{{ parameter.example }}</span></p>
      </div>

      <div style="font-size: 13px;" class="mt-4">
        <span>Пример ответа</span>
        <pre style="font-size: 12px; background: #262a2f;" class="p-3">{{ responseExample }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from "@/components/backoffice/UI/InputBox";

export default {
  name: "ApiContainer",
  components: {
    InputBox
  },
  props: {
    title: String,
    url: String,
    method: String,
    responseExample: String,
    query: Object, // { required: [{ key: "name1", type: "string", example: "test", description: "Поле 1" }], optional: [{ key: "name1", type: "string", example: "test2", description: "Поле 2" }] }
  },
  methods: {
    apiLinkCreator() {
      let mainUrl = `https://${this.$store.state.Index.websiteName.full}/api/${this.url}?`;
      const queries = this.query.required.concat(this.query.optional);
      for (let i = 0; i < queries.length; i++) {
        mainUrl += `${queries[i].key}=${queries[i].example}${i+1 === queries.length ? '' : '&' }`;
      }
      return mainUrl;
    },
  }
}
</script>

<style lang="scss" scoped>

.api-container:not(:last-child) {
  border-bottom: 1px solid #32383e;
  margin-bottom: 3rem !important;
  padding-bottom: 3rem !important;
}

</style>