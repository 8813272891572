<i18n>
{
  "en": {
    "ticketInformation": "Ticket Information",
    "chatHistory": "Chat History",
    "closeTicket": "Close ticket",
    "category": "Category",
    "subject": "Subject",
    "yourMessage": "Message",
    "typeMessage": "Describe your problem",
    "withdraw": "Withdraw",
    "deposit": "Deposit",
    "trading": "Trading",
    "account": "Account",
    "staking": "Staking",
    "none": "None",
    "id": "Ticket ID",
    "status": "Status",
    "date": "Date",
    "closed": "Closed",
    "answered": "Answered",
    "waiting": "Waiting"
  },
  "zh": {
    "ticketInformation": "票务信息",
    "chatHistory": "聊天记录",
    "closeTicket": "关闭工单",
    "category": "类别",
    "subject": "主题",
    "yourMessage": "消息",
    "typeMessage": "描述你的问题",
    "withdraw": "撤回",
    "deposit": "存款",
    "trading": "交易",
    "account": "帐户",
    "staking": "质押",
    "none": "没有任何",
    "id": "票号",
    "status": "状态",
    "date": "日期",
    "closed": "关闭",
    "answered": "已回答",
    "waiting": "等待"
  },
  "kr": {
    "ticketInformation": "티켓 정보",
    "chatHistory": "채팅 기록",
    "closeTicket": "티켓 닫기",
    "category": "카테고리",
    "subject": "주제",
    "yourMessage": "메시지",
    "typeMessage": "문제 설명",
    "withdraw": "철회",
    "deposit": "예금",
    "trading": "거래",
    "account": "계정",
    "staking": "스테이킹",
    "none": "없음",
    "id": "티켓 ID",
    "status": "상태",
    "date": "날짜",
    "closed": "닫힌",
    "answered": "대답",
    "waiting": "기다려"
  }
}
</i18n>
<template>
  <Page :is-page-loaded="loadingStatus" class="dashboard">
    <PageContainer :no-line="true" v-if="loadingStatus.isDone">
      <div class="container-fluid" style="padding: 0; margin-bottom: 40px;">
        <div class="col-lg-4" style=" margin-bottom: 40px;">
          <div class="wrapper" style="flex-direction: inherit; padding: 0 20px 10px 20px; display: block;">
            <div>
              <h3>{{ $t('ticketInformation') }}</h3>
              <div class="info">{{ $t('id') }}: {{ ticketInfo.id }}</div>
              <div class="info">{{ $t('category') }}: {{ $t(ticketInfo.category) }}</div>
              <div class="info">{{ $t('subject') }}: {{ ticketInfo.subject }}</div>
              <div class="info">{{ $t('date') }}: {{ ticketInfo.date | toDate }}</div>
              <div class="info">
                {{ $t('status') }}:
                <span :class="ticketInfo.isClosed ? 'closed' : ticketInfo.isAnswered ? 'answered' : 'waiting'">
                  {{ ticketInfo.isClosed ? $t('closed') : ticketInfo.isAnswered ? $t('answered') : $t('waiting') }}
                </span>
              </div>
            </div>
            <div style="display: flex; align-items: end; margin-top: 15px;" v-if="!ticketInfo.isClosed">
              <Button
                  :callback="closeTicket"
                  style="width: 100%;"
              >
                {{ $t('closeTicket') }}
              </Button>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="wrapper" style="display: block; padding-top: 0;">
            <h3>{{ $t('chatHistory') }}</h3>
            <div class="messages-wrapper" ref="chatBox">
              <div class="messages">
                <div class="message-wrapper" v-for="message, index in ticketInfo.messages" :key="'ticket message' + index">
                  <div class="message" :class="{ 'ml-auto': message.isSupport, 'mr-auto': !message.isSupport }">
                    <div>
                      <span class="text">{{ message.text }}</span>
                      <span class="date">{{ message.date | toDate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!ticketInfo.isClosed">
              <div>
                <textarea
                    maxlength="1500"
                    v-model.trim="newMessage"
                    :placeholder="$t('typeMessage')"
                    style="resize: none;" rows="5"
                ></textarea>
              </div>
              <div style="float: right; padding: 0;" class="col-lg-4 col-xs-12 col-md-12">
                <Button :is-disabled="sendButtonDisabled" :callback="sendMessage" style="width: 100%;" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  </Page>
</template>

<script>
import Page from "@/components/main/UI/Page";
import PageContainer from "@/components/main/UI/PageContainer";
import Button from "@/components/main/UI/Button";
import axios from "@/plugins/axios";
import * as workerTimers from "worker-timers";

export default {
  name: "Ticket",
  components: {
    Page,
    PageContainer,
    Button
  },
  data() {
    return {
      isChatLoading: false,
      loadingStatus: { isDone: false, isError: false },
      ticketInfo: {
        messages: []
      },
      updateTimer: null,
      newMessage: '',
      sendButtonDisabled: false
    }
  },
  methods: {
    async closeTicket() {
      try {
        const fetchedData = await axios.post("/main/user/ticketClose", { ticketId: this.$route.params.ticketId });
        if (fetchedData.data.error) return this.$callNotification(this.$i18n.locale, fetchedData);
        this.ticketInfo.isClosed = true;
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async sendMessage() {
      try {
        this.newMessage = this.newMessage.trim();
        if (this.newMessage === '') return;
        this.sendButtonDisabled = true;
        const fetchedData = await axios.post("/main/user/ticketNewMessage", { ticketId: this.$route.params.ticketId, message: this.newMessage });
        if (fetchedData.data.error) return this.$callNotification(this.$i18n.locale, fetchedData);
        this.newMessage = '';
        this.sendButtonDisabled = false;
        this.ticketInfo.messages.push(fetchedData.data.message);
        this.$nextTick(() => this.scrollToChatBottom());
      } catch (error) {
        console.log(error)
        console.log(error)
        this.sendButtonDisabled = false;
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async loadTicket() {
      try {
        if (this.isChatLoading) return;
        this.isChatLoading = true;
        const fetchedData = await axios.post("/main/user/ticketInfo", { ticketId: this.$route.params.ticketId });
        if (fetchedData.data.error) {
          this.clearCurrentInterval();
          return this.loadingStatus.isError = true;
        }
        if (
            this.ticketInfo.messages.length < fetchedData.data.ticketInfo.messages.length &&
            fetchedData.data.ticketInfo.messages.length &&
            fetchedData.data.ticketInfo.messages[fetchedData.data.ticketInfo.messages.length-1].isSupport
        ){
          this.ticketInfo = fetchedData.data.ticketInfo;
          this.$nextTick(() => this.scrollToChatBottom());
        } else {
          this.ticketInfo = fetchedData.data.ticketInfo;
        }
        this.isChatLoading = false;
        this.loadingStatus.isDone = true;
      } catch (e) {
        console.log(e)
        this.clearCurrentInterval();
        this.loadingStatus.isError = true;
      }
    },
    scrollToChatBottom() {
      const el = this.$refs.chatBox;
      if (el) el.scrollTop = el.scrollHeight;
    },
    clearCurrentInterval() {
      if (this.updateTimer) workerTimers.clearInterval(this.updateTimer);
    }
  },
  async mounted() {
    await this.loadTicket();
    this.$nextTick(() => this.scrollToChatBottom());
    document.title = 'Ticket #' + this.ticketInfo.id
    this.updateTimer = workerTimers.setInterval(this.loadTicket, 10000);
  },
  beforeDestroy() {
    this.clearCurrentInterval();
  }
}
</script>

<style lang="scss" scoped>

div.info {
  margin-bottom: 5px;
  font-size: 13px;
}

div.messages-wrapper  {
  height: 500px;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;

  div.messages {
    margin-top: auto;
    padding: 24px;
    width: 100%;

    div.message-wrapper {
      border-radius: 5px;
      padding: 5px 0;
      margin-bottom: 12px;

      div.message {
        position: relative;
        display: flex;
        max-width: 80%;

        &.mr-auto {
          margin-left: auto !important;
          justify-content: end;
          text-align: right;

          span.text {
            background-color: #2A85FF;
            color: white;
          }
        }

        &.ml-auto {
          margin-right: auto !important;
          justify-content: start;
          text-align: left;

          span.text {
            background-color: #e9e9e9;
            color: rgba(0, 0, 0, 0.86);
          }
        }
        span.text {
          display: block;
          padding: 12px 20px;
          position: relative;
          border-radius: 3px;
          order: 2;
          box-shadow: none;
          word-wrap: break-word;
          word-break: break-word;
        }
        span.date {
          display: block;
          color: #878a99 !important;
          font-size: 11px;
          margin-top: 5px;
        }
      }
    }
  }
}

textarea {
  display: block;
  box-sizing: border-box;
  background: transparent;
  border: 1px solid #bebebe;
  border-radius: 2px;
  padding: 7px;
  margin: 5px 0;
  width: 100%;
  outline: none;
  transition: all .2s ease;
}

.answered {
  color: #00871e;
}

.closed {
  color: #d90518;
}

.waiting {
  color: #e9b10f;
}
</style>