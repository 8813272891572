import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import modules from './modules';
import Notifications from 'vue-notification';

Vue.use(Vuex);
Vue.use(Notifications);
const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
  modules,
  strict: debug,
  plugins: debug? [ createLogger() ] : [],
})