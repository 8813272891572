<template>
  <div class="row m-0">
    <div class="row m-0">
      <h3 class="mt-0">Отправить уведомление</h3>
      <InputBox
          label="Заголовок"
          type="text"
          class="col-lg-4"
          :value="newNotificationInfo.title"
          @input="newNotificationInfo.title = $event"
      />
      <div class="form-control col-lg-12">
        <label>Текст</label>
        <textarea v-model="newNotificationInfo.text" style="resize: none;" rows="4"></textarea>
      </div>
      <div style="float: right;" class="col-lg-4 col-md-12 col-xs-12 mb-5">
        <Button
            :is-async="true"
            :callback="createNotificationHandler"
            :is-disabled="newNotificationInfo.title.trim() === '' || newNotificationInfo.text.trim() === ''"
            class="primary w-100"
        >
          Отправить
        </Button>
      </div>
    </div>
    <div class="row m-0">
      <h3 class="mt-0">Уведомления</h3>
      <Table
          ref="notificationsTableComponent"
          parse-url="/backoffice/admin/user/notifications"
          :columnList="notificationsTableColumns"
          :post-data="{ userId }"
          default-sort="_id"
          searchPlaceholderText="Введите заголовок"
          @handle-data="notificationsTableData = $event"
          class="col-lg-12 mb-3 pb-5"
      >
        <tr v-for="notification, index in notificationsTableData" :key="'notification' + index">
          <td style="word-break: break-word;">{{ notification.title }}</td>
          <td style="max-width: 400px; word-break: break-word;">{{ notification.text }}</td>
          <td :class="notification.readState.isRead ? 'color-success' : 'color-danger'">{{ notification.readState.isRead ? 'Прочитано' : 'Не прочитано' }}<br>{{ notification.readState.readDate | toDate }}</td>
          <td class="no-animate" style="text-align: center;">
            <span @click="deleteNotificationHandler(notification._id)" class="delete-btn">
              <font-awesome-icon class="icon" :icon="['fas', 'trash']" />
            </span>
          </td>
        </tr>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from "@/components/backoffice/UI/Table";
import InputBox from "@/components/backoffice/UI/InputBox";
import Button from "@/components/backoffice/UI/Button";
import axios from "@/plugins/axios";

export default {
  name: "UserNotification",
  props: {
    userId: String
  },
  components: {
    Table,
    InputBox,
    Button,
  },
  data() {
    return {
      newNotificationInfo: {
        title: 'New Notification',
        text: ''
      },
      notificationsTableData: [],
      notificationsTableColumns: [
        {
          name: 'title',
          title: 'Заголовок',
          isSortable: true
        },
        {
          name: 'text',
          title: 'Текст',
          isSortable: true
        },
        {
          name: 'readState.isRead',
          title: 'Статус',
          isSortable: true
        },
        {
          name: 'actions',
          title: '',
          isSortable: false
        },
      ]
    }
  },
  methods: {
    async createNotificationHandler() {
      try {
        const fetchedData = await axios.post('/backoffice/admin/user/createNotification', {
          userId: this.userId,
          notificationInfo: this.newNotificationInfo
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.notificationsTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    },
    async deleteNotificationHandler(notificationId) {
      try {
        const fetchedData = await axios.post('/backoffice/admin/user/deleteNotification', {
          userId: this.userId,
          notificationId
        })
        this.$callNotification(this.$i18n.locale, fetchedData);
        if (!fetchedData.data.error) this.$refs.notificationsTableComponent.fetchTableData();
      } catch (e) {
        console.log(e)
        this.$callNotification(this.$i18n.locale, e);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

h3 {
  font-weight: 500;
  padding-left: 15px;
}

span.delete-btn {
  text-align: center;
  cursor: pointer;

  .icon {
    transition: .1s;
  &:hover {
     color: #f06548;
   }
  }
}
</style>