<i18n>
{
  "en": {
    "amountCurrency": "Amount {0}",
    "availableBalance": "Available balance:",
    "tableColumns": {
      "currency": "Currency",
      "lockedAmount": "Locked Amount",
      "earned": "Earned",
      "date": "Date"
    },
    "tablePlaceholder": "Search by currency...",
    "tableTitle": "My staking",
    "unstakingWarning": "Three days have not passed since the start of staking. When unstaking, the locked amount will be returned with a commission of 0.1%. Do you agree?",
    "lockedStaking": "Locked Staking",
    "token": "Token",
    "apy": "Est. APY",
    "minLockedAmount": "Minimum Locked Amount",
    "perYear": "Per Year",
    "perMonth": "Per Month",
    "perDay": "Per Day",
    "stake": "Stake Now",
    "unstake": "Unstake"
  },
  "zh": {
    "amountCurrency": "金额 {0}",
    "availableBalance": "可用余额：",
    "tableColumns": {
      "currency": "货币",
      "lockedAmount": "锁定金额",
      "earned": "赚",
      "date": "日期"
    },
    "tablePlaceholder": "按货币搜索...",
    "tableTitle": "我的质押",
    "unstakingWarning": "自 Staking 开始以来还没有过去三天。 解除质押时，将返还锁定的金额并收取0.1％的佣金。 你同意？",
    "lockedStaking": "锁定质押",
    "token": "令牌",
    "apy": "估计 APY",
    "minLockedAmount": "最小锁定金额",
    "perYear": "每年",
    "perMonth": "每月",
    "perDay": "每天",
    "stake": "立即质押",
    "unstake": "取消抵押"
  },
  "kr": {
    "amountCurrency": "금액 {0}",
    "availableBalance": "사용 가능한 잔액:",
    "tableColumns": {
      "currency": "통화",
      "lockedAmount": "잠금 금액",
      "earned": "얻었다",
      "date": "날짜"
    },
    "tablePlaceholder": "통화로 검색...",
    "tableTitle": "내 스테이킹",
    "unstakingWarning": "스테이킹 시작 후 3일이 지나지 않았습니다. 언스테이킹 시 잠금 금액이 0.1%의 수수료와 함께 반환됩니다. 동의하십니까?",
    "lockedStaking": "잠긴 스테이킹",
    "token": "토큰",
    "apy": "APY 예상",
    "minLockedAmount": "최소 잠금 금액",
    "perYear": "연간",
    "perMonth": "월별",
    "perDay": "일당",
    "stake": "지금 스테이크",
    "unstake": "언스테이크"
  }
}
</i18n>
<template>
  <Page :is-page-loaded="dataLoadingStatus" class="dashboard">
    <PageContainer :no-line="true" v-if="dataLoadingStatus.isDone">
      <div class="container">
        <h2>{{ $t('lockedStaking') }}</h2>
        <div class="staking-table">
          <div class="header">
            <div class="td">{{ $t('token') }}</div>
            <div class="td">{{ $t('apy') }}</div>
            <div class="td">{{ $t('minLockedAmount') }}</div>
            <div class="td"></div>
          </div>

          <div class="body">
            <div :id="'plan_' + index" class="tr" v-for="plan, index in stakingPlans" :key="index">
              <div @click="openPlan($event, 'plan_' + index)" class="preview">
                <div class="td">
                  <div style="align-items: center; display:flex; width: 16px; height: 16px;">
                    <img style="margin-right: 5px; width: 100%; height: 100%;" :src="'/currencies/'+plan.currency+'.png'" alt="">
                    {{ plan.currency }}
                  </div>
                </div>
                <div class="td" style="color: rgb(14, 203, 129);">{{ plan.farmRate.toFixed(2) }}%</div>
                <div class="td">{{ plan.minimalAmount }} {{ plan.currency }}</div>
                <div class="td" style="text-align: right;"> <div class="chevron"><i class="fas fa-chevron-down"></i></div> </div>
              </div>
              <div class="information">
                <div class="data">
                  <div style="margin-right: 25px;">
                    <span style="cursor: pointer;" @click="formControls['amountInput_' + index].value = balance[plan.currency].fundingWallet">{{ $t('availableBalance') }} {{ balance[plan.currency].fundingWallet.toFixed(6) }} {{ plan.currency }}</span>
                    <InputBox
                        :type="formControls['amountInput_' + index].type"
                        :label="$t('amountCurrency', [plan.currency])"
                        :validation="formControls['amountInput_' + index].validation"
                        v-model="formControls['amountInput_' + index].value"
                        @check-valid="formControls['amountInput_' + index].isValid = $event"
                        :error-message="$t(formControls['amountInput_' + index].errorMessage)"
                        :style="formControls['amountInput_' + index].style"
                    />
                  </div>
                  <div>
                    <span>{{ $t('perYear') }}: {{ calculateProfit(formControls['amountInput_' + index].value, plan.farmRate).year }} {{ plan.currency }}</span><br>
                    <span>{{ $t('perMonth') }}: {{ calculateProfit(formControls['amountInput_' + index].value, plan.farmRate).month }} {{ plan.currency }}</span><br>
                    <span>{{ $t('perDay') }}: {{ calculateProfit(formControls['amountInput_' + index].value, plan.farmRate).day }} {{ plan.currency }}</span>
                  </div>
                  <div style="margin-left: auto;">
                    <button class="stake-btn" @click="startStake(plan.currency, index)">{{ $t('stake') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <h2>{{ $t('tableTitle') }}</h2>
        <OnlineTable
            parse-url="/main/user/stakings"
            :column-list="tableColumns"
            default-sort="date"
            :search-placeholder-text="$t('tablePlaceholder')"
            @handle-data="tableData = $event"
        >
          <tr v-for="data, index in tableData" :key="index">
            <td>
              <div style="display: flex; align-items: center; width: 60px;">
                <div style="width: 16px; height: 16px; margin-right: 5px; min-width: 16px;">
                  <img style=" width: 100%; height: 100%; vertical-align: unset;" :src="$coinImage(data.currency)" alt="">
                </div>
                {{ data.currency }}
              </div>
            </td>
            <td>{{ data.lockedAmount.toFixed(6) }}</td>
            <td><staking :startDate="data.date" :locked-amount="data.lockedAmount" :earned="data.earned" :farm-rate="data.farmRate" :fixed="10" /></td>
            <td style="min-width: 200px;">{{ data.date | toDate }}</td>
            <td><button class="stake-btn" @click="unstake(data._id, data.isCommissionRequired)">{{ $t('unstake') }}</button></td>
          </tr>
        </OnlineTable>
      </div>
    </PageContainer>
  </Page>
</template>

<script>
import PageContainer from "@/components/main/UI/PageContainer";
import Page from "@/components/main/UI/Page";
import axios from "@/plugins/axios";
import OnlineTable from "@/components/main/UI/OnlineTable";
import Button from "@/components/main/UI/Button";
import InputBox from "@/components/main/UI/InputBox";
import staking from "@/components/main/UI/staking";

export default {
  name: "Staking",
  components: {
    PageContainer,
    Page,
    OnlineTable,
    InputBox,
    staking
  },
  data() {
    return {
      formControls: {},
      tableData: [],
      tableColumns: [
        {
          name: 'currency',
          title: this.$t('tableColumns.currency'),
          isSortable: true
        },
        {
          name: 'lockedAmount',
          title: this.$t('tableColumns.lockedAmount'),
          isSortable: true
        },
        {
          name: 'earned',
          title: this.$t('tableColumns.earned'),
          isSortable: true
        },
        {
          name: 'date',
          title: this.$t('tableColumns.date'),
          isSortable: true
        },
        {
          name: '',
          title: '',
          isSortable: false
        }
      ],
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      stakingPlans: [],
      balance: {}
    }
  },
  methods: {
    async unstake(id, fee) {
      try {
        if (fee) {
          const warning = confirm(this.$t('unstakingWarning'));
          if (!warning) return;
        }
        const fetchedData = await axios.put('/main/user/staking', {
          id
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (!fetchedData.data.error) return location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    async startStake(currency, index) {
      try {
        const amount = this.formControls['amountInput_' + index];
        if (!amount.isValid || amount.value === '') return
        const fetchedData = await axios.post('/main/user/staking', {
          currency,
          amount: amount.value
        });
        this.$callNotification(
            this.$i18n.locale,
            fetchedData
        );
        if (!fetchedData.data.error) return location.reload();
      } catch (error) {
        console.log(error)
        this.$callNotification(this.$i18n.locale, error);
      }
    },
    createInputs() {
      this.stakingPlans.map((x, index) => {
        this.$set(this.formControls, 'amountInput_' + index, {
          value: 0,
          type: 'number',
          label: 'tableColumns.amountUsd',
          isValid: false,
          validation: {
            required: true
          }
        });
      })
    },
    calculateProfit(value, percent) {
      return {
        year: (percent / 1 * value / 100).toFixed(6),
        month: (percent / 12 * value / 100).toFixed(6),
        day: (percent / 365 * value / 100).toFixed(6)
      }
    },
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.$callNotification(this.$i18n.locale, { type: 'info', message: this.$t('copied') })
    },
    openPlan(event, id) {
      document.getElementById(id).classList.toggle('open');
    },
    hideUserEmail(email) {
      return email.slice(0, 3) + "***" + email.slice(email.indexOf("@"), email.length);
    }
  },
  async mounted() {
    try {
      let fetchedData = await axios.get('main/user/staking');
      if (fetchedData.data.error) {
        this.dataLoadingStatus.isDone = true;
        this.dataLoadingStatus.isError = true;
        return;
      }
      this.stakingPlans = fetchedData.data.stakingPlans;
      this.balance = fetchedData.data.balance;
      this.createInputs();
      this.dataLoadingStatus.isDone = true;
    } catch (e) {
      console.log(e)
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>

.dark {
  .staking-table .header {
    background-image: initial;
    background-color: rgb(31, 33, 33);
    border-color: rgb(52, 56, 58);
  }
  .staking-table .body {
    border-left-color: rgb(52, 56, 58);
    border-right-color: rgb(52, 56, 58);
  }
  .staking-table .body div.tr div.preview div.td {
    color: rgb(144, 136, 125);
  }
  .staking-table .body div.tr .information {
    border-top-color: transparent;
    background-image: initial;
    background-color: rgb(31, 33, 33);
    color: rgb(144, 136, 125);
  }
  .staking-table .body div.tr {
    border-bottom: 1px solid rgb(52, 56, 58);
  }
  .staking-table .body div.tr.open .information {
    height: auto;
    padding: 15px 16px;
    border-top: 1px solid rgb(52, 56, 58);
  }
}

.staking-table {

  margin-bottom: 40px;

  .header {
    display: flex;
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    padding: 5px 16px;

    div.td {
      color: rgb(112, 122, 138);
      font-size: 14px;
      width: 25%;
    }
  }

  .body {
    border-left: 1px solid #F5F5F5;
    border-right: 1px solid #F5F5F5;

    div.tr {
      border-bottom: 1px solid rgb(245, 245, 245);

      div.preview {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 24px 16px;

        div.td {
          color: rgb(112, 122, 138);
          font-size: 14px;
          width: 25%;

          .chevron {
            transition: .3s;
          }
        }

      }

      .information {
        height: 0;
        width: 100%;
        transition: .3s;
        border-top: 0 solid transparent;
        padding: 0 16px;
        background: #FAFAFA;
        color: #707a8a;

        .data {
          display: none;
          align-items: center;
        }
      }

      &.open {

        .chevron {
          transform: rotateX(180deg);
        }
        .information {
          height: auto;
          padding: 15px 16px;
          border-top: 1px solid rgb(245, 245, 245);

          .data {
            display: flex;
            font-size: 12px;
          }
        }
      }
    }
  }

}

.stake-btn {
  border: 1px solid transparent;
  background: #716fb2;
  color: white;
  font-size: 12px;
  transition: .3s;

  &:hover {
    border-color: #1E2026;
  }
}

</style>