<template>
  <div class="p-4">
    <div style="position:relative;" class="">
      <div style="margin-bottom: 15px;">
        <div style="position:relative; display: flex; justify-content: space-between;">
          <div class="form-control d-flex mb-0">
            <div>
              <button @click="fetchTableData" :disabled="this.tableStatus.isLoading" style="border-radius: 4px; padding: 8px 14px; outline: none; color: #ced4da; background-color: #262a2f; border: 1px solid #2a2f34;" class="mr-2">
                <font-awesome-icon
                    :icon="['fal', 'redo']"
                />
              </button>
            </div>
            <div>
              <select style="width: auto !important;" class="mr-auto" v-model="limitQuery">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>


          <div style="position:relative;" v-if="!noSearch">
            <div class="search-input form-control" :class="{ mobile: $store.state.Index.isMobile }">
              <input style="padding-left: 40px;" v-model.lazy="searchQuery" type="text" :placeholder="searchPlaceholderText">
              <div class="placeholder">
                <font-awesome-icon
                    :icon="['fal', 'search']"
                />
              </div>
              <font-awesome-icon
                  :icon="['fal', 'times']"
                  @click="searchQuery = ''"
                  v-show="searchQuery"
                  class="clear_search"
              />
            </div>
          </div>
        </div>

        <span @click="clearSort" class="toggle_filters" v-show="sortQuery && sortQuery !== defaultSort">
          <span style="margin-right: 5px;">Сбросить сортировку</span>
          <font-awesome-icon
              :icon="['fal', 'times']"
              size="sm"
          />
        </span>
      </div>

    </div>
    <div>

      <div style="overflow-x: auto;" :class="{ empty: tableStatus.isLoading || tableStatus.isError || tableDataLength === 0 }" class="Table table_div p-0">

        <div :style="{ height: tableEmptyBlockHeight+'px' }" class="empty" v-show="tableStatus.isLoading && !tableStatus.isError">Загрузка данных...</div>
        <div :style="{ height: tableEmptyBlockHeight+'px' }" class="empty" v-show="!tableStatus.isLoading && tableStatus.isError">Ошибка загрузки данных. Обновите страницу.</div>
        <div :style="{ height: tableEmptyBlockHeight+'px' }" class="empty" v-show="!tableStatus.isLoading && !tableStatus.isError && tableDataLength === 0">Нет данных</div>

        <table class="table" v-show="!tableStatus.isLoading && !tableStatus.isError && tableDataLength > 0">

          <thead>

          <tr>
            <th @click="column.isSortable ? changeSort(column) : null" :class="[column.classes, column.isSortable ? 'animated' : '' ]" v-for="column, index in columnList" :key="index">{{ column.title }}
              <font-awesome-icon
                  :icon="['fas', 'sort-up']"
                  @click="searchQuery = ''"
                  v-show="sortQuery === column.name"
                  class="arrow"
                  :class="ascendingQuery ? 'up' : 'down'"
              />
            </th>
          </tr>

          </thead>

          <tbody>

          <slot></slot>

          </tbody>

        </table>

      </div>

      <div style="margin-top: 10px; display: flex; justify-content: space-between; align-items: center;" v-show="tableDataLength > 0">
        <span style="color: #757d91; font-size: 11.3833px;">{{ `${(pageQuery-1)*limitQuery+1} - ${(pageQuery-1)*limitQuery+tableDataLength} из ${totalDocumentsCount} элементов` }}</span>
        <ul class="pagination justify-content-end">
          <button @click="changePage(pageQuery-1)" :class="{ disabled: pageQuery === 1 }" class="page-item">
            ❮
          </button>
          <button @click="changePage(item)" :class="{ currentPage: item === pageQuery }" class="page-item" v-for="item, index in paginationButtons" :key="index">{{ item }}</button>
          <button @click="changePage(pageQuery+1)" :class="{ disabled: pageQuery === totalPages }" class="page-item">
            ❯
          </button>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "SpammerTable",
  props: {
    parseUrl: String,
    columnList: Array,
    noSearch: Boolean,
    postData: Object,
    defaultSort: String,
    searchPlaceholderText: String
  },
  data() {
    return {
      tableStatus: {
        isLoading: true,
        isError: false
      },
      totalPages: 0,
      tableDataLength: 0,
      totalDocumentsCount: 0,
      searchQuery: '',
      sortQuery: this.defaultSort || '',
      ascendingQuery: true,
      limitQuery: 10,
      pageQuery: 1
    }
  },
  watch: {
    searchQuery(newVal, oldVal) {
      if (newVal == oldVal) return;
      this.pageQuery = 1;
      this.fetchTableData();
    },
    limitQuery(newVal, oldVal) {
      if (newVal == oldVal) return;
      this.pageQuery = 1;
      this.fetchTableData();
    }
  },
  methods: {
    clearSort() {
      if (this.sortQuery === '') return;
      this.sortQuery = this.defaultSort;
      this.ascendingQuery = true;
      this.pageQuery = 1;
      this.fetchTableData();
    },
    changeSort(column) {
      this.sortQuery = column.name;
      this.ascendingQuery = !this.ascendingQuery;
      this.pageQuery = 1;
      this.fetchTableData();
      console.log(this.sortQuery)
    },
    changePage(page) {
      if (!Number.isInteger(page)) return;
      if (this.pageQuery === page) return;
      if (page < 1 || page > this.totalPages) return;
      this.pageQuery = page;
      this.fetchTableData();
    },
    async fetchTableData() {
      this.tableStatus.isLoading = true;
      try {
        let tableData = await axios[this.postData ? 'post' : 'get'](`${this.parseUrl}?searchQuery=${this.searchQuery}&sortQuery=${this.sortQuery}&limitQuery=${this.limitQuery}&pageQuery=${this.pageQuery}&ascendingQuery=${this.ascendingQuery}`, this.postData);
        this.tableDataLength = tableData.data.documents.length;
        this.totalDocumentsCount = tableData.data.totalDocumentsCount;
        this.totalPages = Math.ceil(tableData.data.totalDocumentsCount / this.limitQuery);
        this.$emit('handle-data', tableData.data.documents);
        this.tableStatus.isLoading = false;
      } catch (e) {
        console.log(e)
        this.tableStatus.isLoading = false;
        this.tableStatus.isError = true;
      }
    },
    async reloadData(event) {
      event.target.disabled = true;
      await this.fetchTableData();
      event.target.disabled = false;
    }
  },
  computed: {
    paginationButtons() {
      let total = Array.from({length: this.totalPages}, (_, i) => i + 1);
      const step = 4;
      if (this.pageQuery < step) {
        total = total.slice(0, step)
        if (this.totalPages >= step + 1) {
          total.push('...')
          total.push(this.totalPages)
        }
      } else if ((this.pageQuery >= step && this.pageQuery + step <= this.totalPages) || (this.pageQuery + step - 1 === this.totalPages)) {
        total = total.slice(total.indexOf(this.pageQuery) - 1, total.indexOf(this.pageQuery) + step - 2)
        total.unshift('...')
        total.unshift(1)
        total.push('...')
        total.push(this.totalPages)
      } else if (this.totalPages <= step) {
        total = total.slice(0, this.totalPages)
      } else {
        total = total.slice(total.indexOf(this.totalPages - step + 1), this.totalPages)
        total.unshift('...')
        total.unshift(1)
      }
      return total
    },
    tableEmptyBlockHeight() {
      return 65 + 52.5 + (this.tableDataLength * 53);
    }
  },
  mounted() {
    this.fetchTableData();
  }
}
</script>

<style lang="scss" scoped>

@media(max-width: 1670px) {
  th { min-width: 170px; }
}

.table_div.empty {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

table {

  width: 100%;
  font-weight: 400;

  thead {
    border-top: 1px solid #32383E;
    border-bottom: 1px solid #32383E;

    th {
      border-left: 1px solid #32383E;
      border-right: 1px solid #32383E;
      padding: 10px 10px !important;
      color: rgba(10,15,56,.6);
      font-size: 14px;
      vertical-align: middle;
      text-align: left;
      transition: .1s;

      &.animated {
        cursor: pointer;

        &:hover {
          background: #2B3035;
        }
      }
    }

  }

  tbody {

    tr {
      border-top: 1px solid #32383E;
      border-bottom: 1px solid #32383E;

      td {
        padding: 10px 10px !important;
        border-left: 1px solid #32383E;
        border-right: 1px solid #32383E;

        &.animated {
          cursor: pointer;

          &:hover {
            background: #2B3035;
          }
        }
      }
    }

  }

}

td i {
  transition: .3s;
  cursor: pointer;
}

td i.fa-wallet:hover {
  color: #eed48b;
}

td i.fa-user-edit:hover {
  color: #6aa7e9;
}

td i.fa-list-alt:hover {
  color: #67d8eb;
}

td i.fa-user-slash:hover {
  color: #f16572;
}


.arrow {
  float: right;
  transition: .3s;
}

.arrow.up {
  transform: rotate(180deg);
}

.clear_search.fa-times {
  background: #262A2F;
  position: absolute;
  right: 5px;
  top: 12px;
  transition: .3s;
  cursor: pointer;
}

.clear_search.fa-times:hover {
  color: #f16572;
}

.toggle_filters {
  color: #f16572;
  font-size: 12px;
  cursor: pointer;
}

.toggle_filters i {
  position: relative;
  top: 1px;
}

.warn_twins {
  font-size: 12px;
}

ul {
  list-style-type: none;

  li {
    display: inline-block;
  }
}

.currentPage {
  background: #405189 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: white;
}

.page-item {
  cursor: pointer;
  font-size: 14px;
  border-radius: .125rem;
  position: relative;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  border: none;
  background: none;
}

.page-link:hover {
  color: white;
}

.page-item.disabled .page-link {

}

.search-input {
  position: relative;
  display: inline-block;
  width: 380px;

  &.mobile {
    width: 214px;
  }
}

.search-input .placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
}

.search-input input {
  width: 100%;
  height: 100%;
  padding: 14px 16px 14px calc(14px + 20px + 10px);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #0a0f38;
  font-weight: 500;
  border: none;
  background-color: #edf0f5;
  border-radius: 2px;
  outline: 0;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out;
  transition: .25s ease-out;
}

</style>
